import { get, post, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addCoupon(data) {
    return post('/stripe/coupons', data);
}

export function getCoupons(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/stripe/coupons' + _query);
}

export function removeCoupon(id) {
    return remove('/stripe/coupons', { id: id });
}
