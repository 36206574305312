import store from '@/store/index';
import { ToastProgrammatic as Toast } from 'buefy';
import router from '@/router/index';

export default {
    computed: {
        getUser() {
            return store.getters['auth/getUser'];
        },
        getToken() {
            return store.getters['auth/getToken'];
        },
    },
    methods: {
        addUser(user) {
            store.commit('auth/addUser', user);
        },
        addToken(token) {
            store.commit('auth/setToken', token);
        },
        onSessionExpired() {
            Toast.open({
                message: 'Sessão expirada, repita o processo!',
                type: 'is-secondary',
                position: 'is-bottom',
            });
            store.commit('auth/resetStore');
            store.commit('auth/setToken', null);
            store.commit('trackerSteps/resetTracker', 0);
            store.commit('subscriptionSteps/resetSubscription', 0);
            store.commit('mySpotSteps/resetMySpot', 0);
            store.commit('subscriptionSteps/setSubscriptionStep', 0);
            localStorage.removeItem('vuex');
            router.push('/');
        },
        getGender(g) {
            return this.$t('shared.genders').find((gender) => gender.value == g);
        },
    },
};
