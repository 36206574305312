<template>
    <section>
        <TopMenu />
        <CategoryMenu />

        <div class="logo-container">
            <img
                class="logo"
                src="../../assets/icons/coracao_verde.png"
                alt="" />
        </div>

        <div class="main">
            <div class="card mt-4">
                <p
                    class="title has-text-centered"
                    :style="{ 'font-family': this.changeFont }">
                    {{ t.giftCard }}
                </p>
                <p
                    class="subtitle has-text-centered mt-2"
                    :style="{ 'font-family': this.changeFont }">
                    {{ t.surprise }}
                </p>

                <div class="media">
                    <div class="media-left">
                        <b-input
                            :class="{ 'error-text': errors.has('from') }"
                            class="has-text-centered ml-3 field"
                            type="text"
                            v-validate="'required'"
                            name="from"
                            v-model="from"
                            size="is-small"
                            ref="from"
                            :placeholder="t.from" />
                        <p
                            v-show="errors.has('from')"
                            class="help is-danger has-text-centered">
                            Name
                        </p>

                        <b-input
                            :class="{ 'error-text': errors.has('from') }"
                            class="has-text-centered ml-3 mt-8 field"
                            type="text"
                            v-validate="'required'"
                            name="to"
                            v-model="to"
                            size="is-small"
                            ref="to"
                            :placeholder="t.to" />
                        <p
                            v-show="errors.has('to')"
                            class="help is-danger has-text-centered">
                            Name
                        </p>

                        <b-select
                            class="field mt-8 ml-3"
                            :placeholder="t.amount"
                            :class="{ 'error-text': errors.has('amount') }"
                            v-validate="'required'"
                            size="is-small"
                            name="amount"
                            v-model="amount"
                            expanded>
                            <option :value="10">10</option>
                        </b-select>
                    </div>
                    <div class="media-content"></div>
                    <div class="media-right">
                        <b-input
                            :class="{ 'error-text': errors.has('fromEmail') }"
                            class="has-text-centered field mr-3"
                            type="text"
                            v-validate="'required'"
                            name="fromEmail"
                            v-model="fromEmail"
                            size="is-small"
                            ref="fromEmail"
                            :placeholder="t.email" />
                        <p
                            v-show="errors.has('fromEmail')"
                            class="help is-danger has-text-centered">
                            Name
                        </p>

                        <b-input
                            :class="{ 'error-text': errors.has('toEmail') }"
                            class="has-text-centered field mr-3 mt-8"
                            type="text"
                            v-validate="'required'"
                            name="toEmail"
                            v-model="toEmail"
                            size="is-small"
                            ref="toEmail"
                            :placeholder="t.email" />
                        <p
                            v-show="errors.has('toEmail')"
                            class="help is-danger has-text-centered">
                            Name
                        </p>

                        <b-select
                            class="field mt-8 mr-3"
                            :placeholder="t.product"
                            :class="{ 'error-text': errors.has('product') }"
                            v-validate="'required'"
                            size="is-small"
                            name="product"
                            v-model="product"
                            expanded>
                            <option value="Livro">Livro</option>
                        </b-select>
                    </div>
                </div>

                <b-field class="mt-8 ml-3">
                    <b-input
                        class="message-area mb-6"
                        type="textarea"
                        size="is-small"
                        v-validate="'required'"
                        name="message"
                        v-model="message"
                        :placeholder="t.message"></b-input>
                </b-field>
            </div>

            <div class="credit-card mb-3 mt-6">
                <b-input
                    :class="{ 'error-text': errors.has('toEmail') }"
                    class="has-text-centered card-name px-5 mt-5"
                    type="text"
                    v-validate="'required'"
                    name="toEmail"
                    v-model="toEmail"
                    size="is-small"
                    ref="toEmail"
                    :placeholder="t.cardName" />
                <div
                    id="card-number"
                    class="px-5 mt-5"></div>

                <div
                    id="card-expiry"
                    class="credit-card-field mr-3 pl-5"></div>

                <div
                    id="card-cvc"
                    class="credit-card-field pl-5"></div>

                <div id="card-error"></div>
                <div id="card-name"></div>

                <b-button class="buy mr-5">{{ t.buy }}</b-button>
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import CategoryMenu from '@/components/MyShop/CategoryMenu.vue';
    export default {
        components: {
            TopMenu,
            CategoryMenu,
        },
        data() {
            return {
                t: this.$t('components.MyShop.GiftCard'),
                from: null,
                to: null,
                fromEmail: null,
                toEmail: null,
                amount: null,
                message: null,
                product: null,
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
                cardName: null,
                windowHeight: 0,
            };
        },

        computed: {
            stripeElements() {
                return this.$stripe.elements();
            },
        },

        methods: {
            handleResize() {
                this.windowHeight = window.innerHeight;
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },
        created() {
            this.scrollToTop();
        },

        mounted() {
            const style = {
                base: {
                    color: 'black',
                    fontFamily: '"Lota Grotesque"',
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                        color: '#C2C2C2',
                    },
                    backgroundColor: 'white',
                    lineHeight: '21px',
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            };

            this.cardNumber = this.stripeElements.create('cardNumber', { style });
            this.cardNumber.mount('#card-number');
            this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
            this.cardExpiry.mount('#card-expiry');
            this.cardCvc = this.stripeElements.create('cardCvc', { style });
            this.cardCvc.mount('#card-cvc');
        },

        beforeDestroy() {
            this.cardNumber.destroy();
            this.cardExpiry.destroy();
            this.cardCvc.destroy();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .logo-container {
        display: flex;
        justify-content: center;
        padding-top: 210px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 130px;
        }
    }

    .logo {
        height: 30px;
    }

    .card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        margin-bottom: 60px;
        padding: 1rem;
        @include from($tablet) {
            max-width: $card-max-width;
            margin: 0 auto;
        }
    }

    .title {
        font-family: 'Borest-Standard';
        font-size: 3.125rem;
        font-weight: normal;
    }

    .subtitle {
        font-family: 'Borest-Standard';
        font-size: 1.5rem;
        font-weight: normal;
    }

    .field {
        width: 120px;
    }

    .credit-card-field {
        width: 100px;
        margin-top: 20px;
    }

    .message-area {
        width: 270px;
    }

    .credit-card {
        background-color: $grey-variant;
        height: 250px;
        border-radius: 48px;
        padding: 1rem;
        @include from($tablet) {
            max-width: $card-max-width;
            margin: 0 auto;
        }
    }

    /deep/ .card-name input {
        height: 21px !important;
    }

    .buy {
        width: 76px;
        height: 24px;
        font-size: 0.7rem;
        font-family: 'Lota Grotesque';
        background-color: #bfd9d1;
        color: white;
        border: none;
        outline: none;
        cursor: pointer;
        transform: translate(0, -23px);
        float: right;
    }
</style>
