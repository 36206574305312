<template>
    <section>
        <div class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-card">
                <section class="modal-card-body">
                    <button
                        @click="closeModal()"
                        class="delete"
                        aria-label="close"></button>
                    <p class="modal-card-title title has-text-primary">
                        <b>{{ t.title }} #{{ gift }}</b>
                    </p>
                    <br />
                    <div class="field email-field">
                        <label class="label">{{ t.status }}</label>
                        <div class="select control">
                            <select
                                v-validate="'required'"
                                v-model="request"
                                name="status">
                                <option
                                    value="false"
                                    disabled>
                                    {{ t.unused }}
                                </option>
                                <option value="true">{{ t.used }}</option>
                            </select>
                        </div>
                        <p
                            v-show="errors.has('status')"
                            class="help is-danger">
                            {{ t.error }}
                        </p>
                    </div>

                    <button
                        class="button is-rounded is-primary is-fullwidth"
                        :class="{ 'is-loading': isButtonLoading }"
                        @click="updateTicket">
                        Guardar
                    </button>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
    import { updateGift } from '@/api/gifts';
    export default {
        name: 'Modal',
        props: ['id', 'gift', 'status'],
        data() {
            return {
                t: this.$t('components.BackOffice.Gifts.Modal'),
                isButtonLoading: false,
                request: this.status,
                requestDescription: '',
                inputError: false,
            };
        },
        methods: {
            closeModal(event) {
                this.$emit('clicked', false);
            },
            updateTicket() {
                updateGift({
                    id: this.id,
                    used: this.request,
                })
                    .then((response) => {
                        this.closeModal();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .auth-div {
        bottom: 5%;
    }

    .field {
        margin-bottom: 0;
    }

    .email-field {
        position: relative;
        margin-bottom: 40px;
    }

    .button {
        margin-top: 40px;
    }

    .next-div {
        margin-top: 25px;
    }

    .register-steps > .step-1 {
        background: #1d4696;
    }

    .select-origin {
        color: #bac3dc !important;
    }

    .select,
    select {
        width: 100% !important;
    }

    .modal-card {
        bgift-radius: 20px;
    }

    .modal-card-body {
        min-height: 20rem;
    }

    .delete {
        float: right;
    }

    textarea {
        resize: none;
        max-height: 80px;
    }
</style>
