<template>
    <section>
        <TopMenu />
        <SavedCards v-if="getStep == 0" />
        <AddCard v-else />
        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import SavedCards from '@/components/Payments/SavedCards.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    import AddCard from '@/components/Payments/AddCard.vue';

    export default {
        components: {
            TopMenu,
            SavedCards,
            BotMenu,
            AddCard,
        },
        data() {
            return {
                loginStep: null,
            };
        },

        computed: {
            getStep() {
                return this.$store.getters['paymentSteps/getPaymentStep'];
            },
        },
    };
</script>

<style lang="scss" scoped></style>
