<template>
    <div class="tracker mt-3 mb-3">
        <div class="has-text-centered">
            <div
                class="button btn-tracker is-rounded mt-2 is-clickable"
                @click="goToTracker(0)">
                Tracker
            </div>
        </div>
        <div class="icons px-6 mt-3">
            <div @click="goToTracker(0)">
                <b-icon
                    pack="bl-icons"
                    icon="bl-meal"
                    type="is-white"
                    class="is-size-4">
                </b-icon>
            </div>
            <div @click="goToTracker(1)">
                <b-icon
                    pack="bl-icons"
                    icon="bl-apple"
                    type="is-white"
                    class="is-size-4">
                </b-icon>
            </div>
            <div @click="goToTracker(2)">
                <b-icon
                    pack="bl-icons"
                    icon="bl-broccoli"
                    type="is-white"
                    class="is-size-4">
                </b-icon>
            </div>
            <div @click="goToTracker(3)">
                <b-icon
                    pack="bl-icons"
                    icon="bl-medicine"
                    type="is-white"
                    class="is-size-4">
                </b-icon>
            </div>
        </div>
        <div class="icons px-6 mt-6">
            <div @click="goToTracker(4)">
                <b-icon
                    pack="bl-icons"
                    icon="bl-cup"
                    type="is-white"
                    class="is-size-4">
                </b-icon>
            </div>
            <div @click="goToTracker(5)">
                <b-icon
                    pack="bl-icons"
                    icon="bl-bed"
                    type="is-white"
                    class="is-size-6">
                </b-icon>
            </div>
            <div @click="goToTracker(6)">
                <b-icon
                    pack="bl-icons"
                    icon="bl-legumes"
                    type="is-white"
                    class="is-size-4">
                </b-icon>
            </div>
            <div @click="goToTracker(7)">
                <b-icon
                    pack="bl-icons"
                    icon="bl-sport"
                    type="is-white"
                    class="is-size-4">
                </b-icon>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        methods: {
            goToTracker(step) {
                this.updateTrackerStep(step);
                this.goToPage('Tracker');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .tracker {
        background-color: $primary;
        height: 172px;
        width: 100%;
        border-radius: 36px;
        @include from($mobile-l) {
            max-width: $card-max-width;
            margin: 0 auto;
        }
    }

    .btn-tracker {
        color: white;
        background-color: $primary;
        border-color: white;
        font-size: 14px;
        cursor: default;
    }

    .btn-tracker:hover {
        color: white;
        background-color: $primary;
        border-color: white;
    }

    .icons {
        display: flex;
        justify-content: space-between;
    }

    .icons div {
        cursor: pointer;
    }

    @media only screen and (max-width: 350px) {
        .btn-tracker {
            font-size: 13px;
        }
    }
</style>
