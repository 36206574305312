<template>
    <div id="app">
        <section>
            <Loading />
            <router-view :key="$route.fullPath" />
            <PWAPrompt
                copyClosePrompt="OK"
                :copyTitle="$t('views.App.add')"
                :copyBody="$t('views.App.promptBody')"
                :copyShareButtonLabel="$t('views.App.shareButton')"
                :copyAddHomeButtonLabel="$t('views.App.addButton')" />
        </section>
    </div>
</template>
<script>
    import Loading from '@/components/Loading/Loading.vue';
    import PWAPrompt from 'vue2-ios-pwa-prompt';

    export default {
        name: 'App',

        components: {
            Loading,
            PWAPrompt,
        },

        created() {
            addEventListener('resize', () => {
                this.updateWidth(innerWidth);
            });
        },
    };
</script>
<style lang="scss">
    @import './assets/scss/app';

    @font-face {
        font-family: 'Borest-Standard';
        src:
            local('Borest-Standard'),
            url(./fonts/Borest-Standard.ttf) format('truetype');
    }

    @font-face {
        font-family: 'Lota Grotesque';
        src:
            local('Lota-Grotesque-Regular'),
            url(./fonts/Lota-Grotesque-Regular.otf) format('truetype');
    }

    @font-face {
        font-family: 'Lota Grotesque Bold';
        src:
            local('Lota Grotesque Alt 1 Bold'),
            url(./fonts/Lota-Grotesque-Regular.otf) format('truetype');
    }

    @font-face {
        font-family: 'Reftograph';
        src:
            local('Reftograph'),
            url(./fonts/Reftograph.ttf) format('truetype');
    }
</style>
