<template>
    <div class="components-backoffice-navbar">
        <nav
            v-if="isMobile"
            class="navbar is-fixed-top navbar-home"
            role="navigation"
            aria-label="main navigation"
            type="is-light">
            <div class="navbar-brand">
                <a
                    role="button"
                    @click.prevent="setSidebarOpen(true)"
                    class="navbar-burger has-text-white"
                    aria-label="menu"
                    aria-expanded="false">
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                    <span aria-hidden="true"></span>
                </a>
                <a class="navbar-item">
                    <img
                        :src="require('@/assets/icons/coracao_verde.png')"
                        alt="" />
                </a>
            </div>
        </nav>
    </div>
</template>

<script>
    export default {
        name: 'Navbar',
        data() {
            return {};
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .navbar-brand {
        .navbar-item {
            img {
                max-height: 2.75rem;
            }
        }
        .navbar-item:nth-of-type(2) {
            order: 2;
        }
        .navbar-item:nth-of-type(3) {
            order: 10;
            margin-left: auto;
            img {
                border-radius: 9999px;
                border: 2px solid $white;
            }
        }

        .navbar-burger {
            order: 1;
            margin-left: unset;
        }
        .navbar-burger span {
            height: 2px;
            left: calc(50% - 13px);
            width: 26px;
        }
        .navbar-burger span:nth-child(1) {
            top: calc(50% - 6px);
        }
        .navbar-burger span:nth-child(2) {
            top: calc(50% + 1px);
        }
        .navbar-burger span:nth-child(3) {
            top: calc(50% + 8px);
        }
    }

    .columns {
        img {
            border-radius: 9999px;
            border: 2px solid $white;
            max-height: 2.75rem;
        }
    }
</style>
