<template>
    <form @submit.prevent="formSubmit">
        <div class="modal-card">
            <header class="modal-card-head">
                <p class="modal-card-title">{{ t.title }}</p>
                <button
                    type="button"
                    class="delete"
                    @click="$emit('close')" />
            </header>
            <section class="modal-card-body">
                <b-field
                    :label="t.pt"
                    :type="invalids.includes('phrase.pt') ? 'is-danger' : ''">
                    <b-input
                        type="text"
                        v-model="form.phrase.pt"
                        required></b-input>
                </b-field>
                <b-field
                    :label="t.en"
                    :type="invalids.includes('phrase.en') ? 'is-danger' : ''">
                    <b-input
                        type="text"
                        v-model="form.phrase.en"
                        required></b-input>
                </b-field>
            </section>
            <footer class="modal-card-foot">
                <b-button
                    :loading="isLoading"
                    @click="deletePhrase"
                    type="is-danger">
                    <b-icon
                        icon="mdi mdi-delete"
                        size="is-small"></b-icon>
                </b-button>
                <b-button
                    :label="t.close"
                    :loading="isLoading"
                    @click="$emit('close')" />
                <b-button
                    :label="t.submit"
                    :loading="isLoading"
                    type="is-primary"
                    native-type="submit" />
            </footer>
        </div>
    </form>
</template>
<script>
    import { addPhrase, updatePhrases, removePhrase } from '@/api/phrases';

    export default {
        name: 'Modal',
        components: {},
        data() {
            return {
                t: this.$t('components.BackOffice.DailyPhrases.Modal'),
                isLoading: false,
                invalids: [],
                form: {
                    phrase: {
                        pt: null,
                        en: null,
                    },
                },
            };
        },
        props: {
            edit: {
                type: Boolean,
                default: false,
            },
            phrase: {
                type: Object,
                required: false,
            },
        },
        created() {
            if (this.edit) {
                this.form = this.phrase;
            }
        },
        methods: {
            formSubmit() {
                this.isLoading = true;
                if (!this.edit) {
                    addPhrase(this.form)
                        .then((response) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$emit('close');
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: this.t.api.error,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updatePhrases({
                        id: this.form._id,
                        phrase: this.form.phrase,
                    })
                        .then((response) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.updateSuccess,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$emit('close');
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: this.t.api.error,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
            deletePhrase() {
                this.isLoading = true;
                removePhrase(this.form._id)
                    .then((response) => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: this.t.api.deleteSuccess,
                            position: 'is-bottom',
                            type: 'is-success',
                        });
                        this.$emit('close');
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: this.t.api.error,
                            position: 'is-bottom',
                            type: 'is-danger',
                        });
                    });
            },
        },
    };
</script>
<style lang="scss" scoped>
    .modal-card-foot {
        justify-content: flex-end;
    }
</style>
