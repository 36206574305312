<template>
    <section>
        <TopMenu />
        <div class="container">
            <div class="profile-card">
                <div class="media pt-6">
                    <div class="media-left">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-lock"
                            type="is-white"
                            class="is-size-2">
                        </b-icon>
                    </div>
                    <div class="media-content">
                        <p class="label ml-2">{{ t.change }}</p>

                        <div class="has-text-centered field-container">
                            <b-input
                                type="password"
                                name="password"
                                ref="password"
                                :class="{ 'error-text': errors.has('password') }"
                                v-validate="'required'"
                                class="field has-text-centered"
                                v-model="currentPassword"
                                password-reveal
                                size="is-small"
                                :placeholder="t.current">
                            </b-input>
                        </div>
                        <p
                            v-show="errors.has('password')"
                            class="help is-danger has-text-centered">
                            {{ t.enterPassword }}
                        </p>
                        <p
                            v-show="invalidCurrentPassword"
                            class="help is-danger has-text-centered">
                            {{ t.invalidOld }}
                        </p>

                        <div class="has-text-centered field-container mt-3">
                            <b-input
                                :class="{ 'error-text': errors.has('new_password') }"
                                class="field has-text-centered"
                                ref="newPassword"
                                :placeholder="t.new"
                                v-validate="'required|min:8'"
                                name="new_password"
                                type="password"
                                size="is-small"
                                v-model="newPassword"
                                password-reveal />
                        </div>
                        <p
                            v-show="errors.has('new_password')"
                            class="help is-danger has-text-centered">
                            {{ t.shortPassword }}
                        </p>

                        <div class="has-text-centered field-container mt-3">
                            <b-input
                                :class="{ 'error-text': errors.has('confirm_password') }"
                                class="field has-text-centered"
                                :placeholder="t.confirm"
                                v-validate="'required|confirmed:newPassword'"
                                name="confirm_password"
                                type="password"
                                data-vv-as="newPassword"
                                size="is-small"
                                v-model="confirmPassword"
                                password-reveal />
                        </div>
                        <p
                            v-show="errors.has('confirm_password')"
                            class="help is-danger has-text-centered">
                            {{ t.matchPassword }}
                        </p>

                        <b-button
                            class="edit mt-5"
                            rounded
                            @click="change"
                            >{{ t.save }}</b-button
                        >
                        <p
                            v-show="changed"
                            class="help is-primary has-text-centered">
                            {{ t.changed }}
                        </p>
                    </div>
                    <div
                        class="media-right"
                        @click="$router.back()">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-black"
                            class="is-size-4 arrow">
                        </b-icon>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    import { changePassword } from '@/api/apiUser';

    export default {
        components: { TopMenu, BotMenu },
        data() {
            return {
                t: this.$t('components.User.ChangePassword'),
                currentPassword: null,
                newPassword: null,
                confirmPassword: null,
                invalidCurrentPassword: false,
                changed: false,
            };
        },
        methods: {
            change() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        changePassword(this.currentPassword, this.newPassword)
                            .then((response) => {
                                this.invalidCurrentPassword = false;
                                this.changed = true;
                            })
                            .catch((error) => {
                                if (error.response.data.code === 'InvalidOldPassword') {
                                    this.invalidCurrentPassword = true;
                                }
                            });
                    }
                });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        padding: 1rem;
        @include from($tablet) {
            width: 70%;
            margin: 0 auto;
        }
    }

    .container {
        padding-top: 140px;
    }

    .label {
        font-family: 'Lota Grotesque';
        font-size: 0.9em;
        font-weight: bold;
    }

    .media {
        padding-left: 8%;
        padding-right: 6%;
    }
    .arrow {
        transform: translate(0, -23px);
    }

    .edit {
        width: 80px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }
</style>
