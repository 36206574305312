<template>
    <section>
        <TopMenu />

        <div
            class="menu-header"
            v-touch:swipe="handleSwipe">
            <div class="media has-text-white">
                <div class="media-left pl-4">
                    <div @click="previousStep">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-white"
                            class="is-size-4 is-clickable">
                        </b-icon>
                    </div>
                </div>
                <div class="media-content has-text-centered column is-vcentered">
                    <p
                        v-if="this.getSpotStep === 0"
                        class="ml-2">
                        {{ t.myTracker }}
                    </p>
                    <p
                        v-if="this.getSpotStep === 1"
                        class="ml-2">
                        {{ t.favorites }}
                    </p>
                    <p
                        v-if="this.getSpotStep === 2"
                        class="ml-2">
                        {{ t.myMenus }}
                    </p>
                    <p
                        v-if="this.getSpotStep === 3"
                        class="ml-2">
                        {{ t.grocery }}
                    </p>
                </div>
                <div class="media-right pr-4">
                    <div @click="nextStep">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-right"
                            type="is-white"
                            class="is-size-4 is-clickable">
                        </b-icon>
                    </div>
                </div>
            </div>
        </div>

        <div class="main">
            <Content />
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import Content from '@/components/MySpot/MySpot.vue';
    export default {
        components: {
            TopMenu,
            Content,
        },

        data() {
            return {
                t: this.$t('components.MySpot.mySpot'),
            };
        },

        methods: {
            nextStep() {
                if (this.getSpotStep == 3) {
                    this.updateSpotStep(0);
                } else this.updateSpotStep(this.getSpotStep + 1);
            },
            previousStep() {
                if (this.getSpotStep == 0) {
                    this.updateSpotStep(3);
                } else this.updateSpotStep(this.getSpotStep - 1);
            },
            handleSwipe(direction) {
                if (direction === 'left') {
                    this.nextStep();
                }

                if (direction === 'right') {
                    this.previousStep();
                }
            },
        },

        beforeDestroy() {
            if (this.$route.name !== 'RecipeDetails') {
                this.updateSpotStep(0);
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .menu-header {
        position: fixed;
        height: 101px;
        background-color: #d9b0c3;
        top: 85px;
        width: 100%;
        z-index: 5;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 58px;
            top: 53px;
        }
    }

    .media {
        align-items: center;
        height: 101px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 70px;
        }
    }

    .media-content .mdi,
    p {
        display: inline-flex;
    }

    .media-content p {
        transform: translateY(-7px);
        font-size: 14px;
        font-family: 'Lota Grotesque';

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            transform: translateY(-2px);
        }
    }

    .main {
        padding-top: 200px;
        @include until($mobile-s) {
            padding-left: 3%;
            padding-right: 3%;
        }
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 100px;
        }
    }

    @media print {
        .menu-header {
            visibility: hidden;
        }
    }
</style>
