<template>
    <section>
        <TopMenu />
        <div class="spaced-name has-text-primary">
            <p>B</p>
            <p>E</p>
            <p>L</p>
            <p>O</p>
            <p>V</p>
            <p>E</p>
            <p>R</p>
            <p></p>
            <p>M</p>
            <p>E</p>
            <p>A</p>
            <p>L</p>
            <p></p>
            <p>P</p>
            <p>L</p>
            <p>A</p>
            <p>N</p>
            <p>N</p>
            <p>E</p>
            <p>R</p>
        </div>
        <div class="main mb-5 top-menus">
            <div class="media">
                <div class="media-left">
                    <button
                        class="button is-rounded has-text-primary is-fullwidth"
                        @click="goToPage('Menus')">
                        Menus
                    </button>
                    <b-icon
                        pack="bl-icons"
                        icon="bl-file"
                        type="is-primary"
                        class="is-size-5">
                    </b-icon>
                </div>
                <div class="media-content">
                    <button
                        class="button is-rounded has-text-primary is-fullwidth"
                        @click="goToPage('MyShop')">
                        My Shopping
                    </button>
                    <b-icon
                        pack="bl-icons"
                        icon="bl-cesto_compras"
                        type="is-primary"
                        class="is-size-5">
                    </b-icon>
                </div>
                <div class="media-right">
                    <button
                        class="button is-rounded has-text-primary is-fullwidth"
                        @click="goToPage('MySpot')">
                        My Spot
                    </button>
                    <b-icon
                        pack="bl-icons"
                        icon="bl-objective"
                        type="is-primary"
                        class="is-size-5">
                    </b-icon>
                </div>
            </div>
        </div>

        <div class="video">
            <img :src="require('@/assets/images/main1.png')" />
        </div>

        <div class="main">
            <hr />

            <div v-touch:swipe="handleSwipe">
                <div v-show="index === 0">
                    <p
                        class="has-text-primary has-text-centered"
                        :style="{ 'font-family': changeFont }">
                        {{ phrase }}
                    </p>
                </div>

                <div
                    v-for="(news, newsIndex) in upcomingNews"
                    :key="newsIndex">
                    <div @click="openNews(news.link)">
                        <div v-show="index === newsIndex + 1">
                            <p
                                class="has-text-primary has-text-centered"
                                :style="{ 'font-family': changeFont }">
                                {{ news.short_description[getLocale] }}
                            </p>
                            <div
                                class="recipe-image"
                                :style="{ 'background-image': `url(${news.image})` }"></div>
                        </div>
                    </div>
                </div>
                <!-- 	<div v-if="index === 1" @click="seeDetails(newRecipe._id)">
					<p class="has-text-primary has-text-centered" :style="{ 'font-family': this.changeFont }">{{ t.newRecipe }}</p>
					<div class="recipe-image" :style="{ 'background-image': `url(${this.recipeImg})` }"></div>
					<p class="has-text-primary has-text-centered" :style="{ 'font-family': this.changeFont }">{{ newRecipe.name[this.getLocale] }}</p>
				</div> -->
            </div>

            <hr class="mb-0" />
            <div class="register-steps center-inside is-fullwidth">
                <div
                    v-for="(step, stepIndex) in upcomingNewsIndex"
                    :key="stepIndex"
                    :class="{ 'step-1': index === stepIndex }"></div>
            </div>
            <div
                v-if="!isMobile"
                @click="handleSwipe('right')">
                <b-icon
                    pack="bl-icons"
                    icon="bl-arrow-left"
                    type="is-primary"
                    class="arrow-left is-size-4">
                </b-icon>
            </div>
            <div
                v-if="!isMobile"
                @click="handleSwipe('left')">
                <b-icon
                    pack="bl-icons"
                    icon="bl-arrow-right"
                    type="is-primary"
                    class="arrow-right is-size-4">
                </b-icon>
            </div>

            <Tracker />
            <hr />
            <DailyRecipesHome class="mb-5" />
            <About />
        </div>
    </section>
</template>

<script>
    import { getLoggedUser } from '@/api/apiAuth';
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import Tracker from '@/components/HomePage/Tracker.vue';
    import { getPhrases } from '@/api/phrases';
    import { getUpcomingNews } from '@/api/upcomingNews';
    import { getDailyRecipe } from '@/api/dailyRecipes';
    import moment from 'moment';
    import About from '@/views/About.vue';
    import DailyRecipesHome from '@/components/Recipes/DailyRecipesHomepage.vue';
    import { Browser } from '@capacitor/browser';

    export default {
        components: {
            TopMenu,
            Tracker,
            About,
            DailyRecipesHome,
        },
        data() {
            return {
                t: this.$t('components.Homepage.Homepage'),
                phrase: null,
                date: new Date(),
                infoReceived: null,
                carousels: [],
                newRecipe: null,
                index: 0,
                recipeImg: null,
                recipes: [],
                recipeIndex: 0,
                receivedRecipes: [],
                upcomingNews: [],
                upcomingNewsIndex: 1,
            };
        },
        methods: {
            handleSwipe(direction) {
                if (direction === 'left' && this.index >= 0 && this.index < this.upcomingNews.length) {
                    this.index++;
                } else if (direction === 'left' && this.index === this.upcomingNews.length) {
                    this.index = 0;
                }

                if (direction === 'right' && this.index === 0) {
                    this.index = this.upcomingNews.length;
                } else if (direction === 'right' && this.index <= this.upcomingNews.length && this.index > 0) {
                    this.index--;
                }
            },
            handleRecipesSwipe(direction) {
                if (direction === 'left' && this.recipeIndex >= 0 && this.recipeIndex < 12) {
                    this.recipeIndex++;
                } else if (direction === 'left' && this.recipeIndex === 12) {
                    this.recipeIndex = 0;
                }

                if (direction === 'right' && this.recipeIndex === 0) {
                    this.recipeIndex = 12;
                } else if (direction === 'right' && this.recipeIndex <= 12 && this.recipeIndex > 0) {
                    this.recipeIndex--;
                }
            },

            seeDetails(id) {
                this.$router.push({ name: 'RecipeDetails', params: { id: id } });
            },

            scrollToTop() {
                window.scrollTo(0, 0);
            },

            openNews(url) {
                window.open('https://' + url, '_blank');
            },
        },

        computed: {
            formattedDate() {
                return moment(this.date).format('YYYY-MM-DD');
            },
        },

        created() {
            this.setIsPageLoading(true);
            document.addEventListener('visibilitychange', () => {
                const state = document.visibilityState;
                if (state === 'visible') {
                    location.reload();
                }
            });

            this.scrollToTop();

            getPhrases({ display_date: this.formattedDate })
                .then((response) => {
                    this.phrase = response.data.phrases[0].phrase[this.getLocale];
                    this.carousels.push({ title: null, name: this.phrase });
                })
                .catch((error) => {
                    console.error(error);
                });

            getLoggedUser()
                .then((response) => {
                    this.infoReceived = response.data.body;
                    this.addUser(this.infoReceived);
                    this.updateIntolerances(this.infoReceived.info.intolerances);
                    this.updateMeals(this.infoReceived.info.meals);
                })
                .catch((error) => {
                    console.error(error);
                });

            getUpcomingNews()
                .then((response) => {
                    this.upcomingNews = response.data.upcoming_news;
                    this.upcomingNewsIndex += this.upcomingNews.length;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .spaced-name {
        justify-content: space-between;
        display: flex;
        padding-left: 6%;
        padding-right: 6%;
        margin-bottom: 30px;
        padding-top: 70px;
        font-family: 'Borest-Standard';
        font-weight: bold;
    }
    .top-menus .media .is-fullwidth {
        @include from($mobile-l) {
            width: auto;
            margin: 0 auto;
        }
    }

    .button {
        border-color: $primary;
        font-size: 14px;
    }

    .media-left,
    .media-content,
    .media-right {
        text-align: center;
        overflow: hidden;
    }

    .video img {
        object-fit: scale-down;
        width: 100%;
        height: 250px;
    }

    .main p {
        font-family: 'Borest-Standard';
        font-size: 25px;
    }

    hr {
        color: $primary;
        border: solid 1px;
        margin: 16px 0;
        background-color: $primary;
    }

    .news-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .news {
        border-color: $secondary;
        background-color: $secondary;
        color: white;
        margin-left: 10px;
    }

    .register-steps > div {
        width: 8px;
        height: 8px;
        background: white;
        border: solid 1px $primary;
        border-radius: 50%;
        margin: 15px;
    }
    .register-steps > .step-1 {
        background: $primary;
    }

    .recipe-steps {
        margin: 8px !important;
    }

    .recipe-image {
        justify-content: center;
        display: flex;
        height: 170px;

        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        border-radius: 30px;
        @include from($mobile-l) {
            max-width: $card-max-width;
            margin: 0 auto;
        }
    }

    .arrow-left {
        transform: translate(45px, -34px);
    }

    .arrow-right {
        float: right;
        transform: translate(-53px, -62px);
    }
    .arrow-left-recipes {
        transform: translate(45px, 2px);
    }

    .arrow-right-recipes {
        float: right;
        transform: translate(-53px, -25px);
    }

    .arrow-left,
    .arrow-right,
    .arrow-right-recipes,
    .arrow-left-recipes {
        cursor: pointer;
    }

    .sugestion {
        font-size: 2rem !important;
    }

    @media only screen and (max-width: 350px) {
        .button {
            font-size: 13px;
        }
    }
</style>
