import { get, post, put } from '@/utils/http';

export function createAccount(data) {
    return post('/users', {
        auth: {
            email: data.email,
            password: data.password,
        },
        info: {
            birthday: new Date(data.birth).toISOString(),
        },
        marketing: data.marketing,
        acceptsTerms: data.terms,
    });
}

export function login(email, password) {
    return post('/auth', {
        email: email,
        password: password,
    });
}

export function activationEmail(email) {
    return post('/users/newcode', {
        email: email,
    });
}

export function completeRegister(data) {
    return post('/users/register', {
        info: {
            name: data.name,
            last_name: data.lastName,
            nif: data.nif,
            genre: data.genre,
        },
    });
}

export function recoverPassword(email) {
    return post('/users/password/recover', {
        email: email,
    });
}

export function logout() {
    return post('/auth/logout', {});
}

export function getLoggedUser() {
    return get('/auth');
}

export function activateAccount(id) {
    return put('/users/' + id + '/activate');
}

export function changePassword(token, password) {
    return put('/users/password/recover?token=' + token, {
        password: password,
    });
}
