<template>
    <section
        class="mb-3"
        @scroll="handleScroll">
        <div
            v-touch:swipe="handleSwipe(recipe.recipe._id)"
            @click="seeDetails(recipe.recipe._id)"
            class="card mt-4"
            v-for="(recipe, index) in favorites"
            :key="index">
            <div class="media">
                <div class="media-left">
                    <div
                        class="card-image"
                        :style="{ 'background-image': `url(${recipe.recipe.image})` }">
                        <div
                            class="cross"
                            @click="removeFavorite(recipe._id, index)">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-cross"
                                type="is-primary"
                                class="is-size-4" />
                        </div>
                    </div>
                </div>
                <div class="media-content has-text-centered has-text-secondary details">
                    <p class="recipe-detail-name mt-2">{{ recipe.recipe.name[getLocale] }}</p>
                    <div class="mt-2">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-user"
                            type="is-secondary"
                            class="is-size-5">
                        </b-icon>
                        x {{ recipe.recipe.people }}
                    </div>
                    <div style="display: inline-flex">
                        <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                        <p style="transform: translateY(17px)">{{ recipe.recipe.preparing_time }}</p>
                    </div>
                </div>
                <div class="media-right"></div>
            </div>
        </div>
    </section>
</template>

<script>
    import { getFavorite, removeFavorite } from '@/api/favorites';
    export default {
        data() {
            return {
                isRemove: false,
                favorites: [],
                page: 1,
                offset: 100,
                isRequesting: false,
            };
        },
        methods: {
            getFavorites() {
                let query = {
                    order: 'asc',
                    page: this.page,
                    limit: 5,
                };
                getFavorite(query)
                    .then((response) => {
                        this.favorites = response.data.favorites;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.setIsPageLoading(false);
                    });
            },
            seeDetails(id) {
                if (this.isRemove == false) {
                    this.$router.push({ name: 'RecipeDetails', params: { id: id } });
                }
            },
            handleSwipe(id) {
                return (dir) => {
                    if (dir === 'left') {
                        this.seeDetails(id);
                    }
                };
            },
            removeFavorite(id, index) {
                this.isRemove = true;

                let data = {
                    id: id,
                };
                this.favorites.splice(index, 1);

                removeFavorite(data)
                    .then((response) => {
                        let query = {
                            order: 'asc',
                        };
                        getFavorite(query)
                            .then((response) => {
                                this.favorites = response.data.favorites;
                            })
                            .catch((error) => {
                                console.error(error);
                            })
                            .finally(() => {
                                this.isRequesting = false;
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            handleScroll(e) {
                let target = {
                    scrollTop: e.target.scrollingElement.scrollTop,
                    clientHeight: e.target.scrollingElement.clientHeight,
                    scrollHeight: e.target.scrollingElement.scrollHeight,
                };
                if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
                    if (this.isRequesting) return;
                    this.isRequesting = true;
                    this.page++;

                    let query = {
                        order: 'asc',
                        recipe_type: this.recipe_type,
                        page: this.page,
                        limit: 5,
                    };
                    getFavorite(query)
                        .then((response) => {
                            this.favorites.push(...response.data.favorites);
                        })
                        .catch((error) => {
                            console.error(error);
                        })
                        .finally(() => {
                            this.isRequesting = false;
                        });
                }
            },
        },
        created() {
            this.setIsPageLoading(true);
            this.getFavorites();
            window.addEventListener('scroll', this.handleScroll);
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
    };
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .mdi {
        color: #ce9aa9;
    }

    .media-content,
    .media-right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .media-right {
        height: 54px;
    }

    .media-content {
        overflow: hidden;
        height: 54px;
    }

    .btn-menu {
        border-color: #ce9aa9;
        color: #ce9aa9;
        margin-left: 10px;
        height: 32px;
        font-weight: bold;
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .btn-add {
        border-color: $secondary;
        background-color: $secondary;
        color: white;
        margin-left: 10px;
        height: 32px;
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .button-container .button {
        margin-top: 8px;
        height: 32px;
        margin-left: 3px;
        margin-right: 3px;
        margin-bottom: 8px;
        border: 1px solid $secondary;
        color: $secondary;
        width: 82px;
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .button-container {
        display: inline-flex;
        width: 100%;
        justify-content: center;
    }

    .topActive {
        background-color: $secondary;
        color: white !important;
    }

    .botActive {
        background-color: #93bfb7;
        color: white !important;
        border: solid 1px #93bfb7 !important;
    }

    .card {
        height: 172px;
        border-radius: 48px;
        border: 1px solid $secondary;

        background-color: transparent;
        border-radius: 25px;
        @include from($mobile-l) {
            width: 400px;
            margin: 0 auto;
        }

        @include until($mobile-s) {
            margin-bottom: 20px;
        }
    }

    .card-image {
        background-image: url('https://realfood.tesco.com/media/images/1400x919-Pink-ombre-pancakes-283d8170-a45b-4bc5-a3e9-ef09ad841530-0-1400x919.jpg');
        justify-content: center;
        display: flex;
        height: 170px;
        border-top-left-radius: 25px 25px;
        border-bottom-left-radius: 25px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .card .media-left {
        width: 63%;
    }

    .cross {
        transform: translate(-78px, 12px);
        height: 30px;

        @include until($mobile-s) {
            transform: translate(-60px, 12px);
        }
    }

    .details {
        height: 170px;
        display: inline;
    }

    .recipe-detail-name {
        font-size: 12px;
        font-weight: bold;
        letter-spacing: 1px;
    }

    @media only screen and (max-width: 346px) {
        .button-container .button {
            margin-left: 3px;
            margin-right: 3px;
            width: 72px;
            font-size: 12px;
        }
    }
</style>
