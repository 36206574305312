<template>
    <form
        @submit.prevent="formSubmit"
        class="columns is-mobile is-multiline">
        <b-field
            class="column is-6"
            :label="t.name">
            <b-input
                type="text"
                v-model="form.name"
                disabled></b-input>
        </b-field>
        <b-field
            class="column is-6"
            :label="t.email">
            <b-input
                type="text"
                v-model="form.email"
                disabled></b-input>
        </b-field>
        <b-field
            class="column is-12"
            :label="t.question">
            <b-input
                type="textarea"
                v-model="form.question"
                disabled></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('answer') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.answer">
            <b-input
                type="textarea"
                v-model="form.answer"
                :disabled="form.answered"></b-input>
        </b-field>
        <div class="column is-offset-4-desktop is-4-desktop is-12-touch">
            <b-button
                expanded
                :loading="isLoading"
                class="is-headline-bold-d-d is-headline-m-m"
                type="is-primary"
                native-type="submit"
                :disabled="form.answered"
                >{{ t.submit }}</b-button
            >
        </div>
    </form>
</template>
<script>
    import { getForms, updateForm } from '@/api/forms';

    export default {
        name: 'FormsForm',
        data() {
            return {
                t: this.$t('components.BackOffice.Forms.Form'),
                isLoading: false,
                invalids: [],
                form: {
                    name: null,
                    email: null,
                    question: null,
                    answer: null,
                },
            };
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                let query = {
                    _id: this.$route.params.id,
                };
                getForms(query)
                    .then((response) => {
                        let form = response.data.data[0];

                        if (this.isUpdate) this.$emit('on-edit', form.name);

                        this.form = {
                            id: form._id,
                            name: form.name,
                            email: form.email,
                            question: form.question,
                            answer: form.answer,
                            answered: form.answered,
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            formSubmit() {
                this.isLoading = true;
                this.invalids = [];

                updateForm({
                    id: this.form.id,
                    answer: this.form.answer,
                })
                    .then((result) => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: this.t.api.update_success,
                            position: 'is-bottom',
                            type: 'is-success',
                        });
                        this.$router.push({ name: 'BackOfficeForms' });
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        let message = this.t.api.error;
                        if (Array.isArray(error.response.data)) {
                            error.response.data.forEach((element) => {
                                this.invalids.push(element.param);
                            });
                        }
                        this.$buefy.toast.open({
                            message: message,
                            position: 'is-bottom',
                            type: 'is-danger',
                        });
                    });
            },
        },
    };
</script>
