<template>
    <section>
        <TopMenu />
        <TrackerContent />
        <BotMenu class="menu-position" />
        <div class="background-white">
            <div class="logo-container">
                <img
                    class="logo"
                    src="../assets/icons/coracao_verde.png"
                    alt="" />
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import TrackerContent from '@/components/Tracker/TrackerCategory.vue';
    import BotMenu from '@/components/Menu/BottomAboutMenu.vue';
    export default {
        components: {
            TopMenu,
            TrackerContent,
            BotMenu,
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },

        created() {
            this.scrollToTop();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    section {
        overflow-x: hidden !important;
        width: 100%;
    }

    .logo-container {
        position: fixed;
        left: 50%;
        bottom: 30px;
        transform: translate(-50%, 26px);
        margin: 0 auto;
        width: 100px;
        z-index: 10;
        display: flex;
        justify-content: center;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 10px;
            transform: translate(-50%, 10px);
        }
    }

    .background-white {
        position: fixed;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        background-color: white;
        height: 40px;
        z-index: 6;

        @include until($mobile-s) {
            height: 30px;
        }
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 34px;
        }
    }

    .logo {
        height: 24px;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 24px;
        }
    }
</style>
