<template>
    <section>
        <div class="main">
            <div class="container">
                <p
                    class="subtitle has-text-centered mt-2"
                    :style="{ 'font-family': this.changeFont }">
                    {{ t.loginPhrase }}
                </p>
                <b-input
                    :class="{ 'error-text': errors.has('email') }"
                    type="email"
                    v-validate="'required|email'"
                    size="is-small"
                    name="email"
                    v-model="email"
                    :placeholder="t.email" />
                <p
                    v-show="errors.has('email')"
                    class="help is-danger">
                    {{ t.validEmail }}
                </p>

                <b-input
                    type="password"
                    name="password"
                    ref="password"
                    :class="{ 'error-text': errors.has('password') }"
                    class="mt-5"
                    v-validate="'required'"
                    v-model="password"
                    password-reveal
                    size="is-small"
                    :placeholder="t.password">
                </b-input>

                <p
                    v-show="errors.has('password')"
                    class="help is-danger">
                    {{ t.enterPassword }}
                </p>
            </div>
            <p
                v-show="wrongCredentials"
                class="help is-danger has-text-centered">
                {{ t.wrongCredentials }}
            </p>
            <p
                v-show="notActivated"
                class="help is-danger has-text-centered">
                {{ t.userNotActivated }}
            </p>
            <b-button
                class="login mt-5"
                rounded
                :loading="isLoading"
                @click="submitLogin"
                >{{ t.go }}</b-button
            >

            <p
                class="recover mt-10 is-clickable"
                @click="updateStep(1)">
                {{ t.recover }}
            </p>
            <p
                class="recover mt-4 mb-10 is-clickable"
                @click="updateStep(2)">
                {{ t.resend }}
            </p>
        </div>
    </section>
</template>

<script>
    import { login } from '@/api/apiAuth';

    export default {
        data() {
            return {
                t: this.$t('components.Auth.Login'),
                email: null,
                password: null,
                notActivated: false,
                wrongCredentials: false,
                isLoading: false,
            };
        },

        methods: {
            submitLogin() {
                this.isLoading = true;
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        login(this.email, this.password)
                            .then((response) => {
                                if (response.data.code === 'UserNotRegistered') {
                                    this.addToken(response.headers.authorization);
                                    this.addUser(response.data.body);
                                    this.goToPage('CompleteRegister');
                                } else if (response.data.body.user_type === 'admin') {
                                    this.addToken(response.headers.authorization);
                                    this.goToPage('BackOffice');
                                } else if (response.data.code === 'SubscriptionExpired') {
                                    this.updateIntolerances(response.data.body.info.intolerances);
                                    this.updateMeals(response.data.body.info.meals);
                                    this.addToken(response.headers.authorization);
                                    this.addUser(response.data.body);
                                    this.goToPage('SubscriptionExpired');
                                } else if (response.data.body.subscription.status === 'trialing') {
                                    this.addUser(response.data.body);
                                    this.$store.commit('freeTrial/setFreeTrial', response.data.body.subscription);
                                    this.addToken(response.headers.authorization);
                                    this.goToPage('FreeTrial');
                                } else {
                                    this.addUser(response.data.body);
                                    this.updateIntolerances(response.data.body.info.intolerances);
                                    this.updateMeals(response.data.body.info.meals);
                                    this.addToken(response.headers.authorization);
                                    this.goToPage('Homepage');
                                }
                            })
                            .catch((error) => {
                                console.error(error);
                                if (error.response.data.code === 'UserNotActivated') {
                                    this.notActivated = true;
                                    this.wrongCredentials = false;
                                }

                                if (error.response.data.code === 'WrongEmailOrPassword') {
                                    this.wrongCredentials = true;
                                    this.notActivated = false;
                                }
                            })
                            .finally(() => {
                                this.isLoading = false;
                            });
                    } else this.isLoading = false;
                });
            },

            updateStep(step) {
                this.$store.commit('loginSteps/setLoginSteps', step);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .login {
        width: 70px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .subtitle {
        font-size: 24px;
        font-family: 'Borest-Standard';
        font-weight: normal;
    }

    .recover {
        font-size: 0.75rem;
        color: $secondary;
        font-family: 'Lota Grotesque';
    }
</style>
