<template>
    <div>
        <b-table
            :data="data"
            ref="table"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="_id"
            :show-detail-icon="showDetailIcon"
            paginated
            backend-pagination
            :total="total"
            per-page="10"
            :range-after="5"
            :current-page="page"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page">
            <b-table-column
                field="name"
                :label="t.columns.name"
                v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column
                field="preparing_time"
                :label="t.columns.preparing_time"
                sortable
                v-slot="props"
                centered>
                {{ props.row.preparing_time }}
            </b-table-column>

            <b-table-column
                field="people"
                :label="t.columns.people"
                sortable
                v-slot="props"
                centered>
                {{ props.row.people }}
            </b-table-column>

            <b-table-column
                class="level"
                v-slot="props"
                width="5">
                <b-button
                    class="level-item"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-primary"
                    :loading="isLoading"
                    @click="createRecipe(props.row)"></b-button>
            </b-table-column>
            <b-table-column
                class="level"
                v-slot="props"
                width="5">
                <b-button
                    class="level-item"
                    size="is-small"
                    icon-right="mdi mdi-delete"
                    type="is-danger"
                    :loading="isLoading"
                    @click="deleteRecipe(props.row._id)"></b-button>
            </b-table-column>

            <template #detail="props">
                <article class="media">
                    <figure
                        v-if="!isMobile"
                        class="media-left">
                        <p class="image is-128x128">
                            <img :src="props.row.image" />
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="content">
                            <p
                                v-if="isMobile"
                                class="image is-128x128">
                                <img :src="props.row.image" />
                            </p>
                            <p>{{ props.row.details }}</p>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { getRecipes, removeRecipe } from '@/api/userRecipes';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Recipes.List'),
                data: [],
                total: 0,
                defaultOpenedDetails: [1],
                showDetailIcon: true,
                page: 1,
                last_page: null,
                filters: {
                    tag: 'all',
                    type: null,
                    search: null,
                },
            };
        },
        created() {
            this.getData({
                page: this.page,
                limit: 10,
            });
        },
        methods: {
            createRecipe(recipe) {
                let routeData = this.$router.resolve({ name: 'NewRecipe', params: { recipe } });
                window.open(routeData.href, '_blank');
            },
            getData(query) {
                this.data = [];
                getRecipes(query)
                    .then((response) => {
                        this.data = response.data.recipes;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onPageChange(page) {
                this.page = page;
                let query = {
                    page: this.page,
                };
                this.getData(query);
            },
            deleteRecipe(id) {
                this.$buefy.dialog.confirm({
                    title: this.t.dialog.title,
                    message: this.t.dialog.message,
                    confirmText: this.t.dialog.confirm,
                    cancelText: this.t.dialog.cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.isLoading = true;
                        removeRecipe(id)
                            .then((response) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.success,
                                    position: 'is-bottom',
                                    type: 'is-success',
                                });
                                this.getData();
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.error,
                                    position: 'is-bottom',
                                    type: 'is-danger',
                                });
                            });
                    },
                });
            },
        },
    };
</script>
