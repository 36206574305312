import { post, get, formData, formDataPut, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addVideo(image, data) {
    let form = new FormData();

    if (image) {
        let new_name_image = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name_image);
    }
    form.append('data', JSON.stringify(data));
    return formData('/videos', form);
}

export function getVideos(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/videos' + _query);
}

export function updateVideo(image, data) {
    let form = new FormData();

    if (image) {
        let new_name_image = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name_image);
    } else {
        form.append('image', null);
    }
    form.append('data', JSON.stringify(data));
    return formDataPut('/videos', form);
}

export function removeVideo(id) {
    return remove('/videos', { id: id });
}
