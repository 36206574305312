import Vue from 'vue';

import app from './modules/app';
import user from './modules/user';
import tracker from './modules/tracker';
import mySpot from './modules/mySpot';
import registerPreferences from './modules/registerPreferences';
import searchRecipes from './modules/searchRecipes';

import getLocale from './shared/getLocale';
import goToPage from './shared/goToPage';

let mixins = [app, user, getLocale, goToPage, tracker, mySpot, registerPreferences, searchRecipes];

for (let mixin of mixins) Vue.mixin(mixin);
