<template>
    <section>
        <div class="button-container mt-7">
            <button
                class="button is-rounded"
                @click="updateSpotStep(0)"
                :class="{ active: getSpotStep === 0 }">
                {{ t.myTracker }}
            </button>
            <button
                class="button is-rounded"
                @click="updateSpotStep(1)"
                :class="{ active: getSpotStep === 1 }">
                {{ t.favorites }}
            </button>
        </div>

        <div class="button-container">
            <button
                class="button is-rounded"
                @click="updateSpotStep(2)"
                :class="{ active: getSpotStep === 2 }">
                {{ t.myMenus }}
            </button>
            <button
                class="button is-rounded"
                @click="updateSpotStep(3)"
                :class="{ active: getSpotStep === 3 }">
                {{ t.grocery }}
            </button>
        </div>

        <Tracker v-if="getSpotStep === 0" />
        <TrackerTab v-if="getSpotStep === 0" />
        <FavoritesTab v-if="getSpotStep === 1" />
        <MyMenus v-if="getSpotStep === 2" />
        <GroceryList v-if="getSpotStep === 3" />
    </section>
</template>

<script>
    import Tracker from '@/components/HomePage/Tracker.vue';
    import TrackerTab from '@/components/MySpot/TrackerTab.vue';
    import FavoritesTab from '@/components/MySpot/Favoritestab.vue';
    import GroceryList from '@/components/MySpot/GroceryListTab.vue';
    import MyMenus from '@/components/MySpot/MyMenus.vue';
    export default {
        components: {
            Tracker,
            TrackerTab,
            FavoritesTab,
            GroceryList,
            MyMenus,
        },
        data() {
            return {
                activeButton: this.getSpotStep,
                t: this.$t('components.MySpot.mySpot'),
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .button {
        margin-top: 8px;
        height: 32px;
        width: 156px;
        margin-left: 8px;
        margin-right: 8px;
        margin-bottom: 8px;
        border: 1px solid $secondary;
        color: $secondary;
        font-size: 14px;
        font-family: 'Lota Grotesque';

        &:hover {
            color: white;
            background-color: $secondary;
            border-color: white;
        }
    }

    .button-container {
        display: inline-flex;
        width: 100%;
        justify-content: center;
    }

    .active {
        background-color: $secondary;
        color: white;
    }
    .active:focus {
        background-color: $secondary;
        color: white;
    }

    @media print {
        .button-container {
            visibility: hidden;
        }
    }
</style>
