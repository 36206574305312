<template>
    <form
        @submit.prevent="update"
        class="columns is-mobile is-multiline">
        <b-field
            :type="invalids.includes('url') ? 'is-danger' : ''"
            class="column is-12-mobile is-12-desktop"
            :label="t.url">
            <b-input
                type="text"
                v-model="url"
                required></b-input>
        </b-field>
        <div class="column is-offset-4-desktop is-4-desktop is-12-touch">
            <b-button
                expanded
                :loading="isLoading"
                class="is-headline-bold-d-d is-headline-m-m"
                type="is-primary"
                native-type="submit"
                >{{ t.submit }}</b-button
            >
        </div>
    </form>
</template>
<script>
    import { getReiki, updateReiki } from '@/api/reiki';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                invalids: [],
                t: this.$t('components.BackOffice.Reiki.List'),
                url: null,
            };
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                getReiki()
                    .then((response) => {
                        this.url = response.data.reiki.url;
                    })
                    .catch((error) => {
                        this.url = null;
                    });
            },
            update() {
                this.isLoading = true;
                updateReiki({
                    url: this.url,
                })
                    .then((response) => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: this.t.api.success,
                            position: 'is-bottom',
                            type: 'is-success',
                        });
                        this.getData();
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        if (Array.isArray(error.response.data)) {
                            error.response.data.forEach((element) => {
                                this.invalids.push(element.param);
                            });
                        }
                        this.$buefy.toast.open({
                            message: this.t.api.error,
                            position: 'is-bottom',
                            type: 'is-danger',
                        });
                    });
            },
        },
        computed: {},
    };
</script>
