<template>
    <section class="hi">
        <TopMenu />
        <CategoryMenu />
        <div class="main">
            <h1 class="has-text-primary is-size-2 has-text-centered">{{ $t('shared.availableSoon') }}</h1>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import CategoryMenu from '@/components/MyShop/CategoryMenu.vue';
    export default {
        components: {
            TopMenu,
            CategoryMenu,
        },
        data() {
            return {
                t: this.$t('components.MyShop.Partners'),
            };
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },
        created() {
            this.scrollToTop;
        },
    };
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 186px;
        padding-bottom: 20px;
        width: 100%;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 130px;
        }

        h1 {
            font-family: 'Borest-Standard';
            position: fixed;
            top: 50%;
            left: 50%;
            transform: translate(-50%, -50%);
        }
    }
</style>
