<template>
    <section>
        <TopMenu />
        <div class="main">
            <div class="card">
                <div class="heart-container pt-4">
                    <b-image
                        class="img"
                        :src="require('@/assets/icons/coracao_logo_branco.png')"
                        alt="Green Heart"></b-image>
                </div>
                <p class="title has-text-centered">{{ t.login }}</p>
                <Login v-if="getStep == 0" />
                <ForgotPassword v-else-if="getStep == 1" />
                <ResendEmail v-else />
            </div>
            <p
                class="title has-text-centered mt-6 register"
                @click="goToPage('Register')">
                {{ t.register }}
            </p>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import Login from '@/components/Auth/Login.vue';
    import ForgotPassword from '@/components/Auth/ForgotPassword.vue';
    import ResendEmail from '@/components/Auth/ResendEmail.vue';

    export default {
        components: {
            Login,
            TopMenu,
            ForgotPassword,
            ResendEmail,
        },
        data() {
            return {
                t: this.$t('views.Login'),
                loginStep: null,
            };
        },
        methods: {
            emitStep(value) {
                this.loginStep = value;
            },
        },

        computed: {
            getStep() {
                return this.$store.getters['loginSteps/getLoginStep'];
            },
        },

        created() {},
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 10rem;
        @include until($mobile-s) {
            padding-top: 6rem;
        }
        margin-bottom: 30px;
    }

    .card {
        background-color: #e8e8e8;
        height: auto;
        border-radius: 48px;
        padding-bottom: 20px;

        max-width: $card-max-width;
        margin-left: auto;
        margin-right: auto;
    }

    .title {
        font-size: 52px;
        font-family: 'Borest-Standard';
        font-weight: normal;
    }

    .heart-container {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 10;
    }

    .img {
        width: 36px;
        height: 34px;
    }

    .register {
        cursor: pointer;
    }
</style>
