<template>
    <div>
        <b-pagination
            :total="total"
            v-model="page"
            :range-before="range_before"
            :range-after="range_after"
            per-page="10"
            @change="onPageChange"
            class="mt-2">
        </b-pagination>
        <!-- :data="data"
			ref="table"
			paginated
			backend-pagination
			:total="total"
			per-page="10"
			@page-change="onPageChange"
			aria-next-label="Next page"
			aria-previous-label="Previous page"
			aria-page-label="Page"
			aria-current-label="Current page"
			default-sort="description.pt" -->
        <b-table
            :data="data"
            ref="table"
            default-sort="description.pt">
            <b-table-column
                field="description.pt"
                :label="t.columns.pt"
                :searchable="true">
                <template #searchable="props">
                    <b-input
                        v-model="search"
                        @input="filter"
                        icon="magnify"
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row.description.pt }}
                </template>
            </b-table-column>

            <b-table-column
                field="description.en"
                :label="t.columns.en"
                v-slot="props">
                {{ props.row.description.en }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-pencil"
                    type="is-primary"
                    :loading="isLoading"
                    @click="$router.push({ name: 'UpdateIngredient', params: { id: props.row._id } })"></b-button>
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-delete"
                    type="is-danger"
                    :loading="isLoading"
                    @click="deleteIngredient(props.row._id)"></b-button>
            </b-table-column>
        </b-table>
        <b-pagination
            :total="total"
            v-model="page"
            :range-before="range_before"
            :range-after="range_after"
            per-page="10"
            @change="onPageChange"
            class="mt-2">
        </b-pagination>
    </div>
</template>

<script>
    import { getIngredients, removeIngredient } from '@/api/ingredients';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Ingredients.List'),
                data: [],
                total: 0,
                range_before: 5,
                range_after: 10,
                page: 1,
                search: null,
            };
        },
        created() {},
        methods: {
            filter(text) {
                this.search = text;
                if (this.getLocale == 'pt') {
                    this.getData({
                        search_pt: text,
                    });
                } else {
                    this.getData({
                        search_en: text,
                    });
                }
            },
            getData(query) {
                getIngredients(query)
                    .then((response) => {
                        this.data = response.data.ingredients;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onPageChange(page) {
                this.page = page;

                let query = {
                    page: this.page,
                };
                if (this.search && this.getLocale == 'pt') {
                    query.search_pt = this.search;
                } else if (this.search && this.getLocale == 'en') {
                    query.search_en = this.search;
                }

                this.getData(query);
            },
            deleteIngredient(id) {
                this.$buefy.dialog.confirm({
                    title: this.t.dialog.title,
                    message: this.t.dialog.message,
                    confirmText: this.t.dialog.confirm,
                    cancelText: this.t.dialog.cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.isLoading = true;
                        removeIngredient(id)
                            .then((response) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.success,
                                    position: 'is-bottom',
                                    type: 'is-success',
                                });
                                this.getData();
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.error,
                                    position: 'is-bottom',
                                    type: 'is-danger',
                                });
                            });
                    },
                });
            },
        },
        computed: {},
        beforeRouteLeave(to, from, next) {
            to.params.page = this.page;
            to.params.search = this.search;
            next(to.params);
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.params.search) vm.search = to.params.search;
                if (to.params.page) vm.page = to.params.page;
            });
        },
        mounted() {
            let query = {
                page: this.page,
                limit: 10,
            };
            if (this.search && this.getLocale == 'pt') {
                query.search_pt = this.search;
            } else if (this.search && this.getLocale == 'en') {
                query.search_en = this.search;
            }
            this.getData(query);
        },
    };
</script>
