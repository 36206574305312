import { get, put } from '@/utils/http';
const queryString = require('query-string');

export function getForms(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/forms' + _query);
}

export function updateForm(data) {
    return put('/forms', data);
}
