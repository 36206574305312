<template>
    <section>
        <div class="modal is-active">
            <div class="modal-background"></div>
            <div
                class="modal-card"
                style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title mr-2">{{ t.add }}</p>
                    <button
                        type="button"
                        class="delete"
                        @click="$emit('close')" />
                </header>
                <section class="modal-card-body">
                    <b-field :label="t[getTitle]">
                        <b-input
                            v-if="title == 'waterintakes' || title == 'sleephours' || title == 'exercisehours' || title == 'fruits' || title == 'veggies' || title == 'plantbasedmeals'"
                            type="number"
                            v-model.number="value">
                        </b-input>
                    </b-field>
                    <div v-if="title == 'vitamins'">
                        <b-field
                            v-for="(type, index) in vitamins"
                            :key="index">
                            <b-checkbox
                                type="is-secondary"
                                class="text"
                                :native-value="type.value"
                                v-model="selectedVitamins"
                                >{{ type.name.toUpperCase() }}</b-checkbox
                            ><br />
                        </b-field>
                    </div>

                    <div v-if="title == 'poos'">
                        <b-field
                            v-for="(type, index) in pooTypes"
                            :key="index">
                            <b-checkbox
                                :disabled="selectedTypes.length >= 3 && selectedTypes.indexOf(type) == -1"
                                type="is-secondary"
                                class="text"
                                :native-value="type.value"
                                v-model="selectedTypes"
                                >{{ type.type }}</b-checkbox
                            ><br />
                        </b-field>
                    </div>
                </section>
                <footer class="modal-card-foot center-inside">
                    <b-button
                        :label="t.save"
                        @click="save"
                        type="is-primary" />
                </footer>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        props: ['title'],
        data() {
            return {
                value: null,
                t: this.$t('components.Tracker.TrackerModal'),
                vitamins: [
                    {
                        name: 'b12',
                        value: 'b12',
                    },
                    {
                        name: 'omega 3',
                        value: 'omega3',
                    },
                    {
                        name: 'd3',
                        value: 'd3',
                    },
                ],
                pooTypes: [],
                selectedTypes: [],
                selectedVitamins: [],
            };
        },
        computed: {
            getTitle() {
                switch (this.title) {
                    case 'waterintakes':
                        return 'water';

                    case 'exercisehours':
                        return 'exercise';

                    case 'plantbasedmeals':
                        return 'meals';

                    case 'fruits':
                        return 'fruits';

                    case 'veggies':
                        return 'veggies';

                    case 'vitamins':
                        return 'vitamins';

                    case 'poos':
                        return 'poo';

                    case 'sleephours':
                        return 'sleep';
                }
            },
        },
        methods: {
            closeModal(event) {
                this.$emit('close', false);
            },
            save(event) {
                if (this.title === 'vitamins') {
                    this.$emit('addTracker', this.selectedVitamins);
                    this.closeModal();
                } else if (this.title === 'poos') {
                    this.$emit('addTracker', this.selectedTypes);
                    this.closeModal();
                } else {
                    this.$emit('addTracker', this.value);
                    this.closeModal();
                }
            },
        },
        created() {
            this.pooTypes = this.t.types;
        },
    };
</script>

<style lang="scss" scoped>
    p,
    .modal-card-body {
        font-family: 'Lota Grotesque';
    }

    .modal-card-title {
        font-size: 1rem;
    }

    .modal-card-body {
        font-size: 0.9rem !important;
    }
</style>
