<template>
    <section>
        <b-sidebar
            :fullheight="true"
            :overlay="isMobile"
            v-model="isSidebarOpen"
            :on-cancel="closeSidebar"
            class="sidebar-home"
            :can-cancel="isMobile ? ['escape', 'outside'] : null"
            type="is-light">
            <div class="top-bar">
                <img
                    :src="require('@/assets/icons/coracao_verde.png')"
                    alt="" />
                <b-button
                    v-show="isMobile"
                    @click="closeSidebar"
                    type="is-ghost"
                    class="has-text-black">
                    <b-icon
                        icon="mdi mdi-close"
                        size="is-medium"></b-icon>
                </b-button>
            </div>
            <div class="p-1">
                <b-menu class="is-custom-mobile">
                    <b-menu-list label="App">
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-silverware-fork"
                            :label="t.recipes"
                            @click="goTo('Recipes')"
                            :active="$route.name == 'Recipes'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-silverware-fork"
                            :label="t.user_recipes"
                            @click="goTo('BackOfficeUserRecipes')"
                            :active="$route.name == 'BackOfficeUserRecipes'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-food-apple"
                            :label="t.ingredients"
                            @click="goTo('Ingredients')"
                            :active="$route.name == 'Ingredients'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-calendar-text"
                            :label="t.dailyphrases"
                            @click="goTo('DailyPhrases')"
                            :active="$route.name == 'DailyPhrases'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-help"
                            :label="t.support"
                            @click="goTo('Support')"
                            :active="$route.name == 'Support'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-account-multiple"
                            :label="t.partners"
                            @click="goTo('BackOfficePartners')"
                            :active="$route.name == 'BackOfficePartners'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-ticket-percent"
                            :label="t.coupons"
                            @click="goTo('BackOfficeCoupons')"
                            :active="$route.name == 'BackOfficeCoupons'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-new-box"
                            :label="t.upcomingnews"
                            @click="goTo('BackOfficeUpcomingNews')"
                            :active="$route.name == 'BackOfficeUpcomingNews'"></b-menu-item>
                    </b-menu-list>
                    <b-menu-list label="Site">
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-newspaper"
                            :label="t.news"
                            @click="goTo('BackOfficeNews')"
                            :active="$route.name == 'BackOfficeNews'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-newspaper"
                            :label="t.posts"
                            @click="goTo('BackOfficePosts')"
                            :active="$route.name == 'BackOfficePosts'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-volume-high"
                            :label="t.podcasts"
                            @click="goTo('BackOfficePodcasts')"
                            :active="$route.name == 'BackOfficePodcasts'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-video"
                            :label="t.videos"
                            @click="goTo('BackOfficeVideos')"
                            :active="$route.name == 'BackOfficeVideos'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-message"
                            :label="t.testimonials"
                            @click="goTo('BackOfficeTestimonials')"
                            :active="$route.name == 'BackOfficeTestimonials'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-account"
                            :label="t.participations"
                            @click="goTo('BackOfficeParticipations')"
                            :active="$route.name == 'BackOfficeParticipations'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-shopping"
                            :label="t.products"
                            @click="goTo('BackOfficeProducts')"
                            :active="$route.name == 'BackOfficeProducts'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-cart"
                            :label="t.orders"
                            @click="goTo('BackOfficeOrders')"
                            :active="$route.name == 'BackOfficeOrders'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-image-multiple"
                            :label="t.soul_states"
                            @click="goTo('BackOfficeSoulStates')"
                            :active="$route.name == 'BackOfficeSoulStates'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-comment-question-outline"
                            :label="t.forms"
                            @click="goTo('BackOfficeForms')"
                            :active="$route.name == 'BackOfficeForms'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-wallet-giftcard"
                            :label="t.gifts"
                            @click="goTo('BackOfficeGifts')"
                            :active="$route.name == 'BackOfficeGifts'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-calendar-question"
                            :label="t.workshops"
                            @click="goTo('BackOfficeWorkshops')"
                            :active="$route.name == 'BackOfficeWorkshops'"></b-menu-item>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-dumbbell"
                            :label="t.reiki"
                            @click="goTo('BackOfficeReiki')"
                            :active="$route.name == 'BackOfficeReiki'"></b-menu-item>
                    </b-menu-list>
                    <b-menu-list>
                        <b-menu-item
                            class="sidebar"
                            icon="mdi mdi-logout-variant mdi-rotate-180"
                            :label="t.logout"
                            @click="endSession"></b-menu-item>
                    </b-menu-list>
                </b-menu>
            </div>
        </b-sidebar>
    </section>
</template>

<script>
    import { logout } from '@/api/apiAuth';

    export default {
        name: 'Sidebar',
        data() {
            return {
                t: this.$t('components.BackOffice.Sidebar'),
            };
        },
        created() {},
        methods: {
            closeSidebar() {
                this.setSidebarOpen(false);
            },
            endSession() {
                logout()
                    .then((response) => {
                        this.onSessionExpired();
                    })
                    .catch((error) => {});
            },
            goTo(name) {
                this.$router.push({ name: name });
                this.closeSidebar();
            },
        },
    };
</script>

<style lang="scss" scoped>
    .top-bar {
        display: flex;
    }
    .top-bar img {
        height: 10rem;
        margin: auto;
    }
</style>
