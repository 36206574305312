import { get, put } from '@/utils/http';
const queryString = require('query-string');

export function getGifts(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/gifts' + _query);
}

export function updateGift(data) {
    return put('/gifts', data);
}
