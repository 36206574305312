import { render, staticRenderFns } from "./DailyRecipes.vue?vue&type=template&id=a9c69b86&scoped=true"
import script from "./DailyRecipes.vue?vue&type=script&lang=js"
export * from "./DailyRecipes.vue?vue&type=script&lang=js"
import style0 from "./DailyRecipes.vue?vue&type=style&index=0&id=a9c69b86&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "a9c69b86",
  null
  
)

export default component.exports