import store from '@/store';
import axios from 'axios';
import user from '@/mixins/modules/user';

axios.defaults.timeout = 30000;
// MIDDLEWARE
axios.interceptors.response.use(
    function (response) {
        if (response.headers.authorization && response.headers) {
            store.commit('auth/setToken', response.headers.authorization);
        }
        return response;
    },
    function (error) {
        console.error(error);
        if (error.response.data.code !== 'UserNotActivated' && error.response.data.code !== 'WrongEmailOrPassword') {
            if (error.response.status == 401 || error.response.status == 403) {
                user.methods.onSessionExpired();
            }
            return Promise.reject(error);
        } else return Promise.reject(error);
    },
);

const getHeaders = () => {
    let token = store.getters['auth/getToken'];
    if (token) {
        return {
            Authorization: token,
        };
    }
};

export function post(uri, data) {
    return axios.post(endpoint(uri), data, {
        headers: getHeaders(),
    });
}

export function put(uri, data) {
    return axios.put(endpoint(uri), data, {
        headers: getHeaders(),
    });
}

export function remove(uri, data) {
    return axios.delete(endpoint(uri), {
        data,
        headers: getHeaders(),
    });
}

export function get(uri) {
    return axios.get(endpoint(uri), {
        headers: getHeaders(),
    });
}

export function endpoint(uri) {
    return process.env.VUE_APP_API_URL + uri;
}

export function formData(uri, data) {
    let config = {
        headers: getHeaders(),
    };
    config.headers['Content-Type'] = 'multipart/form-data';
    return axios.post(endpoint(uri), data, config);
}
export function formDataPut(uri, data) {
    let config = {
        headers: getHeaders(),
    };
    config.headers['Content-Type'] = 'multipart/form-data';
    return axios.put(endpoint(uri), data, config);
}
