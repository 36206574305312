import store from '@/store/index';

export default {
    computed: {
        getSpotStep() {
            return store.getters['mySpotSteps/getMySpotStep'];
        },
    },
    methods: {
        updateSpotStep(step) {
            store.commit('mySpotSteps/setMySpotStep', step);
        },
    },
};
