<template>
    <section>
        <TopMenu />
        <div class="main">
            <div class="has-text-centered mt-3">
                <p class="title has-text-primary header">{{ t.guide }}</p>
            </div>

            <div class="has-text-centered">
                <b-icon
                    pack="bl-icons"
                    icon="bl-question"
                    type="is-primary"
                    class="is-size-3 mt-2 mb-6">
                </b-icon>

                <p class="black-bold mb-3">{{ this.t.appGuide }}</p>

                <div class="body-icons">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-home"
                        type="is-primary"
                        class="is-size-4">
                    </b-icon>
                    <b-icon
                        pack="bl-icons"
                        icon="bl-file"
                        type="is-primary"
                        class="is-size-4">
                    </b-icon>
                    <b-icon
                        pack="bl-icons"
                        icon="bl-cesto_compras"
                        type="is-primary"
                        class="is-size-4">
                    </b-icon>
                    <b-icon
                        pack="bl-icons"
                        icon="bl-user"
                        type="is-primary"
                        class="is-size-4">
                    </b-icon>
                </div>

                <!-- <p class="has-text-primary mt-10 mb-3" @click="seeInfo('servingSizes')">{{ this.t.servingSizes }}</p> -->
                <p
                    class="has-text-primary mt-10 mb-3"
                    @click="seeInfo('grocery')">
                    {{ this.t.grocery }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('newMenu')">
                    {{ this.t.newMenu }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('favorites')">
                    {{ this.t.favorites }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('create')">
                    {{ this.t.create }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('newRecipes')">
                    {{ this.t.newRecipes }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('weekRecipes')">
                    {{ this.t.weekRecipes }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('findRecipes')">
                    {{ this.t.findRecipes }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('alergies')">
                    {{ this.t.alergies }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('updatePref')">
                    {{ this.t.updatePref }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('changeMeasure')">
                    {{ this.t.changeMeasure }}
                </p>
            </div>
        </div>
        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomDefaultMenu.vue';
    export default {
        components: {
            TopMenu,
            BotMenu,
        },

        data() {
            return {
                t: this.$t('views.AppGuide'),
            };
        },

        methods: {
            seeInfo(infoType) {
                this.$router.push({ name: 'AppGuideInfo', params: { type: infoType } });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .main {
        padding-top: 100px;
        padding-bottom: 110px;
    }

    .title {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .body-icons {
        justify-content: space-between;
        display: flex;
        width: 70%;
        margin: 0 auto;
    }

    .header {
        font-family: 'Borest-Standard';
        font-size: 55px;
        font-weight: normal;
    }

    .black-bold {
        font-family: 'Lota Grotesque';
        font-size: 16px;
        font-weight: normal;
    }

    p {
        font-family: 'Lota Grotesque';
        font-size: 14px;
        font-weight: bold;
    }

    @media only screen and (max-width: 350px) {
        .main {
            padding-top: 76px;
        }

        p {
            font-size: 12px;
        }

        .header {
            font-size: 50px;
        }
    }
</style>
