<template>
    <form
        @submit.prevent="formSubmit"
        class="columns is-mobile is-multiline">
        <b-field
            :type="invalids.includes('name') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.name">
            <b-input
                type="text"
                v-model="form.name"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('profession.pt') ? 'is-danger' : ''"
            class="column is-6"
            :label="t.profession.pt">
            <b-input
                type="text"
                v-model="form.profession.pt"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('profession.en') ? 'is-danger' : ''"
            class="column is-6"
            :label="t.profession.en">
            <b-input
                type="text"
                v-model="form.profession.en"></b-input>
        </b-field>
        <b-tabs
            v-model="activeTab"
            style="min-width: 100%">
            <b-tab-item :label="t.testimonial.pt">
                <b-field
                    :type="invalids.includes('testimonial.pt') ? 'is-danger' : ''"
                    class="column is-12">
                    <b-input
                        type="textarea"
                        v-model="form.testimonial.pt"></b-input>
                </b-field>
            </b-tab-item>
            <b-tab-item :label="t.testimonial.en">
                <b-field
                    :type="invalids.includes('testimonial.en') ? 'is-danger' : ''"
                    class="column is-12">
                    <b-input
                        type="textarea"
                        v-model="form.testimonial.en"></b-input>
                </b-field>
            </b-tab-item>
        </b-tabs>
        <b-field
            :type="invalids.includes('image') ? 'is-danger' : ''"
            class="column is-12 has-text-centered"
            :label="t.image">
            <b-upload
                v-model="form.image"
                expanded
                drag-drop
                accept="image/*">
                <section class="section">
                    <div
                        v-if="!form.image && !form._image"
                        class="content has-text-centered">
                        <p>
                            <b-icon
                                icon="upload"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>{{ t.upload }}</p>
                    </div>
                    <div
                        v-else
                        class="content has-text-centered">
                        <img
                            class="img-preview"
                            :src="getFileURL" />
                    </div>
                </section>
            </b-upload>
        </b-field>
        <b-field
            :type="invalids.includes('image') ? 'is-danger' : ''"
            class="column is-12 has-text-centered"
            :label="t.color">
            <v-swatches
                v-model="form.color"
                inline
                :swatches="swatches"
                background-color="lightgray"></v-swatches>
        </b-field>
        <div class="column is-offset-4-desktop is-4-desktop is-12-touch">
            <b-button
                expanded
                :loading="isLoading"
                class="is-headline-bold-d-d is-headline-m-m"
                type="is-primary"
                native-type="submit"
                >{{ t.submit }}</b-button
            >
        </div>
    </form>
</template>
<script>
    import { addTestimonial, getTestimonials, updateTestimonial } from '@/api/testimonials';
    import VSwatches from 'vue-swatches';

    import 'vue-swatches/dist/vue-swatches.css';

    export default {
        name: 'TestimonialsForm',
        components: {
            VSwatches,
        },
        data() {
            return {
                t: this.$t('components.BackOffice.Testimonials.Form'),
                activeTab: 0,
                isUpdate: false,
                isLoading: false,
                invalids: [],
                swatches: ['#3DB8BC', '#93BFB7', '#BF8897', '#F2D8E1', '#D9B0C3', '#BFD9D1'],
                form: {
                    name: null,
                    profession: {
                        pt: null,
                        en: null,
                    },
                    image: null,
                    testimonial: {
                        pt: null,
                        en: null,
                    },
                    color: null,
                },
            };
        },
        created() {
            if (this.$route.name == 'UpdateTestimonials') {
                this.isUpdate = true;
                this.getData();
            }
        },
        methods: {
            getData() {
                //used to update
                let query = {
                    id: this.$route.params.id,
                };
                getTestimonials(query)
                    .then((response) => {
                        let testimonial = response.data.testimonials[0];

                        if (this.isUpdate) this.$emit('on-edit', testimonial.name);

                        this.form = {
                            id: testimonial._id,
                            name: testimonial.name,
                            testimonial: testimonial.testimonial,
                            profession: testimonial.profession,
                            image: null,
                            _image: testimonial.image,
                            color: testimonial.color,
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            formSubmit() {
                this.isLoading = true;
                this.invalids = [];
                let copy = Object.clone(this.form);

                let image = this.form.image;
                delete copy.image;

                if (!this.isUpdate) {
                    addTestimonial(image, copy)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeTestimonials' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updateTestimonial(image, copy)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.update_success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeTestimonials' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
        },
        computed: {
            getFileURL() {
                if (this.form.image) {
                    let url = window.URL.createObjectURL(this.form.image);
                    return url;
                } else if (this.form._image) {
                    return this.form._image;
                }
            },
        },
    };
</script>
