<template>
    <div>
        <form
            @submit.prevent="formSubmit"
            class="columns is-mobile is-multiline">
            <b-field
                :type="invalids.includes('type') ? 'is-danger' : ''"
                class="column is-12-mobile is-6-desktop"
                :label="t.type.label">
                <b-select
                    v-model="form.type"
                    :placeholder="t.type.placeholder"
                    required
                    expanded>
                    <option
                        v-for="(option, name, i) in $t('shared.ingredient_types')"
                        :value="name"
                        :key="i">
                        {{ option }}
                    </option>
                </b-select>
            </b-field>
            <b-field
                :type="invalids.includes('type') ? 'is-danger' : ''"
                class="column is-12-mobile is-6-desktop"
                :label="t.category.label">
                <b-select
                    v-model="form.category"
                    :placeholder="t.type.placeholder"
                    required
                    expanded>
                    <option
                        v-for="(option, name, i) in $t('shared.ingredient_categories')"
                        :value="name"
                        :key="i">
                        {{ option }}
                    </option>
                </b-select>
            </b-field>
            <b-field
                class="column is-12-mobile is-6-desktop"
                :label="t.description.pt"
                :type="invalids.includes('description.pt') ? 'is-danger' : ''">
                <b-input
                    type="text"
                    v-model="form.description.pt"></b-input>
            </b-field>
            <b-field
                class="column is-12-mobile is-6-desktop"
                :label="t.description.en"
                :type="invalids.includes('description.en') ? 'is-danger' : ''">
                <b-input
                    type="text"
                    v-model="form.description.en"></b-input>
            </b-field>
            <b-field
                :type="invalids.includes('intolerances') ? 'is-danger' : ''"
                class="column is-12"
                :label="t.intolerances.label">
                <b-taginput
                    size="is-small"
                    v-model="form.intolerances"
                    :data="filteredIntolerances"
                    autocomplete
                    :allow-new="false"
                    ellipsis
                    icon="label"
                    type="is-primary"
                    :placeholder="t.intolerances.placeholder"
                    :aria-close-label="t.intolerances.close"
                    @add="checkIntolerances"
                    @typing="getFilteredIntolerances">
                </b-taginput>
            </b-field>
            <div class="column is-offset-2-desktop is-8-desktop is-12-touch">
                <b-button
                    expanded
                    :loading="isLoading"
                    class="is-headline-bold-d-d is-headline-m-m"
                    type="is-primary"
                    native-type="submit"
                    >{{ t.submit }}</b-button
                >
            </div>
        </form>
    </div>
</template>

<script>
    import { addIngredient, updateIngredient, getIngredients } from '@/api/ingredients';
    export default {
        name: 'Form',
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.prevQuery = to.params;
            });
        },
        data() {
            return {
                t: this.$t('components.BackOffice.Ingredients.Form'),
                isUpdate: false,
                isLoading: false,
                prevQuery: null,
                invalids: [],
                intolerances: [],
                filteredIntolerances: [],
                form: {
                    description: {
                        pt: null,
                        en: null,
                    },
                    intolerances: [],
                    type: null,
                    category: null,
                },
            };
        },
        created() {
            Object.keys(this.$t('shared.intolerances')).forEach((key) => {
                this.intolerances.push({ key: key, value: this.$t('shared.intolerances')[key] });
            });
            if (this.$route.name == 'UpdateIngredient') {
                this.isUpdate = true;
                this.getData();
            }
        },
        methods: {
            getData() {
                //used to update
                let query = {
                    id: this.$route.params.id,
                };
                getIngredients(query)
                    .then((response) => {
                        let ingr = response.data.ingredients[0];

                        if (this.isUpdate) this.$emit('on-edit', ingr.description[this.getLocale]);

                        ingr.intolerances.forEach((intolerance, i) => {
                            ingr.intolerances[i] = this.intolerances.filter((t) => t.key == intolerance)[0];
                        });
                        this.form = {
                            id: ingr._id,
                            name: ingr.name,
                            description: {
                                pt: ingr.description.pt,
                                en: ingr.description.en,
                            },
                            intolerances: ingr.intolerances,
                            type: ingr.type,
                            category: ingr.category,
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            getFilteredIntolerances(text) {
                this.filteredIntolerances = this.intolerances.filter((option) => {
                    return option.value.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
                });
            },
            checkIntolerances(value) {
                if (value.key == 'all') this.form.intolerances = this.intolerances;
            },
            formSubmit() {
                this.invalids = [];
                let _copy = Object.clone(this.form);
                _copy.intolerances.forEach((intolerance, index) => {
                    _copy.intolerances[index] = intolerance.key;
                });
                if (!this.isUpdate) {
                    addIngredient(_copy)
                        .then((response) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'Ingredients', params: this.prevQuery });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                    if (element.msg.code == 'Duplicated') message = this.t.api.duplicated;
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updateIngredient(_copy)
                        .then((response) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.updateSuccess,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'Ingredients', params: this.prevQuery });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }

                            this.$buefy.toast.open({
                                message: this.t.api.error,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
        },
        computed: {},
    };
</script>

<style lang="scss" scoped>
    .pagination-list {
        display: none;
    }
    .is-top-bordered {
        border-top: 1px solid black;
    }
</style>
