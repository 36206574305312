<template>
    <section>
        <TopMenu />
        <CategoryMenu />

        <div class="main">
            <p class="has-text-centered has-text-primary">{{ t.promotionText }}</p>
            <p class="has-text-centered has-text-primary mt-6">{{ t.promotionTextTwo }}</p>

            <div
                class="columns is-mobile is-vcentered"
                style="min-height: 35vh">
                <div class="column is-fullwidth center-inside">
                    <b-button
                        class="button is-rounded is-primary"
                        @click="goToStore"
                        >{{ t.store }}</b-button
                    >
                </div>
            </div>

            <!-- <div class="button-container mt-10">
				<b-button class="button is-rounded is-primary">{{ t.books }}</b-button>
				<br />
				<b-button class="button is-rounded is-primary">{{ t.cookingSchool }}</b-button>
				<br />
				<b-button class="button is-rounded is-primary">{{ t.workshops }}</b-button>
				<br />
				<b-button class="button is-rounded is-primary">{{ t.nutrition }}</b-button>
			</div> -->
        </div>

        <div class="socials has-text-secondary mb-5">
            <b-icon
                @click.native="goToInstagram"
                icon="instagram"
                custom-size="mdi-24px"></b-icon>
            <b-icon
                icon="facebook"
                @click.native="goToFacebook"
                custom-size="mdi-24px">
            </b-icon>
            <b-icon
                icon="pinterest"
                @click.native="goToPinterest"
                custom-size="mdi-24px">
            </b-icon>
            <b-icon
                icon="twitter"
                @click.native="goToTwitter"
                custom-size="mdi-24px">
            </b-icon>

            <!-- 	<b-icon icon="share-variant" custom-size="mdi-24px"> </b-icon> -->
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import CategoryMenu from '@/components/MyShop/CategoryMenu.vue';
    import { Browser } from '@capacitor/browser';

    export default {
        components: {
            TopMenu,
            CategoryMenu,
        },
        data() {
            return {
                t: this.$t('components.MyShop.Products'),
            };
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },

            goToStore() {
                if (this.isMobile) {
                    /* 	const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://site.belovebeyou.iotech.pt/store/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://site.belovebeyou.iotech.pt/store/', '_blank');
                } else window.open('https://site.belovebeyou.iotech.pt/store/', '_blank');
            },

            goToInstagram() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://www.instagram.com/belovebeyou/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://www.instagram.com/belovebeyou/', '_blank');
                } else window.open('https://www.instagram.com/belovebeyou/', '_blank');
            },
            goToFacebook() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/', '_blank');
                } else window.open('https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/', '_blank');
            },
            goToPinterest() {
                if (this.isMobile) {
                    /* 	const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://www.pinterest.pt/blovebyoufg/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://www.pinterest.pt/blovebyoufg/', '_blank');
                } else window.open('https://www.pinterest.pt/blovebyoufg/', '_blank');
            },
            goToTwitter() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://twitter.com/belovebeyoufg' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://twitter.com/belovebeyoufg', '_blank');
                } else window.open('https://twitter.com/belovebeyoufg', '_blank');
            },
        },
        created() {
            this.scrollToTop();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 240px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 130px;
        }

        @include from($mobile-s) {
            min-height: 90vh;
        }
    }

    .main p {
        font-family: 'Lota Grotesque';
        font-size: 0.875rem;
    }

    .button-container {
        display: flex;
        align-items: center;
        flex-direction: column;
    }

    .socials {
        justify-content: space-between;
        display: flex;
        padding-left: 5%;
        padding-right: 5%;
        margin-top: 20px;
        @include from($mobile-l) {
            margin: 0 auto;
            max-width: $card-max-width;
        }
    }

    a {
        color: $secondary;
    }
</style>
