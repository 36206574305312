<template>
    <form
        @submit.prevent="formSubmit"
        class="columns is-mobile is-multiline">
        <b-field
            :type="invalids.includes('name.pt') ? 'is-danger' : ''"
            class="column is-12-mobile is-6-desktop"
            :label="t.name.pt">
            <b-input
                type="text"
                v-model="form.name.pt"
                required></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('name.en') ? 'is-danger' : ''"
            class="column is-12-mobile is-6-desktop"
            :label="t.name.en">
            <b-input
                type="text"
                v-model="form.name.en"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('description.pt') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.description.pt">
            <b-input
                type="textarea"
                v-model="form.description.pt"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('description.en') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.description.en">
            <b-input
                type="textarea"
                v-model="form.description.en"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('unit_amount') ? 'is-danger' : ''"
            class="column is-4-desktop is-12-mobile"
            :label="t.price">
            <b-input
                type="decimal"
                v-model="form.unit_amount"
                :required="form.purchasable"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('url') ? 'is-danger' : ''"
            class="column is-8-desktop is-12-mobile"
            :label="t.url">
            <!-- <b-input type="url" v-model="form.url" :required="!form.purchasable"></b-input> -->
            <b-input
                type="url"
                v-model="form.url"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('purchasable') ? 'is-danger' : ''"
            class="column is-4-desktop is-12-mobile">
            <b-checkbox
                v-model="form.purchasable"
                class="inverted-checkbox">
                {{ t.purchasable }}
            </b-checkbox>
        </b-field>
        <b-field
            :type="invalids.includes('store') ? 'is-danger' : ''"
            class="column is-4-desktop is-12-mobile">
            <b-checkbox
                v-model="form.store"
                class="inverted-checkbox">
                {{ t.store }}
            </b-checkbox>
        </b-field>
        <b-field
            :type="invalids.includes('image') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.images">
            <b-upload
                @input="onImgAdded"
                v-model="uploadedImgs"
                :loading="isLoadingUpload"
                expanded
                drag-drop
                multiple
                accept="image/*">
                <section class="section">
                    <div class="content has-text-centered">
                        <p>
                            <b-icon
                                icon="upload"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>{{ t.upload }}</p>
                    </div>
                </section>
            </b-upload>
        </b-field>
        <div
            class="column is-12"
            id="sortableGrid">
            <div
                v-for="img in form.images"
                :key="img">
                <button
                    class="button is-small is-danger"
                    @click="deleteImg(img)">
                    <span class="icon">
                        <i class="mdi mdi-delete"></i>
                    </span>
                </button>
                <img
                    :src="img"
                    alt="" />
            </div>
        </div>

        <div class="column is-offset-4-desktop is-4-desktop is-12-touch">
            <b-button
                expanded
                :loading="isLoading"
                class="is-headline-bold-d-d is-headline-m-m"
                type="is-primary"
                native-type="submit"
                >{{ t.submit }}</b-button
            >
        </div>
    </form>
</template>
<script>
    import { addProduct, getProducts, updateProduct, uploadImages } from '@/api/products';
    import Sortable from 'sortablejs';

    export default {
        name: 'ProductsForm',
        data() {
            return {
                t: this.$t('components.BackOffice.Products.Form'),
                activeTab: 0,
                isUpdate: false,
                isLoading: false,
                isLoadingUpload: false,
                invalids: [],
                uploadedImgs: [],
                form: {
                    name: {
                        pt: null,
                        en: null,
                    },
                    unit_amount: null,
                    images: [],
                    description: {
                        pt: null,
                        en: null,
                    },
                    url: null,
                    purchasable: true,
                    store: true,
                },
            };
        },
        created() {
            if (this.$route.name == 'UpdateProducts') {
                this.isUpdate = true;
                this.getData();
            }
        },
        mounted() {
            const that = this;
            const el = document.getElementById('sortableGrid');
            let sortable = Sortable.create(el, {
                disabled: false,
                ghostClass: 'sortable-ghost',
                onEnd(event) {
                    // saveOrder();
                    // get children img src
                    let images = [];
                    el.childNodes.forEach((child) => {
                        images.push(child.childNodes[1].src);
                    });
                    that.form.images = images;
                },
            });
        },
        methods: {
            onImgAdded(files) {
                console.log(files);
                this.isLoadingUpload = true;
                uploadImages(files)
                    .then((response) => {
                        response.data.forEach((element) => {
                            this.form.images.push(element);
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.uploadedImgs = [];
                        this.isLoadingUpload = false;
                    });
            },
            getData() {
                //used to update
                let query = {
                    id: this.$route.params.id,
                };
                getProducts(query)
                    .then((response) => {
                        let product = response.data.products[0];

                        if (this.isUpdate) this.$emit('on-edit', product.name[this.getLocale]);

                        this.form = {
                            id: product._id,
                            name: product.name,
                            unit_amount: product.unit_amount,
                            description: product.description,
                            url: product.url,
                            purchasable: product.purchasable,
                            store: product.store,
                            images: product.images,
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            deleteImg(img) {
                this.form.images = this.form.images.filter((image) => image != img);
            },
            formSubmit() {
                this.isLoading = true;
                this.invalids = [];
                let copy = Object.clone(this.form);
                // delete copy.images;
                let images = [];
                /* if (copy.unit_amount != '') copy.unit_amount = this.form.unit_amount.replace(',', '.');
				else delete copy.unit_amount; */

                if (!this.isUpdate) {
                    addProduct(images, copy)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeProducts' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updateProduct(images, copy)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.update_success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeProducts' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .img-preview {
        height: 30vh;
    }

    #sortableGrid {
        display: flex;
        flex-wrap: wrap;

        > div {
            position: relative;
        }

        img {
            width: 256px;
            height: 256px;
            object-fit: contain;
            margin: 0.5rem;

            &:hover {
                cursor: move;
            }
        }
    }

    .sortable-ghost {
        z-index: 6;
    }

    .sortable-ghost::after {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: hsl(182, 51%, 49%);
        opacity: 0.3;
        z-index: 5;
    }
</style>
