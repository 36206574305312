<template>
    <section>
        <p class="has-text-secondary has-text-centered title">Cancelar Subscrição</p>
        <p class="has-text-primary has-text-centered">Tem a certeza que pretende cancelar a sua subscrição?</p>

        <div style="display: grid">
            <b-button
                :loading="isLoading"
                class="renew mt-6"
                type="is-primary"
                @click="goBack"
                >Voltar à página principal</b-button
            ><br />
            <b-button
                :loading="isLoading"
                class="cancel"
                type="is-white"
                @click="cancel"
                >Cancelar</b-button
            >
        </div>
    </section>
</template>

<script>
    import { cancelSubscription } from '@/api/apiUser';
    export default {
        data() {
            return {
                isLoading: false,
            };
        },
        methods: {
            cancel() {
                cancelSubscription()
                    .then((response) => {})
                    .catch((error) => {
                        console.error(error);
                    });
            },

            goBack() {
                if (this.getUser.subscription.status === 'active') {
                    this.goToPage('Home');
                } else this.goToPage('SignSubscription');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .title {
        font-size: 2rem;
        margin-top: 30px;
    }

    .renew,
    .cancel {
        margin: 0 auto;
    }

    .cancel {
        border: solid 1px $primary !important;
    }
</style>
