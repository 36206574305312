<template>
    <section>
        <TopMenu />
        <div class="main">
            <b-icon
                pack="bl-icons"
                icon="bl-arrow-left"
                type="is-black"
                class="is-size-4"
                @click.native="$router.back()">
            </b-icon>
            <highcharts
                v-if="$route.params.type === 'vitamins'"
                :options="vitaminsChart"></highcharts>
            <highcharts
                v-else-if="$route.params.type === 'poos'"
                :options="pooChart"></highcharts>
            <highcharts
                v-else
                :options="chartOptions"></highcharts>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import Highcharts from 'highcharts';
    import exportingInit from 'highcharts/modules/exporting';
    import moment from 'moment';

    import { getTrackerData } from '@/api/tracker';

    exportingInit(Highcharts);
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                t: this.$t('components.Tracker.Chart'),
                chartOptions: {
                    exporting: {
                        enabled: false,
                    },
                    chart: {
                        type: 'column',
                    },
                    legend: {
                        itemStyle: {
                            font: 'bold 15px Reftograph',
                        },
                    },
                    title: {
                        text: '',
                        style: {
                            font: 'bold 16px "Borest-Standard"',
                        },
                    },
                    subtitle: {
                        text: '',
                    },
                    xAxis: {
                        categories: [],
                        crosshair: true,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '',
                            style: {
                                font: '14px "Borest-Standard"',
                            },
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: [
                        {
                            name: '',
                            data: [],
                            color: '#3db8bc',
                        },
                    ],
                    credits: {
                        enabled: false,
                    },
                },

                vitaminsChart: {
                    exporting: {
                        enabled: false,
                    },
                    chart: {
                        type: 'column',
                    },
                    legend: {
                        itemStyle: {
                            font: 'bold 12px Borest-Standard',
                        },
                    },
                    title: {
                        text: '',
                        style: {
                            font: 'bold 16px "Borest-Standard"',
                        },
                    },
                    subtitle: {
                        text: '',
                    },
                    xAxis: {
                        categories: [],
                        crosshair: true,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '',
                            style: {
                                font: '14px "Borest-Standard"',
                            },
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            pointPadding: 0.2,
                            borderWidth: 0,
                        },
                    },
                    series: [
                        {
                            name: 'B12',
                            data: [],
                            color: '#3db8bc',
                        },
                        {
                            name: 'OMEGA 3',
                            data: [],
                            color: '#bf8897',
                        },
                        {
                            name: 'D3',
                            data: [],
                            color: '#E8CED7',
                        },
                    ],
                    credits: {
                        enabled: false,
                    },
                },

                pooChart: {
                    exporting: {
                        enabled: false,
                    },
                    chart: {
                        type: 'column',
                    },
                    legend: {
                        itemStyle: {
                            font: 'bold 12px Borest-Standard',
                        },
                    },
                    title: {
                        text: '',
                        style: {
                            font: 'bold 16px "Borest-Standard"',
                        },
                    },
                    subtitle: {
                        text: '',
                    },
                    xAxis: {
                        categories: [],
                        crosshair: true,
                    },
                    yAxis: {
                        min: 0,
                        title: {
                            text: '',
                            style: {
                                font: '14px "Borest-Standard"',
                            },
                        },
                    },
                    tooltip: {
                        headerFormat: '<span style="font-size:10px">{point.key}</span><table>',
                        pointFormat: '<tr><td style="color:{series.color};padding:0">{series.name}: </td>' + '<td style="padding:0"><b>{point.y:1f}</b></td></tr>',
                        footerFormat: '</table>',
                        shared: true,
                        useHTML: true,
                    },
                    plotOptions: {
                        column: {
                            stacking: 'normal',
                        },
                    },

                    series: [
                        {
                            name: 'Type 1',
                            data: [],
                            stack: 'bad',
                            color: '#FF0000',
                        },
                        {
                            name: 'Type 2',
                            data: [],
                            stack: 'medium',
                            color: '#F5C33B',
                        },
                        {
                            name: 'type 3',
                            data: [],
                            stack: 'good',
                            color: '#3db8bc',
                        },
                        {
                            name: 'Type 4',
                            data: [],
                            stack: 'good',
                            color: '#48DBE0',
                        },
                        {
                            name: 'Type 5',
                            data: [],
                            stack: 'medium',
                            color: '#F5C43B',
                        },
                        {
                            name: 'Type 6',
                            data: [],
                            stack: 'medium',
                            color: '#C29B2F',
                        },
                        {
                            name: 'Type 7',
                            data: [],
                            stack: 'bad',
                            color: '#AD0000',
                        },
                    ],
                    credits: {
                        enabled: false,
                    },
                },
            };
        },

        computed: {
            getYear() {
                return moment(new Date()).format('YYYY');
            },
        },

        created() {
            switch (this.$route.params.query) {
                case 'week':
                    this.chartOptions.title.text = this.t.week;
                    this.vitaminsChart.title.text = this.t.week;
                    break;

                case 'month':
                    this.chartOptions.title.text = this.t.month;
                    this.vitaminsChart.title.text = this.t.month;
                    break;

                case 'year':
                    this.chartOptions.title.text = this.t.year;
                    this.vitaminsChart.title.text = this.t.year;
                    break;
            }

            switch (this.$route.params.type) {
                case 'waterintakes':
                    this.chartOptions.series[0].name = this.t.water;

                    break;

                case 'exercisehours':
                    this.chartOptions.series[0].name = this.t.exercise;
                    break;

                case 'sleephours':
                    this.chartOptions.series[0].name = this.t.sleep;
                    break;

                case 'fruits':
                    this.chartOptions.series[0].name = this.t.fruits;
                    break;

                case 'veggies':
                    this.chartOptions.series[0].name = this.t.veggies;
                    break;

                case 'plantbasedmeals':
                    this.chartOptions.series[0].name = this.t.meals;
                    break;
            }

            this.chartOptions.yAxis.title.text = this.t.quantity;

            let data = {
                query: this.$route.params.query,
            };

            getTrackerData(this.$route.params.type, data)
                .then((response) => {
                    response.data.data.forEach((element, index) => {
                        this.chartOptions.series[0].data.push(element.quantity);

                        if (this.$route.params.query === 'week') {
                            if (this.$route.params.type === 'vitamins') {
                                this.vitaminsChart.xAxis.categories.push(moment(element.date).format('dddd'));
                                this.vitaminsChart.series[0].data.push(null);
                                this.vitaminsChart.series[1].data.push(null);
                                this.vitaminsChart.series[2].data.push(null);
                                element.type.forEach((type) => {
                                    if (type === 'b12') {
                                        this.vitaminsChart.series[0].data[index] = 1;
                                    } else if (type === 'omega3') {
                                        this.vitaminsChart.series[1].data[index] = 1;
                                    } else this.vitaminsChart.series[2].data[index] = 1;
                                });
                            } else if (this.$route.params.type === 'poos') {
                                this.pooChart.xAxis.categories.push(moment(element.date).format('dddd'));
                                this.pooChart.series[0].data.push(null);
                                this.pooChart.series[1].data.push(null);
                                this.pooChart.series[2].data.push(null);
                                this.pooChart.series[3].data.push(null);
                                this.pooChart.series[4].data.push(null);
                                this.pooChart.series[5].data.push(null);
                                this.pooChart.series[6].data.push(null);

                                element.type.forEach((type) => {
                                    if (type === 'type1') {
                                        this.pooChart.series[0].data[index] = 1;
                                    } else if (type === 'type2') {
                                        this.pooChart.series[1].data[index] = 1;
                                    } else if (type === 'type3') {
                                        this.pooChart.series[2].data[index] = 1;
                                    } else if (type === 'type4') {
                                        this.pooChart.series[3].data[index] = 1;
                                    } else if (type === 'type5') {
                                        this.pooChart.series[4].data[index] = 1;
                                    } else if (type === 'type6') {
                                        this.pooChart.series[5].data[index] = 1;
                                    } else this.pooChart.series[6].data[index] = 1;
                                });
                            } else this.chartOptions.xAxis.categories.push(moment(element.date).format('dddd'));
                        } else if (this.$route.params.query === 'year') {
                            if (this.$route.params.type === 'vitamins') {
                                this.vitaminsChart.xAxis.categories.push(this.getMonth(moment(element.month)));
                                this.vitaminsChart.series[0].data.push(null);
                                this.vitaminsChart.series[1].data.push(null);
                                this.vitaminsChart.series[2].data.push(null);

                                element.values.forEach((vitamin) => {
                                    if (vitamin.type == 'b12') {
                                        this.vitaminsChart.series[0].data[index] = vitamin.count;
                                    } else if (vitamin.type == 'omega3') {
                                        this.vitaminsChart.series[1].data[index] = vitamin.count;
                                    } else this.vitaminsChart.series[2].data[index] = vitamin.count;
                                });
                            } else if (this.$route.params.type === 'poos') {
                                this.pooChart.xAxis.categories.push(this.getMonth(moment(element.month)));
                                this.pooChart.series[0].data.push(null);
                                this.pooChart.series[1].data.push(null);
                                this.pooChart.series[2].data.push(null);
                                this.pooChart.series[3].data.push(null);
                                this.pooChart.series[4].data.push(null);
                                this.pooChart.series[5].data.push(null);
                                this.pooChart.series[6].data.push(null);

                                element.values.forEach((type) => {
                                    if (type.type === 'type1') {
                                        this.pooChart.series[0].data[index] = type.count;
                                    } else if (type.type === 'type2') {
                                        this.pooChart.series[1].data[index] = type.count;
                                    } else if (type.type === 'type3') {
                                        this.pooChart.series[2].data[index] = type.count;
                                    } else if (type.type === 'type4') {
                                        this.pooChart.series[3].data[index] = type.count;
                                    } else if (type.type === 'type5') {
                                        this.pooChart.series[4].data[index] = type.count;
                                    } else if (type.type === 'type6') {
                                        this.pooChart.series[5].data[index] = type.count;
                                    } else this.pooChart.series[6].data[index] = type.count;
                                });
                            } else this.chartOptions.xAxis.categories.push(this.getMonth(moment(element.month)));
                        } else {
                            if (this.$route.params.type === 'vitamins') {
                                this.vitaminsChart.xAxis.categories.push(this.getDays(element.week));
                                this.vitaminsChart.series[0].data.push(null);
                                this.vitaminsChart.series[1].data.push(null);
                                this.vitaminsChart.series[2].data.push(null);
                                element.values.forEach((vitamin) => {
                                    if (vitamin.type == 'b12') {
                                        this.vitaminsChart.series[0].data[index] = vitamin.count;
                                    } else if (vitamin.type == 'omega3') {
                                        this.vitaminsChart.series[1].data[index] = vitamin.count;
                                    } else this.vitaminsChart.series[2].data[index] = vitamin.count;
                                });
                            } else if (this.$route.params.type === 'poos') {
                                this.pooChart.xAxis.categories.push(this.getDays(element.week));
                                this.pooChart.series[0].data.push(null);
                                this.pooChart.series[1].data.push(null);
                                this.pooChart.series[2].data.push(null);
                                this.pooChart.series[3].data.push(null);
                                this.pooChart.series[4].data.push(null);
                                this.pooChart.series[5].data.push(null);
                                this.pooChart.series[6].data.push(null);

                                element.values.forEach((type) => {
                                    if (type.type === 'type1') {
                                        this.pooChart.series[0].data[index] = type.count;
                                    } else if (type.type === 'type2') {
                                        this.pooChart.series[1].data[index] = type.count;
                                    } else if (type.type === 'type3') {
                                        this.pooChart.series[2].data[index] = type.count;
                                    } else if (type.type === 'type4') {
                                        this.pooChart.series[3].data[index] = type.count;
                                    } else if (type.type === 'type5') {
                                        this.pooChart.series[4].data[index] = type.count;
                                    } else if (type.type === 'type6') {
                                        this.pooChart.series[5].data[index] = type.count;
                                    } else this.pooChart.series[6].data[index] = type.count;
                                });
                            } else this.chartOptions.xAxis.categories.push(this.getDays(element));
                        }
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },

        methods: {
            getDays(week) {
                return moment(this.getYear).add(week, 'weeks').startOf('week').format('DD MMM') + ' - ' + moment(this.getYear).add(week, 'weeks').endOf('week').format('DD MMM');
            },

            getMonth(month) {
                return moment().month(month).format('MMMM');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 9rem;
        margin-bottom: 30px;
        @include until($mobile-s) {
            padding-top: 7rem;
        }

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 5rem;
        }
    }
</style>
