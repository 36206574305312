<template>
    <form
        @submit.prevent="formSubmit"
        class="columns is-mobile is-multiline">
        <b-field
            :type="invalids.includes('image') ? 'is-danger' : ''"
            class="column is-12 has-text-centered"
            :label="t.preview">
            <b-upload
                v-model="form.image"
                expanded
                drag-drop
                accept="image/*">
                <section class="section">
                    <div
                        v-if="!form.image && !form._image"
                        class="content has-text-centered">
                        <p>
                            <b-icon
                                icon="upload"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>{{ t.upload }}</p>
                    </div>
                    <div
                        v-else
                        class="content has-text-centered">
                        <img
                            class="img-preview"
                            :src="getFileURL" />
                    </div>
                </section>
            </b-upload>
        </b-field>

        <div class="column is-offset-4-desktop is-4-desktop is-12-touch">
            <b-button
                expanded
                :loading="isLoading"
                class="is-headline-bold-d-d is-headline-m-m"
                type="is-primary"
                native-type="submit"
                >{{ t.submit }}</b-button
            >
        </div>
    </form>
</template>
<script>
    import { addSoulState, getSoulStates, updateSoulState } from '@/api/soulStates';

    export default {
        name: 'SoulStatesForm',
        data() {
            return {
                t: this.$t('components.BackOffice.SoulStates.Form'),
                activeTab: 0,
                isUpdate: false,
                isLoading: false,
                invalids: [],
                form: {
                    name: null,
                    image: null,
                    isActive: null,
                },
            };
        },
        created() {
            if (this.$route.name == 'UpdateSoulStates') {
                this.isUpdate = true;
                this.getData();
            }
        },
        methods: {
            getData() {
                //used to update
                let query = {
                    id: this.$route.params.id,
                };
                getSoulStates(query)
                    .then((response) => {
                        let soul_state = response.data.soul_states[0];
                        this.form = {
                            name: soul_state.name,
                            isActive: soul_state.isActive,
                            image: null,
                            _image: soul_state.image,
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            formSubmit() {
                this.isLoading = true;
                this.invalids = [];
                let image = this.form.image;

                if (!this.isUpdate) {
                    addSoulState(image)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeSoulStates' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updateSoulState(image, form)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.update_success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeSoulStates' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
        },
        computed: {
            getFileURL() {
                if (this.form.image) {
                    let url = window.URL.createObjectURL(this.form.image);
                    return url;
                } else if (this.form._image) {
                    return this.form._image;
                }
            },
        },
    };
</script>
