<template>
    <div class="menu-header">
        <div @click="previousStep">
            <b-icon
                v-if="this.$route.name != 'Products'"
                pack="bl-icons"
                icon="bl-arrow-left"
                class="is-size-5 arrow-left"
                type="is-white" />
        </div>
        <div class="category-container">
            <b-icon
                v-if="this.$route.name == 'Products'"
                pack="bl-icons"
                icon="bl-cesto_compras"
                class="is-size-5"
                type="is-white" />
            <p
                v-if="this.$route.name == 'Products'"
                class="ml-2">
                {{ t.ourProducts }}
            </p>
            <p
                v-if="this.$route.name == 'GiftCard'"
                class="ml-2">
                {{ t.giftCard }}
            </p>
            <p
                v-if="this.$route.name == 'Partners'"
                class="ml-2">
                {{ t.partners }}
            </p>
            <p
                v-if="this.$route.name == 'OurChoices'"
                class="ml-2">
                {{ t.choices }}
            </p>
        </div>
        <div @click="nextStep">
            <b-icon
                v-if="this.$route.name != 'OurChoices'"
                pack="bl-icons"
                icon="bl-arrow-right"
                class="is-size-5 arrow-right"
                type="is-white" />
        </div>
    </div>
</template>

<script>
    export default {
        data() {
            return {
                t: this.$t('components.MyShop.CategoryMenu'),
            };
        },

        methods: {
            nextStep() {
                switch (this.$route.name) {
                    case 'Products':
                        this.goToPage('Partners');
                        break;

                    case 'Partners':
                        this.goToPage('OurChoices');
                        break;

                    /* case 'GiftCard':
						this.goToPage('OurChoices');
						break; */
                }
            },

            previousStep() {
                switch (this.$route.name) {
                    /* case 'GiftCard':
						this.goToPage('Partners');
						break; */

                    case 'Partners':
                        this.goToPage('Products');
                        break;

                    case 'OurChoices':
                        this.goToPage('Partners');
                        break;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .menu-header {
        position: fixed;
        height: 101px;
        background-color: #bfd9d1;
        top: 85px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 5;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 58px;
            top: 53px;
        }
    }
    .menu-header p {
        color: white;
        font-size: 0.875rem;
        font-family: 'Lota Grotesque';
        font-weight: bold;
    }

    .arrow-right {
        position: absolute;
        right: 20px;
        top: 40px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            top: 16px;
        }
    }

    .arrow-left {
        position: absolute;
        left: 20px;
        top: 40px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            top: 16px;
        }
    }
    .category-container {
        display: inline-flex;
        height: 101px;
        justify-content: center;
        align-items: center;
    }
</style>
