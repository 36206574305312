<template>
    <div class="container">
        <div class="main">
            <div class="profile-card">
                <div class="media pt-2 mb-5">
                    <div class="media-left"></div>
                    <div class="media-content">
                        <p class="label ml-2">{{ t.addCreditCard }}</p>
                    </div>
                    <div
                        class="media-right mr-4"
                        @click="updateStep">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-black"
                            class="is-size-4 arrow">
                        </b-icon>
                    </div>
                </div>

                <div
                    id="card-number"
                    class="px-5"></div>

                <div
                    id="card-expiry"
                    class="field mr-3 pl-5"></div>

                <div
                    id="card-cvc"
                    class="field ml-3"></div>

                <div id="card-error"></div>
                <div id="card-name"></div>

                <b-button
                    class="add"
                    :loading="isLoading"
                    rounded
                    @click="createToken"
                    >{{ t.add }}</b-button
                >
            </div>
        </div>
    </div>
</template>

<script>
    import { addCard } from '@/api/apiUser';
    import { getLoggedUser } from '@/api/apiAuth';
    export default {
        data() {
            return {
                token: null,
                cardNumber: null,
                cardExpiry: null,
                cardCvc: null,
                t: this.$t('components.Payments.AddCard'),
                isLoading: false,
            };
        },
        computed: {
            stripeElements() {
                return this.$stripe.elements();
            },
        },
        mounted() {
            // Style Object documentation here: https://stripe.com/docs/js/appendix/style
            const style = {
                base: {
                    color: 'black',
                    fontFamily: '"Lota Grotesque"',
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    backgroundColor: 'white',
                    lineHeight: '21px',
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            };
            this.cardNumber = this.stripeElements.create('cardNumber', { style });
            this.cardNumber.mount('#card-number');
            this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
            this.cardExpiry.mount('#card-expiry');
            this.cardCvc = this.stripeElements.create('cardCvc', { style });
            this.cardCvc.mount('#card-cvc');
        },
        beforeDestroy() {
            this.cardNumber.destroy();
            this.cardExpiry.destroy();
            this.cardCvc.destroy();
        },
        methods: {
            updateStep() {
                this.$store.commit('paymentSteps/setPaymentStep', 0);
            },

            async createToken() {
                this.isLoading = true;
                const { token, error } = await this.$stripe.createToken(this.cardNumber);
                if (error) {
                    // handle error here
                    document.getElementById('card-error').innerHTML = error.message;
                    this.isLoading = false;
                    return;
                }
                // handle the token
                // send it to your server
                if (token) {
                    let data = {
                        token: token.id,
                    };
                    addCard(data)
                        .then((response) => {
                            getLoggedUser()
                                .then((response) => {
                                    this.infoReceived = response.data.body;
                                    this.addUser(this.infoReceived);
                                    this.$store.commit('paymentSteps/setPaymentStep', 0);
                                    this.isLoading = false;
                                })
                                .catch((error) => {
                                    this.isLoading = false;
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                            this.isLoading = false;
                        });
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    #custom-button {
        height: 30px;
        outline: 1px solid grey;
        background-color: green;
        padding: 5px;
        color: white;
    }

    #card-error {
        color: red;
    }

    .container {
        padding-top: 140px;
    }

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        padding: 1rem;
        @include from($tablet) {
            width: 40%;
            margin: 0 auto;
        }
    }

    label,
    p {
        font-family: 'Lota Grotesque';
    }
    .label {
        font-size: 14px;
    }

    .field {
        width: 100px;
        display: inline-block;
        margin-top: 10px;
    }

    .add {
        width: 90px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        margin-left: 20px;
    }
</style>
