<template>
    <div>
        <b-table
            :data="data"
            ref="table"
            paginated
            backend-pagination
            :total="total"
            per-page="10"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            default-sort="createdAt"
            default-sort-direction="desc"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="_id"
            :show-detail-icon="showDetailIcon">
            <b-table-column
                field="name"
                :label="t.columns.name"
                v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column
                field="email"
                :label="t.columns.email"
                v-slot="props">
                {{ props.row.email }}
            </b-table-column>

            <b-table-column
                field="createdAt"
                :label="t.columns.posted_at"
                v-slot="props"
                sortable>
                {{ moment(props.row.createdAt).utc().format('DD/MM/YYYY') }}
            </b-table-column>
            <template #detail="props">
                <div class="columns">
                    <div class="column is-6-desktop is-12-mobile">
                        <p v-if="props.row.company">{{ t.columns.company }} : {{ props.row.company }}</p>
                        <p v-if="props.row.event">{{ t.columns.event }} : {{ props.row.event }}</p>
                        <p v-if="props.row.location">{{ t.columns.location }} : {{ props.row.location }}</p>
                        <p v-if="props.row.budget">{{ t.columns.budget }} : {{ props.row.budget }}</p>
                        <p v-if="props.row.type">{{ t.columns.type }} : {{ props.row.type }}</p>
                        <p v-if="props.row.date">{{ t.columns.date }} : {{ moment(props.row.date).utc().format('DD/MM/YYYY') }}</p>
                    </div>
                    <div class="column is-6-desktop is-12-mobile">
                        <p v-if="props.row.message">{{ t.columns.message }} : {{ props.row.message }}</p>
                    </div>
                </div>
            </template>
        </b-table>
    </div>
</template>

<script>
    import { getWorkshops } from '@/api/workshops';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Workshops.List'),
                data: [],
                total: 0,
                page: 1,
            };
        },
        created() {
            this.getData({
                page: this.page,
                limit: 10,
            });
        },
        methods: {
            getData(query) {
                getWorkshops(query)
                    .then((response) => {
                        this.data = response.data.data;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onPageChange(page) {
                this.page = page;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
        },
        computed: {},
    };
</script>
