<template>
    <div>
        <b-table
            :data="data"
            ref="table"
            paginated
            backend-pagination
            :total="total"
            per-page="10"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            default-sort="createdAt"
            default-sort-direction="desc">
            <b-table-column
                field="title"
                :label="t.columns.name"
                v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column
                field="views"
                :label="t.columns.email"
                v-slot="props">
                {{ props.row.email }}
            </b-table-column>

            <b-table-column
                field="views"
                :label="t.columns.question"
                v-slot="props">
                {{ props.row.question }}
            </b-table-column>

            <!-- <b-table-column :field="accept" :label="t.columns.active" v-slot="props">
				<b-switch @input="accept($event, props.row._id)" v-model="props.row.accept"></b-switch>
			</b-table-column> -->

            <b-table-column
                field="createdAt"
                :label="t.columns.posted_at"
                v-slot="props"
                sortable>
                {{ moment(props.row.createdAt).utc().format('DD/MM/YYYY') }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-pencil"
                    :type="props.row.answered ? 'is-success' : 'is-primary'"
                    :loading="isLoading"
                    @click="$router.push({ name: 'AnswerForm', params: { id: props.row._id } })"></b-button>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import { getForms } from '@/api/forms';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Forms.List'),
                data: [],
                total: 0,
                page: 1,
            };
        },
        created() {
            this.getData({
                page: this.page,
                limit: 10,
            });
        },
        methods: {
            getData(query) {
                getForms(query)
                    .then((response) => {
                        this.data = response.data.data;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onPageChange(page) {
                this.page = page;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
            /* deleteTestimonial(id) {
				this.$buefy.dialog.confirm({
					title: this.t.dialog.title,
					message: this.t.dialog.message,
					confirmText: this.t.dialog.confirm,
					cancelText: this.t.dialog.cancel,
					type: 'is-danger',
					hasIcon: true,
					onConfirm: () => {
						this.isLoading = true;
						removeTestimonial(id)
							.then((response) => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: this.t.api.success,
									position: 'is-bottom',
									type: 'is-success',
								});
								this.getData();
							})
							.catch((error) => {
								this.isLoading = false;
								this.$buefy.toast.open({
									message: this.t.api.error,
									position: 'is-bottom',
									type: 'is-danger',
								});
							});
					},
				});
			}, */
        },
        computed: {},
    };
</script>
