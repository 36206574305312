const state = {
    activeStep: 0,
    intolerances: null,
    metrics: null,
    meals: null,
};

// getters
const getters = {
    getPreferenceStep(state, getters) {
        return state.activeStep;
    },

    getIntolerances(state, getters) {
        return state.intolerances;
    },

    getMetrics(state, getters) {
        return state.metrics;
    },

    getMeals(state, getters) {
        return state.meals;
    },
};

// mutations
const mutations = {
    setPreferenceStep(state, active) {
        state.activeStep = active;
    },

    setIntolerances(state, data) {
        state.intolerances = data;
    },

    setMetrics(state, data) {
        state.metrics = data;
    },

    setMeals(state, data) {
        state.meals = data;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
