<template>
    <div class="container">
        <b-icon
            pack="bl-icons"
            icon="bl-arrow-left"
            type="is-black"
            class="is-size-5 mb-10"
            @click.native="goBack"></b-icon>

        <div class="profile-card">
            <label>{{ t.cardNumber }}</label>
            <div
                v-if="getUser.payments.length === 0"
                id="card-number"
                class="mb-3"></div>
            <b-select
                v-if="getUser.payments.length > 0"
                class="field mb-3"
                :class="{ 'error-text': errors.has('amount') }"
                v-validate="'required'"
                size="is-small"
                name="amount"
                v-model="cardNumber"
                expanded>
                <option
                    v-for="(card, index) in getUser.payments"
                    :key="index"
                    :value="card.id">
                    **** **** **** {{ card.last4 }}
                </option>
            </b-select>

            <label>{{ t.cardExpiry }}</label>
            <div
                v-if="getUser.payments.length === 0"
                id="card-expiry"
                class="mb-3"></div>
            <b-input
                v-if="getUser.payments.length > 0"
                class="cvc-number mb-3"
                v-model="getValidity"
                disabled />

            <label v-if="getUser.payments == 0">{{ t.cardCVC }}</label>
            <div
                v-if="getUser.payments.length === 0"
                id="card-cvc"></div>

            <div id="card-error"></div>
            <div
                id="card-name"
                class="mb-3"></div>
        </div>

        <b-input
            class="mt-3 mb-6 code-input"
            v-model="coupon"
            size="is-small"
            :placeholder="t.insertCode" />
        <b-button
            v-if="getUser.payments.length > 0"
            class="add mt-3"
            :loading="isLoading"
            rounded
            @click="subscribe"
            >{{ t.subscribe }}</b-button
        >
        <b-button
            v-if="getUser.payments.length > 0"
            class="add mt-3"
            :loading="isLoading"
            rounded
            @click="goToPage('AddNewCard')"
            >{{ t.addNew }}</b-button
        >
        <b-button
            v-else
            class="add mt-4"
            :loading="isLoading"
            @click="newCard"
            rounded
            >{{ t.add }}</b-button
        >

        <p
            class="mt-8 has-text-centered has-text-primary"
            @click="logout">
            {{ t.logout }}
        </p>
    </div>
</template>

<script>
    import { updateSubscription } from '@/api/apiUser';
    import { getLoggedUser, logout } from '@/api/apiAuth';
    import { addCard } from '@/api/apiUser';

    export default {
        data() {
            return {
                token: null,
                cardNumber: null,
                cardCvc: null,
                t: this.$t('components.Subscription.FinishSubscriptionPayment'),
                isLoading: false,
                showFields: true,
                coupon: null,
                today: new Date(),
            };
        },
        computed: {
            stripeElements() {
                return this.$stripe.elements();
            },

            getValidity() {
                let card = this.getUser.payments.filter((card) => card.id === this.cardNumber);
                return card[0].exp_month + '/' + card[0].exp_year;
            },
        },
        mounted() {
            // Style Object documentation here: https://stripe.com/docs/js/appendix/style
            const style = {
                base: {
                    color: 'black',
                    fontFamily: '"Lota Grotesque"',
                    fontSmoothing: 'antialiased',
                    fontSize: '14px',
                    '::placeholder': {
                        color: '#aab7c4',
                    },
                    backgroundColor: 'white',
                    lineHeight: '30px',
                    empty: false,
                },
                invalid: {
                    color: '#fa755a',
                    iconColor: '#fa755a',
                },
            };

            if (this.getUser.payments.length === 0 || this.showFields === false) {
                this.cardNumber = this.stripeElements.create('cardNumber', { style });
                this.cardNumber.mount('#card-number');
                this.cardExpiry = this.stripeElements.create('cardExpiry', { style });
                this.cardExpiry.mount('#card-expiry');
                this.cardCvc = this.stripeElements.create('cardCvc', { style });
                this.cardCvc.mount('#card-cvc');
            } else {
                this.cardNumber = this.getUser.payments[0].id;
            }
        },
        beforeDestroy() {
            this.cardNumber.destroy();
            this.cardExpiry.destroy();
            this.cardCvc.destroy();
        },
        methods: {
            subscribe() {
                let card = this.getUser.payments.filter((card) => card.id === this.cardNumber);
                if (this.today.getFullYear() > card[0].exp_year) {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: this.t.expired,
                        type: 'is-secondary',
                        duration: 4000,
                    });
                } else if (this.today.getFullYear() >= card[0].exp_year && this.today.getMonth() + 1 > card[0].exp_month) {
                    this.isLoading = false;
                    this.$buefy.toast.open({
                        message: this.t.expired,
                        type: 'is-secondary',
                        duration: 4000,
                    });
                } else {
                    this.isLoading = true;
                    let data = {
                        default_source: this.cardNumber,
                        price_id: this.$store.getters['subscriptionSteps/getSubscription'],
                        ...(this.coupon != null && { coupon: this.coupon }),
                    };
                    updateSubscription(data)
                        .then((response) => {
                            this.$buefy.toast.open({
                                message: this.t.success,
                                type: 'is-primary',
                            });
                            getLoggedUser()
                                .then((response) => {
                                    this.infoReceived = response.data.body;
                                    this.addUser(this.infoReceived);
                                    this.$store.commit('subscriptionSteps/setSubscriptionStep', 0);
                                    this.goToPage('Homepage');
                                })
                                .catch((error) => {});
                        })
                        .catch((error) => {
                            this.$buefy.toast.open({
                                message: this.t.error,
                                type: 'is-secondary',
                            });
                            this.isLoading = false;
                        });
                }
            },

            async newCard() {
                this.isLoading = true;
                const { token, error } = await this.$stripe.createToken(this.cardNumber);
                if (error) {
                    // handle error here
                    document.getElementById('card-error').innerHTML = error.message;
                    this.isLoading = false;
                    return;
                }
                // handle the token
                // send it to your server
                if (token) {
                    let data = {
                        token: token.id,
                    };
                    addCard(data)
                        .then((response) => {
                            this.$buefy.toast.open({
                                message: this.t.success,
                                type: 'is-primary',
                            });
                            getLoggedUser()
                                .then((response) => {
                                    this.cardNumber = response.data.body.payments[0].id;
                                    this.infoReceived = response.data.body;
                                    this.addUser(this.infoReceived);

                                    this.subscribe();
                                })
                                .catch((error) => {
                                    console.error(error);
                                    this.$buefy.toast.open({
                                        message: this.t.error,
                                        type: 'is-secondary',
                                    });
                                    this.isLoading = false;
                                });
                        })
                        .catch((error) => {
                            console.error(error);
                            if (error.response.data.code == 'InvalidCreditCard') {
                                this.$buefy.toast.open({
                                    message: this.t.invalidCard,
                                    type: 'is-secondary',
                                });
                            } else {
                                this.$buefy.toast.open({
                                    message: this.t.error,
                                    type: 'is-secondary',
                                });
                            }

                            this.isLoading = false;
                        });
                }
            },

            goBack() {
                this.$store.commit('subscriptionSteps/setSubscriptionStep', 0);
            },

            logout() {
                logout()
                    .then((response) => {
                        this.onSessionExpired();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },

        created() {},
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    #custom-button {
        height: 30px;
        outline: 1px solid grey;
        background-color: green;
        padding: 5px;
        color: white;
    }

    #card-error {
        color: red;
    }

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        padding: 1rem;
        @include from($tablet) {
            width: 40%;
            margin: 0 auto;
        }
    }

    label,
    p {
        font-family: 'Lota Grotesque';
    }
    .label {
        font-size: 14px;
    }

    .card-number-input {
        width: 160px;
    }

    .cvc-number {
        width: 80px;
    }

    .add {
        width: auto;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        margin: 0 auto;
        display: flex;
    }

    /deep/ .code-input {
        width: 200px;
        margin: 0 auto;
    }

    /deep/ .code-input input {
        border: 0 !important;
        box-shadow: none !important;
        border-bottom: 1px solid $primary !important;
        text-align: center;
    }
</style>
