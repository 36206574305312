<template>
    <div>
        <form
            @submit.prevent="formSubmit"
            class="columns is-mobile is-multiline">
            <b-field
                class="column is-12"
                :label="t.type.label"
                :type="invalids.includes('recipe_type') ? 'is-danger' : ''">
                <div class="block">
                    <b-checkbox
                        v-model="form.recipe_type"
                        v-for="(option, name, i) in $t('shared.recipe_types')"
                        :key="i"
                        :native-value="name"
                        >{{ option }}</b-checkbox
                    >
                </div>
                <!-- <b-select multiple native-size="1" size="is-small" v-model="form.recipe_type" :placeholder="t.type.placeholder" required expanded>
					<option v-for="(option, name, i) in $t('shared.recipe_types')" :value="name" :key="i">
						{{ option }}
					</option>
				</b-select> -->
            </b-field>
            <b-field
                :type="invalids.includes('name.pt') ? 'is-danger' : ''"
                class="column is-12-mobile is-6-desktop"
                :label="t.name.pt">
                <b-input
                    size="is-small"
                    type="text"
                    v-model="form.name.pt"
                    required></b-input>
            </b-field>
            <b-field
                :type="invalids.includes('name.en') ? 'is-danger' : ''"
                class="column is-12-mobile is-6-desktop"
                :label="t.name.en">
                <b-input
                    size="is-small"
                    type="text"
                    v-model="form.name.en"></b-input>
            </b-field>

            <b-field
                class="column is-12 is-top-bordered"
                :label="t.ingredients">
                <div
                    v-for="(ingredient, i) in form.ingredients"
                    :key="i"
                    class="columns is-multiline mt-2"
                    :class="{ 'background-white': i % 2 == 1 }">
                    <b-field
                        :type="invalids.includes('ingredients[' + i + '].ingredient') ? 'is-danger' : ''"
                        class="column is-12-mobile is-2-desktop"
                        :label="t.ingredient">
                        <b-autocomplete
                            size="is-small"
                            ref="ingredientAutocomplete"
                            placeholder="e.g. banana"
                            :data="filteredIngredients"
                            field="description.pt"
                            required
                            :append-to-body="true"
                            @typing="getFilteredIngredients"
                            @select="(option) => (form.ingredients[i].ingredient = option)">
                        </b-autocomplete>
                    </b-field>

                    <b-field
                        :type="invalids.includes('ingredients[' + i + '].hierarchy.pt') ? 'is-danger' : ''"
                        class="column is-12-mobile is-1-desktop"
                        label="Tag(Pt)">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.ingredients[i].hierarchy.pt"></b-input>
                    </b-field>
                    <b-field
                        :type="invalids.includes('ingredients[' + i + '].hierarchy.en') ? 'is-danger' : ''"
                        class="column is-12-mobile is-1-desktop"
                        label="Tag(En)">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.ingredients[i].hierarchy.en"></b-input>
                    </b-field>
                    <b-field
                        :type="invalids.includes('ingredients[' + i + '].description.pt') ? 'is-danger' : ''"
                        class="column is-12-mobile is-2-desktop"
                        :label="t.name.pt">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.ingredients[i].description.pt"></b-input>
                    </b-field>
                    <b-field
                        :type="invalids.includes('ingredients[' + i + '].description.en') ? 'is-danger' : ''"
                        class="column is-12-mobile is-2-desktop"
                        :label="t.name.en">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.ingredients[i].description.en"></b-input>
                    </b-field>
                    <b-field
                        :type="invalids.includes('ingredients[' + i + '].amount') ? 'is-danger' : ''"
                        class="column is-12-mobile is-2-desktop"
                        :label="t.amount">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.ingredients[i].amount"></b-input>
                    </b-field>
                    <b-field
                        :type="invalids.includes('ingredients[' + i + '].unit') ? 'is-danger' : ''"
                        class="column is-12-mobile is-1-desktop"
                        :label="t.unit">
                        <b-select
                            size="is-small"
                            v-model="form.ingredients[i].unit"
                            :placeholder="t.type.placeholder"
                            required
                            expanded>
                            <option
                                v-for="(option, name, i) in $t('shared.units')"
                                :value="name"
                                :key="100 + i">
                                {{ option }}
                            </option>
                        </b-select>
                    </b-field>
                    <b-field
                        class="column is-12-mobile is-1-desktop"
                        :class="{ 'pb-9': isMobile }">
                        <b-button
                            class="btn btn-delete btn-right"
                            size="is-small"
                            icon-right="mdi mdi-minus"
                            type="is-danger"
                            @click="deleteIngredient(i)"></b-button>
                    </b-field>
                </div>
            </b-field>
            <div class="column is-12 is-bottom-bordered">
                <b-button
                    class="btn btn-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-primary"
                    @click="addIngredient"></b-button>
            </div>

            <b-field
                class="column is-12 is-top-bordered"
                :label="t.recipes">
                <div
                    v-for="(recipe, i) in form.recipes"
                    :key="i"
                    class="columns is-multiline mt-2"
                    :class="{ 'background-white': i % 2 == 1 }">
                    <b-field
                        :type="invalids.includes('recipe[' + i + ']') ? 'is-danger' : ''"
                        class="column is-12-mobile is-5-desktop"
                        :label="t.recipe">
                        <b-autocomplete
                            size="is-small"
                            ref="recipeAutocomplete"
                            placeholder="e.g. humus"
                            :data="filteredRecipes"
                            field="name.pt"
                            expanded
                            @typing="getFilteredRecipes"
                            @select="(option) => (form.recipes[i].recipe = option)">
                        </b-autocomplete>
                    </b-field>
                    <b-field
                        :type="invalids.includes('recipe[' + i + '].hierarchy.pt') ? 'is-danger' : ''"
                        class="column is-12-mobile is-3-desktop"
                        label="Tag(Pt)">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.recipes[i].hierarchy.pt"></b-input>
                    </b-field>
                    <b-field
                        :type="invalids.includes('recipe[' + i + '].hierarchy.en') ? 'is-danger' : ''"
                        class="column is-12-mobile is-3-desktop"
                        label="Tag(En)">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.recipes[i].hierarchy.en"></b-input>
                    </b-field>
                    <b-field
                        class="column is-12-mobile is-1-desktop"
                        :class="{ 'pb-9': isMobile }">
                        <b-button
                            class="btn btn-delete btn-right"
                            size="is-small"
                            icon-right="mdi mdi-minus"
                            type="is-danger"
                            @click="deleteRecipe(i)"></b-button>
                    </b-field>
                </div>
            </b-field>
            <div class="column is-12 is-bottom-bordered">
                <b-button
                    class="btn btn-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-primary"
                    @click="addRecipe"></b-button>
            </div>

            <b-field
                class="column is-12"
                :label="t.steps">
                <div class="columns is-mobile is-multiline">
                    <b-field
                        :type="invalids.includes('steps.*.pt') ? 'is-danger' : ''"
                        class="column is-12-mobile is-6-desktop"
                        :label="t.pt">
                        <quill-editor
                            ref="myQuillEditor"
                            class="background-white"
                            v-model="form.steps.pt"
                            :options="editorOption" />
                    </b-field>
                    <b-field
                        :type="invalids.includes('steps.*.en') ? 'is-danger' : ''"
                        class="column is-12-mobile is-6-desktop"
                        :label="t.en">
                        <quill-editor
                            ref="myQuillEditor2"
                            class="background-white"
                            v-model="form.steps.en"
                            :options="editorOption" />
                    </b-field>
                </div>
            </b-field>
            <div class="column is-12 is-bottom-bordered"></div>
            <b-field
                class="column is-12"
                :label="t.tips">
                <div
                    v-for="(tip, i) in form.tips"
                    :key="200 + i"
                    class="columns is-multiline"
                    :class="{ 'background-white': i % 2 == 1 }">
                    <b-field
                        :type="invalids.includes('tips[' + i + '].pt') ? 'is-danger' : ''"
                        class="column is-12-mobile is-6-desktop"
                        :label="t.pt">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.tips[i].pt"></b-input>
                    </b-field>
                    <b-field
                        :type="invalids.includes('tips[' + i + '].en') ? 'is-danger' : ''"
                        class="column is-12-mobile is-5-desktop"
                        :label="t.en">
                        <b-input
                            size="is-small"
                            type="text"
                            v-model="form.tips[i].en"></b-input>
                    </b-field>
                    <b-field class="column is-12-mobile is-1-desktop">
                        <b-button
                            class="btn btn-delete btn-right"
                            size="is-small"
                            icon-right="mdi mdi-minus"
                            type="is-danger"
                            @click="deleteTip(i)"></b-button>
                    </b-field>
                </div>
            </b-field>
            <div class="column is-12 is-bottom-bordered">
                <b-button
                    class="btn btn-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-primary"
                    @click="addTip"></b-button>
            </div>
            <b-field
                :type="invalids.includes('preparing_time') ? 'is-danger' : ''"
                class="column is-12-mobile is-6-desktop"
                :label="t.preparing_time">
                <!-- <b-timepicker size="is-small" icon="clock" hour-format="24" locale="en-GB" v-model="form.preparing_time" :time-creator="formatTime" :default-minutes="0" required></b-timepicker> -->
                <b-clockpicker
                    size="is-small"
                    placeholder="Click to select..."
                    icon="clock"
                    v-model="form.preparing_time"></b-clockpicker>
            </b-field>
            <b-field
                :type="invalids.includes('people') ? 'is-danger' : ''"
                class="column is-12-mobile is-6-desktop"
                :label="t.people">
                <b-input
                    size="is-small"
                    type="number"
                    v-model="form.people"></b-input>
            </b-field>
            <b-field
                :type="invalids.includes('image') ? 'is-danger' : ''"
                class="column is-12">
                <b-upload
                    v-model="form.image"
                    expanded
                    drag-drop
                    accept="image/*">
                    <section class="section">
                        <div
                            v-if="!form.image && !form._image"
                            class="content has-text-centered">
                            <p>
                                <b-icon
                                    icon="upload"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>{{ t.upload }}</p>
                        </div>
                        <div
                            v-else
                            class="content has-text-centered">
                            <img
                                class="img-preview"
                                :src="getFileURL" />
                        </div>
                    </section>
                </b-upload>
            </b-field>
            <b-field
                :type="invalids.includes('tags') ? 'is-danger' : ''"
                class="column is-12"
                :label="t.tags.label">
                <b-taginput
                    size="is-small"
                    v-model="form.tags"
                    :data="filteredTags"
                    autocomplete
                    :allow-new="false"
                    ellipsis
                    icon="label"
                    type="is-primary"
                    :placeholder="t.tags.placeholder"
                    :aria-close-label="t.tags.close"
                    @add="checkTags"
                    @typing="getFilteredTags">
                </b-taginput>
            </b-field>
            <b-field
                :type="invalids.includes('parent') ? 'is-danger' : ''"
                :message="invalids.includes('parent') ? 'Não pode colocar esta receita como receita principal' : ''"
                class="column is-12"
                :label="t.parent">
                <b-autocomplete
                    size="is-small"
                    ref="parentAutocomplete"
                    placeholder="e.g. humus"
                    :data="filteredRecipes"
                    field="name.pt"
                    expanded
                    @typing="getFilteredRecipes"
                    @select="(option) => (form.parent = option)">
                </b-autocomplete>
            </b-field>
            <div class="column is-offset-2-desktop is-8-desktop is-12-touch">
                <b-button
                    expanded
                    :loading="isLoading"
                    class="is-headline-bold-d-d is-headline-m-m"
                    type="is-primary"
                    native-type="submit"
                    >{{ t.submit }}</b-button
                >
            </div>
        </form>
    </div>
</template>

<script>
    import { addRecipe, getRecipes, updateRecipe } from '@/api/recipes';
    import { getIngredients } from '@/api/ingredients';
    export default {
        name: 'Form',
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                vm.prevQuery = to.params;
            });
        },
        data() {
            return {
                t: this.$t('components.BackOffice.Recipes.Form'),
                isUpdate: false,
                isLoading: false,
                prevQuery: null,
                content: null,
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [['bold', 'italic', 'underline', 'strike']],
                        },
                    },
                },
                invalids: [],
                tags: [],
                filteredTags: [],
                filteredIngredients: [],
                filteredRecipes: [],
                ingredient: [''],
                ingredients: [],
                ingredient_name: '',
                form: {
                    name: {
                        pt: null,
                        en: null,
                    },
                    ingredients: [
                        {
                            hierarchy: {
                                pt: null,
                                en: null,
                            },
                            ingredient: null,
                            description: {
                                pt: null,
                                en: null,
                            },
                            amount: null,
                            unit: null,
                        },
                    ],
                    recipes: [
                        {
                            recipe: null,
                            hierarchy: {
                                pt: null,
                                en: null,
                            },
                        },
                    ],
                    steps: {
                        en: null,
                        pt: null,
                    },
                    tips: [
                        {
                            pt: null,
                            en: null,
                        },
                    ],
                    tags: [],
                    preparing_time: null,
                    people: null,
                    image: null,
                    recipe_type: [],
                    parent: null,
                },
            };
        },
        created() {
            /*  */
            //this.getIngredients();
            Object.keys(this.$t('shared.tags')).forEach((key) => {
                this.tags.push({ key: key, value: this.$t('shared.tags')[key] });
            });
            if (this.$route.name == 'UpdateRecipe') {
                this.isUpdate = true;
                this.getData();
            } else if (this.$route.params.duplicated) {
                this.getData();
            }
        },
        mounted() {
            this.$refs['myQuillEditor'].quill.enable(false);
            this.$refs['myQuillEditor2'].quill.enable(false);
            setTimeout(() => {
                this.$refs['myQuillEditor'].quill.enable(true);
                this.$refs['myQuillEditor2'].quill.enable(true);
            }, 2000);
        },
        methods: {
            formatTime(val) {},
            getData() {
                let query = {
                    id: this.$route.params.id,
                };
                getRecipes(query)
                    .then((response) => {
                        let rec = response.data.recipes[0];

                        if (this.isUpdate) this.$emit('on-edit', rec.name[this.getLocale]);

                        if (this.$route.params.duplicated) delete rec._id;
                        rec._image = rec.image;
                        rec.image = null;

                        let date = new Date();
                        let str = rec.preparing_time.split('h');

                        if (str.length == 1 && str[0].includes('h')) {
                            let h = str[0].replace('h', '');
                            date.setHours(h);
                            date.setMinutes(0);
                        } else if (str.length == 1 && str[0].includes('min')) {
                            let m = str[0].replace('min', '');
                            date.setHours(0);
                            date.setMinutes(m);
                        } else {
                            let h = str[0].replace('h', '');
                            date.setHours(h);

                            let m = str[0].replace('min', '');
                            date.setMinutes(m);
                        }
                        rec.preparing_time = date;
                        rec.id = rec._id;

                        rec.tags.forEach((tag, i) => {
                            rec.tags[i] = this.tags.filter((t) => t.key == tag)[0];
                        });

                        let pt = '';
                        let en = '';
                        rec.steps.forEach((step, i) => {
                            pt += '<p>' + step.pt + '</p>';
                            en += '<p>' + step.en + '</p>';
                        });
                        rec.steps = {
                            pt: pt,
                            en: en,
                        };

                        this.form = rec;

                        this.$nextTick(() => {
                            let refs = this.$refs['ingredientAutocomplete'];

                            this.form.ingredients.forEach((ingredient, index) => {
                                refs[index].setSelected(ingredient.ingredient);
                            });

                            let _refs = this.$refs['recipeAutocomplete'];

                            this.form.recipes.forEach((recipe, index) => {
                                _refs[index].setSelected(recipe.recipe);
                            });

                            this.$refs['parentAutocomplete'].setSelected(this.form.parent);
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            getIngredients() {
                getIngredients({
                    limit: 1000,
                })
                    .then((response) => {
                        this.ingredients = response.data.ingredients;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            addIngredient() {
                this.ingredient.push('');
                this.form.ingredients.push({
                    hierarchy: this.form.ingredients[this.form.ingredients.length - 1] ? this.form.ingredients[this.form.ingredients.length - 1].hierarchy : { pt: null, en: null },
                    ingredient: null,
                    description: {
                        pt: null,
                        en: null,
                    },
                    amount: null,
                    unit: null,
                });
            },
            deleteIngredient(i) {
                this.$nextTick(() => {
                    let refs = this.$refs['ingredientAutocomplete'];
                    this.form.ingredients.splice(i, 1);
                    this.form.ingredients.forEach((ingredient, index) => {
                        refs[index].setSelected(ingredient.ingredient);
                    });
                });
            },
            addTip() {
                this.form.tips.push({
                    pt: null,
                    en: null,
                });
            },
            deleteTip(i) {
                this.form.tips.splice(i, 1);
            },
            getFilteredTags(text) {
                this.filteredTags = this.tags.filter((option) => {
                    return option.value.toString().toLowerCase().indexOf(text.toLowerCase()) >= 0;
                });
            },
            getFilteredIngredients(text) {
                getIngredients({
                    search_pt: text,
                })
                    .then((response) => {
                        this.filteredIngredients = response.data.ingredients;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            getFilteredRecipes(text) {
                getRecipes({
                    search_pt: text,
                    ...(this.form.id && { notid: this.form.id }),
                })
                    .then((response) => {
                        this.filteredRecipes = response.data.recipes;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            checkTags(value) {
                if (value.key == 'all') this.form.tags = this.tags;
            },
            addRecipe() {
                this.form.recipes.push({
                    recipe: null,
                    hierarchy: this.form.recipes[this.form.recipes.length - 1] ? this.form.recipes[this.form.recipes.length - 1].hierarchy : { pt: null, en: null },
                });
            },
            deleteRecipe(i) {
                this.$nextTick(() => {
                    let _refs = this.$refs['recipeAutocomplete'];
                    this.form.recipes.splice(i, 1);
                    this.form.recipes.forEach((recipe, index) => {
                        _refs[index].setSelected(recipe);
                    });
                });
            },
            formSubmit() {
                this.isLoading = true;
                this.invalids = [];

                let _copy = Object.clone(this.form);
                _copy.tags.forEach((tag, index) => {
                    _copy.tags[index] = tag.key;
                });

                let image = this.form.image;
                delete _copy.image;
                if (this.form.preparing_time) {
                    let hours = this.form.preparing_time.getHours();
                    let minutes = this.form.preparing_time.getMinutes();
                    let preparing_time = '';
                    if (hours > 0) preparing_time += hours + 'h';
                    if (minutes > 0) preparing_time += minutes + 'min';
                    _copy.preparing_time = preparing_time;
                }

                _copy.ingredients.forEach((element, index) => {
                    if (typeof element.ingredient === 'object' && element.ingredient !== null) _copy.ingredients[index].ingredient = element.ingredient._id;
                });
                _copy.recipes.forEach((element, index) => {
                    if (typeof element.recipe === 'object' && element.recipe !== null) _copy.recipes[index].recipe = element.recipe._id;
                });

                let steps = [];
                let steps_pt = [];
                if (_copy.steps.pt != null) {
                    steps_pt = _copy.steps.pt.split('</p>');

                    steps_pt.forEach((step, index) => {
                        if (step !== '')
                            steps.push({
                                pt: step.replace(/(<([^>]+)>)/gi, ''),
                                en: '',
                            });
                    });
                }

                let steps_en = [];
                if (_copy.steps.en != null) {
                    steps_en = _copy.steps.en.split('</p>');

                    steps_en.forEach((step, index) => {
                        if (step !== '') {
                            if (steps[index]) steps[index]['en'] = step.replace(/(<([^>]+)>)/gi, '');
                            else
                                steps.push({
                                    pt: '',
                                    en: step.replace(/(<([^>]+)>)/gi, ''),
                                });
                        }
                    });
                }
                _copy.steps = steps;

                if (_copy.parent) _copy.parent = _copy.parent._id;

                if (!this.isUpdate) {
                    addRecipe(image, _copy)
                        .then((response) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'Recipes', params: this.prevQuery });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            } else if (error.response.data.code === 'InvalidImage') {
                                message = this.t.api.invalidImage;
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updateRecipe(image, _copy)
                        .then((response) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.updateSuccess,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'Recipes', params: this.prevQuery });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            } else if (error.response.data.code === 'InvalidImage') {
                                message = this.t.api.invalidImage;
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
        },
        computed: {
            getFileURL() {
                if (this.form.image) {
                    let url = window.URL.createObjectURL(this.form.image);
                    return url;
                } else if (this.form._image) {
                    return this.form._image;
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';
    .background-white {
        background-color: $white;
    }
    .is-top-bordered {
        border-top: 1px solid black;
    }
    .is-bottom-bordered {
        border-bottom: 1px solid black;
    }
    .img-preview {
        height: 30vh;
    }
    .btn-delete {
        @include until($desktop) {
            margin-bottom: 2rem;
        }
        @include from($mobile) {
            margin-top: 2rem;
        }
    }
</style>
<style lang="scss" scoped>
    .pagination-list {
        display: none;
    }
</style>
