<template>
    <section>
        <TopMenu />
        <div class="main">
            <div class="img-container">
                <b-image
                    class="img"
                    :src="require('@/assets/icons/coracao_verde.png')"
                    alt="Green Heart"></b-image>
            </div>

            <div class="heart-text">Hello!</div>

            <div class="card">
                <p class="has-text-centered has-text-white title pt-6">{{ t.register }}</p>
                <p class="has-text-centered has-text-black subtitle pt-2">{{ t.newAccount }}</p>

                <div class="has-text-centered field-container">
                    <b-input
                        :class="{ 'error-text': errors.has('email') }"
                        class="field has-text-centered"
                        :placeholder="t.enterYourEmail"
                        type="email"
                        v-validate="'required|email'"
                        name="email"
                        v-model="email"
                        size="is-small"
                        ref="email" />
                </div>
                <p
                    v-show="errors.has('email')"
                    class="help is-danger has-text-centered">
                    {{ t.validEmail }}
                </p>

                <div class="has-text-centered field-container">
                    <b-input
                        :class="{ 'error-text': errors.has('email_confirmation') }"
                        class="field has-text-centered"
                        :placeholder="t.confirmEmail"
                        v-validate="'required|confirmed:email'"
                        name="email_confirmation"
                        type="email"
                        data-vv-as="email"
                        size="is-small"
                        v-model="confirmEmail" />
                </div>
                <p
                    v-show="errors.has('email_confirmation')"
                    class="help is-danger has-text-centered">
                    {{ t.matchEmail }}
                </p>

                <div class="has-text-centered field-container">
                    <b-input
                        type="password"
                        name="password"
                        ref="password"
                        :class="{ 'error-text': errors.has('password') }"
                        v-validate="'required|min:8'"
                        class="field has-text-centered"
                        v-model="password"
                        password-reveal
                        size="is-small"
                        :placeholder="t.password">
                    </b-input>
                </div>
                <p
                    v-show="errors.has('password')"
                    class="help is-danger has-text-centered">
                    {{ t.shortPassword }}
                </p>

                <!-- <div class="has-text-centered field-container">
					<b-input
						name="name"
						:class="{ 'error-text': errors.has('name') }"
						v-validate="'required'"
						class="field has-text-centered"
						v-model="name"
						size="is-small"
						:placeholder="t.name"
					>
					</b-input>
				</div>
				<p v-show="errors.has('name')" class="help is-danger has-text-centered">{{ t.validName }}</p> -->

                <p class="birth has-text-white">{{ t.birth }}</p>
                <div class="field-container">
                    <b-field>
                        <b-datepicker
                            v-model="birth"
                            :placeholder="t.selectDate"
                            :icon-right="birth ? 'close-circle' : ''"
                            icon-right-clickable
                            @icon-right-click="clearDate"
                            trap-focus
                            :max-date="maxDate"
                            size="is-small"
                            class="datepicker">
                        </b-datepicker>
                    </b-field>
                </div>

                <div class="boxed">
                    <p class="marketing has-text-white">{{ t.marketing }}</p>
                    <input
                        type="radio"
                        id="yesMarkting"
                        :value="true"
                        v-model="marketing" />
                    <label
                        for="yesMarkting"
                        class="first-btn"
                        >{{ t.yes }}</label
                    >

                    <input
                        type="radio"
                        id="noMarkting"
                        :value="false"
                        v-model="marketing" />
                    <label for="noMarkting">{{ t.no }}</label>
                </div>

                <div class="boxed">
                    <p
                        class="marketing has-text-white"
                        @click="goToPage('Terms')">
                        {{ t.accept }}
                    </p>
                    <p
                        class="marketing has-text-white is-underlined ml-1"
                        @click="goToPage('Terms')">
                        {{ t.terms }}
                    </p>

                    <input
                        type="radio"
                        id="yesTerms"
                        :value="true"
                        v-model="terms" />
                    <label for="yesTerms">{{ t.yes }}</label>

                    <input
                        type="radio"
                        id="noTerms"
                        :value="false"
                        v-model="terms" />
                    <label for="noTerms">{{ t.no }}</label>
                </div>
                <p
                    v-if="errorTerms"
                    class="help is-danger has-text-centered is-clickable">
                    {{ t.acceptTerms }}
                </p>

                <div class="btn-container pt-6">
                    <b-button
                        class="register"
                        rounded
                        :loading="isLoading"
                        @click="submitRegister"
                        >{{ t.register.toUpperCase() }}</b-button
                    >
                </div>
                <p
                    class="login has-text-centered mt-4"
                    @click="goToPage('Login')">
                    {{ t.login }}
                </p>
                <p
                    v-if="errorEmail"
                    class="help is-danger has-text-centered">
                    {{ t.usedEmail }}
                </p>
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import { createAccount } from '@/api/apiAuth';
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                t: this.$t('views.Register'),
                email: null,
                password: null,
                confirmEmail: null,
                name: null,
                birth: new Date(),
                maxDate: new Date(),
                errorEmail: false,
                errorPassword: false,
                errorDifferentPassword: false,
                marketing: null,
                terms: null,
                isLoading: false,
                errorTerms: false,
            };
        },

        methods: {
            submitRegister() {
                if (this.terms === false || this.terms === null) {
                    this.errorTerms = true;
                } else {
                    this.errorTerms = false;
                    this.$validator.validateAll().then((result) => {
                        if (result) {
                            this.isLoading = true;
                            let data = {
                                email: this.email,
                                password: this.password,
                                birth: this.birth,
                                marketing: this.marketing,
                                terms: this.terms,
                            };
                            createAccount(data)
                                .then((response) => {
                                    this.isLoading = false;
                                    this.$buefy.toast.open({
                                        message: this.t.verifyEmail,
                                        position: 'is-top',
                                        type: 'is-secondary',
                                        duration: 4000,
                                    });
                                    this.goToPage('Login');
                                })
                                .catch((error) => {
                                    if (error.response.data[0].msg.code === 'EmailAlreadyUsed') {
                                        this.isLoading = false;
                                        this.errorEmail = true;
                                    }
                                });
                        }
                    });
                }
            },
            clearDate() {
                this.birth = null;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 6rem;
        margin-bottom: 30px;
        @include until($mobile-s) {
            padding-top: 3rem;
        }
    }
    .card {
        background-color: $primary;
        height: auto;
        border-radius: 48px;
        padding-bottom: 20px;

        max-width: $card-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    .title {
        font-size: 52px;
        font-family: 'Borest-Standard';
        font-weight: normal;
    }

    .subtitle {
        font-size: 24px;
        font-family: 'Borest-Standard';
        font-weight: normal;
    }

    .field-container {
        display: flex;
        justify-content: center;
        margin-top: 1rem;
    }

    .field {
        width: 90% !important;
    }

    .birth {
        font-family: 'Lota Grotesque';
        font-size: 12px;
        margin-left: 5%;
        margin-top: 20px;
    }

    .radio {
        width: 26px;
        border-radius: 0px;
        display: inline-flex !important;
        font-size: 0.75rem !important;
    }
    .marketing {
        display: inline-flex;
        font-family: 'Lota Grotesque';
        font-size: 12px;
        margin-left: 5%;
        margin-top: 20px;
    }

    .boxed label {
        display: inline-block;
        width: 30px;
        border: solid 2px #ccc;
        transition: all 0.3s;
        font-size: 12px;
        text-align: center;
        margin-left: 16px;
        font-family: 'Lota Grotesque';
    }

    .boxed input[type='radio'] {
        display: none;
        border: solid 1px white;
    }

    .boxed input[type='radio']:checked + label {
        border: solid 1px white;
        background-color: white;
    }

    .first-btn {
        margin-left: 33px !important;
    }

    .btn-container {
        display: flex;
        justify-content: center;
    }

    .register {
        width: 89px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        color: $primary;
        background-color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .img {
        width: 160px;
        transform: translateY(40px);
    }

    .img-container {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 10;
    }

    .heart-text {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -120%);
        font-size: 42px;
        color: white;
        font-family: 'Borest-Standard';
        z-index: 11;
    }

    .login {
        font-size: 1rem;
        color: #bf8897;
        font-family: 'Lota Grotesque';
    }

    @media only screen and (max-width: 320px) {
        .boxed label {
            margin-left: 6px;
            font-size: 11px;
        }
        .marketing {
            font-size: 11px;
        }

        .first-btn {
            margin-left: 22px !important;
        }
        .main {
            padding-top: 3rem;
        }
    }
</style>
