<template>
    <section>
        <TopMenu />

        <div class="main">
            <!-- <div class="card"> -->
            <SubscriptionPlan v-if="getStep === 0" />
            <Finish v-else />
            <!-- </div> -->
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import SubscriptionPlan from '@/components/Subscription/SubscriptionPlan.vue';
    import Finish from '@/components/Subscription/FinishSubscriptionPayment.vue';
    export default {
        components: {
            TopMenu,
            SubscriptionPlan,
            Finish,
        },

        computed: {
            getStep() {
                return this.$store.getters['subscriptionSteps/getSubscriptionStep'];
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 100px;
    }

    .card {
        background-color: $primary;
        height: auto;
        border-radius: 48px;
        padding-bottom: 20px;
        max-width: $card-max-width;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
    }
</style>
