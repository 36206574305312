import store from '@/store/index';
import router from '@/router/index';

export default function () {
    router.beforeEach((to, from, next) => {
        const requiresAuth = to.matched.some((record) => record.meta.requiresAuth);

        if (requiresAuth) {
            let token = store.getters['auth/getToken'];
            /* ;
			; */
            if (token) {
                if (to.name === 'Homepage' && store.getters['auth/getUser'].user_type === 'admin') next({ name: 'BackOffice' });
                else if (to.name === 'BackOffice' && store.getters['auth/getUser'].user_type === 'user') next({ name: 'Homepage' });
                else if (to.name === 'Homepage' && store.getters['auth/getUser'].isRegistered === false) {
                    store.commit('registerPreferences/setPreferenceStep', 0);
                    store.commit('registerPreferences/setIntolerances', null);
                    store.commit('registerPreferences/setMetrics', null);
                    store.commit('registerPreferences/setMeals', null);
                    next({ name: 'CompleteRegister' });
                } else if (store.getters['auth/getUser'].user_type != 'admin' && to.name != 'SignSubscription') {
                    next();
                } else if (
                    store.getters['auth/getUser'].user_type != 'admin' &&
                    to.name != 'SignSubscription' &&
                    to.name != 'SubscriptionExpired' &&
                    store.getters['auth/getUser'].subscription.status == 'canceled'
                ) {
                    next({ name: 'SubscriptionExpired' });
                } else next();
            } else if (to.name === 'BackOffice') {
                next({ name: 'BackOfficeLogin' });
            } else {
                next({ name: 'Home' });
            }
        } else {
            next();
        }
        const onlyNoAuth = to.matched.some((record) => record.meta.onlyNoAuth);

        if (onlyNoAuth) {
            let token = store.getters['auth/getToken'];
            if (!token) {
                next();
            } else {
                next({ name: 'Homepage' });
            }
        } else {
            next();
        }
    });
}
