<template>
    <div
        class="first-div has-position-relative"
        :class="{ 'has-navbar-fixed-top': isMobile, 'has-sidebar-fixed': !isMobile }">
        <Sidebar />
        <Navbar />
        <div :class="{ 'mt-8': isMobile, 'pt-4': !isMobile, 'mt-8': !isMobile }">
            <div class="level">
                <nav
                    class="breadcrumb level-left"
                    aria-label="breadcrumbs">
                    <ul>
                        <li
                            :class="{ 'is-active': breadcrumb.isActive }"
                            v-for="(breadcrumb, i) in getBreadcrumbs"
                            :key="i">
                            <a
                                :href="breadcrumb.path"
                                aria-current="page"
                                >{{ breadcrumb.text }}</a
                            >
                        </li>
                    </ul>
                </nav>
                <b-button
                    v-if="$route.name == 'Recipes'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'NewRecipe' })"
                    >{{ t.btn.newRecipe }}</b-button
                >
                <b-button
                    v-if="$route.name == 'Ingredients'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'NewIngredient' })"
                    >{{ t.btn.newIngredient }}</b-button
                >
                <b-button
                    v-if="$route.name == 'DailyPhrases'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ query: { create: true } })"
                    >{{ t.btn.NewPhrase }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficePartners'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'NewPartner' })"
                    >{{ t.btn.newPartner }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficeCoupons'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'NewCoupon' })"
                    >{{ t.btn.newCoupon }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficeNews'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddNews' })"
                    >{{ t.btn.addNew }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficePosts'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddPosts' })"
                    >{{ t.btn.newPost }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficePodcasts'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddPodcasts' })"
                    >{{ t.btn.newPodcast }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficeVideos'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddVideos' })"
                    >{{ t.btn.newVideo }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficeUpcomingNews'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddUpcomingNews' })"
                    >{{ t.btn.newUpcomingNew }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficeProducts'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddProducts' })"
                    >{{ t.btn.newProduct }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficeTestimonials'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddTestimonials' })"
                    >{{ t.btn.newTestimonial }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficeParticipations'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddParticipations' })"
                    >{{ t.btn.newParticipation }}</b-button
                >
                <b-button
                    v-if="$route.name == 'BackOfficeSoulStates'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-plus"
                    type="is-success"
                    @click="$router.push({ name: 'AddSoulStates' })"
                    >{{ t.btn.newSoulState }}</b-button
                >
            </div>
            <router-view
                @on-edit="updateBreadcrumb"
                :key="$route.fullPath" />
        </div>
    </div>
</template>

<script>
    import Sidebar from '@/components/BackOffice/Sidebar.vue';
    import Navbar from '@/components/BackOffice/Navbar.vue';

    export default {
        name: 'BackOffice',
        components: {
            Sidebar,
            Navbar,
        },
        data() {
            return {
                t: this.$t('views.BackOffice'),
                last: null,
            };
        },
        created() {},
        methods: {
            updateBreadcrumb(last) {
                this.last = last;
            },
        },
        computed: {
            getBreadcrumbs() {
                let breadcrumbs = this.$route.path.split('/');
                let array = [];
                breadcrumbs.forEach((breadcrumb, i) => {
                    if (breadcrumb != 'backoffice' && !breadcrumb.includes('edit')) {
                        array.push({
                            text: this.t.BreadCrumbs[breadcrumb],
                            path: '/backoffice/' + breadcrumb,
                            isActive: false,
                        });
                    }
                });
                if (this.last) {
                    array[array.length - 1] = {
                        text: this.last,
                        path: '/backoffice/',
                        isActive: false,
                    };
                }
                array[array.length - 1].isActive = true;
                return array;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .first-div {
        padding: 2rem;
        background-color: lightgray;
        // padding-bottom: 10vh;
        min-height: 100vh;
    }
</style>
