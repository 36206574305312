<template>
    <section>
        <TopMenu />

        <div class="menu-header"><p>My shop</p></div>

        <div class="main">
            <div class="icon-content">
                <b-icon
                    pack="bl-icons"
                    icon="bl-cesto_compras"
                    type="is-secondary"
                    class="is-size-3">
                </b-icon>
                <button class="button btn-menu is-rounded">My Shop</button>
            </div>

            <hr class="mt-15" />
            <p
                class="has-text-primary has-text-centered"
                :style="{ 'font-family': changeFont }">
                {{ t.phrase }}
            </p>
            <hr />

            <div class="button-container mt-10">
                <div
                    class="button is-rounded"
                    @click="goToPage('Products')">
                    {{ t.products }}
                </div>
                <div
                    class="button is-rounded"
                    @click="goToPage('Partners')">
                    {{ t.partners }}
                </div>
                <div
                    class="button is-rounded"
                    @click="goToPage('OurChoices')">
                    {{ t.choices }}
                </div>
            </div>

            <div class="logo-container mt-6">
                <img
                    class="logo"
                    src="../assets/icons/coracao_verde.png"
                    alt="" />
            </div>
        </div>
        <div class="socials has-text-secondary mb-5">
            <b-icon
                @click.native="goToInstagram"
                icon="instagram"
                custom-size="mdi-24px"></b-icon>
            <b-icon
                icon="facebook"
                @click.native="goToFacebook"
                custom-size="mdi-24px">
            </b-icon>
            <b-icon
                icon="pinterest"
                @click.native="goToPinterest"
                custom-size="mdi-24px">
            </b-icon>
            <b-icon
                icon="twitter"
                @click.native="goToTwitter"
                custom-size="mdi-24px">
            </b-icon>

            <!-- 	<b-icon icon="share-variant" custom-size="mdi-24px"> </b-icon> -->
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import { Browser } from '@capacitor/browser';
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                t: this.$t('views.MyShop'),
            };
        },

        methods: {
            goToInstagram() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://www.instagram.com/belovebeyou/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://www.instagram.com/belovebeyou/', '_blank');
                } else window.open('https://www.instagram.com/belovebeyou/', '_blank');
            },
            goToFacebook() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/', '_blank');
                } else window.open('https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/', '_blank');
            },
            goToPinterest() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://www.pinterest.pt/blovebyoufg/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://www.pinterest.pt/blovebyoufg/', '_blank');
                } else window.open('https://www.pinterest.pt/blovebyoufg/', '_blank');
            },
            goToTwitter() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://twitter.com/belovebeyoufg' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://twitter.com/belovebeyoufg', '_blank');
                } else window.open('https://twitter.com/belovebeyoufg', '_blank');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .menu-header {
        position: fixed;
        height: 101px;
        background-color: #f2d4e0;
        top: 85px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 2;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 58px;
            top: 53px;
        }
    }
    .menu-header p {
        color: white;
        font-size: 67px;
        font-family: 'Borest-Standard';

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            font-size: 30px;
        }
    }

    .main {
        padding-top: 240px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 132px;
        }

        @include from($mobile-s) {
            min-height: 90vh;
        }
    }

    .btn-menu {
        border-color: #ce9aa9;
        color: #ce9aa9;
        margin-left: 10px;
        height: 32px;
        font-weight: bold;
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .icon-content {
        display: flex;
        justify-content: center;
    }

    hr {
        color: $primary;
        border: solid 1px;
        margin: 16px 0;
        background-color: $primary;
    }

    .main p {
        font-family: 'Borest-Standard';
        font-size: 1.25rem;
    }

    .button-container {
        display: grid;
        width: 100%;
        justify-content: center;
    }

    .button-container .button {
        margin-top: 8px;
        height: 32px;
        margin-left: 3px;
        margin-right: 3px;
        margin-bottom: 8px;
        border: 1px solid $secondary;
        color: $secondary;
        width: 160px;
        font-size: 14px;
        font-family: 'Lota Grotesque';

        @include until($mobile-s) {
            width: 84px;
            font-size: 9.5px;
        }

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            width: 120px;
            font-size: 12px;
        }
    }

    .logo-container {
        display: flex;
        justify-content: center;
    }

    .logo {
        height: 30px;
    }

    .socials {
        justify-content: space-between;
        display: flex;
        margin-top: 20px;
        padding-left: 5%;
        padding-right: 5%;
        @include from($mobile-l) {
            margin: 0 auto;
            max-width: $card-max-width;
        }
    }

    a {
        color: $secondary;
    }

    /* @media only screen and (max-width: 346px) {
		.button-container .button {
			margin-left: 3px;
			margin-right: 3px;
			width: 72px;
			font-size: 12px;
		}
	} */
</style>
