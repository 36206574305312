<template>
    <div>
        <div class="ticket-container">
            <div>
                <span class="open">{{ t.open }}</span>
                <div>
                    <ul>
                        <draggable
                            v-bind="dragOptions"
                            @start="drag = true"
                            @end="drag = false"
                            @change="log"
                            group="tickets"
                            :list="openTickets">
                            <li
                                v-for="ticket in openTickets"
                                :key="ticket._id"
                                :name="!drag ? 'flip-list' : null"
                                class="card open"
                                type="transition"
                                @click="openModal(ticket._id, ticket.type, ticket.description, ticket.createdAt, ticket.email, ticket.status, ticket.file)">
                                <div>
                                    <span class="card-label">{{ t.type }}</span>
                                    <span>{{ ticket.type }} </span>
                                </div>
                                <br />
                                <span class="card-label">{{ t.description }}</span>
                                <span class="card-description"> {{ ticket.description }}</span
                                ><br />
                                <span class="card-label">{{ t.description }} </span>
                                <span> {{ ticket.email }}</span
                                ><br />
                            </li>
                        </draggable>
                    </ul>
                </div>
            </div>
        </div>
        <div class="ticket-container">
            <div>
                <span class="in-progress">{{ t.progress }}</span>
            </div>
            <div>
                <ul>
                    <draggable
                        v-bind="dragOptions"
                        @start="drag = true"
                        @end="drag = false"
                        @change="log"
                        group="tickets"
                        :list="progressTickets">
                        <li
                            v-for="ticket in progressTickets"
                            :key="ticket._id"
                            class="card in-progress"
                            @click="openModal(ticket._id, ticket.type, ticket.description, ticket.createdAt, ticket.email, ticket.status, ticket.file)">
                            <div>
                                <span class="card-label">{{ t.type }}</span>
                                <span>{{ ticket.type }} </span>
                            </div>
                            <br />
                            <span class="card-label">{{ t.description }}</span>
                            <span class="card-description"> {{ ticket.description }}</span
                            ><br />
                            <span class="card-label">{{ t.description }} </span>
                            <span> {{ ticket.email }}</span
                            ><br />
                        </li>
                    </draggable>
                </ul>
            </div>
        </div>
        <div class="ticket-container">
            <div>
                <span class="solved">{{ t.solved }}</span>
            </div>
            <div>
                <ul>
                    <draggable
                        v-bind="dragOptions"
                        @start="drag = true"
                        @end="drag = false"
                        @change="log"
                        group="tickets"
                        :list="solvedTickets">
                        <li
                            v-for="ticket in solvedTickets"
                            :key="ticket._id"
                            class="card solved"
                            @click="openModal(ticket._id, ticket.type, ticket.description, ticket.createdAt, ticket.email, ticket.status, ticket.file)">
                            <div>
                                <span class="card-label">{{ t.type }}</span>
                                <span>{{ ticket.type }} </span>
                            </div>
                            <br />
                            <span class="card-label">{{ t.description }}</span>
                            <span class="card-description"> {{ ticket.description }}</span
                            ><br />
                            <span class="card-label">{{ t.description }} </span>
                            <span> {{ ticket.email }}</span
                            ><br />
                        </li>
                    </draggable>
                </ul>
            </div>
        </div>
        <div class="ticket-container">
            <div>
                <span class="closed">{{ t.closed }}</span>
            </div>
            <div>
                <ul>
                    <draggable
                        v-bind="dragOptions"
                        @start="drag = true"
                        @end="drag = false"
                        @change="log"
                        group="tickets"
                        :list="closedTickets">
                        <li
                            v-for="ticket in closedTickets"
                            :key="ticket._id"
                            class="card closed"
                            @click="openModal(ticket._id, ticket.type, ticket.description, ticket.createdAt, ticket.email, ticket.status, ticket.file)">
                            <div>
                                <span class="card-label">{{ t.type }}</span>
                                <span>{{ ticket.type }} </span>
                            </div>
                            <br />
                            <span class="card-label">{{ t.description }}</span>
                            <span class="card-description"> {{ ticket.description }}</span
                            ><br />
                            <span class="card-label">{{ t.description }} </span>
                            <span> {{ ticket.email }}</span
                            ><br />
                        </li>
                    </draggable>
                </ul>
            </div>
        </div>
        <TicketModal
            v-if="isModalOpen"
            :isModalOpen="isModalOpen"
            @clicked="closeModal"
            :id="modalId"
            :problem="modalProblem"
            :description="modalDescription"
            :created="modalCreatedAt"
            :email="modalEmail"
            :status="modalStatus"
            :file="modalFile"></TicketModal>
    </div>
</template>

<script>
    import { getSupports, updateSupport } from '@/api/support';
    import TicketModal from '@/components/BackOffice/Support/TicketModal';
    import draggable from 'vuedraggable';
    export default {
        name: 'Tickets',
        components: {
            draggable,
            TicketModal,
        },
        data() {
            return {
                t: this.$t('components.BackOffice.Support.Draggable'),
                openTickets: [],
                progressTickets: [],
                solvedTickets: [],
                closedTickets: [],
                isModalOpen: false,
                modalId: '',
                modalProblem: '',
                modalDescription: '',
                modalCreatedAt: '',
                modalEmail: '',
                modalStatus: '',
                modalFile: '',
                drag: false,
            };
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost',
                };
            },
        },
        methods: {
            openModal(id, type, description, created, email, status, file) {
                this.modalId = id;
                this.modalProblem = type;
                this.modalDescription = description;
                this.modalCreatedAt = created;
                this.modalEmail = email;
                this.modalStatus = status;
                this.modalFile = file;
                this.isModalOpen = true;
            },
            closeModal(value) {
                this.isModalOpen = value;
            },
            getData() {
                getSupports()
                    .then((response) => {
                        this.openTickets = response.data.supports.filter((sup) => sup.status == 'open');
                        this.progressTickets = response.data.supports.filter((sup) => sup.status == 'in-progress');
                        this.solvedTickets = response.data.supports.filter((sup) => sup.status == 'solved');
                        this.closedTickets = response.data.supports.filter((sup) => sup.status == 'closed');
                    })
                    .catch((error) => {});
            },
            update(id, status) {
                updateSupport({
                    id: id,
                    status: status,
                })
                    .then((response) => {})
                    .catch((error) => {});
            },
            log: function (evt) {
                if (evt.added) {
                    let id = evt.added.element._id;
                    if (this.openTickets.find((ticket) => ticket._id === id)) {
                        this.update(id, 'open');
                    } else if (this.progressTickets.find((ticket) => ticket._id === id)) {
                        this.update(id, 'in-progress');
                    } else if (this.solvedTickets.find((ticket) => ticket._id === id)) {
                        this.update(id, 'solved');
                    } else if (this.closedTickets.find((ticket) => ticket._id === id)) {
                        this.update(id, 'closed');
                    }
                }
            },
        },
        created() {
            this.getData();
        },
        updated() {
            this.getData();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .ticket-container {
        display: inline-grid;
        width: 24%;
        margin-right: 10px;
        text-align: center;
        height: 100%;
    }

    .card {
        border-radius: 12px;
        box-shadow: none;
        padding: 1rem;
        margin-top: 10px;
        width: 90%;
        height: 160px;
        background-color: #f1f1f1;
        text-align: left !important;
        font-size: 12px;
        margin-bottom: 16px;
        color: black;
    }
    .open {
        box-shadow: -5px -2px 10px 0px rgb(250, 83, 5);
    }
    span.open {
        border-bottom: solid 2px rgb(250, 83, 5);
        box-shadow: none;
    }

    .in-progress {
        box-shadow: -5px -2px 10px 0px rgb(7, 123, 201);
    }
    span.in-progress {
        border-bottom: solid 2px rgb(7, 123, 201);
        box-shadow: none;
    }

    .solved {
        box-shadow: -5px -2px 10px 0px rgb(5, 139, 17);
    }

    span.solved {
        border-bottom: solid 2px rgb(5, 139, 17);
        box-shadow: none;
    }

    .closed {
        box-shadow: -5px -2px 10px 0px rgb(170, 11, 11);
    }

    span.closed {
        border-bottom: solid 2px rgb(170, 11, 11);
        box-shadow: none;
    }

    .card:hover {
        cursor: pointer;
    }

    .card-label {
        font-weight: bold;
    }

    .card-description {
        display: -webkit-box;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        overflow: hidden;
    }

    @media screen and (max-width: 1023px) {
        .ticket-container {
            display: unset;
        }
    }

    @media screen and (min-width: 1024px) and (max-width: 1439px) {
        .ticket-container {
            display: inline-grid;
            width: 32%;
        }
    }
</style>
