<template>
    <section>
        <div
            class="top-menu-default"
            :class="myClass">
            <div class="media">
                <div class="media-left">
                    <div
                        @click="goToPage('Homepage')"
                        class="is-clickable">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-home"
                            class="is-size-5 custom-color">
                        </b-icon>
                    </div>
                    <div
                        @click="goToPage('Menus')"
                        class="is-clickable">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-file"
                            class="is-size-5 custom-color">
                        </b-icon>
                    </div>
                </div>
                <div class="media-content has-text-centered column is-vcentered">
                    <p>{{ letter }}</p>
                </div>
                <div class="media-right">
                    <div
                        v-if="
                            this.$route.name != 'MySpot' &&
                            this.$route.name != 'Menus' &&
                            this.$route.name != 'MyShop' &&
                            this.$route.name != 'Products' &&
                            this.$route.name != 'GiftCard' &&
                            this.$route.name != 'Partners' &&
                            this.$route.name != 'OurChoices' &&
                            this.$route.name != 'DailyRecipes' &&
                            this.$route.name != 'RecipeDetails' &&
                            this.$route.name != 'Charts' &&
                            this.$route.name != 'Search'
                        "
                        @click="goToPage('MyShop')"
                        class="is-clickable">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-cesto_compras"
                            class="is-size-5 custom-color">
                        </b-icon>
                    </div>
                    <div v-if="this.$route.name != 'Tracker' && this.$route.name != 'TrackerInfo'">
                        <div
                            @click="goToPage('Profile')"
                            class="is-clickable">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-user"
                                class="is-size-5 custom-color">
                            </b-icon>
                        </div>
                    </div>
                    <div
                        @click="goToPage('MySpot')"
                        class="is-clickable">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-objective"
                            class="is-size-5 custom-color">
                        </b-icon>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                firstLetter: null,
                lastLetter: null,
            };
        },
        computed: {
            myClass() {
                if (
                    this.$route.name == 'MySpot' ||
                    this.$route.name == 'Menus' ||
                    this.$route.name == 'MyShop' ||
                    this.$route.name == 'Products' ||
                    this.$route.name == 'GiftCard' ||
                    this.$route.name == 'Partners' ||
                    this.$route.name == 'OurChoices' ||
                    this.$route.name == 'Search'
                ) {
                    return 'pink';
                } else {
                    return 'blue';
                }
            },
            letter() {
                return this.firstLetter + this.lastLetter;
            },
        },

        created() {
            this.firstLetter = this.getUser.info.name.charAt(0);
            this.lastLetter = this.getUser.info.last_name.charAt(0);
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .top-menu-default {
        border: solid 1px $primary;
        width: 100%;
        height: 105px;
        position: fixed;
        top: 0;
        z-index: 5;
        background-color: white;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 57px;
        }
    }

    .pink {
        border: none;

        .custom-color {
            color: $secondary;
        }

        .media-content p {
            color: $secondary;
        }
    }

    .blue {
        border: none;
        .custom-color {
            color: $primary;
        }

        .media-content p {
            color: $primary;
        }
    }

    .media {
        height: 105px;
        padding-left: 5%;
        padding-right: 5%;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            transform: translateY(-18px);
        }
    }

    .media-right,
    .media-left {
        height: 105px;
        align-items: center;
        width: 100px;
        display: flex;
        justify-content: space-between;
        @include until($mobile-s) {
            width: 80px;
        }
    }

    .media-content p {
        font-family: 'Borest-Standard';
        font-size: 48px;
        color: $primary;
    }

    i::before {
        color: $primary;
    }

    @media only screen and (max-width: 350px) {
        .top-menu-default,
        .media,
        .media-right,
        .media-left {
            height: 85px;
        }
    }

    @media print {
        .top-menu-default {
            visibility: hidden;
        }
    }
</style>
