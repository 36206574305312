<template>
    <section>
        <div class="columns is-mobile is-multiline">
            <div class="column is-3-desktop is-12-mobile">
                <h3 class="mb-2">{{ t.displayed }}</h3>
                <draggable
                    class="list-group colums is-multiline is-mobile is-variable"
                    :list="displayedPhrases"
                    group="people"
                    @change="log">
                    <div
                        class="list-group-item column"
                        v-for="element in displayedPhrases"
                        :key="element._id">
                        <p class="displayed">{{ element.phrase[getLocale] }}</p>
                    </div>
                </draggable>
            </div>

            <div class="column is-9-desktop is-12-mobile">
                <h3 class="mb-5">{{ t.used }}</h3>
                <draggable
                    class="list-group columns is-multiline is-mobile is-variable"
                    :list="memoryPhrases"
                    group="people"
                    @change="log">
                    <div
                        class="list-group-item column is-4-desktop is-12-mobile is-clickable"
                        v-for="element in memoryPhrases"
                        :key="element._id"
                        @click="$router.push({ query: { edit: element._id } })">
                        <p>{{ element.phrase[getLocale] }}</p>
                    </div>
                </draggable>
            </div>
        </div>
    </section>
</template>
<script>
    import { getPhrases, updatePhrases, reorderPhrases } from '@/api/phrases';
    import draggable from 'vuedraggable';
    import Modal from './Modal';

    export default {
        name: 'DailyPhrases',
        components: {
            draggable,
        },
        data() {
            return {
                t: this.$t('components.BackOffice.DailyPhrases.Draggable'),
                displayedPhrases: [],
                memoryPhrases: [],
                drag: false,
                modal: null,
            };
        },
        computed: {
            dragOptions() {
                return {
                    animation: 200,
                    group: 'description',
                    disabled: false,
                    ghostClass: 'ghost',
                };
            },
        },
        created() {
            this.getData();
        },
        methods: {
            getData() {
                getPhrases()
                    .then((response) => {
                        this.displayedPhrases = response.data.phrases.filter((phr) => phr.display_date != null);
                        this.memoryPhrases = response.data.phrases.filter((phr) => phr.display_date == null);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            update(id, date) {
                updatePhrases({
                    id: id,
                    display_date: date,
                })
                    .then((response) => {})
                    .catch((error) => {
                        console.error(error);
                    });
            },
            log: function (evt) {
                if (evt.added) {
                    let id = evt.added.element._id;

                    if (this.displayedPhrases.find((phr) => phr._id === id)) {
                        this.reorder();
                    } else if (this.memoryPhrases.find((phr) => phr._id === id)) {
                        this.update(id, null);
                        this.reorder();
                    }
                } else if (evt.moved) {
                    let id = evt.moved.element._id;
                    if (this.displayedPhrases.find((phr) => phr._id === id)) {
                        this.reorder();
                    }
                }
            },
            getNewDate(index) {
                let d = this.moment().startOf('day').add(index, 'day');
                return d.toISOString();
            },
            reorder() {
                let array = [];
                for (let i = 0; i < this.displayedPhrases.length; i++) {
                    array.push({
                        id: this.displayedPhrases[i]._id,
                        display_date: this.getNewDate(i),
                    });
                }
                reorderPhrases({ array: array })
                    .then((response) => {
                        this.getData();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },
        watch: {
            '$route.query': {
                handler: function (query) {
                    if (query.create) {
                        this.modal = this.$buefy.modal.open({
                            parent: this,
                            component: Modal,
                            hasModalCard: true,
                            customClass: 'custom-class custom-class-2',
                            trapFocus: true,
                        });
                        this.modal.$on('close', () => {
                            this.$router.replace({ query: null });
                            this.getData();
                        });
                    } else if (query.edit) {
                        getPhrases({ id: query.edit })
                            .then((response) => {
                                this.modal = this.$buefy.modal.open({
                                    parent: this,
                                    component: Modal,
                                    props: {
                                        edit: true,
                                        phrase: response.data.phrases[0],
                                    },
                                    hasModalCard: true,
                                    customClass: 'custom-class custom-class-2',
                                    trapFocus: true,
                                });
                                this.modal.$on('close', () => {
                                    this.$router.replace({ query: null });
                                    this.getData();
                                });
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                },
                deep: true,
                immediate: true,
            },
        },
    };
</script>
<style lang="scss" scoped>
    .list-group-item {
        text-align: center;
        padding-left: 0;
    }
    .list-group-item p {
        background-color: #fff;
        padding: 1rem;
        border-radius: 5px;
    }
    .displayed {
        cursor: move;
    }
</style>
