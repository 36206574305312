import { get, post, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function getTrackerData(data, query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/' + data + _query);
}

export function addTrackerData(param, data) {
    return post('/' + param, data);
}
