import { remove, post, put, formDataPut } from '@/utils/http';

export function updatePreferences(image, data) {
    let form = new FormData();
    form.append('image', image);
    form.append('data', JSON.stringify(data));

    return formDataPut('/users/', form);
}

export function changePassword(oldPassword, newPassword) {
    return put('/users/password/', {
        old_password: oldPassword,
        password: newPassword,
    });
}

export function addCard(data) {
    return post('/users/sources', data);
}

export function removeCard(data) {
    return remove('/users/sources', data);
}

export function updateSubscription(data) {
    return put('/users/subscription', data);
}

export function cancelSubscription() {
    return remove('/users/subscription');
}
