<template>
    <div>
        <form
            @submit.prevent="formSubmit"
            class="columns is-mobile is-multiline">
            <b-field
                class="column is-12-mobile is-6-desktop"
                :label="t.name"
                :type="invalids.includes('name') ? 'is-danger' : ''">
                <b-input
                    type="text"
                    v-model="form.name"></b-input>
            </b-field>
            <b-field
                class="column is-12-mobile is-6-desktop"
                :label="t.url"
                :type="invalids.includes('url') ? 'is-danger' : ''">
                <b-input
                    type="text"
                    v-model="form.url"></b-input>
            </b-field>
            <b-field
                :type="invalids.includes('image') ? 'is-danger' : ''"
                class="column is-12">
                <b-upload
                    v-model="form.image"
                    expanded
                    drag-drop
                    accept="image/*">
                    <section class="section">
                        <div
                            v-if="!form.image && !form._image"
                            class="content has-text-centered">
                            <p>
                                <b-icon
                                    icon="upload"
                                    size="is-large">
                                </b-icon>
                            </p>
                            <p>{{ t.upload }}</p>
                        </div>
                        <div
                            v-else
                            class="content has-text-centered">
                            <img
                                class="img-preview"
                                :src="getFileURL" />
                        </div>
                    </section>
                </b-upload>
            </b-field>
            <div class="column is-offset-2-desktop is-8-desktop is-12-touch">
                <b-button
                    expanded
                    :loading="isLoading"
                    class="is-headline-bold-d-d is-headline-m-m"
                    type="is-primary"
                    native-type="submit"
                    >{{ t.submit }}</b-button
                >
            </div>
        </form>
    </div>
</template>

<script>
    import { addPartner, updatePartner, getPartners } from '@/api/partners';
    export default {
        name: 'Form',
        data() {
            return {
                t: this.$t('components.BackOffice.Partners.Form'),
                isUpdate: false,
                isLoading: false,
                invalids: [],
                form: {
                    name: null,
                    image: null,
                    url: null,
                },
            };
        },
        created() {
            if (this.$route.name == 'UpdatePartner') {
                this.isUpdate = true;
                this.getData();
            }
        },
        methods: {
            getData() {
                //used to update
                let query = {
                    id: this.$route.params.id,
                };
                getPartners(query)
                    .then((response) => {
                        let partner = response.data.partners[0];
                        if (this.isUpdate) this.$emit('on-edit', partner.name);
                        this.form = {
                            id: partner._id,
                            name: partner.name,
                            _image: partner.image,
                            url: partner.url,
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            formSubmit() {
                this.invalids = [];
                let _copy = Object.clone(this.form);
                delete _copy.image;

                if (!this.isUpdate) {
                    addPartner(this.form.image, _copy)
                        .then((response) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficePartners' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                    if (element.msg.code == 'Duplicated') message = this.t.api.duplicated;
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updatePartner(this.form.image, _copy)
                        .then((response) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.updateSuccess,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficePartners' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }

                            this.$buefy.toast.open({
                                message: this.t.api.error,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
        },
        computed: {
            getFileURL() {
                if (this.form.image) {
                    let url = window.URL.createObjectURL(this.form.image);
                    return url;
                } else if (this.form._image) {
                    return this.form._image;
                }
            },
        },
    };
</script>

<style lang="scss" scoped>
    .pagination-list {
        display: none;
    }
    .is-top-bordered {
        border-top: 1px solid black;
    }
</style>
