import { get, post, remove } from '@/utils/http';
const queryString = require('query-string');

export function addFavorite(data) {
    return post('/favorites', data);
}

export function getFavorite(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/favorites' + _query);
}

export function removeFavorite(data) {
    return remove('/favorites', data);
}
