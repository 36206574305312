<template>
    <section>
        <TopMenu />

        <div class="container">
            <div class="profile-card">
                <div class="media pt-6">
                    <div class="media-left"></div>
                    <div class="media-content">
                        <p class="label ml-2">{{ t.customer }}</p>

                        <div class="has-text-centered field-container mt-5">
                            <b-input
                                disabled
                                :class="{ 'error-text': errors.has('password') }"
                                class="field has-text-centered"
                                v-model="name"
                                size="is-small">
                            </b-input>
                        </div>

                        <b-field
                            type="is-primary"
                            class="has-text-centered select-container mt-5">
                            <b-select
                                :placeholder="t.selectSubject"
                                :class="{ 'error-text': errors.has('subject') }"
                                v-validate="'required'"
                                size="is-small"
                                name="subject"
                                v-model="subject"
                                expanded>
                                <option
                                    v-for="option in supportOptions"
                                    :key="option.value"
                                    :value="option.value">
                                    {{ option.text }}
                                </option>
                            </b-select>
                        </b-field>
                        <p
                            v-show="errors.has('subject')"
                            class="help is-danger has-text-centered">
                            {{ t.emptyOption }}
                        </p>

                        <b-field class="mt-5">
                            <b-input
                                maxlength="160"
                                type="textarea"
                                v-validate="'required'"
                                name="description"
                                v-model="description"
                                :placeholder="t.tellUs"></b-input>
                        </b-field>
                        <p
                            v-show="errors.has('description')"
                            class="help is-danger has-text-centered">
                            {{ t.emptyDescription }}
                        </p>
                    </div>

                    <div
                        class="media-right"
                        @click="$router.back()">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-black"
                            class="is-size-4 arrow">
                        </b-icon>
                    </div>
                </div>

                <div class="media files">
                    <div class="media-left ml-4">
                        <b-field>
                            <b-upload
                                v-model="file"
                                class="file-label attach">
                                <span class="file-cta">
                                    <span class="file-label">{{ t.attach }}</span>
                                </span>
                            </b-upload>
                        </b-field>
                    </div>
                    <div class="media-content">
                        <p
                            class="files-name mt-1"
                            v-if="file === null">
                            {{ t.noFiles }}
                        </p>
                        <p
                            class="files-name mt-1"
                            v-else>
                            {{ file.name }}
                        </p>
                    </div>
                    <div class="media-right">
                        <b-button
                            v-if="!sended"
                            @click="send"
                            class="send"
                            rounded
                            >{{ t.save }}</b-button
                        >
                        <b-button
                            v-if="sended"
                            class="sended"
                            rounded
                            >{{ t.sended }}</b-button
                        >
                    </div>
                </div>

                <div class="files mt-0">
                    <div class="ml-10-touch ml-23-desktop mt-2">
                        <p class="files-name">{{ t.limit }}</p>
                        <div style="width: 72%">
                            <p class="important">{{ t.important }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';

    import { addSupport } from '@/api/support';

    export default {
        components: { TopMenu, BotMenu },
        data() {
            return {
                t: this.$t('components.Support.ContactSupport'),
                name: null,
                subject: null,
                file: null,
                sended: false,
                email: null,
                description: null,
            };
        },
        computed: {
            supportOptions() {
                return [
                    {
                        value: 'Não consigo entrar na minha conta',
                        text: this.t.cantLogin,
                    },
                    {
                        value: 'As receitas diárias não estão disponíveis',
                        text: this.t.dailyRecipes,
                    },
                    {
                        value: 'Tenho dúvidas no funcionamento da aplicação',
                        text: this.t.doubts,
                    },
                    {
                        value: 'Não recebi nenhuma confirmação do pagamento da minha compra',
                        text: this.t.noPayment,
                    },
                    {
                        value: 'Outros assuntos',
                        text: this.t.other,
                    },
                ];
            },
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },
            send() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let data = {
                            type: this.subject,
                            description: this.description,
                            email: this.email,
                        };
                        let form = new FormData();
                        form.append('file', this.file);
                        form.append('data', JSON.stringify(data));

                        addSupport(form)
                            .then((response) => {
                                this.sended = true;
                            })
                            .catch((error) => {
                                console.error(error);
                                this.$buefy.toast.open({
                                    message: this.t.error,
                                    position: 'is-top',
                                    type: 'is-secondary',
                                });
                            });
                    }
                });
            },
        },
        created() {
            this.scrollToTop();
            this.name = this.getUser.info.name + ' ' + this.getUser.info.last_name;
            this.email = this.getUser.auth.email;
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        margin-bottom: 150px;
        padding: 1rem;
        @include from($mobile-l) {
            width: 70%;
            margin: 0 auto;
        }
    }

    .container {
        padding-top: 110px;
        padding-bottom: 10px;

        @include from($mobile-s) {
            padding-top: 140px;
        }
    }

    .label {
        font-family: 'Lota Grotesque';
        font-size: 0.9em;
        font-weight: bold;
    }

    .media {
        padding-left: 2%;
        padding-right: 2%;

        @include from($mobile-s) {
            padding-left: 8%;
            padding-right: 6%;
        }
    }
    .arrow {
        transform: translate(0, -23px);
    }

    .edit {
        width: 60px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    textarea {
        font-family: 'Lota Grotesque';
    }

    .attach {
        font-family: 'Lota Grotesque';
        font-size: 0.6rem;
        border-radius: 20px;
    }

    .files-name {
        color: #676767;
        font-size: 0.7rem;
        font-family: 'Lota Grotesque';
        opacity: 0.5;
    }

    .important {
        font-size: 0.8rem;
        font-family: 'Lota Grotesque';
    }

    .files {
        border-top: none !important;
    }

    .send {
        width: 92px;
        height: 28px;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 0.7rem;
    }

    .sended {
        width: 80px;
        height: 28px;
        font-family: 'Lota Grotesque';
        background-color: $primary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        font-size: 0.75rem;
    }
</style>
