import { post, get, formData, formDataPut, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addTestimonial(image, data) {
    let form = new FormData();

    if (image) {
        let new_name_image = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name_image);
    }
    form.append('data', JSON.stringify(data));
    return formData('/testimonials', form);
}

export function getTestimonials(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/testimonials' + _query);
}

export function updateTestimonial(image, data) {
    let form = new FormData();
    if (image) {
        let new_name_image = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name_image);
    } else {
        form.append('image', null);
    }
    form.append('data', JSON.stringify(data));
    return formDataPut('/testimonials', form);
}

export function removeTestimonial(id) {
    return remove('/testimonials', { id: id });
}
