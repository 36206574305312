import { post, get, formData, formDataPut, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addPodcast(image, data) {
    let form = new FormData();

    if (image) {
        let new_name_image = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name_image);
    }
    form.append('data', JSON.stringify(data));
    return formData('/podcasts', form);
}

export function getPodcasts(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/podcasts' + _query);
}

export function updatePodcast(image, audio, data) {
    let form = new FormData();
    if (audio) {
        let new_name = 'audio.' + audio.type.split('/')[1];
        form.append('audio', audio, new_name);
    } else {
        form.append('audio', null);
    }
    if (image) {
        let new_name_image = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name_image);
    } else {
        form.append('image', null);
    }
    form.append('data', JSON.stringify(data));
    return formDataPut('/podcasts', form);
}

export function removePodcast(id) {
    return remove('/podcasts', { id: id });
}
