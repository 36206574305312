<template>
    <section>
        <TopMenu />

        <div class="main">
            <b-field
                class="search-input"
                @click="search">
                <b-icon
                    @click.native="$router.go(-1)"
                    pack="bl-icons"
                    icon="bl-arrow-left"
                    type="is-secondary"
                    class="is-size-5 mr-4">
                </b-icon>
                <b-input
                    :placeholder="t.search"
                    type="search"
                    icon="magnify"
                    icon-clickable
                    v-model="searchString"
                    v-debounce:1s="search"
                    @icon-click="search">
                    >
                </b-input>
                <b-icon
                    icon="filter"
                    type="is-secondary"
                    class="is-size-5 ml-3"
                    @click.native="isModalActive = true">
                </b-icon>
            </b-field>

            <p
                v-show="breakfastRecipes.length > 0"
                class="has-text-secondary day-label has-text-centered"
                :style="{ 'font-family': this.changeFont }">
                {{ t.breakfast }}
            </p>
            <div
                @scroll="scrollBreakfast"
                id="scrollBreakfast"
                v-show="breakfastRecipes.length > 0"
                class="breakfast-container mt-2">
                <div
                    class="mr-4"
                    v-for="(recipe, index) in breakfastRecipes"
                    :key="index">
                    <div
                        class="card"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="recipe.name[getLocale].length < 20">
                                    {{ recipe.name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ recipe.name[getLocale].substring(0, 20) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-7">
                                    </b-icon>
                                    x
                                    {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-18px mr-2"> </i>
                                    <p style="transform: translateY(5px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>
            </div>

            <p
                v-show="lunchRecipes.length > 0"
                class="has-text-secondary day-label has-text-centered"
                :style="{ 'font-family': this.changeFont }">
                {{ t.lunch }}
            </p>
            <div
                @scroll="scrollLunch"
                v-show="lunchRecipes.length > 0"
                id="scrollLunch"
                class="breakfast-container mt-2">
                <div
                    class="mr-4"
                    v-for="(recipe, index) in lunchRecipes"
                    :key="index">
                    <div
                        class="card"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="recipe.name[getLocale].length < 20">
                                    {{ recipe.name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ recipe.name[getLocale].substring(0, 20) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-7">
                                    </b-icon>
                                    x
                                    {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-18px mr-2"> </i>
                                    <p style="transform: translateY(5px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>
            </div>

            <p
                v-show="dinnerRecipes.length > 0"
                class="has-text-secondary day-label has-text-centered"
                :style="{ 'font-family': this.changeFont }">
                {{ t.dinner }}
            </p>
            <div
                @scroll="scrollDinner"
                v-show="dinnerRecipes.length > 0"
                id="scrollDinner"
                class="breakfast-container mt-2">
                <div
                    class="mr-4"
                    v-for="(recipe, index) in dinnerRecipes"
                    :key="index">
                    <div
                        class="card"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="recipe.name[getLocale].length < 20">
                                    {{ recipe.name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ recipe.name[getLocale].substring(0, 20) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-7">
                                    </b-icon>
                                    x
                                    {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-18px mr-2"> </i>
                                    <p style="transform: translateY(5px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>
            </div>

            <p
                v-show="snacksRecipes.length > 0"
                class="has-text-secondary day-label has-text-centered"
                :style="{ 'font-family': this.changeFont }">
                {{ t.snacks }}
            </p>
            <div
                @scroll="scrollSnacks"
                v-show="snacksRecipes.length > 0"
                id="scrollSnacks"
                class="breakfast-container mt-2">
                <div
                    class="mr-4"
                    v-for="(recipe, index) in snacksRecipes"
                    :key="index">
                    <div
                        class="card"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="recipe.name[getLocale].length < 20">
                                    {{ recipe.name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ recipe.name[getLocale].substring(0, 20) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-7">
                                    </b-icon>
                                    x
                                    {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-18px mr-2"> </i>
                                    <p style="transform: translateY(5px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>
            </div>

            <p
                v-show="otherRecipes.length > 0"
                class="has-text-secondary day-label has-text-centered"
                :style="{ 'font-family': this.changeFont }">
                {{ t.other }}
            </p>
            <div
                @scroll="scrollOther"
                v-show="otherRecipes.length > 0"
                id="scrollOther"
                class="breakfast-container mt-2">
                <div
                    class="mr-4"
                    v-for="(recipe, index) in otherRecipes"
                    :key="index">
                    <div
                        class="card"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="recipe.name[getLocale].length < 20">
                                    {{ recipe.name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ recipe.name[getLocale].substring(0, 20) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-7">
                                    </b-icon>
                                    x
                                    {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-18px mr-2"> </i>
                                    <p style="transform: translateY(5px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>
            </div>

            <p
                v-show="accompanimentRecipes.length > 0"
                class="has-text-secondary day-label has-text-centered"
                :style="{ 'font-family': this.changeFont }">
                {{ t.accompaniment }}
            </p>
            <div
                @scroll="scrollAccompaniment"
                v-show="accompanimentRecipes.length > 0"
                id="scrollAccompaniment"
                class="breakfast-container mt-2">
                <div
                    class="mr-4"
                    v-for="(recipe, index) in accompanimentRecipes"
                    :key="index">
                    <div
                        class="card"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="recipe.name[getLocale].length < 20">
                                    {{ recipe.name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ recipe.name[getLocale].substring(0, 20) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-7">
                                    </b-icon>
                                    x
                                    {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-18px mr-2"> </i>
                                    <p style="transform: translateY(5px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>
            </div>

            <p
                v-show="sauceRecipes.length > 0"
                class="has-text-secondary day-label has-text-centered"
                :style="{ 'font-family': this.changeFont }">
                {{ t.sauce }}
            </p>
            <div
                @scroll="scrollSauce"
                v-show="sauceRecipes.length > 0"
                id="scrollSauce"
                class="breakfast-container mt-2">
                <div
                    class="mr-4"
                    v-for="(recipe, index) in sauceRecipes"
                    :key="index">
                    <div
                        class="card"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="recipe.name[getLocale].length < 20">
                                    {{ recipe.name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ recipe.name[getLocale].substring(0, 20) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-7">
                                    </b-icon>
                                    x
                                    {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-18px mr-2"> </i>
                                    <p style="transform: translateY(5px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>

        <b-modal
            v-model="isModalActive"
            class="modal"
            :width="640">
            <div
                class="modal-card"
                style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Filtrar receitas</p>
                    <button
                        type="button"
                        class="delete"
                        @click="isModalActive = false" />
                </header>
                <section class="modal-card-body">
                    <b-field
                        v-for="(value, name, i) in meals"
                        :key="100 + i">
                        <b-radio
                            v-if="name !== 'all' && name !== 'athlete' && name !== 'geriatrics'"
                            class="text checkbox"
                            :native-value="name"
                            type="is-secondary"
                            v-model="checkedMeals"
                            >{{ value }}
                        </b-radio>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        @click="filterRecipes"
                        :label="t.searchButton"
                        type="is-primary" />
                    <b-button
                        @click="clearFilter"
                        :label="t.clear"
                        type="is-secondary" />
                </footer>
            </div>
        </b-modal>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import { getRecipes } from '@/api/recipes';

    export default {
        components: {
            TopMenu,
        },

        data() {
            return {
                t: this.$t('views.SearchRecipe'),
                receivedRecipes: [],
                breakfastRecipes: [],
                lunchRecipes: [],
                dinnerRecipes: [],
                snacksRecipes: [],
                otherRecipes: [],
                accompanimentRecipes: [],
                sauceRecipes: [],
                page: 1,
                end: false,
                searchString: null,
                isModalActive: false,
                meals: this.$t('shared.tags'),
                checkedMeals: [],
            };
        },

        computed: {
            getSavedCheckedMeals() {
                return this.getCheckedMeals;
            },
        },

        methods: {
            receiveRecipes(data) {
                getRecipes(data)
                    .then((response) => {
                        this.receivedRecipes = response.data.recipes;
                        if (data.recipe_type === 'breakfast') {
                            this.breakfastRecipes.push(...this.receivedRecipes);
                        } else if (data.recipe_type === 'lunch') {
                            this.lunchRecipes.push(...this.receivedRecipes);
                        } else if (data.recipe_type === 'dinner') {
                            this.dinnerRecipes.push(...this.receivedRecipes);
                        } else if (data.recipe_type === 'snacks') {
                            this.snacksRecipes.push(...this.receivedRecipes);
                        } else if (data.recipe_type === 'other') {
                            this.otherRecipes.push(...this.receivedRecipes);
                        } else if (data.recipe_type === 'accompaniment') {
                            this.accompanimentRecipes.push(...this.receivedRecipes);
                        } else if (data.recipe_type === 'sauce') {
                            this.sauceRecipes.push(...this.receivedRecipes);
                        } else {
                            for (let index = 0; index < this.receivedRecipes.length; index++) {
                                for (let i = 0; i < this.receivedRecipes[index].recipe_type.length; i++) {
                                    if (this.receivedRecipes[index].recipe_type[i] === 'breakfast') {
                                        this.breakfastRecipes.push(this.receivedRecipes[index]);
                                    } else if (this.receivedRecipes[index].recipe_type[i] === 'lunch') {
                                        this.lunchRecipes.push(this.receivedRecipes[index]);
                                    } else if (this.receivedRecipes[index].recipe_type[i] === 'dinner') {
                                        this.dinnerRecipes.push(this.receivedRecipes[index]);
                                    } else if (this.receivedRecipes[index].recipe_type[i] === 'snacks') {
                                        this.snacksRecipes.push(this.receivedRecipes[index]);
                                    } else if (this.receivedRecipes[index].recipe_type[i] === 'other') {
                                        this.otherRecipes.push(this.receivedRecipes[index]);
                                    } else if (this.receivedRecipes[index].recipe_type[i] === 'accompaniment') {
                                        this.accompanimentRecipes.push(this.receivedRecipes[index]);
                                    } else if (this.receivedRecipes[index].recipe_type[i] === 'sauce') {
                                        this.sauceRecipes.push(this.receivedRecipes[index]);
                                    }
                                }
                            }
                        }

                        this.isLoading = false;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.setIsPageLoading(false);
                    });
            },

            seeDetails(id) {
                this.$router.push({ name: 'RecipeDetails', params: { id: id } });
            },

            search() {
                this.setIsPageLoading(true);
                this.breakfastRecipes = [];
                this.lunchRecipes = [];
                this.dinnerRecipes = [];
                this.snacksRecipes = [];
                this.otherRecipes = [];
                this.accompanimentRecipes = [];
                this.sauceRecipes = [];
                let breakfastScroll = document.getElementById('scrollBreakfast');
                breakfastScroll.scrollTo(0, 0);

                let lunchScroll = document.getElementById('scrollLunch');
                lunchScroll.scrollTo(0, 0);

                let dinnerScroll = document.getElementById('scrollDinner');
                dinnerScroll.scrollTo(0, 0);

                let snacksScroll = document.getElementById('scrollSnacks');
                snacksScroll.scrollTo(0, 0);

                let otherScroll = document.getElementById('scrollOther');
                otherScroll.scrollTo(0, 0);

                let accompanimentScroll = document.getElementById('scrollAccompaniment');
                accompanimentScroll.scrollTo(0, 0);

                let sauceScroll = document.getElementById('scrollSauce');
                sauceScroll.scrollTo(0, 0);

                if (this.searchString) {
                    if (this.getLocale == 'pt') {
                        this.receiveRecipes({
                            search_pt: this.searchString,
                            limit: 5,
                            page: this.page,
                            ...(this.checkedMeals.length > 0 && { tags: this.checkedMeals }),
                        });
                    } else {
                        this.receiveRecipes({
                            search_en: this.searchString,
                            limit: 5,
                            page: this.page,
                            ...(this.checkedMeals.length > 0 && { tags: this.checkedMeals }),
                        });
                    }
                } else {
                    this.receiveRecipes({
                        recipe_type: 'breakfast',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'lunch',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'dinner',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'snacks',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'other',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'accompaniment',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'sauce',
                        page: this.page,
                        limit: 5,
                    });
                }
            },

            scrollBreakfast(e) {
                const { target } = e;
                if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length == 0) {
                    //this code will run when the user scrolls to the end of this div so
                    //you could do an api call here to implement lazy loading

                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            recipe_type: 'breakfast',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length > 0) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            tags: this.checkedMeals,
                            limit: 5,
                            recipe_type: 'breakfast',
                            page: this.page,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && this.searchString) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        if (this.getLocale == 'pt') {
                            this.receiveRecipes({
                                search_pt: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'breakfast',
                            });
                        } else {
                            this.receiveRecipes({
                                search_en: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'breakfast',
                            });
                        }
                    }
                }
            },
            scrollLunch(e) {
                const { target } = e;
                if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length == 0) {
                    //this code will run when the user scrolls to the end of this div so
                    //you could do an api call here to implement lazy loading

                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            recipe_type: 'lunch',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length > 0) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            tags: this.checkedMeals,
                            limit: 5,
                            recipe_type: 'lunch',
                            page: this.page,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && this.searchString) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        if (this.getLocale == 'pt') {
                            this.receiveRecipes({
                                search_pt: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'lunch',
                            });
                        } else {
                            this.receiveRecipes({
                                search_en: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'lunch',
                            });
                        }
                    }
                }
            },
            scrollDinner(e) {
                const { target } = e;
                if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString) {
                    //this code will run when the user scrolls to the end of this div so
                    //you could do an api call here to implement lazy loading

                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            recipe_type: 'dinner',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length > 0) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            tags: this.checkedMeals,
                            limit: 5,
                            recipe_type: 'dinner',
                            page: this.page,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && this.searchString) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        if (this.getLocale == 'pt') {
                            this.receiveRecipes({
                                search_pt: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'dinner',
                            });
                        } else {
                            this.receiveRecipes({
                                search_en: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'dinner',
                            });
                        }
                    }
                }
            },
            scrollSnacks(e) {
                const { target } = e;
                if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length == 0) {
                    //this code will run when the user scrolls to the end of this div so
                    //you could do an api call here to implement lazy loading

                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            recipe_type: 'snacks',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length > 0) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            tags: this.checkedMeals,
                            limit: 5,
                            recipe_type: 'snacks',
                            page: this.page,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && this.searchString) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        if (this.getLocale == 'pt') {
                            this.receiveRecipes({
                                search_pt: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'snacks',
                            });
                        } else {
                            this.receiveRecipes({
                                search_en: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'snacks',
                            });
                        }
                    }
                }
            },
            scrollOther(e) {
                const { target } = e;
                if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length == 0) {
                    //this code will run when the user scrolls to the end of this div so
                    //you could do an api call here to implement lazy loading

                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            recipe_type: 'other',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length > 0) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            tags: this.checkedMeals,
                            limit: 5,
                            recipe_type: 'other',
                            page: this.page,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && this.searchString) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        if (this.getLocale == 'pt') {
                            this.receiveRecipes({
                                search_pt: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'other',
                            });
                        } else {
                            this.receiveRecipes({
                                search_en: this.searchString,
                                limit: 5,
                                page: this.page,
                                recipe_type: 'other',
                            });
                        }
                    }
                }
            },

            scrollAccompaniment(e) {
                const { target } = e;
                if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length == 0) {
                    //this code will run when the user scrolls to the end of this div so
                    //you could do an api call here to implement lazy loading

                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            recipe_type: 'accompaniment',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length > 0) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            tags: this.checkedMeals,
                            recipe_type: 'accompaniment',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && this.searchString) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        if (this.getLocale == 'pt') {
                            this.receiveRecipes({
                                search_pt: this.searchString,
                                recipe_type: 'accompaniment',
                                page: this.page,
                                limit: 5,
                            });
                        } else {
                            this.receiveRecipes({
                                search_en: this.searchString,
                                recipe_type: 'accompaniment',
                                page: this.page,
                                limit: 5,
                            });
                        }
                    }
                }
            },

            scrollSauce(e) {
                const { target } = e;
                if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length == 0) {
                    //this code will run when the user scrolls to the end of this div so
                    //you could do an api call here to implement lazy loading

                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            recipe_type: 'sauce',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && !this.searchString && this.checkedMeals.length > 0) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        this.receiveRecipes({
                            tags: this.checkedMeals,
                            recipe_type: 'sauce',
                            page: this.page,
                            limit: 5,
                        });
                    }
                } else if (Math.abs(target.scrollLeft) === target.scrollWidth - target.clientWidth && this.searchString) {
                    this.end = true;
                    if (this.end) {
                        this.page++;
                        if (this.getLocale == 'pt') {
                            this.receiveRecipes({
                                search_pt: this.searchString,
                                recipe_type: 'sauce',
                                page: this.page,
                                limit: 5,
                            });
                        } else {
                            this.receiveRecipes({
                                search_en: this.searchString,
                                recipe_type: 'sauce',
                                page: this.page,
                                limit: 5,
                            });
                        }
                    }
                }
            },

            filterRecipes() {
                this.setIsPageLoading(true);
                this.setCheckedMeals(this.checkedMeals);
                this.breakfastRecipes = [];
                this.lunchRecipes = [];
                this.dinnerRecipes = [];
                this.snacksRecipes = [];
                this.otherRecipes = [];
                this.accompanimentRecipes = [];
                this.sauceRecipes = [];

                let breakfastScroll = document.getElementById('scrollBreakfast');
                breakfastScroll.scrollTo(0, 0);

                let lunchScroll = document.getElementById('scrollLunch');
                lunchScroll.scrollTo(0, 0);

                let dinnerScroll = document.getElementById('scrollDinner');
                dinnerScroll.scrollTo(0, 0);

                let snacksScroll = document.getElementById('scrollSnacks');
                snacksScroll.scrollTo(0, 0);

                let otherScroll = document.getElementById('scrollOther');
                otherScroll.scrollTo(0, 0);

                let accompanimentScroll = document.getElementById('scrollAccompaniment');
                accompanimentScroll.scrollTo(0, 0);

                let sauceScroll = document.getElementById('scrollSauce');
                sauceScroll.scrollTo(0, 0);
                this.receiveRecipes({
                    tags: this.checkedMeals,
                    limit: 5,
                    recipe_type: 'breakfast',
                    page: this.page,
                });
                this.receiveRecipes({
                    tags: this.checkedMeals,
                    limit: 5,
                    recipe_type: 'lunch',
                    page: this.page,
                });
                this.receiveRecipes({
                    tags: this.checkedMeals,
                    limit: 5,
                    recipe_type: 'dinner',
                    page: this.page,
                });
                this.receiveRecipes({
                    tags: this.checkedMeals,
                    limit: 5,
                    recipe_type: 'snacks',
                    page: this.page,
                });
                this.receiveRecipes({
                    tags: this.checkedMeals,
                    limit: 5,
                    recipe_type: 'other',
                    page: this.page,
                });
                this.receiveRecipes({
                    tags: this.checkedMeals,
                    limit: 5,
                    recipe_type: 'accompaniment',
                    page: this.page,
                });
                this.receiveRecipes({
                    tags: this.checkedMeals,
                    limit: 5,
                    recipe_type: 'sauce',
                    page: this.page,
                });

                this.isModalActive = false;
            },

            clearFilter() {
                this.checkedMeals = [];
                this.setCheckedMeals(this.checkedMeals);
            },
        },
        watch: {
            searchString() {
                if (this.searchString === '') {
                    this.page = 1;
                    this.receiveRecipes({
                        recipe_type: 'breakfast',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'lunch',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'dinner',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'snacks',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'other',
                        page: this.page,
                        limit: 5,
                    });

                    this.receiveRecipes({
                        recipe_type: 'accompaniment',
                        page: this.page,
                        limit: 5,
                    });
                    this.receiveRecipes({
                        recipe_type: 'sauce',
                        page: this.page,
                        limit: 5,
                    });
                }
            },
        },

        created() {
            this.setIsPageLoading(true);
            this.checkedMeals = this.getSavedCheckedMeals;
            this.receiveRecipes({
                recipe_type: 'breakfast',
                page: this.page,
                limit: 5,
            });
            this.receiveRecipes({
                recipe_type: 'lunch',
                page: this.page,
                limit: 5,
            });
            this.receiveRecipes({
                recipe_type: 'dinner',
                page: this.page,
                limit: 5,
            });
            this.receiveRecipes({
                recipe_type: 'snacks',
                page: this.page,
                limit: 5,
            });
            this.receiveRecipes({
                recipe_type: 'other',
                page: this.page,
                limit: 5,
            });
            this.receiveRecipes({
                recipe_type: 'accompaniment',
                page: this.page,
                limit: 5,
            });
            this.receiveRecipes({
                recipe_type: 'sauce',
                page: this.page,
                limit: 5,
            });
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 120px;

        @include from($tablet) {
            padding-left: 20%;
            padding-right: 20%;
            padding-bottom: 100px;
        }
    }

    .container {
        background-color: #ce9aa9;
        width: 100%;
        z-index: 1;
        height: 166px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 126px;
            padding-top: 10px;
        }
    }

    .days-container {
        width: 100%;
        justify-content: space-between;
        display: flex;
        padding-left: 4%;
        padding-right: 4%;
        @include from($tablet) {
            padding-left: 28%;
            padding-right: 28%;
        }

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-left: 2%;
            padding-right: 2%;
            transform: translateY(-24px);
        }
    }
    .day {
        background-color: $secondary;
        color: white;
        display: inline-block;
        padding-top: 0.6rem;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        font-family: 'Lota Grotesque';
        font-size: 14px;
        cursor: pointer;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            width: 34px;
            height: 34px;
        }
    }

    .day-label {
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .btn-menu-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-menu {
        border-color: white;
        color: white;
        margin-left: 10px;
        height: 32px;
        font-size: 13px;
        font-family: 'Lota Grotesque';
        background-color: transparent !important;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 28px;
        }
    }

    p {
        font-family: 'Borest-Standard';
        font-size: 40px;
        text-align: center;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            font-size: 30px;
        }
    }

    .card {
        height: 98px;
        border-radius: 48px;
        border: 1px solid $secondary;
        cursor: pointer;
        background-color: transparent;
        border-radius: 25px;
        width: 240px;

        @include from($tablet) {
            width: 400px;
            height: 120px;
        }
    }

    .card-image {
        /* background-image: url('https://realfood.tesco.com/media/images/1400x919-Pink-ombre-pancakes-283d8170-a45b-4bc5-a3e9-ef09ad841530-0-1400x919.jpg'); */
        justify-content: center;
        display: flex;
        height: 96px;
        border-top-left-radius: 25px 25px;
        border-bottom-left-radius: 25px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
        @include from($tablet) {
            height: 120px;
        }
    }

    .media-left {
        width: 50%;
    }
    .media-right,
    .media-left {
        margin: 0;
    }

    .media-content p {
        font-size: 0.675rem;
        font-family: 'Lota Grotesque';
        white-space: break-spaces;
        @include from($tablet) {
            font-size: 0.8rem;
        }
    }

    .media-content {
        overflow: hidden;
    }

    .breakfast-container {
        height: 130px;
        display: flex;
        overflow: auto;
        white-space: nowrap;
        @include from($tablet) {
            height: 160px;
        }
    }

    /deep/ .field.has-addons {
        display: flex;
        justify-content: center;
    }

    .modal {
        padding-left: 20px;
        padding-right: 20px;
    }
</style>
