<template>
    <section>
        <TopMenu />
        <div class="container">
            <div class="profile-card">
                <div class="media pt-6">
                    <div class="media-left">
                        <p class="label ml-2">{{ t.help }}</p>
                        <p
                            class="ml-6 mt-3"
                            @click="goToPage('ContactSupport')">
                            {{ t.support }}
                        </p>
                        <p
                            class="ml-6 mt-3"
                            @click="goToPage('Terms')">
                            {{ t.terms }}
                        </p>
                        <!-- <p class="ml-6 mt-3">{{ t.order }}</p> -->
                        <p
                            class="ml-6 mt-3"
                            @click="seeInfo('payments')">
                            {{ t.payments }}
                        </p>
                        <p
                            class="ml-6 mt-3"
                            @click="seeInfo('shipping')">
                            {{ t.shipping }}
                        </p>
                        <p
                            class="ml-6 mt-3"
                            @click="seeInfo('returns')">
                            {{ t.returns }}
                        </p>
                        <p
                            class="ml-6 mt-3"
                            @click="goToPage('PrivacyPolicies')">
                            {{ t.alternative }}
                        </p>
                        <p
                            class="ml-6 mt-3"
                            @click="goToPage('ContactSupport')">
                            {{ t.comments }}
                        </p>
                        <p
                            class="ml-6 mt-3"
                            @click="seeInfo('coupons')">
                            {{ t.coupons }}
                        </p>
                    </div>
                    <div class="media-content"></div>
                    <div class="media-right">
                        <div @click="$router.back()">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-arrow-left"
                                type="is-black"
                                class="is-size-4 arrow">
                            </b-icon>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    export default {
        components: {
            TopMenu,
            BotMenu,
        },
        data() {
            return {
                t: this.$t('components.Help'),
            };
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },

            seeInfo(infoType) {
                this.$router.push({ name: 'HelpInfo', params: { type: infoType } });
            },
        },

        created() {
            this.scrollToTop();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        padding: 1rem;
        padding-bottom: 40px;
        @include from($mobile-l) {
            max-width: 60%;
            margin: 0 auto;
        }
    }

    .container {
        padding-top: 110px;
        padding-bottom: 140px;
        @include from($mobile-s) {
            padding-top: 140px;
        }

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-bottom: 76px;
        }
    }

    .label {
        font-family: 'Lota Grotesque';
        font-size: 0.9em;
        font-weight: bold;
        cursor: default;
    }
    p {
        font-family: 'Lota Grotesque';
        font-size: 0.75rem;
        cursor: pointer;
    }

    .media {
        padding-left: 12%;
        padding-right: 12%;
    }
    .arrow {
        transform: translate(3px, -23px);

        @include until($mobile-s) {
            transform: translate(-33px, -23px);
        }
    }

    .field {
        width: 66% !important;
    }

    .select-container {
        display: flex;
        justify-content: center;
    }
</style>
