import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersistence from 'vuex-persist';

import auth from './modules/auth';
import app from './modules/app';
import loginSteps from './modules/loginSteps';
import paymentSteps from './modules/paymentsSteps';
import freeTrial from './modules/freeTrial';
import subscriptionSteps from './modules/subscriptionSteps';
import trackerSteps from './modules/trackerSteps';
import mySpotSteps from './modules/mySpotSteps';
import registerPreferences from './modules/registerPreferences';
import language from './modules/language';
import searchRecipes from './modules/searchRecipes';

Vue.use(Vuex);
const vuexLocal = new VuexPersistence({
    storage: window.localStorage,
});

export default new Vuex.Store({
    modules: { auth, app, loginSteps, paymentSteps, freeTrial, subscriptionSteps, trackerSteps, mySpotSteps, registerPreferences, language, searchRecipes },
    plugins: [vuexLocal.plugin],
});
