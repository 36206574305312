import { get, post, remove } from '@/utils/http';
const queryString = require('query-string');

export function addRecipe(image, data) {
    let form = new FormData();
    form.append('image', image);
    form.append('data', JSON.stringify(data));

    return post('/user-recipes', form);
}

export function getRecipes(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/user-recipes' + _query);
}

export function removeRecipe(id) {
    return remove('/user-recipes/', { id: id });
}
