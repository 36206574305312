const state = {
    checkedMeals: [],
};

// getters
const getters = {
    getCheckedMeals(state, getters) {
        return state.checkedMeals;
    },
};

// mutations
const mutations = {
    setCheckedMeals(state, meals) {
        state.checkedMeals = meals;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
