<template>
    <section>
        <div class="header spaced-name has-text-white">
            <p>B</p>
            <p>E</p>
            <p>L</p>
            <p>O</p>
            <p>V</p>
            <p>E</p>
            <p>R</p>
            <p></p>
            <p>M</p>
            <p>E</p>
            <p>A</p>
            <p>L</p>
            <p></p>
            <p>P</p>
            <p>L</p>
            <p>A</p>
            <p>N</p>
            <p>N</p>
            <p>E</p>
            <p>R</p>
        </div>
    </section>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .header {
        background-color: $primary;
        width: 100%;
        height: 50px;
        position: fixed;
        top: 0;
        z-index: 1;
        align-items: center;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 52px;
        }
    }

    .spaced-name {
        justify-content: space-between;
        display: flex;
        padding-left: 6%;
        padding-right: 6%;
        font-family: 'Borest-Standard';
    }
</style>
