<template>
    <section>
        <TopMenu />
        <div class="main">
            <div class="img-container">
                <b-image
                    class="img"
                    :src="require('@/assets/icons/coracao_verde.png')"
                    alt="Green Heart"></b-image>
            </div>

            <div class="heart-text">Hello!</div>

            <div class="card">
                <p
                    class="title has-text-white has-text-centered pt-6"
                    :style="{ 'font-family': this.changeFont }">
                    {{ t.welcome }}
                </p>
                <p
                    class="subtitle has-text-white has-text-centered mt-4"
                    :style="{ 'font-family': this.changeFont }">
                    {{ t.text }}
                </p>
            </div>

            <div class="btn-container">
                <b-button
                    class="go mt-5"
                    rounded
                    @click="goToPage('Login')"
                    >{{ t.go }}</b-button
                >
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                t: this.$t('views.Auth'),
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 6rem;
        margin-bottom: 30px;
        @include until($mobile-s) {
            padding-top: 3rem;
        }
    }
    .card {
        background-color: $primary;
        height: auto;
        border-radius: 48px;
        padding-bottom: 20px;

        max-width: $card-max-width;
        margin-left: auto;
        margin-right: auto;
    }
    .title {
        font-size: 52px;
        font-weight: normal;
        font-size: 2rem;
        @include until($mobile-s) {
            font-size: 28px;
        }
    }

    .subtitle {
        font-size: 24px;
        font-family: 'Borest-Standard';
        font-weight: normal;
        padding-left: 32px;
        padding-right: 32px;
        white-space: pre-line;
        text-align: center !important;
        font-size: 1rem;
    }

    .img {
        width: 160px;
        transform: translateY(40px);
    }

    .img-container {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 10;
    }

    .heart-text {
        position: absolute;
        left: 50%;
        transform: translate(-50%, -120%);
        font-size: 42px;
        color: white;
        font-family: 'Borest-Standard';
        z-index: 11;
    }

    .go {
        width: 70px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .btn-container {
        display: flex;
        justify-content: center;
    }

    @media only screen and (max-width: 320px) {
        .main {
            padding-top: 3rem;
        }
    }
</style>
