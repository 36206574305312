<template>
    <b-table
        :data="data"
        ref="table"
        paginated
        backend-pagination
        :total="total"
        per-page="10"
        @page-change="onPageChange"
        aria-next-label="Next page"
        aria-previous-label="Previous page"
        aria-page-label="Page"
        aria-current-label="Current page"
        default-sort="createdAt"
        default-sort-direction="asc">
        <b-table-column
            field="images"
            class="has-text-centered"
            v-slot="props"
            width="5rem">
            <img
                :src="props.row.images[0]"
                class="image" />
        </b-table-column>

        <b-table-column
            field="name"
            :label="t.columns.name"
            v-slot="props"
            width="1rem">
            {{ props.row.name[getLocale] }}
        </b-table-column>

        <b-table-column
            field="description"
            :label="t.columns.description"
            v-slot="props"
            width="20rem">
            {{ props.row.description[getLocale].substring(0, 160) }}
        </b-table-column>

        <b-table-column
            field="price"
            :label="t.columns.price"
            v-slot="props"
            width="1rem">
            {{ props.row.unit_amount }} €
        </b-table-column>

        <b-table-column
            v-slot="props"
            width="0.1rem">
            <b-button
                class="level-right"
                size="is-small"
                icon-right="mdi mdi-pencil"
                type="is-primary"
                :loading="isLoading"
                @click="$router.push({ name: 'UpdateProducts', params: { id: props.row._id } })"></b-button>
        </b-table-column>

        <b-table-column
            v-slot="props"
            width="5">
            <b-button
                class="level-right"
                size="is-small"
                icon-right="mdi mdi-delete"
                type="is-danger"
                :loading="isLoading"
                @click="deleteProduct(props.row._id)"></b-button>
        </b-table-column>
    </b-table>
</template>

<script>
    import { getProducts, removeProduct } from '@/api/products';

    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Products.List'),
                data: [],
                total: 0,
                page: 1,
            };
        },
        created() {
            this.getData({
                limit: 10,
            });
        },
        methods: {
            getData(query) {
                getProducts(query)
                    .then((response) => {
                        this.data = response.data.products;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        this.data = [];
                        this.total = 0;
                    });
            },
            onPageChange(page) {
                this.page = page;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
            deleteProduct(id) {
                this.$buefy.dialog.confirm({
                    title: this.t.dialog.title,
                    message: this.t.dialog.message,
                    confirmText: this.t.dialog.confirm,
                    cancelText: this.t.dialog.cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.isLoading = true;
                        removeProduct(id)
                            .then((response) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.success,
                                    position: 'is-bottom',
                                    type: 'is-success',
                                });
                                this.getData();
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.error,
                                    position: 'is-bottom',
                                    type: 'is-danger',
                                });
                            });
                    },
                });
            },
        },
    };
</script>
<style scoped>
    .image {
        height: 5rem;
        width: auto;
        margin: auto;
    }
</style>
