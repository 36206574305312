import { get, post, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addSupport(data) {
    return post('/support', data);
}

export function getSupports(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/support' + _query);
}

export function updateSupport(data) {
    return put('/support', data);
}
