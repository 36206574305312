<template>
    <section class="grey">
        <div class="media">
            <div class="media-left">
                <div
                    @click="$router.back()"
                    class="icons">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-arrow-left"
                        type="is-black"
                        class="is-size-4">
                    </b-icon>
                </div>
            </div>
            <div class="media-content has-text-centered column is-vcentered">
                <p class="header-text">{{ t.myOrders }}</p>
            </div>
            <div class="media-right">
                <div
                    @click="goToPage('MyShop')"
                    class="icons">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-cesto_compras"
                        type="is-black"
                        class="is-size-4">
                    </b-icon>
                </div>
            </div>
        </div>

        <b-field class="mt-20">
            <b-autocomplete
                class="auto"
                :data="['jQuery', 'Vue', 'React']"
                :placeholder="t.search"
                icon="magnify"
                clearable
                @select="(option) => (selected = option)">
                <template #empty>{{ t.noResults }}</template>
            </b-autocomplete>
        </b-field>

        <div class="orders-container">
            <div class="date"></div>
            <div
                class="order-id"
                :class="{ 'order-sent': sent === true }"></div>
            <div
                class="status"
                :class="{ 'order-sent': sent === true }"></div>
        </div>

        <BotMenu />
    </section>
</template>

<script>
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    export default {
        components: {
            BotMenu,
        },
        data() {
            return {
                sent: false,
                t: this.$t('components.Orders.MyOrders'),
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .grey {
        background-color: #e8e8e8;
        height: 100vh;
    }
    .media {
        padding-left: 5%;
        padding-right: 5%;
    }

    .media-left,
    .media-right {
        height: 96px;
        align-items: center;
        display: flex;
    }

    .header-text {
        font-family: 'Borest-Standard';
        font-size: 2.5rem;
    }

    .orders-container {
        display: flex;
    }

    .date {
        height: 70px;
        background-color: $primary;
        width: 20%;
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        z-index: 2;
        @include from($mobile-l) {
            width: 100px;
        }
    }

    .order-id {
        height: 70px;
        background-color: #f2d8e1;
        width: 55%;
        transform: translateX(-25px);
        border-top-right-radius: 35px;
        border-bottom-right-radius: 35px;
        @include from($mobile-l) {
            width: 300px;
        }
    }

    .status {
        height: 70px;
        background-color: #f2d8e1;
        width: 26%;
        border-top-left-radius: 35px;
        border-bottom-left-radius: 35px;
        position: absolute;
        right: 0;
        @include from($mobile-l) {
            width: 140px;
        }
    }

    .order-sent {
        background-color: #bfd9d1;
    }

    .icons {
        cursor: pointer;
    }
</style>
