<template>
    <section>
        <Header />
        <div class="container">
            <div class="field">
                <b-field class="file center-inside">
                    <div class="file-cta center-inside">
                        <img
                            class="profile-photo"
                            v-if="imgReceived"
                            :src="imgReceived" />
                        <b-icon
                            v-else
                            pack="bl-icons"
                            icon="bl-user"
                            type="is-black"
                            class="is-size-1">
                        </b-icon>
                    </div>
                </b-field>
            </div>

            <div class="profile-card">
                <div class="has-text-centered name pt-5">
                    <p>{{ info.info.name }} {{ info.info.last_name }}</p>
                </div>
                <!-- <div class="has-text-centered city"><p>PORTO, PORTUGAL</p></div> -->
                <hr />

                <div class="info">
                    <div class="media pt-2">
                        <div class="media-left">
                            <p class="label">{{ t.myAccount }}</p>
                            <!-- <p class="user-info">{{ t.orders }}</p> -->
                            <p
                                class="user-info"
                                @click="goToPage('Panel')">
                                {{ t.manage }}
                            </p>
                        </div>
                        <div class="media-content"></div>
                        <div class="media-right"></div>
                    </div>
                </div>

                <div class="info">
                    <div class="media pt-2">
                        <div class="media-left">
                            <p class="label">{{ t.settings }}</p>
                            <p
                                class="user-info"
                                @click="goToPage('Settings')">
                                {{ t.changeLang }}
                            </p>
                            <p
                                class="user-info"
                                @click="goToPage('EditIntolerances')">
                                {{ t.meals }}
                            </p>
                            <p
                                class="user-info"
                                @click="goToPage('EditMetrics')">
                                {{ t.metrics }}
                            </p>
                        </div>
                        <div class="media-content"></div>
                        <div class="media-right"></div>
                    </div>
                </div>

                <!-- <div class="info">
					<div class="media pt-2">
						<div class="media-left">
							<p class="label">{{ t.notifications }}:</p>
							<p class="user-info">{{ t.preferences }}</p>
						</div>
						<div class="media-content"></div>
						<div class="media-right"></div>
					</div>
				</div> -->

                <div class="info">
                    <div class="media pt-2">
                        <div class="media-left">
                            <p class="label">{{ t.help }}:</p>
                            <p
                                class="user-info"
                                @click="goToPage('Help')">
                                {{ t.comments }}
                            </p>
                            <p
                                class="user-info"
                                @click="goToPage('AboutUs')">
                                {{ t.about }}
                            </p>
                            <!-- <p class="user-info mb-3">{{ t.news }}</p> -->
                            <p
                                class="user-info mb-3"
                                @click="goToPage('AppGuide')">
                                {{ t.appguide }}
                            </p>
                            <p
                                class="user-info mb-3"
                                @click="goToPage('ToKnow')">
                                {{ t.toKnow }}
                            </p>
                        </div>
                        <div class="media-content"></div>
                        <div class="media-right"></div>
                    </div>
                </div>
            </div>
        </div>

        <BotMenu />
    </section>
</template>

<script>
    import Header from '@/components/Header/Header.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    import { Browser } from '@capacitor/browser';
    export default {
        components: { Header, BotMenu },
        data() {
            return {
                t: this.$t('components.User.Profile'),
                img: null,
                info: null,
            };
        },
        computed: {
            getFileURL() {
                if (this.img) {
                    return window.URL.createObjectURL(this.img);
                }
            },
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },

            goToSite() {
                if (this.isMobile) {
                    /* 	const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://site.belovebeyou.iotech.pt/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://site.belovebeyou.iotech.pt/', '_blank');
                } else window.open('https://site.belovebeyou.iotech.pt/', '_blank');
            },
        },
        created() {
            this.scrollToTop();
            this.info = this.getUser;
            this.imgReceived = this.info.info.image;
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    section {
        background-color: $primary;
    }

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        margin-bottom: 100px;
        padding: 1rem;
        @include from($tablet) {
            height: 890px;
            width: 70%;
            margin: 0 auto;
            margin-bottom: 100px;
        }
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            margin-bottom: 40px;
        }
    }

    .container {
        padding-top: 0px;
        padding-bottom: 20px;
        @include from($mobile-l) {
            padding-bottom: 50px;
        }

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 0px;
        }
    }

    .field {
        transform: translateY(30%);
    }

    .file-cta {
        width: 138px;
        height: 138px;
        border-radius: 138px;
        margin: 0 auto;
        padding: 0;
    }

    .profile-photo {
        border-radius: 50%;
        width: 138px;
        height: 138px;
        object-fit: cover;
    }

    .media {
        padding-top: 30px;
        width: 90%;
    }

    .name {
        margin-top: 20px;
        font-family: 'Reftograph';
        font-size: 50px;
    }

    .city {
        font-family: 'Lota Grotesque';
        font-size: 0.75rem;
    }

    .label {
        font-family: 'Lota Grotesque';
        font-size: 0.9em;
        font-weight: bold;
    }

    .user-info {
        font-family: 'Lota Grotesque';
        font-size: 0.9em;
        margin-left: 28px;
        margin-top: 10px;
        cursor: pointer;
    }

    .info {
        padding-left: 14%;
        padding-top: 6%;
        padding-right: 14%;
    }

    .edit {
        width: 89px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    hr {
        background-color: $primary;
    }

    @media only screen and (min-width: 325px) and (max-width: 425px) {
        .container {
            padding-bottom: 10px;
        }
    }

    @media only screen and (min-height: 1125px) {
        .container {
            padding-bottom: 250px;
        }
    }
</style>
