<template>
    <section>
        <div class="media mt-7">
            <div class="media-content has-text-centered">
                <b-icon
                    pack="bl-icons"
                    icon="bl-file"
                    type="is-secondary"
                    class="is-size-3">
                </b-icon>
                <button
                    class="button btn-menu is-rounded"
                    @click="goToPage('Search')">
                    Menus
                </button>
            </div>
        </div>
        <div class="center-inside">
            <b-field
                class="search-input mt-4"
                @click="search">
                <b-input
                    :placeholder="t.search"
                    type="search"
                    icon="magnify"
                    icon-clickable
                    v-model="searchString"
                    v-debounce:1s="search"
                    @icon-click="search">
                    >
                </b-input>
                <b-icon
                    icon="filter"
                    type="is-secondary"
                    class="is-size-5 ml-3"
                    @click.native="isModalActive = true">
                </b-icon>
            </b-field>
        </div>

        <div>
            <div class="button-container mt-7">
                <div
                    class="button is-rounded"
                    @click="updateTopButton(0)"
                    :class="{ topActive: this.topButtons === 0 }">
                    {{ t.favorites }}
                </div>
                <div
                    class="button is-rounded"
                    @click="updateTopButton(1)"
                    :class="{ topActive: this.topButtons === 1 }">
                    {{ t.myMenus }}
                </div>
                <div
                    class="button is-rounded"
                    @click="updateTopButton(2)"
                    :class="{ topActive: this.topButtons === 2 }">
                    {{ t.addNew }}
                </div>
                <div
                    class="button is-rounded"
                    @click="updateTopButton(3)"
                    :class="{ topActive: this.topButtons === 3 }">
                    {{ t.grocery }}
                </div>
            </div>

            <div
                v-if="topButtons === 0"
                class="button-container">
                <div
                    class="button is-rounded"
                    @click="updateBotButton(0)"
                    :class="{ botActive: this.botButtons === 0 }">
                    {{ t.breakfast }}
                </div>
                <div
                    class="button is-rounded"
                    @click="updateBotButton(1)"
                    :class="{ botActive: this.botButtons === 1 }">
                    {{ t.lunch }}
                </div>
                <div
                    class="button is-rounded"
                    @click="updateBotButton(2)"
                    :class="{ botActive: this.botButtons === 2 }">
                    {{ t.dinner }}
                </div>
                <div
                    class="button is-rounded"
                    @click="updateBotButton(3)"
                    :class="{ botActive: this.botButtons === 3 }">
                    {{ t.snacks }}
                </div>
            </div>

            <div
                v-if="topButtons === 1"
                class="button-container">
                <div
                    class="button is-rounded"
                    @click="updateBotButton(0)"
                    :class="{ botActive: this.botButtons === 0 }">
                    {{ t.week }}
                </div>
                <div
                    class="button is-rounded"
                    @click="updateBotButton(1)"
                    :class="{ botActive: this.botButtons === 1 }">
                    {{ t.all }}
                </div>
            </div>
        </div>

        <div v-if="topButtons === 0">
            <div
                v-show="favorites.length > 0"
                v-touch:swipe="handleSwipe(recipe.recipe._id)"
                @click="seeDetails(recipe.recipe._id)"
                class="card mt-4"
                v-for="(recipe, index) in favorites"
                :key="index">
                <div class="media">
                    <div class="media-left">
                        <div
                            class="card-image"
                            :style="{ 'background-image': `url(${recipe.recipe.image})` }">
                            <div
                                class="cross"
                                @click="removeFavorite(recipe._id, index)">
                                <b-icon
                                    pack="bl-icons"
                                    icon="bl-cross"
                                    type="is-primary"
                                    class="is-size-4" />
                            </div>
                        </div>
                    </div>
                    <div class="media-content has-text-centered has-text-secondary details">
                        <p class="recipe-detail-name mt-2">{{ recipe.recipe.name[getLocale] }}</p>
                        <div class="mt-2">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-user"
                                type="is-secondary"
                                class="is-size-5">
                            </b-icon>
                            x {{ recipe.recipe.people }}
                        </div>
                        <div style="display: inline-flex">
                            <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                            <p style="transform: translateY(17px)">{{ recipe.recipe.preparing_time }}</p>
                        </div>
                    </div>
                    <div class="media-right"></div>
                </div>
            </div>
            <div
                v-show="favorites.length == 0"
                class="has-text-centered has-text-secondary mt-4">
                {{ t.noResultsFav }}
            </div>
        </div>

        <div v-if="topButtons === 1">
            <div
                v-show="myMenus.length > 0"
                v-touch:swipe="handleSwipe(recipe.recipe._id)"
                @click="seeDetails(recipe.recipe._id)"
                class="card mt-4"
                v-for="(recipe, index) in myMenus"
                :key="index">
                <div class="media">
                    <div class="media-left">
                        <div
                            class="card-image"
                            :style="{ 'background-image': `url(${recipe.recipe.image})` }">
                            <div
                                class="cross"
                                @click="removeMenu(recipe._id, index)">
                                <b-icon
                                    pack="bl-icons"
                                    icon="bl-cross"
                                    type="is-primary"
                                    class="is-size-4" />
                            </div>
                        </div>
                    </div>
                    <div class="media-content has-text-centered has-text-secondary details">
                        <p class="recipe-detail-name mt-2">{{ recipe.recipe.name[getLocale] }}</p>
                        <div class="mt-2">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-user"
                                type="is-secondary"
                                class="is-size-5">
                            </b-icon>
                            x {{ recipe.recipe.people }}
                        </div>
                        <div style="display: inline-flex">
                            <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                            <p style="transform: translateY(17px)">{{ recipe.recipe.preparing_time }}</p>
                        </div>
                    </div>
                    <div class="media-right"></div>
                </div>
            </div>
            <div
                v-show="myMenus.length == 0"
                class="has-text-centered has-text-secondary mt-4">
                {{ t.noResultsMenu }}
            </div>
        </div>

        <div v-if="topButtons === 2">
            <div
                class="card mt-4"
                v-if="!isIngredients">
                <div class="media">
                    <div class="media-left">
                        <div
                            class="card-image"
                            v-if="!imgUploaded">
                            <b-upload
                                v-validate="'required'"
                                name="image"
                                v-model="imgUploaded"
                                accept="image/*">
                                <div>
                                    <div class="center-inside mt-14">
                                        <b-icon
                                            icon="plus-circle-outline"
                                            class="is-black"
                                            custom-size="is-size-3">
                                        </b-icon>
                                    </div>
                                    <p class="has-text-black is-size-7 mt-2">{{ t.uploadImage }}</p>
                                </div>
                            </b-upload>
                        </div>
                        <div
                            v-else
                            class="card-image"
                            :style="{ 'background-image': `url(${getFileURL})` }">
                            <b-upload
                                style="width: 100%"
                                v-model="imgUploaded"
                                accept="image/*">
                            </b-upload>
                        </div>
                    </div>

                    <div class="media-content has-text-centered has-text-secondary details">
                        <p
                            v-if="!recipeName"
                            class="recipe-detail-name mt-2">
                            {{ t.recipeName }}
                        </p>
                        <p
                            v-else
                            class="recipe-detail-name mt-2">
                            {{ recipeName }}
                        </p>
                        <div
                            class="mt-2"
                            style="display: inline-flex">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-user"
                                type="is-secondary"
                                class="is-size-5">
                            </b-icon>
                            <p style="line-break: anywhere">x {{ recipePeople }}</p>
                        </div>
                        <div style="display: inline-flex">
                            <i class="mdi mdi-clock mdi-36px mr-0"></i>
                            <p style="transform: translateY(17px; line-break:anywhere">{{ recipeTime }}</p>
                        </div>
                    </div>
                    <div class="media-right"></div>
                </div>
                <p
                    v-show="errors.has('image')"
                    class="has-text-centered help is-danger">
                    {{ t.imageError }}
                </p>

                <b-input
                    v-model="recipeName"
                    :placeholder="t.recipeName"
                    maxlength="40"
                    name="recipeName"
                    v-validate="'required'" />
                <p
                    v-show="errors.has('recipeName')"
                    class="has-text-centered help is-danger">
                    {{ t.nameError }}
                </p>

                <b-input
                    v-validate="'required|numeric'"
                    v-model="recipePeople"
                    name="people"
                    maxlength="3"
                    :placeholder="t.recipePeople" />
                <p
                    v-show="errors.has('people')"
                    class="has-text-centered help is-danger">
                    {{ t.peopleError }}
                </p>

                <b-input
                    v-validate="'required'"
                    v-model="recipeTime"
                    name="time"
                    :placeholder="t.preparationTime" />
                <p
                    v-show="errors.has('time')"
                    class="has-text-centered help is-danger">
                    {{ t.timeError }}
                </p>
                <br />
                <b-button
                    class="ingredients-btn is-rounded"
                    @click="isIngredients = true"
                    >{{ t.ingredients }}</b-button
                >
                <br />

                <b-button
                    class="ingredients-btn is-rounded my-6"
                    :loading="isLoading"
                    @click="addUserRecipe"
                    >{{ t.save }}</b-button
                >
            </div>

            <div v-else>
                <div class="ingredients-container">
                    <p
                        class="mt-2 ml-2 has-text-weight-bold has-text-black"
                        style="font-family: Lota Grotesque">
                        {{ t.ingredients }}
                    </p>

                    <b-input
                        maxlength="200"
                        type="textarea"
                        :placeholder="t.ingredientsPlaceholder"
                        v-model="preparation"></b-input>
                    <p
                        v-show="ingredientsError"
                        class="has-text-centered help is-danger">
                        {{ t.ingredientsError }}
                    </p>

                    <b-button
                        class="ingredients-btn is-rounded"
                        :loading="isLoading"
                        @click="isIngredients = false"
                        >{{ t.saveIngredients }}</b-button
                    >
                </div>
            </div>
        </div>

        <GroceryList v-if="topButtons === 3" />

        <b-modal
            v-model="isModalActive"
            class="modal"
            :width="640">
            <div
                class="modal-card"
                style="width: auto">
                <header class="modal-card-head">
                    <p class="modal-card-title">Filtrar receitas</p>
                    <button
                        type="button"
                        class="delete"
                        @click="isModalActive = false" />
                </header>
                <section class="modal-card-body">
                    <b-field
                        v-for="(value, name, i) in meals"
                        :key="100 + i">
                        <b-radio
                            v-if="name !== 'all' && name !== 'athlete' && name !== 'geriatrics'"
                            class="text checkbox"
                            :native-value="name"
                            type="is-secondary"
                            v-model="checkedMeals"
                            >{{ value }}
                        </b-radio>
                    </b-field>
                </section>
                <footer class="modal-card-foot">
                    <b-button
                        @click="filterRecipes"
                        :label="t.searchButton"
                        type="is-primary" />
                    <b-button
                        @click="checkedMeals = []"
                        :label="t.clear"
                        type="is-secondary" />
                </footer>
            </div>
        </b-modal>
    </section>
</template>

<script>
    import { getFavorite, removeFavorite } from '@/api/favorites';
    import { getMyMenus, removeMyMenus } from '@/api/myMenus';
    import { addRecipe } from '@/api/userRecipes';
    import GroceryList from '@/components/MySpot/GroceryListTab.vue';
    export default {
        components: {
            GroceryList,
        },
        data() {
            return {
                t: this.$t('components.Menus.MenusContent'),
                topButtons: 0,
                botButtons: 0,
                isRemove: false,
                recipe_type: null,
                favorites: [],
                myMenus: [],
                imgUploaded: null,
                recipeName: null,
                recipePeople: null,
                recipeTime: null,
                isIngredients: false,
                preparation: null,
                ingredientsError: false,
                isLoading: false,
                isModalActive: false,
                checkedMeals: [],
                meals: this.$t('shared.tags'),
                page: 1,
                offset: 100,
                isRequesting: false,
                searchString: null,
            };
        },

        computed: {
            getFileURL() {
                if (this.imgUploaded) {
                    return window.URL.createObjectURL(this.imgUploaded);
                }
                return '';
            },
        },

        methods: {
            removeFavorite(id, index) {
                this.isRemove = true;

                let data = {
                    id: id,
                };
                this.favorites.splice(index, 1);

                removeFavorite(data)
                    .then((response) => {
                        let query = {
                            limit: 5,
                            order: 'asc',
                            page: 1,
                            recipe_type: this.recipe_type,
                        };
                        getFavorite(query)
                            .then((response) => {
                                this.favorites = response.data.favorites;
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            seeDetails(id) {
                if (this.isRemove == false) {
                    this.$router.push({ name: 'RecipeDetails', params: { id: id } });
                }
            },

            getFavorites() {
                if (this.botButtons === 0) {
                    this.recipe_type = 'breakfast';
                } else if (this.botButtons === 1) {
                    this.recipe_type = 'lunch';
                } else if (this.botButtons === 2) {
                    this.recipe_type = 'dinner';
                } else this.recipe_type = 'snacks';

                let query = {
                    order: 'asc',
                    recipe_type: this.recipe_type,
                    page: this.page,
                    limit: 5,
                    ...(this.checkedMeals.length > 0 && { tags: this.checkedMeals }),
                    ...(this.searchString != '' && this.searchString != null && this.getLocale == 'pt' && { search_pt: this.searchString }),
                    ...(this.searchString != '' && this.searchString != null && this.getLocale == 'en' && { search_en: this.searchString }),
                };
                getFavorite(query)
                    .then((response) => {
                        this.favorites = response.data.favorites;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.setIsPageLoading(false);
                    });
            },

            getMenus() {
                if (this.botButtons === 0) {
                    this.recipe_type = 'week';
                } else {
                    this.recipe_type = 'all';
                }

                let query = {
                    order: 'asc',
                    retrieve: this.recipe_type,
                    page: this.page,
                    limit: 5,
                    ...(this.checkedMeals.length > 0 && { tags: this.checkedMeals }),
                    ...(this.searchString != '' && this.searchString != null && this.getLocale == 'pt' && { search_pt: this.searchString }),
                    ...(this.searchString != '' && this.searchString != null && this.getLocale == 'en' && { search_en: this.searchString }),
                };

                getMyMenus(query)
                    .then((response) => {
                        this.myMenus = response.data.mymenus;
                    })
                    .catch((error) => {
                        console.error(error);
                    })
                    .finally(() => {
                        this.setIsPageLoading(false);
                    });
            },

            updateBotButton(number) {
                this.setIsPageLoading(true);
                this.botButtons = number;
                this.favorites = [];
                this.myMenus = [];
                this.page = 1;
                if (this.topButtons == 0) {
                    this.getFavorites();
                } else if (this.topButtons == 1) {
                    this.getMenus();
                }
            },

            updateTopButton(number) {
                if (number == 0 || number == 1) {
                    this.setIsPageLoading(true);
                }
                this.topButtons = number;
                this.page = 1;
                if (this.topButtons == 0) {
                    this.getFavorites();
                } else if (this.topButtons == 1) {
                    this.getMenus();
                }
            },
            handleSwipe(id) {
                return (dir) => {
                    if (dir === 'left') {
                        this.seeDetails(id);
                    }
                };
            },

            removeMenu(id, index) {
                this.isRemove = true;

                let data = {
                    id: id,
                };
                this.myMenus.splice(index, 1);

                removeMyMenus(data)
                    .then((response) => {
                        let query = {
                            limit: 5,
                            order: 'asc',
                            retrieve: this.recipe_type,
                        };
                        getMyMenus(query)
                            .then((response) => {
                                this.myMenus = response.data.mymenus;
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            addUserRecipe() {
                this.isLoading = true;
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        if (!this.preparation) {
                            this.isIngredients = true;
                            this.ingredientsError = true;
                            this.isLoading = false;
                        } else {
                            this.isLoading = true;
                            this.isIngredients = false;
                            this.ingredientsError = false;

                            let data = {
                                name: this.recipeName,
                                details: this.preparation,
                                preparing_time: this.recipeTime,
                                people: this.recipePeople,
                            };
                            addRecipe(this.imgUploaded, data)
                                .then((response) => {
                                    this.$buefy.toast.open({
                                        message: this.t.success,
                                        position: 'is-top',
                                        type: 'is-primary',
                                    });
                                    this.topButtons = 0;
                                })
                                .catch((error) => {
                                    console.error(error);
                                    this.$buefy.toast.open({
                                        message: this.t.error,
                                        position: 'is-top',
                                        type: 'is-primary',
                                    });
                                })
                                .finally(() => {
                                    this.isLoading = false;
                                });
                        }
                    } else this.isLoading = false;
                });
            },

            handleScroll(e) {
                let target = {
                    scrollTop: e.target.scrollingElement.scrollTop,
                    clientHeight: e.target.scrollingElement.clientHeight,
                    scrollHeight: e.target.scrollingElement.scrollHeight,
                };
                if (target.scrollTop + target.clientHeight >= target.scrollHeight - this.offset) {
                    if (this.isRequesting) return;
                    this.isRequesting = true;
                    this.page++;

                    if (this.topButtons == 0) {
                        let query = {
                            order: 'asc',
                            recipe_type: this.recipe_type,
                            page: this.page,
                            limit: 5,
                            ...(this.checkedMeals.length > 0 && { tags: this.checkedMeals.join() }),
                            ...(this.searchString != '' && this.searchString != null && this.getLocale == 'pt' && { search_pt: this.searchString }),
                            ...(this.searchString != '' && this.searchString != null && this.getLocale == 'en' && { search_en: this.searchString }),
                        };

                        getFavorite(query)
                            .then((response) => {
                                this.favorites.push(...response.data.favorites);
                            })
                            .catch((error) => {
                                console.error(error);
                            })
                            .finally(() => {
                                this.setIsPageLoading(false);
                                this.isRequesting = false;
                            });
                    } else if (this.topButtons == 1) {
                        let query = {
                            order: 'asc',
                            retrieve: this.recipe_type,
                            page: this.page,
                            limit: 5,
                            ...(this.checkedMeals.length > 0 && { tags: this.checkedMeals.join() }),
                            ...(this.searchString != '' && this.searchString != null && this.getLocale == 'pt' && { search_pt: this.searchString }),
                            ...(this.searchString != '' && this.searchString != null && this.getLocale == 'en' && { search_en: this.searchString }),
                        };

                        getMyMenus(query)
                            .then((response) => {
                                this.myMenus.push(...response.data.mymenus);
                            })
                            .catch((error) => {
                                console.error(error);
                            })
                            .finally(() => {
                                this.isRequesting = false;
                            });
                    }
                }
            },

            search() {
                this.favorites = [];
                this.myMenus = [];

                if (this.topButtons == 0) {
                    this.getFavorites();
                } else if (this.topButtons == 1) {
                    this.getMenus();
                }
            },

            filterRecipes() {
                this.setIsPageLoading(true);
                this.favorites = [];
                this.myMenus = [];
                this.page = 1;

                if (this.topButtons == 0) {
                    this.getFavorites();
                } else if (this.topButtons == 1) {
                    this.getMenus();
                }

                this.isModalActive = false;
            },
        },

        watch: {
            searchString() {
                if (this.searchString === '') {
                    this.page = 1;
                    if (this.topButtons == 0) {
                        this.getFavorites();
                    } else if (this.topButtons == 1) {
                        this.getMenus();
                    }
                }
            },
        },

        created() {
            this.setIsPageLoading(true);
            this.getFavorites();
            window.addEventListener('scroll', this.handleScroll);
        },

        destroyed() {
            window.removeEventListener('scroll', this.handleScroll);
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .mdi {
        color: #ce9aa9;
    }

    .media-content,
    .media-right {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .media-right {
        height: 54px;
    }

    .media-content {
        overflow: hidden;
        height: 54px;
    }

    .btn-menu {
        border-color: #ce9aa9;
        color: #ce9aa9;
        margin-left: 10px;
        height: 32px;
        font-weight: bold;
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .btn-add {
        border-color: $secondary;
        background-color: $secondary;
        color: white;
        margin-left: 10px;
        height: 32px;
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .button-container .button {
        margin-top: 8px;
        height: 32px;
        margin-left: 3px;
        margin-right: 3px;
        margin-bottom: 8px;
        border: 1px solid $secondary;
        color: $secondary;
        width: 100px;
        font-size: 12px;
        font-family: 'Lota Grotesque';
    }

    .button-container {
        display: inline-flex;
        width: 100%;
        justify-content: center;
    }

    .topActive {
        background-color: $secondary;
        color: white !important;
    }

    .botActive {
        background-color: #93bfb7;
        color: white !important;
        border: solid 1px #93bfb7 !important;
    }

    .card {
        height: 172px;
        border-radius: 48px;
        border: 1px solid $secondary;

        background-color: transparent;
        border-radius: 25px;
        @include from($mobile-l) {
            width: 400px;
            margin: 0 auto;
        }

        @include until($mobile-s) {
            margin-bottom: 20px;
        }
    }

    .card-image {
        justify-content: center;
        display: flex;
        height: 170px;
        border-top-left-radius: 25px 25px;
        border-bottom-left-radius: 25px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .card .media-left {
        width: 63%;
    }

    .cross {
        transform: translate(-78px, 12px);
        height: 30px;

        @include until($mobile-s) {
            transform: translate(-60px, 12px);
        }
    }

    .details {
        height: 170px;
        display: inline;
    }

    .recipe-detail-name {
        font-size: 12px;
        font-weight: bold;
    }

    @media only screen and (max-width: 346px) {
        .button-container .button {
            margin-left: 3px;
            margin-right: 3px;
            width: 72px;
            font-size: 12px;
        }
    }

    @media print {
        .media,
        .button-container,
        .media-content {
            visibility: hidden;
        }
    }

    /deep/ .b-numberinput input[type='number'] {
        text-align: left !important;
    }

    .ingredients-btn {
        background-color: #93bfb7;
        font-size: 12px;
        font-family: 'Lota Grotesque';
        color: white;
        border: 1px solid #93bfb7;
    }

    .ingredients-container {
        background-color: #e8e8e8;
        width: 100% !important;
        height: auto;
        margin-top: 20px;
        border-radius: 40px;
        padding: 1rem;
    }

    /deep/ .textarea {
        resize: none;
        height: 250px;
        border-radius: 40px;
        margin-top: 20px;
        padding: 1rem;
    }

    .help {
        transform: translateY(-16px);
    }
</style>
