<template>
    <section>
        <TopMenu />
        <BotMenu class="menu-position" />
        <div class="main">
            <div @click="$router.back()">
                <b-icon
                    pack="bl-icons"
                    icon="bl-arrow-left"
                    type="is-black"
                    class="arrow-left is-size-4">
                </b-icon>
            </div>
            <p
                class="has-text-primary mt-3"
                v-html="t"></p>
        </div>

        <div class="background-white">
            <div class="logo-container">
                <img
                    class="logo"
                    src="../../assets/icons/coracao_verde.png"
                    alt="" />
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';

    import BotMenu from '@/components/Menu/BottomAboutMenu.vue';
    export default {
        components: {
            TopMenu,
            BotMenu,
        },
        data() {
            return {
                type: this.$route.params.type,
            };
        },
        computed: {
            t() {
                return this.$t('components.Tracker.Info.' + this.type);
            },
        },

        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },

        created() {
            this.scrollToTop();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .logo-container {
        position: fixed;
        left: 50%;
        bottom: 30px;
        transform: translate(-50%, 26px);
        margin: 0 auto;
        width: 100px;
        z-index: 10;
        display: flex;
        justify-content: center;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 10px;
            transform: translate(-50%, 10px);
        }
    }

    .background-white {
        position: fixed;
        bottom: 0;
        margin: 0 auto;
        width: 100%;
        background-color: white;
        height: 40px;
        z-index: 6;

        @include until($mobile-s) {
            height: 30px;
        }
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 34px;
        }
    }

    .logo {
        height: 24px;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 24px;
        }
    }
    .main {
        padding-top: 110px;
        padding-bottom: 200px;
    }

    .main p {
        white-space: pre-line;
        font-size: 0.875rem;
        font-family: 'Lota Grotesque';
    }
</style>
