import store from '@/store/index';

export default {
    computed: {
        getLocale() {
            return store.getters['language/getLanguage'];
        },

        changeFont() {
            if (this.getLocale === 'pt') {
                return 'Retrograph';
            } else return 'Borest-Standard';
        },
    },

    methods: {
        changeLanguage(language) {
            store.commit('language/setLanguage', language);
        },
    },
};
