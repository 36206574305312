<template>
    <section>
        <div class="main">
            <p
                class="subtitle has-text-centered mt-2"
                :style="{ 'font-family': this.changeFont }">
                {{ t.resend }}
            </p>
            <b-input
                :class="{ 'error-text': errors.has('email') }"
                type="email"
                v-validate="'required|email'"
                size="is-small"
                name="email"
                v-model="email"
                :placeholder="t.email" />
            <p
                v-show="errors.has('email')"
                class="help is-danger">
                {{ t.enterEmail }}
            </p>
            <b-button
                class="recover mt-5"
                rounded
                :loading="isLoading"
                @click="resendConfirmation"
                >{{ t.go }}</b-button
            >
            <p
                v-if="checkMessage"
                class="message mt-8">
                {{ t.check }}
            </p>
            <p
                class="login my-5"
                @click="updateStep">
                {{ t.login }}
            </p>
        </div>
    </section>
</template>

<script>
    import { activationEmail } from '@/api/apiAuth';
    export default {
        data() {
            return {
                t: this.$t('components.Auth.ForgotPassword'),
                email: null,
                isLoading: false,
                checkMessage: false,
            };
        },

        methods: {
            resendConfirmation() {
                activationEmail(this.email)
                    .then((response) => {
                        this.isLoading = true;
                        this.$buefy.toast.open({
                            message: this.t.verifyEmail,
                            position: 'is-top',
                            type: 'is-secondary',
                            duration: 4000,
                        });
                        this.isLoading = false;
                        this.$store.commit('loginSteps/setLoginSteps', 0);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            updateStep() {
                this.$store.commit('loginSteps/setLoginSteps', 0);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .recover {
        width: 46px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .subtitle {
        font-size: 24px;
        font-family: 'Borest-Standard';
        font-weight: normal;
    }

    .message {
        font-family: 'Lota Grotesque';
        font-size: 0.75rem;
        background-color: transparent !important;
    }

    .login {
        font-size: 0.75rem;
        color: $secondary;
        font-family: 'Lota Grotesque';
    }
</style>
