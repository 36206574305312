<template>
    <section>
        <TopMenu />

        <div class="main">
            <div class="card">
                <div class="heart-container pt-4 mb-6">
                    <b-image
                        class="img"
                        :src="require('@/assets/icons/coracao_logo_branco.png')"
                        alt="Green Heart"></b-image>
                </div>

                <div class="has-text-centered field-container">
                    <b-input
                        :class="{ 'error-text': errors.has('name') }"
                        class="field has-text-centered"
                        type="text"
                        v-validate="'required'"
                        size="is-small"
                        name="name"
                        v-model="name"
                        :placeholder="t.name" />
                </div>
                <p
                    v-show="errors.has('name')"
                    class="has-text-centered help is-danger">
                    {{ t.enterName }}
                </p>

                <div class="has-text-centered field-container">
                    <b-input
                        :class="{ 'error-text': errors.has('lastName') }"
                        class="field has-text-centered"
                        type="text"
                        v-validate="'required'"
                        size="is-small"
                        name="lastName"
                        v-model="lastName"
                        :placeholder="t.lastName" />
                </div>
                <p
                    v-show="errors.has('lastName')"
                    class="has-text-centered help is-danger">
                    {{ t.enterLastName }}
                </p>

                <b-field
                    type="is-primary"
                    class="has-text-centered select-container">
                    <b-select
                        :placeholder="t.genre"
                        :class="{ 'error-text': errors.has('genre') }"
                        v-validate="'required'"
                        size="is-small"
                        name="genre"
                        v-model="genre"
                        expanded>
                        <option value="M">
                            {{ t.male }}
                        </option>
                        <option value="F">
                            {{ t.female }}
                        </option>
                        <option value="O">
                            {{ t.other }}
                        </option>
                    </b-select>
                </b-field>
                <p
                    v-show="errors.has('genre')"
                    class="has-text-centered help is-danger">
                    {{ t.enterGenre }}
                </p>

                <div class="has-text-centered field-container mb-4">
                    <b-input
                        :class="{ 'error-text': errors.has('nif') }"
                        class="field has-text-centered"
                        type="text"
                        v-validate="'required'"
                        size="is-small"
                        name="nif"
                        v-model="nif"
                        :placeholder="t.nif"
                        @keydown.native="RemoveMessage" />
                </div>
                <p
                    v-show="errors.has('nif')"
                    class="has-text-centered help is-danger">
                    {{ t.enterNif }}
                </p>
                <p
                    v-show="errorNif"
                    class="has-text-centered help is-danger">
                    {{ t.invalidNif }}
                </p>
            </div>
            <div class="btn-container pt-6">
                <b-button
                    class="register"
                    rounded
                    :loading="isLoading"
                    @click="create"
                    >{{ t.create }}</b-button
                >
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import { completeRegister } from '@/api/apiAuth';
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                t: this.$t('components.Auth.CompleteRegister'),
                name: null,
                lastName: null,
                genre: null,
                nif: null,
                isLoading: false,
                errorNif: false,
            };
        },

        methods: {
            create() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        let data = {
                            name: this.name,
                            lastName: this.lastName,
                            genre: this.genre,
                            nif: this.nif,
                        };
                        completeRegister(data)
                            .then((response) => {
                                this.isLoading = false;
                                this.errorNif = false;
                                this.goToPage('MyPreferences');
                            })
                            .catch((error) => {
                                if (error.response.data[0].msg.code === 'InvalidNif') {
                                    this.isLoading = false;
                                    this.errorNif = true;
                                }
                                console.error(error);
                            });
                    }
                });
            },
            RemoveMessage() {
                this.errorNif = false;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 10rem;
        @include until($mobile-s) {
            padding-top: 6rem;
        }
        margin-bottom: 30px;
    }

    .card {
        background-color: #e8e8e8;
        height: auto;
        border-radius: 48px;
        padding-bottom: 20px;

        max-width: $card-max-width;
        margin-left: auto;
        margin-right: auto;
    }

    .field-container {
        display: flex;
        justify-content: center;
        margin-top: 0.9rem;
    }

    .field {
        width: 90% !important;
        font-family: 'Lota Grotesque';
    }

    .heart-container {
        display: flex;
        justify-content: center;
        position: relative;
        z-index: 10;
    }

    .img {
        width: 36px;
        height: 34px;
    }

    .select-container {
        width: 90% !important;
        margin-left: auto;
        margin-right: auto;
        margin-top: 0.9rem;
        font-family: 'Lota Grotesque';
    }

    .btn-container {
        display: flex;
        justify-content: center;
    }

    .register {
        width: 174px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        color: white;
        background-color: $secondary;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    p {
        font-family: 'Lota Grotesque';
    }
</style>
