<template>
    <section>
        <TopMenu />
        <div class="container">
            <div class="btn-menu-container">
                <b-icon
                    pack="bl-icons"
                    icon="bl-file"
                    type="is-white"
                    class="is-size-4">
                </b-icon>
                <button
                    class="button btn-menu is-rounded"
                    @click="goToPage('Menus')">
                    Menus
                </button>
            </div>

            <p
                class="has-text-white"
                :style="{ 'font-family': changeFont }">
                {{ this.t.dailyRecipes }}
            </p>
            <div class="days-container my-5">
                <div
                    v-for="(day, index) in days"
                    :key="index">
                    <div class="has-text-centered">
                        <p class="has-text-white day-label">{{ day | momentString }}</p>
                        <span
                            class="day"
                            :class="{ active: isActive === index }"
                            @click="getRecipesDay(day, index)">
                            {{ day | moment }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="cards-container my-10">
                <p class="has-text-secondary day-label">{{ t.breakfast }}</p>
                <div
                    v-for="(recipe, index) in this.selectedDay[0].recipes.breakfast"
                    :key="index">
                    <div
                        v-touch:swipe="handleSwipe(recipe._id)"
                        class="card mt-4"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p class="pt-5">{{ recipe.name[getLocale] }}</p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-5">
                                    </b-icon>
                                    x {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                    <p style="transform: translateY(17px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>

                <p class="has-text-secondary day-label mt-3">{{ t.lunch }}</p>
                <div
                    v-for="(recipe, index) in this.selectedDay[0].recipes.lunch"
                    :key="index + 200">
                    <div
                        v-touch:swipe="handleSwipe(recipe._id)"
                        class="card mt-4"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p class="pt-5">{{ recipe.name[getLocale] }}</p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-5">
                                    </b-icon>
                                    x {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                    <p style="transform: translateY(17px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>

                <p class="has-text-secondary day-label mt-3">{{ t.dinner }}</p>
                <div
                    v-for="(recipe, index) in this.selectedDay[0].recipes.dinner"
                    :key="index + 300">
                    <div
                        v-touch:swipe="handleSwipe(recipe._id)"
                        class="card mt-4"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p class="pt-5">{{ recipe.name[getLocale] }}</p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-5">
                                    </b-icon>
                                    x {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                    <p style="transform: translateY(17px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>

                <p class="has-text-secondary day-label mt-3">{{ t.snacks }}</p>
                <div
                    v-for="(recipe, index) in this.selectedDay[0].recipes.snacks"
                    :key="index + 400">
                    <div
                        v-touch:swipe="handleSwipe(recipe._id)"
                        class="card mt-4"
                        @click="seeDetails(recipe._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${recipe.image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p class="pt-5">{{ recipe.name[getLocale] }}</p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-5">
                                    </b-icon>
                                    x {{ recipe.people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                    <p style="transform: translateY(17px)">{{ recipe.preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment';
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import { getDailyRecipe } from '@/api/dailyRecipes';
    export default {
        data() {
            return {
                firstDay: new Date(),
                t: this.$t('components.Recipes.DailyRecipes'),
                recipes: null,
                selectedDay: [],
                isActive: null,
            };
        },
        components: {
            TopMenu,
        },
        computed: {
            days() {
                return [
                    this.firstDay,
                    this.addDays(this.firstDay, 1),
                    this.addDays(this.firstDay, 2),
                    this.addDays(this.firstDay, 3),
                    this.addDays(this.firstDay, 4),
                    this.addDays(this.firstDay, 5),
                    this.addDays(this.firstDay, 6),
                ];
            },
        },
        methods: {
            addDays(date, days) {
                let newDate = new Date(date);

                newDate.setDate(this.firstDay.getDate() + days);
                return newDate;
            },

            moment: function () {
                return moment();
            },

            getRecipes() {
                getDailyRecipe()
                    .then((response) => {
                        this.recipes = response.data.recipes;
                        this.getRecipesDay(this.firstDay, 0);
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            getRecipesDay(value, index) {
                this.isActive = index;
                this.selectedDay = [];
                for (let index = 0; index < this.recipes.length; index++) {
                    if (moment(value).isSame(this.recipes[index].day, 'day')) {
                        this.selectedDay.push(this.recipes[index]);
                    }
                }
            },

            seeDetails(id) {
                this.$router.push({ name: 'RecipeDetails', params: { id: id } });
            },

            handleSwipe(id) {
                return (dir) => {
                    if (dir === 'left') {
                        this.seeDetails(id);
                    }
                };
            },
        },
        filters: {
            moment: function (date) {
                return moment(date).format('DD');
            },
            momentString: function (date) {
                return moment(date).format('ddd');
            },
        },
        created() {
            this.getRecipes();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 322px;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 167px;
        }
    }

    .container {
        background-color: $pink-variant;
        width: 100%;
        padding-top: 116px;
        height: 346px;
        position: fixed;
        z-index: 1;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 196px;
            padding-top: 62px;
        }
    }

    .days-container {
        width: 100%;
        justify-content: space-between;
        display: flex;
        padding-left: 4%;
        padding-right: 4%;
        @include from($tablet) {
            padding-left: 28%;
            padding-right: 28%;
        }

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-left: 2%;
            padding-right: 2%;
            transform: translateY(-24px);
        }
    }
    .day {
        background-color: $secondary;
        color: white;
        display: inline-block;
        padding-top: 0.6rem;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        font-family: 'Lota Grotesque';
        font-size: 14px;
        cursor: pointer;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            width: 34px;
            height: 34px;
        }
    }

    .day-label {
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .btn-menu-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-menu {
        border-color: white;
        color: white;
        margin-left: 10px;
        height: 32px;
        font-size: 13px;
        font-family: 'Lota Grotesque';
        background-color: transparent !important;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 28px;
        }
    }

    p {
        font-family: 'Borest-Standard';
        font-size: 52px;
        text-align: center;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            font-size: 30px;
        }
    }

    .card {
        height: 172px;
        border-radius: 48px;
        border: 1px solid $secondary;

        background-color: transparent;
        border-radius: 25px;
        @include from($mobile-l) {
            width: 400px;
            margin: 0 auto;
        }
    }

    .card-image {
        background-image: url('https://realfood.tesco.com/media/images/1400x919-Pink-ombre-pancakes-283d8170-a45b-4bc5-a3e9-ef09ad841530-0-1400x919.jpg');
        justify-content: center;
        display: flex;
        height: 170px;
        border-top-left-radius: 25px 25px;
        border-bottom-left-radius: 25px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .media-left {
        width: 63%;
    }
    .media-right,
    .media-left {
        margin: 0;
    }

    .media-content p {
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .media-content {
        overflow: hidden;
    }

    .active {
        background-color: white;
        color: $secondary;
    }

    @media screen and (min-width: 1024px) {
        .container {
            max-width: 100% !important;
        }
    }
</style>
