<template>
    <section>
        <div class="top-menu-default">
            <div class="media">
                <div class="media-left">
                    <div
                        v-if="
                            this.$route.name === 'Panel' ||
                            this.$route.name === 'Profile' ||
                            this.$route.name === 'ChangePassword' ||
                            this.$route.name === 'EditProfile' ||
                            this.$route.name === 'Payments' ||
                            this.$route.name === 'Settings' ||
                            this.$route.name === 'Help' ||
                            this.$route.name === 'ContactSupport' ||
                            this.$route.name === 'EditIntolerances' ||
                            this.$route.name === 'EditMetrics' ||
                            this.$route.name === 'AppGuide' ||
                            this.$route.name === 'ToKnow' ||
                            this.$route.name === 'AboutUs' ||
                            this.$route.name === 'HowAllStarted'
                        "
                        @click="$router.back()"
                        class="is-clickable">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-white"
                            class="is-size-5">
                        </b-icon>
                    </div>
                </div>
                <div class="media-content">
                    <img
                        class="logo-ag"
                        src="../../assets/icons/logo_ana_garcez_v2.svg"
                        alt="" />
                </div>
                <div class="media-right">
                    <div
                        @click="goToPage('Profile')"
                        class="is-clickable">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-user"
                            type="is-white"
                            class="is-size-5"
                            v-if="
                                this.$route.name !== 'Register' &&
                                this.$route.name !== 'Login' &&
                                this.$route.name !== 'RecoverPassword' &&
                                this.$route.name !== 'Home' &&
                                this.$route.name !== 'CompleteRegister' &&
                                this.$route.name !== 'Panel' &&
                                this.$route.name !== 'Profile' &&
                                this.$route.name !== 'ChangePassword' &&
                                this.$route.name !== 'EditProfile' &&
                                this.$route.name !== 'Payments' &&
                                this.$route.name !== 'SubscriptionExpired' &&
                                this.$route.name !== 'FreeTrial' &&
                                this.$route.name !== 'SignSubscription' &&
                                this.$route.name !== 'AddNewCard'
                            ">
                        </b-icon>
                    </div>

                    <div
                        v-if="
                            this.$route.name === 'Panel' ||
                            this.$route.name === 'Profile' ||
                            this.$route.name === 'ChangePassword' ||
                            this.$route.name === 'EditProfile' ||
                            this.$route.name === 'Payments'
                        "
                        @click="goToPage('MyShop')"
                        class="is-clickable">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-cesto_compras"
                            type="is-white"
                            class="is-size-5">
                        </b-icon>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .top-menu-default {
        background-color: $primary;
        width: 100%;
        height: 58px;
        position: fixed;
        top: 0;
        z-index: 12;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 78px;
        }
    }

    .media {
        height: 58px;
        padding-left: 5%;
        padding-right: 5%;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 70px;
        }
    }

    .media-right {
        height: 58px;
        align-items: center;
        display: flex;
        width: 36px;
        margin-left: 0 !important;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 70px;
        }
    }

    .media-content {
        display: flex;
        justify-content: center;
        height: 100%;
        overflow: hidden;
    }

    .media-left {
        height: 58px;
        align-items: center;
        display: flex;
        width: 36px;
        margin-right: 0 !important;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 70px;
        }
    }

    .logo-ag {
        width: 100px;
        transform: translateY(9px);
    }

    i::before {
        color: $white;
    }

    /* @media only screen and (max-width: 350px) {
		.top-menu-default,
		.media,
		.media-right,
		.media-left {
			height: 85px;
		}
	} */
</style>
