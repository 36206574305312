<template>
    <section class="mt-5">
        <div class="center-inside">
            <button
                class="button btn-add is-rounded mx-2"
                @click="addItem">
                {{ t.addItem }}
            </button>
            <!-- <button class="button btn-add is-rounded mx-2">{{ t.share }}</button> -->
            <b-icon
                pack="mdi"
                icon="printer"
                size="is-medium"
                type="is-primary"
                class="mx-2"
                @click.native="print()">
            </b-icon>
        </div>
        <div class="mt-4 new-container">
            <b-autocomplete
                size="is-small"
                ref="ingredientAutocomplete"
                placeholder="e.g. banana"
                :data="filteredIngredients"
                field="description.pt"
                @typing="getFilteredIngredients"
                @select="(option) => (selectedIngredient.ingredient = option._id)"
                class="auto mx-1"
                v-model="autoText">
            </b-autocomplete>

            <b-input
                :class="{ 'error-text': errors.has('name') }"
                class="field has-text-centered amount mx-1"
                type="number"
                size="is-small"
                v-model.number="selectedIngredient.amount"
                placeholder="e.g. 2" />

            <b-select
                class="field mx-1"
                :class="{ 'error-text': errors.has('amount') }"
                size="is-small"
                v-model="selectedIngredient.unit">
                <option value="null">none</option>
                <option value="g">g</option>
                <option value="l">l</option>
            </b-select>
        </div>
        <p
            v-show="showError"
            class="help is-danger">
            {{ t.error }}
        </p>

        <div class="mx-4 mt-5 non-print">
            <p>{{ groceryList.ingredients.length }} items</p>
        </div>

        <div
            v-for="(hierarchy, category, index) in filteredGroceyList"
            class="print mt-3 mx-4"
            :key="index">
            {{ s[category] }} <br />
            <div
                v-for="(ingredient, i) in hierarchy"
                :key="i">
                <b-field>
                    <b-checkbox
                        @change.native="check(ingredient.ingredient._id, ingredient.check)"
                        type="is-secondary"
                        v-model="ingredient.check">
                    </b-checkbox>
                    <span
                        :class="{ checked: ingredient.check === true }"
                        v-if="ingredient.amount !== null && ingredient.amount !== 'null'"
                        >{{ ingredient.amount }}&nbsp;</span
                    >
                    <span
                        :class="{ checked: ingredient.check === true }"
                        v-if="ingredient.unit !== null && ingredient.unit !== 'null'"
                        >{{ ingredient.unit }} &nbsp;</span
                    >
                    <span :class="{ checked: ingredient.check === true }">{{ ingredient.ingredient.description[getLocale] }} </span>
                </b-field>
            </div>
        </div>

        <div
            class="print mt-3 mx-4"
            v-if="personalizedList.length > 0">
            {{ t.products }}
            <div
                v-for="ingredient in personalizedList"
                :key="ingredient._id">
                <b-field>
                    <b-checkbox
                        type="is-secondary"
                        @change.native="checkOther(ingredient._id, ingredient.check)"
                        v-model="ingredient.check">
                    </b-checkbox>
                    <span :class="{ checked: ingredient.check === true }">{{ ingredient.ingredient }} </span>
                </b-field>
            </div>
        </div>

        <!-- <div class="print">
			<div class="mx-4 mt-2 mb-2" v-for="(ingredient, index) in groceryList.ingredients" :key="index">
				<b-field>
					<b-checkbox @change.native="check(ingredient.ingredient._id, ingredient.check)" type="is-secondary" v-model="ingredient.check"> </b-checkbox>
					<span :class="{ checked: ingredient.check === true }" v-if="ingredient.amount !== null && ingredient.amount !== 'null'">{{ ingredient.amount }}&nbsp;</span>
					<span :class="{ checked: ingredient.check === true }" v-if="ingredient.unit !== null && ingredient.unit !== 'null'">{{ ingredient.unit }} &nbsp;</span>
					<span :class="{ checked: ingredient.check === true }">{{ ingredient.ingredient.description[getLocale] }} </span>
				</b-field>
			</div>
		</div> -->

        <button
            class="button btn-add is-rounded mx-2 my-6"
            @click="clear">
            {{ t.clear }}
        </button>
    </section>
</template>

<script>
    import { getGroceryList, checkIngredient, clearList, addIngredient, addOtherIngredient, checkOtherIngredient } from '@/api/groceryList';
    import { getIngredients } from '@/api/ingredients';
    export default {
        data() {
            return {
                t: this.$t('components.MySpot.GroceryListTab'),
                s: this.$t('shared.ingredient_categories'),
                groceryList: null,
                checked: null,
                filteredIngredients: [],
                selectedIngredient: {
                    ingredient: null,
                    amount: null,
                    unit: null,
                },
                autoText: null,
                showError: false,
                filteredGroceyList: [],
                personalizedList: [],
            };
        },

        methods: {
            getList() {
                getGroceryList()
                    .then((response) => {
                        let grocery = response.data.list.ingredients.reduce((r, a) => {
                            a.ingredient.category == '' || a.ingredient.category == null ? (a.ingredient.category = 'other') : a.ingredient.category;
                            r[a.ingredient.category] = [...(r[a.ingredient.category] || []), a];

                            return r;
                        }, {});

                        this.groceryList = response.data.list;
                        this.filteredGroceyList = grocery;
                        this.personalizedList = response.data.list.others;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            check(id, check) {
                let changeCheck = check;
                let data = {
                    ingredient: id,
                    check: changeCheck,
                };
                checkIngredient(data)
                    .then((response) => {})
                    .catch((error) => {
                        console.error(error);
                    });
            },
            checkOther(id, check) {
                let changeCheck = check;
                let data = {
                    ingredient: id,
                    check: changeCheck,
                };
                checkOtherIngredient(data)
                    .then((response) => {})
                    .catch((error) => {
                        console.error(error);
                    });
            },

            clear() {
                clearList()
                    .then((response) => {
                        this.getList();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            getFilteredIngredients(text) {
                let query;

                if (this.getLocale === 'pt') {
                    query = {
                        search_pt: text,
                    };
                } else {
                    query = {
                        search_en: text,
                    };
                }

                getIngredients(query)
                    .then((response) => {
                        this.filteredIngredients = response.data.ingredients;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            addItem() {
                if (this.autoText) {
                    if (this.selectedIngredient.ingredient) {
                        addIngredient(this.selectedIngredient)
                            .then((response) => {
                                this.showError = false;
                                this.selectedIngredient = {
                                    ingredient: null,
                                    amount: null,
                                    unit: null,
                                };
                                this.autoText = null;

                                this.getList();
                            })
                            .catch((error) => {
                                console.error(error);
                                this.showError = true;
                            });
                    } else {
                        let otherIngredient = {
                            ingredient: this.autoText,
                            amount: this.selectedIngredient.amount,
                            unit: this.selectedIngredient.unit,
                        };
                        addOtherIngredient(otherIngredient)
                            .then((response) => {
                                this.showError = false;
                                this.otherIngredient = {
                                    ingredient: null,
                                    amount: null,
                                    unit: null,
                                };
                                this.autoText = null;

                                this.getList();
                            })
                            .catch((error) => {
                                console.error(error);
                                this.showError = true;
                            });
                    }
                } else this.showError = true;
            },

            print() {
                window.print();
            },
        },

        created() {
            this.getList();
            getIngredients({
                limit: 5000,
            })
                .then((response) => {
                    this.getIngredients = response.data.ingredients;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .btn-add {
        border-color: $primary;
        background-color: $primary;
        color: white;
        height: 32px;
        font-size: 11px;
        font-family: 'Lota Grotesque';

        &:hover {
            color: white;
            background-color: $primary;
            border-color: white;
        }
    }

    .checked {
        text-decoration: line-through;
    }

    /deep/ .auto input {
        background-color: white !important;
        border: solid 1px $primary;
        width: 170px;
    }

    /deep/ .amount input {
        background-color: white !important;
        border: solid 1px $primary;
        width: 60px;
    }

    .new-container {
        display: flex;

        @include from($mobile-l) {
            justify-content: center;
        }
    }

    @media print {
        .print {
            visibility: visible;
            position: relative;
            top: -430px;
        }

        .new-container,
        .center-inside,
        .button,
        .non-print {
            visibility: hidden;
        }
    }
</style>
