<template>
    <section>
        <div class="main">
            <p class="title has-text-centered">{{ t.choosePlan }}</p>

            <div
                class="plan-container"
                v-for="(plan, index) in plans"
                :key="index">
                <div
                    class="plan-button mt-5 is-clickable"
                    @click="savePlan(type.id)"
                    v-for="(type, index) in plan.prices"
                    :key="100 + index">
                    <div class="top-card">
                        <p v-if="type.recurring.interval == 'month'">{{ type.unit_amount / 100 }}€/{{ t.month }}</p>
                        <p v-else>{{ type.unit_amount / 100 / 12 }}€/{{ t.month }}</p>
                    </div>
                    <!-- <p>{{ type.unit_amount / 100 }}</p> -->
                    <p
                        class="card-text"
                        v-if="type.recurring.interval == 'year'">
                        {{ t.annualInvestment }}
                    </p>
                    <p
                        class="card-text is-size-2"
                        v-if="type.recurring.interval == 'year'">
                        {{ type.unit_amount / 100 }}€
                    </p>
                    <p v-if="type.recurring.interval == 'year'">{{ t.save }} {{ getDifference }}€/{{ t.year }}</p>

                    <p
                        class="card-text"
                        v-if="type.recurring.interval == 'month'">
                        {{ t.monthlyInvestment }}
                    </p>
                    <p
                        class="card-text"
                        v-if="type.recurring.interval == 'month'">
                        {{ t.annualValue }}
                    </p>
                    <p
                        class="card-text"
                        v-if="type.recurring.interval == 'month'">
                        {{ (type.unit_amount / 100) * 12 }}€
                    </p>
                </div>
            </div>

            <p
                class="mt-6 has-text-primary has-text-centered is-clickable"
                @click="goToPage('CancelSubscription')">
                {{ t.cancel }}
            </p>
            <p
                class="mt-4 has-text-centered pb-4 is-clickable"
                @click="logout">
                {{ t.logout }}
            </p>
        </div>
    </section>
</template>

<script>
    import { getPlans } from '@/api/subscription';
    import { cancelSubscription } from '@/api/apiUser';
    import { logout } from '@/api/apiAuth';
    export default {
        data() {
            return {
                t: this.$t('components.Subscription.SubscriptionPlan'),
                plans: [],
            };
        },

        methods: {
            savePlan(id) {
                this.$store.commit('subscriptionSteps/setSubscription', id);
                this.$store.commit('subscriptionSteps/setSubscriptionStep', 1);
            },

            cancel() {
                cancelSubscription()
                    .then((response) => {})
                    .catch((error) => {
                        console.error(error);
                    });
            },

            logout() {
                logout()
                    .then((response) => {
                        this.onSessionExpired();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },

        computed: {
            getDifference() {
                let yearPrice = this.plans[0].prices[0].unit_amount / 100;
                let monthPrice = (this.plans[0].prices[1].unit_amount / 100) * 12;
                let final = monthPrice - yearPrice;
                return final.toFixed(2);
            },
        },

        created() {
            this.setIsPageLoading(true);
            getPlans()
                .then((response) => {
                    this.plans = response.data.subscriptions;
                })
                .catch((error) => {
                    console.error(error);
                })
                .finally(() => {
                    this.setIsPageLoading(false);
                });
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .title {
        font-size: 30px;
        font-family: 'Borest-Standard';
        font-weight: bold;
    }

    .plan-button {
        height: 250px;
        width: 100%;
        color: #bf8897;
        text-align: center;
        font-size: 23px;
        border-radius: 20px;
        box-shadow: 0px 6px 28px #00000029;
        font-family: 'Lota Grotesque Light';
        @include from($tablet) {
            max-width: $card-max-width;
            margin: 0 auto;
        }
    }

    .plan-container {
        justify-content: space-between;
        margin: 0 auto;
    }

    .top-card {
        background-color: $primary;
        border-top-left-radius: 20px;
        border-top-right-radius: 20px;
        color: white;
        height: 80px;
        text-align: center;
        font-size: 3rem;
    }

    .card-text {
        font-family: 'Borest-Standard';
        color: black;
        font-size: 1.8rem;
    }
</style>
