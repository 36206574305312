import { get, formData, formDataPut, remove } from '@/utils/http';
const queryString = require('query-string');

export function addUpcomingNew(image, data) {
    let form = new FormData();
    if (image) {
        let new_name = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name);
    }
    form.append('data', JSON.stringify(data));
    return formData('/upcomingnews', form);
}

export function getUpcomingNews(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/upcomingnews' + _query);
}

export function updateUpcomingNew(image, data) {
    let form = new FormData();
    if (image) {
        let new_name = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name);
    } else {
        form.append('image', null);
    }
    form.append('data', JSON.stringify(data));
    return formDataPut('/upcomingnews', form);
}

export function removeUpcomingNew(id) {
    return remove('/upcomingnews', { id: id });
}
