const state = {
    freeTrial: null,
};

// getters
const getters = {
    getFreeTrial(state, getters) {
        return state.freeTrial;
    },
};

// mutations
const mutations = {
    setFreeTrial(state, active) {
        state.freeTrial = active;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
