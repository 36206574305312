<template>
    <section>
        <div class="main">
            <p class="subtitle has-text-centered mt-2">{{ t.forgotPhrase }}</p>
            <b-input
                :class="{ 'error-text': errors.has('email') }"
                type="email"
                v-validate="'required|email'"
                size="is-small"
                name="email"
                v-model="email"
                :placeholder="t.email" />
            <p
                v-show="errors.has('email')"
                class="help is-danger">
                {{ t.enterEmail }}
            </p>
            <b-button
                class="recover mt-5"
                rounded
                :loading="isLoading"
                @click="recover"
                >{{ t.go }}</b-button
            >
            <p
                v-if="checkMessage"
                class="message mt-8">
                {{ t.check }}
            </p>
            <p
                class="login my-5"
                @click="updateStep">
                {{ t.login }}
            </p>
        </div>
    </section>
</template>

<script>
    import { recoverPassword } from '@/api/apiAuth';
    export default {
        data() {
            return {
                t: this.$t('components.Auth.ForgotPassword'),
                email: null,
                isLoading: false,
                checkMessage: false,
            };
        },

        methods: {
            recover() {
                this.$validator.validateAll().then((result) => {
                    if (result) {
                        this.isLoading = true;
                        recoverPassword(this.email)
                            .then((response) => {
                                this.checkMessage = true;
                                this.isLoading = false;
                            })
                            .catch((error) => {
                                console.error(error);
                            });
                    }
                });
            },

            updateStep() {
                this.$store.commit('loginSteps/setLoginSteps', 0);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .recover {
        width: 70px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    .subtitle {
        font-size: 24px;
        font-family: 'Borest-Standard';
        font-weight: normal;
    }

    .message {
        font-family: 'Lota Grotesque';
        font-size: 0.75rem;
        background-color: transparent !important;
    }

    .login {
        font-size: 0.75rem;
        color: $secondary;
        font-family: 'Lota Grotesque';
    }
</style>
