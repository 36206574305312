<template>
    <section>
        <div class="bot-menu-default">
            <div class="icons-container">
                <div @click="goToPage('Homepage')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-home"
                        type="is-primary"
                        class="is-size-4">
                    </b-icon>
                </div>
                <div @click="goToPage('Menus')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-file"
                        type="is-primary"
                        class="is-size-4">
                    </b-icon>
                </div>
                <img
                    class="logo-ag"
                    src="../../assets/icons/logo_ana_garcez_v1.svg"
                    alt="" />
                <div @click="goToPage('MyShop')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-cesto_compras"
                        type="is-primary"
                        class="is-size-4">
                    </b-icon>
                </div>
                <div @click="goToPage('Profile')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-user"
                        type="is-primary"
                        class="is-size-4">
                    </b-icon>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .bot-menu-default {
        position: fixed;
        bottom: 0;
        height: 105px;
        color: $primary;
        width: 100%;
        border-top: 2px solid #bfd9d1;
        background-color: white;
        border-radius: 48px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 55px;
        }
    }

    .icons-container {
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
        justify-content: space-between;
        display: flex;
        height: 105px;
        align-items: center;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 60px;
        }
    }

    .logo-ag {
        width: 55px;
        transform: translateY(7px);
    }

    @media only screen and (max-width: 350px) {
        .bot-menu-default,
        .icons-container {
            height: 85px;
        }
    }
</style>
