const state = {
    activeStep: 0,
    subscription: {},
};

// getters
const getters = {
    getSubscriptionStep(state, getters) {
        return state.activeStep;
    },

    getSubscription(state, getters) {
        return state.subscription;
    },
};

// mutations
const mutations = {
    setSubscriptionStep(state, active) {
        state.activeStep = active;
    },

    setSubscription(state, active) {
        state.subscription = active;
    },

    resetSubscription(state) {
        (state.activeStep = 0), (state.subscription = {});
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
