<template>
    <section>
        <TopMenu />

        <div class="container">
            <div class="profile-card">
                <div class="media pt-6">
                    <div class="media-left">
                        <p class="label ml-2">{{ t.settings }}</p>
                    </div>
                    <div class="media-content"></div>
                    <div class="media-right">
                        <div @click="$router.back()">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-arrow-left"
                                type="is-black"
                                class="is-size-4 arrow">
                            </b-icon>
                        </div>
                    </div>
                </div>

                <div class="select-container">
                    <b-select
                        class="language-select field mt-2"
                        size="is-small"
                        v-model="$i18n.locale"
                        expanded>
                        <option
                            :value="lang.value"
                            v-for="(lang, index) in t.languages"
                            :key="index">
                            {{ lang.lang }}
                        </option>
                    </b-select>
                </div>

                <b-button
                    class="save"
                    @click="saveLanguage"
                    rounded
                    :class="{ isDisabled: getLocale === $i18n.locale }"
                    :disabled="getLocale === $i18n.locale"
                    >{{ t.save }}</b-button
                >
                <br />
                <b-button
                    class="shop mt-3"
                    @click="goToPage('MyShop')"
                    rounded
                    >{{ t.shop }}</b-button
                >
            </div>
        </div>
        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    export default {
        components: {
            TopMenu,
            BotMenu,
        },
        data() {
            return {
                t: this.$t('components.Settings'),
            };
        },

        methods: {
            saveLanguage() {
                this.changeLanguage(this.$i18n.locale);
                this.moment.locale(this.$store.getters['language/getLanguage']);
                this.$buefy.toast.open({
                    message: this.t.changed,
                    type: 'is-secondary',
                });
                this.goToPage('Profile');
            },

            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },

        created() {
            this.scrollToTop();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .profile-card {
        background-color: $grey-variant;
        height: 260px;
        border-radius: 48px;
        padding: 1rem;
        @include from($mobile-l) {
            max-width: 60%;
            margin: 0 auto;
        }
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 240px;
            margin-bottom: 100px;
        }
    }

    .container {
        padding-top: 140px;
    }

    .label {
        font-family: 'Lota Grotesque';
        font-size: 0.9em;
        font-weight: bold;
    }
    p {
        font-family: 'Lota Grotesque';
    }

    .media {
        padding-left: 12%;
        padding-right: 12%;
    }
    .arrow {
        transform: translate(3px, -23px);
    }

    .field {
        width: 66% !important;
    }

    .select-container {
        display: flex;
        justify-content: center;
    }

    .save {
        width: 82px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        transform: translate(0, 26px);
        margin-left: 12%;
    }

    .shop {
        width: 82px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: #f2d8e1;
        color: black;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        transform: translate(0, 26px);
        margin-left: 12%;
    }

    .isDisabled {
        background-color: $grey;
        color: black;
    }
</style>
