<template>
    <section>
        <!-- <Header /> -->
        <div class="main">
            <button
                class="button is-rounded has-text-primary"
                @click="goToPage('HowAllStarted')">
                {{ t.started }}
            </button>
            <button
                class="button is-rounded has-text-primary"
                @click="goToStore">
                {{ t.shop }}
            </button>
            <!-- 		<button class="button is-rounded has-text-primary">{{ t.podcast }}</button> -->
            <button
                class="button is-rounded has-text-primary"
                @click="goToSite">
                {{ t.site }}
            </button>
            <button
                class="button is-rounded has-text-primary"
                @click="goToBlog">
                {{ t.blog }}
            </button>
        </div>
        <!-- <Menu /> -->

        <div class="logo-container">
            <img
                class="logo-ag"
                src="../assets/icons/logo_ana_garcez_v3.svg"
                alt="" />
        </div>
    </section>
</template>

<script>
    import Header from '@/components/Header/Header.vue';
    import Menu from '@/components/Menu/BottomAboutMenu.vue';
    import { Browser } from '@capacitor/browser';

    export default {
        components: {
            Header,
            Menu,
        },

        data() {
            return {
                t: this.$t('views.About'),
            };
        },

        methods: {
            goToAbout() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://site.belovebeyou.iotech.pt/#ag' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://site.belovebeyou.iotech.pt/#ag', '_blank');
                } else window.open('https://site.belovebeyou.iotech.pt/#ag', '_blank');
            },
            goToStore() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://site.belovebeyou.iotech.pt/store/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://site.belovebeyou.iotech.pt/store/', '_blank');
                } else window.open('https://site.belovebeyou.iotech.pt/store/', '_blank');
            },
            goToSite() {
                if (this.isMobile) {
                    /* 	const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://site.belovebeyou.iotech.pt/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://site.belovebeyou.iotech.pt/', '_blank');
                } else window.open('https://site.belovebeyou.iotech.pt/', '_blank');
            },
            goToBlog() {
                if (this.isMobile) {
                    /* 	const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://site.belovebeyou.iotech.pt/blog' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://site.belovebeyou.iotech.pt/blog', '_blank');
                } else window.open('https://site.belovebeyou.iotech.pt/blog', '_blank');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';
    .main {
        /* 	padding-top: 8.75rem; */
        display: grid;
        justify-content: center;
        width: 100%;

        /* @include until($mobile-s) {
			padding-top: 6.5rem;
		} */
    }

    .button {
        width: 189px;
        height: 47px;
        font-family: 'Lota Grotesque';
        font-size: 14px;
        font-weight: bold;
        border: 1px solid $primary;
        margin-bottom: 12px;
        margin-top: 12px;

        @include until($mobile-s) {
            width: 162px;
            height: 41px;
        }
    }

    /* .logo-ag {
			position: fixed;
			left: 50%;
			bottom: 0;
			transform: translate(-50%, 10%);
			margin: 0 auto;
			width: 100px;
		} */

    .logo-container {
        /* 	position: absolute;
		left: 50%; */
        bottom: 0;
        /* 	transform: translate(-50%, 10%); */
        margin: 0 auto;
        width: 100px;
        z-index: 10;
    }
</style>
