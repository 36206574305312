<template>
    <div>
        <b-table
            :data="data"
            ref="table"
            paginated
            backend-pagination
            :total="total"
            per-page="10"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            default-sort="createdAt"
            default-sort-direction="desc">
            <b-table-column
                field="image"
                v-slot="props">
                <img
                    :src="props.row.image"
                    class="image is-64x64 is-clickable"
                    @click="openModal(props.row.image)" />
            </b-table-column>
            <b-table-column
                field="name"
                :label="t.columns.name"
                v-slot="props">
                {{ props.row.name }}
            </b-table-column>

            <b-table-column
                field="likes"
                :label="t.columns.likes"
                v-slot="props">
                {{ props.row.likes.length }}
            </b-table-column>

            <!-- <b-table-column field="author" :label="t.columns.author" v-slot="props"> {{ props.row.author.info.name }} {{ props.row.author.info.last_name }} </b-table-column> -->

            <b-table-column
                field="createdAt"
                :label="t.columns.posted_at"
                v-slot="props"
                sortable>
                {{ moment(props.row.createdAt).utc().format('DD/MM/YYYY') }}
            </b-table-column>

            <b-table-column
                :field="isActive"
                :label="t.columns.active"
                v-slot="props">
                <b-switch
                    @input="active($event, props.row._id)"
                    v-model="props.row.isActive"></b-switch>
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-pencil"
                    type="is-primary"
                    :loading="isLoading"
                    @click="$router.push({ name: 'UpdateSoulStates', params: { id: props.row._id } })"></b-button>
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    v-if="!props.row.user"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-delete"
                    type="is-danger"
                    :loading="isLoading"
                    @click="deleteSoulState(props.row._id, 0)"></b-button>
                <b-button
                    v-else
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-delete"
                    type="is-warning"
                    :loading="isLoading"
                    @click="deleteSoulState(props.row._id, 1)"></b-button>
            </b-table-column>
        </b-table>
        <b-modal v-model="isModalActive">
            <p class="image">
                <img :src="modalImage" />
            </p>
        </b-modal>
    </div>
</template>

<script>
    import { getSoulStates, updateSoulState, removeSoulState } from '@/api/soulStates';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.SoulStates.List'),
                data: [],
                total: 0,
                page: 1,
                isModalActive: false,
                modalImage: null,
            };
        },
        created() {
            this.getData({
                page: this.page,
                limit: 10,
            });
        },
        methods: {
            getData(query) {
                getSoulStates(query)
                    .then((response) => {
                        this.data = response.data.soul_states;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onPageChange(page) {
                this.page = page;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
            remove(body) {
                this.isLoading = true;
                removeSoulState(body)
                    .then((response) => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: this.t.api.success,
                            position: 'is-bottom',
                            type: 'is-success',
                        });
                        this.getData();
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: this.t.api.error,
                            position: 'is-bottom',
                            type: 'is-danger',
                        });
                    });
            },
            deleteSoulState(id, type) {
                if (type == 0) {
                    this.$buefy.dialog.confirm({
                        title: this.t.dialog.title,
                        message: this.t.dialog.message,
                        confirmText: this.t.dialog.confirm,
                        cancelText: this.t.dialog.cancel,
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: () => {
                            this.remove({ id });
                        },
                    });
                } else {
                    this.$buefy.dialog.prompt({
                        title: this.t.dialog.title,
                        message: this.t.dialog.message1,
                        confirmText: this.t.dialog.confirm,
                        cancelText: this.t.dialog.cancel,
                        type: 'is-danger',
                        hasIcon: true,
                        onConfirm: (message) => {
                            this.remove({ id, message });
                        },
                    });
                }
            },
            active(active, id) {
                updateSoulState(null, {
                    id,
                    active,
                });
            },
            openModal(image) {
                this.modalImage = image;
                this.isModalActive = true;
            },
        },
        computed: {},
    };
</script>
