<template>
    <section>
        <div class="container">
            <div class="profile-card">
                <div class="media pt-6">
                    <div class="media-left">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-lock"
                            type="is-white"
                            class="is-size-2">
                        </b-icon>
                    </div>
                    <div class="media-content">
                        <p class="label ml-2">{{ t.saved }}</p>
                    </div>
                    <div class="media-right">
                        <div @click="$router.back()">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-arrow-left"
                                type="is-black"
                                class="is-size-4 arrow">
                            </b-icon>
                        </div>
                        <b-button
                            class="add"
                            rounded
                            @click="updateStep"
                            >{{ t.add }}</b-button
                        >
                    </div>
                </div>

                <div>
                    <p class="label credit-card-label mt-5">{{ t.creditCard }}</p>
                </div>

                <div class="media">
                    <div class="media-left">
                        <div v-if="getUser.payments.length > 0">
                            <div
                                v-for="(card, index) in getUser.payments"
                                class="credit-card mt-3"
                                :key="index">
                                <p>{{ card.brand }}</p>
                                <p>**** **** **** {{ card.last4 }}</p>
                                <p class="label">{{ t.expiration }}</p>
                                <p>{{ card.exp_month }}/{{ card.exp_year }}</p>
                                <b-button
                                    class="remove"
                                    :loading="isLoading"
                                    rounded
                                    @click="remove(card.id)"
                                    >{{ t.remove }}</b-button
                                >

                                <p
                                    v-if="today.getFullYear() > card.exp_year"
                                    class="has-text-danger">
                                    {{ t.expired }}
                                </p>

                                <p
                                    v-else-if="today.getFullYear() >= card.exp_year && today.getMonth() + 1 > card.exp_month"
                                    class="has-text-danger">
                                    {{ t.expired }}
                                </p>
                            </div>
                        </div>
                        <p
                            class="no-cards"
                            v-else>
                            {{ t.noCreditCards }}
                        </p>
                    </div>
                    <div class="media-content"></div>
                    <div class="media-right"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import { removeCard } from '@/api/apiUser';
    import { getLoggedUser } from '@/api/apiAuth';
    export default {
        data() {
            return {
                t: this.$t('components.Payments.SavedCards'),
                currentPassword: null,
                newPassword: null,
                confirmPassword: null,
                invalidCurrentPassword: false,
                changed: false,
                isLoading: false,
                today: new Date(),
            };
        },
        methods: {
            updateStep() {
                this.$store.commit('paymentSteps/setPaymentStep', 1);
            },

            remove(cardId) {
                this.isLoading = true;

                let data = {
                    id: cardId,
                };

                removeCard(data)
                    .then((response) => {
                        getLoggedUser()
                            .then((response) => {
                                this.addUser(response.data.body);
                                this.isLoading = false;
                            })
                            .catch((error) => {});
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },
        mounted() {},
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        padding: 1rem;
        @include from($tablet) {
            max-width: 60%;
            margin: 0 auto;
        }
    }

    .container {
        padding-top: 140px;
        padding-bottom: 200px;
    }

    .label {
        font-family: 'Lota Grotesque';
        font-size: 0.9em;
        font-weight: bold;
    }
    p {
        font-family: 'Lota Grotesque';
    }
    .no-cards {
        font-size: 0.75rem;
    }

    .media {
        padding-left: 8%;
        padding-right: 6%;
    }
    .arrow {
        transform: translate(32px, -23px);
    }

    .add {
        width: 86px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        transform: translate(0, 26px);
    }
    .remove {
        width: 76px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
        float: right;
        transform: translateY(-30px);
    }

    .credit-card-label {
        padding-left: 8%;
    }

    .credit-card {
        background-color: white;
        height: auto;
        border-radius: 16px;
        padding: 1rem;
        width: 266px;
    }
</style>
