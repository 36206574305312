<template>
    <section>
        <TopMenu />

        <div class="main px-0">
            <div
                class="title-container"
                :style="{ 'font-family': this.changeFont }">
                <b-icon
                    @click.native="$router.go(-1)"
                    pack="bl-icons"
                    icon="bl-arrow-left"
                    type="is-white"
                    class="is-size-5 back">
                </b-icon>
                <p class="has-text-centered">{{ recipe.name[getLocale] }}</p>
            </div>
        </div>

        <!-- <div class="main pt-4">
			<div class="has-text-centered has-text-primary">
				<p class="description">This amazing pancakes are full of delicious ingredients</p>
			</div>
		</div> -->

        <div
            v-touch:swipe.right="handleSwipe()"
            class="main pt-0">
            <div class="cards-container my-10">
                <div class="card mt-4">
                    <div class="media">
                        <div class="media-left">
                            <div
                                class="card-image"
                                :style="{ 'background-image': `url(${recipe.image})` }"></div>
                        </div>
                        <div class="media-content has-text-centered has-text-secondary">
                            <div
                                style="display: inline-flex"
                                class="my-1">
                                <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                <p style="transform: translateY(17px)">{{ recipe.preparing_time }}</p>
                            </div>
                            <div class="my-1">
                                <b-icon
                                    pack="bl-icons"
                                    icon="bl-user"
                                    type="is-secondary"
                                    class="is-size-5">
                                </b-icon>
                                x {{ recipe.people }}
                            </div>

                            <div class="my-4 icons-container">
                                <div
                                    v-if="liked === 0"
                                    @click="add(recipe._id)">
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-heart"
                                        type="is-secondary"
                                        class="is-size-4 is-clickable">
                                    </b-icon>
                                </div>
                                <div
                                    v-else
                                    @click="remove()">
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-heart"
                                        type="is-primary"
                                        class="is-size-4 is-clickable">
                                    </b-icon>
                                </div>
                                <div
                                    v-if="saved === 0"
                                    @click="addMenu(recipe._id)">
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-save"
                                        type="is-secondary"
                                        class="is-size-4 is-clickable">
                                    </b-icon>
                                </div>
                                <div
                                    v-else
                                    @click="removeMenu()">
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-save"
                                        type="is-primary"
                                        class="is-size-4 is-clickable">
                                    </b-icon>
                                </div>
                                <div @click="sync()">
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-cart"
                                        type="is-secondary"
                                        class="is-size-4 is-clickable">
                                    </b-icon>
                                </div>
                            </div>
                        </div>
                        <div class="media-right"></div>
                    </div>
                </div>
            </div>

            <div class="socials has-text-secondary">
                <b-icon
                    class="is-clickable"
                    @click.native="goToInstagram"
                    icon="instagram"
                    custom-size="mdi-24px"></b-icon>
                <b-icon
                    class="is-clickable"
                    icon="facebook"
                    @click.native="goToFacebook"
                    custom-size="mdi-24px">
                </b-icon>
                <b-icon
                    class="is-clickable"
                    icon="pinterest"
                    @click.native="goToPinterest"
                    custom-size="mdi-24px">
                </b-icon>
                <b-icon
                    class="is-clickable"
                    icon="twitter"
                    @click.native="goToTwitter"
                    custom-size="mdi-24px">
                </b-icon>
                <!-- <b-icon icon="share-variant" custom-size="mdi-24px" @click.native="share(recipe.image)"> </b-icon> -->
            </div>

            <div class="instructions my-5 has-text-white">
                <div class="pt-4 px-5 pb-4">
                    <p>{{ recipe.name[getLocale] }}</p>
                    <p class="my-2">{{ t.ingredients }}</p>
                    <div
                        v-for="(hierarchy, name, index) in recipe.ingredients"
                        :key="index">
                        <div v-if="recipe.ingredients[name]">
                            <p
                                class="mt-3"
                                v-if="name !== 'list'">
                                {{ name.charAt(0).toUpperCase() + name.slice(1) }}
                            </p>
                            <div
                                v-for="(ingredient, i) in recipe.ingredients[name]"
                                :key="i">
                                <span>{{ ingredient.amount }}</span> <span v-if="ingredient.unit !== 'null'">{{ r[ingredient.unit] }} </span>
                                <span>{{ ingredient.ingredient.description[getLocale] }}</span> <span>{{ ingredient.description[getLocale] }}</span>
                            </div>
                        </div>
                        <div v-else>
                            <p class="mt-4 ml-2">{{ name.charAt(0).toUpperCase() + name.slice(1) }}</p>

                            <div
                                v-for="(ingredient, i) in props.row.ingredients[name]"
                                :key="i">
                                <span>{{ ingredient.amount }}</span> <span v-if="ingredient.unit !== 'null'">{{ r[ingredient.unit] }} </span>
                                <span>{{ ingredient.ingredient.description[getLocale] }}</span> <span>{{ ingredient.description[getLocale] }}</span>
                            </div>
                        </div>
                    </div>
                    <div
                        v-for="(hierarchy, recipe, index) in recipe.recipes"
                        class="underline mt-3"
                        :key="400 + index">
                        <span @click="seeDetails(hierarchy[index].recipe._id)">{{ hierarchy[index].recipe.name[getLocale] }}</span>
                    </div>

                    <p class="mt-5">{{ t.directions }}</p>
                    <div
                        v-for="(step, index) in recipe.steps"
                        :key="100 + index">
                        <p
                            v-if="step[getLocale]"
                            class="mt-1">
                            {{ step[getLocale] }}
                        </p>
                    </div>

                    <p
                        class="mt-3"
                        v-if="recipe.tips[0] && recipe.tips[0][getLocale].length > 0">
                        {{ t.tips }}
                    </p>
                    <div
                        v-for="(tip, index) in recipe.tips"
                        :key="200 + index">
                        <p class="mt-1">{{ tip[getLocale] }}</p>
                    </div>
                </div>
            </div>
        </div>
        <div class="main pt-0 mb-2 tooltip has-text-secondary">
            <p @click="addTracker">
                <b-icon
                    pack="bl-icons"
                    icon="bl-question"
                    type="is-secondary"
                    class="is-size-6">
                </b-icon>
                {{ t.add }}
            </p>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import { pwa } from 'pwafire';
    import { getRecipes } from '@/api/recipes';
    import { addFavorite, getFavorite, removeFavorite } from '@/api/favorites';
    import { addMyMenus, getMyMenus, removeMyMenus } from '@/api/myMenus';
    import { syncGroceryList } from '@/api/groceryList';
    import { addTrackerData } from '@/api/tracker';
    import { Browser } from '@capacitor/browser';
    export default {
        components: {
            TopMenu,
        },

        data() {
            return {
                t: this.$t('components.Recipes.RecipeDetails'),
                r: this.$t('shared.units'),
                id: null,
                recipe: {
                    image: '',
                },
                liked: 0,
                favoriteId: null,
                saved: 0,
                savedId: null,
            };
        },

        methods: {
            handleSwipe() {
                return (dir) => {
                    if (dir === 'right') {
                        this.$router.go(-1);
                    }
                };
            },

            add(id) {
                let data = {
                    recipe: id,
                };

                addFavorite(data)
                    .then((response) => {
                        this.$buefy.toast.open({
                            message: this.t.addFavorite,
                            type: 'is-primary',
                        });
                        let query = {
                            limit: 1000,
                            order: 'asc',
                            page: 0,
                            recipe_type: this.recipe.recipe_type,
                        };

                        getFavorite(query)
                            .then((response) => {
                                this.liked = response.data.count;
                                this.favoriteId = response.data.favorites[0]._id;
                            })
                            .catch((error) => {
                                console.error(error);
                                if (error) {
                                    this.liked = 0;
                                }
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            remove() {
                let data = {
                    id: this.favoriteId,
                };

                removeFavorite(data)
                    .then((response) => {
                        this.$buefy.toast.open({
                            message: this.t.removeFavorite,
                            type: 'is-primary',
                        });
                        let query = {
                            limit: 1000,
                            order: 'asc',
                            page: 0,
                            recipe_type: this.recipe.recipe_type,
                        };
                        this.liked = 0;
                        getFavorite(query)
                            .then((response) => {
                                this.favoriteId = response.data.favorites[0]._id;
                            })
                            .catch((error) => {
                                console.error(error);
                                if (error) {
                                    this.liked = 0;
                                }
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            seeDetails(id) {
                this.$router.push({ name: 'RecipeDetails', params: { id: id } });
            },

            addMenu(id) {
                let data = {
                    recipe: id,
                };

                addMyMenus(data)
                    .then((response) => {
                        this.$buefy.toast.open({
                            message: this.t.addMyMenus,
                            type: 'is-primary',
                        });
                        this.saved = 1;

                        let query = {
                            limit: 1000,
                            order: 'asc',
                            page: 0,
                            recipe: this.recipe._id,
                        };
                        getMyMenus(query)
                            .then((response) => {
                                this.saved = response.data.count;
                                this.savedId = response.data.mymenus[0]._id;
                            })
                            .catch((error) => {
                                this.saved = 0;
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            removeMenu() {
                let data = {
                    id: this.savedId,
                };

                removeMyMenus(data)
                    .then((response) => {
                        this.$buefy.toast.open({
                            message: this.t.removeMyMenus,
                            type: 'is-primary',
                        });
                        let query = {
                            limit: 1000,
                            order: 'asc',
                            page: 0,
                            recipe_type: this.recipe.recipe_type,
                        };

                        getMyMenus(query)
                            .then((response) => {
                                this.saved = 0;
                                this.savedId = response.data.mymenus[0]._id;
                            })
                            .catch((error) => {
                                this.saved = 0;
                            });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            sync() {
                let recipes = [];
                recipes.push(this.id);
                let data = {
                    recipes: recipes,
                };

                syncGroceryList(data)
                    .then((response) => {
                        this.$buefy.toast.open({
                            message: this.t.addGrocery,
                            type: 'is-primary',
                        });
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },

            addTracker() {
                let meals = {
                    quantity: 1,
                };

                let fruitsTotal = 0;
                let vegetablesTotal = 0;
                this.recipe.ingredients.list.map((ingredient) => {
                    if (ingredient.ingredient.type == 'vegetable') {
                        vegetablesTotal += 1;
                    }
                    if (ingredient.ingredient.type == 'fruit') {
                        fruitsTotal += 1;
                    }
                });

                if (fruitsTotal > 0) {
                    let fruits = {
                        quantity: fruitsTotal,
                    };
                    addTrackerData('fruits', fruits)
                        .then((response) => {})
                        .catch((error) => {
                            this.$buefy.toast.open({
                                message: this.t.errorTracker,
                                type: 'is-secondary',
                            });
                        });
                }

                if (vegetablesTotal > 0) {
                    let vegetables = {
                        quantity: vegetablesTotal,
                    };
                    addTrackerData('veggies', vegetables)
                        .then((response) => {})
                        .catch((error) => {
                            this.$buefy.toast.open({
                                message: this.t.errorTracker,
                                type: 'is-secondary',
                            });
                        });
                }

                addTrackerData('plantbasedmeals', meals)
                    .then((response) => {
                        this.$buefy.toast.open({
                            message: this.t.addTracker,
                            type: 'is-primary',
                        });
                    })
                    .catch((error) => {
                        this.$buefy.toast.open({
                            message: this.t.errorTracker,
                            type: 'is-secondary',
                        });
                    });
            },

            goToInstagram() {
                if (this.isMobile) {
                    /* 	const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://www.instagram.com/belovebeyou/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://www.instagram.com/belovebeyou/', '_blank');
                } else window.open('https://www.instagram.com/belovebeyou/', '_blank');
            },
            goToFacebook() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/', '_blank');
                } else window.open('https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/', '_blank');
            },
            goToPinterest() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://www.pinterest.pt/blovebyoufg/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://www.pinterest.pt/blovebyoufg/', '_blank');
                } else window.open('https://www.pinterest.pt/blovebyoufg/', '_blank');
            },
            goToTwitter() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://twitter.com/belovebeyoufg' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://twitter.com/belovebeyoufg', '_blank');
                } else window.open('https://twitter.com/belovebeyoufg', '_blank');
            },
        },

        computed: {
            getImage() {
                return this.recipe.image;
            },
        },

        created() {
            this.id = this.$route.params.id;
            let data = {
                id: this.id,
            };

            getRecipes(data)
                .then((response) => {
                    response.data.recipes.forEach((recipe, index) => {
                        let reduce = recipe.ingredients.reduce((r, a) => {
                            a.hierarchy[this.getLocale] == '' || a.hierarchy[this.getLocale] == null ? (a.hierarchy[this.getLocale] = 'list') : a.hierarchy[this.getLocale];
                            r[a.hierarchy[this.getLocale]] = [...(r[a.hierarchy[this.getLocale]] || []), a];
                            return r;
                        }, {});
                        let _reduce = recipe.recipes.reduce((r, a) => {
                            a.hierarchy[this.getLocale] == '' || a.hierarchy[this.getLocale] == null ? (a.hierarchy[this.getLocale] = 'list') : a.hierarchy[this.getLocale];
                            r[a.hierarchy[this.getLocale]] = [...(r[a.hierarchy[this.getLocale]] || []), a];
                            return r;
                        }, {});
                        response.data.recipes[index].ingredients = reduce;
                        response.data.recipes[index].recipes = _reduce;
                    });
                    this.recipe = response.data.recipes[0];

                    let query = {
                        recipe: this.recipe._id,
                    };
                    getFavorite(query)
                        .then((response) => {
                            this.liked = response.data.count;
                            this.favoriteId = response.data.favorites[0]._id;
                        })
                        .catch((error) => {
                            console.error(error);
                            if (error) {
                                this.liked = 0;
                            }
                        });

                    getMyMenus(query)
                        .then((response) => {
                            this.saved = response.data.count;
                            this.savedId = response.data.mymenus[0]._id;
                        })
                        .catch((error) => {
                            this.saved = 0;
                        });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 105px;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 56px;
        }
    }

    .title-container {
        min-height: 105px;
        height: auto;
        background-color: $secondary;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 42px;
        }
    }

    .title-container p {
        font-size: 32px;
        color: white;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-left: 2%;
        padding-right: 2%;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            font-size: 26px;
            height: 42px;
        }
    }

    .description {
        font-family: 'Borest-Standard';
        font-size: 24px;
    }

    .card {
        height: 172px;
        border-radius: 48px;
        border: 1px solid $secondary;
        background-color: transparent;

        @include from($mobile-l) {
            margin: 0 auto;
            max-width: $card-max-width;
        }
    }

    .card-image {
        justify-content: center;
        display: flex;
        height: 170px;
        border-top-left-radius: 48px 48px;
        border-bottom-left-radius: 48px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .media-left {
        width: 63%;
    }
    .media-right,
    .media-left {
        margin: 0;
    }

    .media-content p {
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .media-content {
        overflow: hidden;
    }

    .socials {
        justify-content: space-between;
        display: flex;
        @include from($mobile-l) {
            margin: 0 auto;
            max-width: $card-max-width;
        }
    }

    .instructions {
        background-color: $secondary;
        border-radius: 48px;
        @include from($mobile-l) {
            margin: 0 auto;
            max-width: $card-max-width;
        }
    }

    .instructions p,
    span {
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .icons-container {
        display: flex;
        justify-content: space-between;
        width: 90px;
        align-self: center;
        margin: 0 auto;
        @include from($mobile-s) {
            width: 96px;
        }
    }

    .underline {
        text-decoration: underline;
        cursor: pointer;
    }

    .tooltip p {
        font-size: 14px;
        font-family: 'Lota Grotesque';
        cursor: pointer;
    }

    .tooltip {
        @include from($mobile-l) {
            text-align: center;
        }
    }
    .back {
        transform: translate(4px, 10px);
    }
</style>
