export default {
    computed: {
        isMobile() {
            return this.$store.getters['app/isMobile'];
        },
        isSidebarOpen() {
            return this.$store.getters['app/isSidebarOpen'] || !this.$store.getters['app/isMobile'];
        },

        isPageLoading() {
            return this.$store.getters['app/isPageLoading'];
        },
    },
    methods: {
        updateWidth(width) {
            this.$store.commit('app/updateWidth', width);
        },
        setSidebarOpen(bol) {
            this.$store.commit('app/setSidebarOpen', bol);
        },

        setIsPageLoading(bol) {
            this.$store.commit('app/setIsPageLoading', bol);
        },
    },
};
