<template>
    <div>
        <b-table
            :data="data"
            ref="table"
            paginated
            backend-pagination
            :total="total"
            per-page="10"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            default-sort="createdAt"
            default-sort-direction="asc">
            <b-table-column
                field="title"
                :label="t.columns.title"
                v-slot="props">
                <a
                    :href="props.row.video"
                    target="_blank"
                    >{{ props.row.title[getLocale] }}
                </a>
            </b-table-column>

            <!-- <b-table-column field="author" :label="t.columns.author" v-slot="props"> {{ props.row.author.info.name }} {{ props.row.author.info.last_name }} </b-table-column> -->

            <b-table-column
                field="createdAt"
                :label="t.columns.posted_at"
                v-slot="props"
                sortable>
                {{ moment(props.row.createdAt).utc().format('DD/MM/YYYY') }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-content-copy"
                    type="is-secondary"
                    :loading="isLoading"
                    @click="copyLink(props.row._id)"></b-button>
            </b-table-column>
            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-pencil"
                    type="is-primary"
                    :loading="isLoading"
                    @click="$router.push({ name: 'UpdateVideos', params: { id: props.row._id } })"></b-button>
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-delete"
                    type="is-danger"
                    :loading="isLoading"
                    @click="deleteVideo(props.row._id)"></b-button>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import { getVideos, removeVideo } from '@/api/videos';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Videos.List'),
                data: [],
                total: 0,
                page: 1,
            };
        },
        created() {
            this.getData({
                page: this.page,
                limit: 10,
            });
        },
        methods: {
            getData(query) {
                getVideos(query)
                    .then((response) => {
                        this.data = response.data.videos;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            copyLink(_id) {
                const url = `${process.env.VUE_APP_SITE_URL}/videos?v=${_id}`;
                // send url to clipboard
                navigator.clipboard.writeText(url).then(() => {
                    this.$buefy.toast.open({
                        message: this.t.dialog.copy,
                        position: 'is-bottom',
                        type: 'is-success',
                    });
                });
            },
            onPageChange(page) {
                this.page = page;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
            deleteVideo(id) {
                this.$buefy.dialog.confirm({
                    title: this.t.dialog.title,
                    message: this.t.dialog.message,
                    confirmText: this.t.dialog.confirm,
                    cancelText: this.t.dialog.cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.isLoading = true;
                        removeVideo(id)
                            .then((response) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.success,
                                    position: 'is-bottom',
                                    type: 'is-success',
                                });
                                this.getData();
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.error,
                                    position: 'is-bottom',
                                    type: 'is-danger',
                                });
                            });
                    },
                });
            },
        },
        computed: {},
    };
</script>
