<template>
    <section>
        <!-- <div class="graph-container mt-14">
			<div class="mx-2 has-text-centered">
				<p class="has-text-primary mb-3">{{ t.week }}</p>
				<p>{{ getDays }}</p>
				<b-icon pack="bl-icons" icon="bl-graph" type="is-primary" class="is-size-3 mt-2"> </b-icon>
			</div>

			<div class="mx-2 has-text-centered">
				<p class="has-text-primary mb-3">{{ t.month }}</p>
				<p>{{ getMonth }}</p>
				<b-icon pack="bl-icons" icon="bl-graph" type="is-primary" class="is-size-3 mt-2"> </b-icon>
			</div>

			<div class="mx-2 has-text-centered">
				<p class="has-text-primary mb-3">{{ t.year }}</p>
				<p>{{ getYear }}</p>
				<b-icon pack="bl-icons" icon="bl-graph" type="is-primary" class="is-size-3 mt-2"> </b-icon>
			</div>
		</div> -->
        <div class="btn-container">
            <b-icon
                pack="bl-icons"
                icon="bl-file"
                type="is-secondary"
                class="is-size-5">
            </b-icon>
            <b-button
                class="btn is-rounded"
                @click="goToPage('DailyRecipes')"
                >{{ t.goToMeal }}</b-button
            >
        </div>

        <div class="social-container">
            <div class="socials has-text-primary">
                <b-icon
                    @click.native="goToInstagram"
                    icon="instagram"
                    custom-size="mdi-24px"></b-icon>
                <b-icon
                    icon="facebook"
                    @click.native="goToFacebook"
                    custom-size="mdi-24px">
                </b-icon>
                <b-icon
                    icon="pinterest"
                    @click.native="goToPinterest"
                    custom-size="mdi-24px">
                </b-icon>
                <b-icon
                    icon="twitter"
                    @click.native="goToTwitter"
                    custom-size="mdi-24px">
                </b-icon>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment';
    import { Browser } from '@capacitor/browser';
    export default {
        data() {
            return {
                t: this.$t('components.MySpot.TrackerTab'),
                firstDay: new Date(),
            };
        },

        computed: {
            getMonth() {
                return moment(new Date()).format('MMMM').toUpperCase();
            },

            getYear() {
                return moment(new Date()).format('YYYY');
            },

            getDays() {
                let startDate = moment().startOf('week');
                let endDate = moment().endOf('week');

                return startDate.format('DD.MM') + ' - ' + endDate.format('DD.MM.YY');
            },
        },

        methods: {
            addDays(date, days) {
                let newDate = new Date(date);

                newDate.setDate(this.firstDay.getDate() + days);
                return newDate;
            },

            goToInstagram() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://www.instagram.com/belovebeyou/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://www.instagram.com/belovebeyou/', '_blank');
                } else window.open('https://www.instagram.com/belovebeyou/', '_blank');
            },
            goToFacebook() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/', '_blank');
                } else window.open('https://pt-pt.facebook.com/pages/category/Health---Wellness-Website/Be-Love-Be-You-1739647529592163/', '_blank');
            },
            goToPinterest() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://www.pinterest.pt/blovebyoufg/' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://www.pinterest.pt/blovebyoufg/', '_blank');
                } else window.open('https://www.pinterest.pt/blovebyoufg/', '_blank');
            },
            goToTwitter() {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://twitter.com/belovebeyoufg' });
					};
					openCapacitorSite(); */
                    window.location.assign('https://twitter.com/belovebeyoufg', '_blank');
                } else window.open('https://twitter.com/belovebeyoufg', '_blank');
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .graph-container {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
    }
    p {
        font-family: 'Lota Grotesque';
        font-size: 0.8rem;
        font-weight: bold;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
        padding-bottom: 40px;
    }

    .btn {
        border-color: $secondary;
        background-color: $secondary;
        color: white;
        margin-left: 10px;
    }

    .socials {
        justify-content: space-between;
        display: flex;
        @include from($mobile-l) {
            margin: 0 auto;
            max-width: $card-max-width;
        }
        margin-bottom: 0px;
    }

    .social-container {
        width: 88vw;
        position: absolute;
        bottom: 10px;

        @include until($mobile-s) {
            position: relative;
            width: 92vw;
        }
    }
</style>
