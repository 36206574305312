import { get, post, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addIngredient(data) {
    return post('/ingredients', data);
}

export function getIngredients(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/ingredients' + _query);
}

export function updateIngredient(data) {
    return put('/ingredients', data);
}

export function removeIngredient(id) {
    return remove('/ingredients/', { id: id });
}
