import { get, put } from '@/utils/http';
const queryString = require('query-string');

export function getOrders(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/orders' + _query);
}

export function updateOrder(data) {
    return put('/orders', data);
}
