<template>
    <div>
        <div class="columns pl-4">
            <b-field
                class="column is-12-mobile is-3-desktop"
                :label="t.tags">
                <b-select v-model="filters.tag">
                    <option
                        v-for="(value, key, i) in $t('shared.tags')"
                        :key="i"
                        :value="key">
                        {{ value }}
                    </option>
                </b-select>
            </b-field>
            <b-field
                class="column is-12-mobile is-3-desktop"
                :label="t.recipe_type">
                <b-select v-model="filters.type">
                    <option
                        :value="null"
                        selected>
                        Todos
                    </option>
                    <option
                        v-for="(value, key, i) in $t('shared.recipe_types')"
                        :key="i"
                        :value="key">
                        {{ value }}
                    </option>
                </b-select>
            </b-field>
        </div>
        <b-pagination
            :total="total"
            v-model="page"
            :range-before="range_before"
            :range-after="range_after"
            per-page="10"
            @change="onPageChange">
        </b-pagination>
        <!-- Pagination on table -->
        <!--
			paginated
			backend-pagination
			:total="total"
			per-page="10"
			:range-after="5"
			:current-page="page"
			@page-change="onPageChange"
			aria-next-label="Next page"
			aria-previous-label="Previous page"
			aria-page-label="Page"
			aria-current-label="Current page"
			pagination-position="both"
		 -->
        <b-table
            :data="data"
            ref="table"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="_id"
            :show-detail-icon="showDetailIcon">
            <b-table-column
                field="name"
                :label="t.columns.name"
                :searchable="true">
                <template #searchable="props">
                    <b-input
                        @input="changeFilter"
                        icon="magnify"
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row.name[getLocale] }}
                </template>
            </b-table-column>

            <b-table-column
                field="preparing_time"
                :label="t.columns.preparing_time"
                sortable
                v-slot="props"
                centered>
                {{ props.row.preparing_time }}
            </b-table-column>

            <b-table-column
                field="people"
                :label="t.columns.people"
                sortable
                v-slot="props"
                centered>
                {{ props.row.people }}
            </b-table-column>

            <b-table-column
                v-if="isMobile"
                class="level"
                v-slot="props"
                width="5">
                <b-button
                    class="level-item"
                    size="is-small"
                    icon-right="mdi mdi-content-duplicate"
                    :loading="isLoading"
                    @click="$router.push({ name: 'NewRecipe', params: { id: props.row._id, duplicated: true } })"></b-button>
                <b-button
                    class="level-item"
                    size="is-small"
                    icon-right="mdi mdi-pencil"
                    type="is-primary"
                    :loading="isLoading"
                    @click="$router.push({ name: 'UpdateRecipe', params: { id: props.row._id } })"></b-button>
                <b-button
                    class="level-item"
                    size="is-small"
                    icon-right="mdi mdi-delete"
                    type="is-danger"
                    :loading="isLoading"
                    @click="deleteRecipe(props.row._id)"></b-button>
            </b-table-column>

            <b-table-column
                v-if="!isMobile"
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-content-duplicate"
                    :loading="isLoading"
                    @click="$router.push({ name: 'NewRecipe', params: { id: props.row._id, duplicated: true } })"></b-button>
            </b-table-column>

            <b-table-column
                v-if="!isMobile"
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-pencil"
                    type="is-primary"
                    :loading="isLoading"
                    @click="$router.push({ name: 'UpdateRecipe', params: { id: props.row._id } })"></b-button>
            </b-table-column>

            <b-table-column
                v-if="!isMobile"
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-delete"
                    type="is-danger"
                    :loading="isLoading"
                    @click="deleteRecipe(props.row._id)"></b-button>
            </b-table-column>

            <template #detail="props">
                <article class="media">
                    <figure
                        v-if="!isMobile"
                        class="media-left">
                        <p class="image is-128x128">
                            <img :src="props.row.image" />
                        </p>
                    </figure>
                    <div class="media-content">
                        <div class="content">
                            <p
                                v-if="isMobile"
                                class="image is-128x128">
                                <img :src="props.row.image" />
                            </p>
                            <p>{{ t.recipe_type }}</p>
                            <b-taglist>
                                <b-tag
                                    type="is-primary"
                                    v-for="(type, i) in props.row.recipe_type"
                                    :key="i"
                                    >{{ $t('shared.recipe_types')[type] }}</b-tag
                                >
                            </b-taglist>
                            <div class="columns is-multiline">
                                <div class="column is-6-desktop is-12-mobile">
                                    <p>{{ t.ingredients }}</p>
                                    <div
                                        v-for="(hierarchy, name, i) in props.row.ingredients"
                                        :key="i">
                                        <div v-if="props.row.recipes[name]">
                                            <p class="mt-4 ml-2">{{ name.charAt(0).toUpperCase() + name.slice(1) }}</p>
                                            <ul>
                                                <li
                                                    v-for="(ingredient, i) in props.row.ingredients[name]"
                                                    :key="i">
                                                    {{ ingredient.amount }} {{ ingredient.unit != 'null' ? $t('shared.units')[ingredient.unit] : '' }}
                                                    {{ ingredient.ingredient.description[getLocale] }} {{ ingredient.description[getLocale] }}
                                                </li>
                                                <li
                                                    v-for="recipe in props.row.recipes[name]"
                                                    :key="recipe.recipe._id">
                                                    {{ recipe.recipe.name[getLocale] }}
                                                </li>
                                            </ul>
                                        </div>
                                        <div v-else>
                                            <p class="mt-4 ml-2">{{ name.charAt(0).toUpperCase() + name.slice(1) }}</p>
                                            <ul>
                                                <li
                                                    v-for="(ingredient, i) in props.row.ingredients[name]"
                                                    :key="i">
                                                    {{ ingredient.amount }} {{ ingredient.unit != 'null' ? $t('shared.units')[ingredient.unit] : '' }}
                                                    {{ ingredient.ingredient.description[getLocale] }} {{ ingredient.description[getLocale] }}
                                                </li>
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="column is-6-desktop is-12-mobile">
                                    <p>{{ t.steps }}</p>
                                    <ul>
                                        <li
                                            v-for="(step, i) in props.row.steps"
                                            :key="i">
                                            {{ step[getLocale] }}
                                        </li>
                                    </ul>
                                </div>
                            </div>
                            <p>{{ t.tips }}</p>
                            <ul>
                                <li
                                    v-for="(tip, i) in props.row.tips"
                                    :key="i">
                                    {{ tip[getLocale] }}
                                </li>
                            </ul>
                            <b-taglist>
                                <b-tag
                                    type="is-primary"
                                    v-for="(tag, i) in props.row.tags"
                                    :key="i"
                                    >{{ $t('shared.tags')[tag] }}</b-tag
                                >
                            </b-taglist>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>
        <b-pagination
            :total="total"
            v-model="page"
            :range-before="range_before"
            :range-after="range_after"
            per-page="10"
            @change="onPageChange"
            class="mt-2">
        </b-pagination>
    </div>
</template>

<script>
    import { getRecipes, removeRecipe } from '@/api/recipes';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Recipes.List'),
                data: [],
                total: 0,
                range_before: 5,
                range_after: 10,
                defaultOpenedDetails: [1],
                showDetailIcon: true,
                page: 1,
                last_page: null,
                filters: {
                    tag: 'all',
                    type: null,
                    search: null,
                },
            };
        },
        created() {
            /* this.getData({
			page: this.page,
			limit: 10,
		}); */
            if (this.isMobile) {
                this.range_after = 2;
                this.range_before = 1;
            }
        },
        methods: {
            changeFilter(text) {
                this.filters.search = text;
                this.getData();
            },
            getData() {
                let query = {
                    page: this.page,
                    limit: 10,
                };
                this.filters.type == null ? query : (query.recipe_type = this.filters.type);
                this.filters.tag == 'all' ? query : (query.tags = this.filters.tag);
                if (this.filters.search && this.getLocale == 'pt') {
                    query.search_pt = this.filters.search;
                } else if (this.filters.search && this.getLocale == 'en') {
                    query.search_en = this.filters.search;
                }

                this.data = [];
                getRecipes(query)
                    .then((response) => {
                        response.data.recipes.forEach((recipe, index) => {
                            let reduce = recipe.ingredients.reduce((r, a) => {
                                a.hierarchy[this.getLocale] == null ? (a.hierarchy[this.getLocale] = 'list') : a.hierarchy[this.getLocale];
                                r[a.hierarchy[this.getLocale]] = [...(r[a.hierarchy[this.getLocale]] || []), a];
                                return r;
                            }, {});
                            let _reduce = recipe.recipes.reduce((r, a) => {
                                a.hierarchy[this.getLocale] == null ? (a.hierarchy[this.getLocale] = 'list') : a.hierarchy[this.getLocale];
                                r[a.hierarchy[this.getLocale]] = [...(r[a.hierarchy[this.getLocale]] || []), a];
                                return r;
                            }, {});
                            response.data.recipes[index].ingredients = reduce;
                            response.data.recipes[index].recipes = _reduce;
                        });
                        this.data = response.data.recipes;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            onPageChange(page) {
                if (!this.last_page) {
                    this.page = page;
                    this.getData();
                }
            },
            deleteRecipe(id) {
                this.$buefy.dialog.confirm({
                    title: this.t.dialog.title,
                    message: this.t.dialog.message,
                    confirmText: this.t.dialog.confirm,
                    cancelText: this.t.dialog.cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.isLoading = true;
                        removeRecipe(id)
                            .then((response) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.success,
                                    position: 'is-bottom',
                                    type: 'is-success',
                                });
                                this.getData();
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.error,
                                    position: 'is-bottom',
                                    type: 'is-danger',
                                });
                            });
                    },
                });
            },
        },
        watch: {
            'filters.tag': {
                handler(val) {
                    if (!this.last_page) {
                        /* val == 'all' ? query : (query.tags = val); */
                        this.getData();
                    }
                },
                deep: true,
            },
            'filters.type': {
                handler(val) {
                    if (!this.last_page) {
                        /* val == null ? query : (query.recipe_type = val); */
                        this.getData();
                    }
                },
                deep: true,
            },
        },
        beforeRouteLeave(to, from, next) {
            to.params.page = this.page;
            to.params.filters = this.filters;
            next(to.params);
        },
        beforeRouteEnter(to, from, next) {
            next((vm) => {
                if (to.params.filters) vm.filters = to.params.filters;
                if (to.params.page) vm.last_page = to.params.page;
            });
        },
        mounted() {
            if (this.last_page) this.page = this.last_page;
            this.getData();
            this.last_page = null;
        },
    };
</script>
