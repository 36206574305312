import { get, post, remove } from '@/utils/http';
const queryString = require('query-string');

export function getMyMenus(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/mymenus' + _query);
}

export function addMyMenus(data) {
    return post('/mymenus', data);
}

export function removeMyMenus(data) {
    return remove('/mymenus', data);
}
