<template>
    <section></section>
</template>

<script>
    import { activateAccount } from '@/api/apiAuth';

    export default {
        data() {
            return {
                id: null,
                t: this.$t('components.Auth.ActivateAccount'),
            };
        },
        created() {
            this.id = this.$route.params.id;
            activateAccount(this.id)
                .then((response) => {
                    this.goToPage('Login');
                    this.$buefy.toast.open({
                        message: this.t.success,
                        type: 'is-secondary',
                        duration: 4000,
                    });
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    };
</script>
