<template>
    <section>
        <TopMenu />
        <div class="main">
            <div class="card">
                <p class="title has-text-centered pt-10">{{ t.reset }}</p>
                <div class="container">
                    <b-input
                        type="password"
                        name="password"
                        ref="password"
                        :class="{ 'error-text': errors.has('password') }"
                        v-validate="'required|min:8'"
                        v-model="password"
                        password-reveal
                        size="is-small"
                        :placeholder="t.enterPassword">
                    </b-input>

                    <p
                        v-show="errors.has('password')"
                        class="help is-danger">
                        {{ t.shortPassword }}
                    </p>

                    <b-input
                        type="password"
                        v-validate="'required|confirmed:password|min:8'"
                        name="password_confirmation"
                        data-vv-as="password"
                        :class="{ 'error-text': errors.has('password_confirmation') }"
                        v-model="confirmPassword"
                        password-reveal
                        size="is-small"
                        class="mt-5"
                        :placeholder="t.confirmPassword">
                    </b-input>

                    <p
                        v-show="errors.has('password_confirmation')"
                        class="help is-danger">
                        {{ t.matchPassword }}
                    </p>

                    <b-button
                        class="login mt-5"
                        rounded
                        :loading="isLoading"
                        @click="change"
                        >{{ t.confirm }}</b-button
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import { changePassword } from '@/api/apiAuth';
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                t: this.$t('components.Auth.ResetPassword'),
                password: null,
                confirmPassword: null,
                isLoading: false,
                token: null,
            };
        },

        created() {
            this.token = this.$route.query.code;
        },

        methods: {
            change() {
                changePassword(this.token, this.password)
                    .then((response) => {
                        this.$store.commit('loginSteps/setLoginSteps', 0);
                        this.goToPage('Login');
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .main {
        padding-top: 12rem;
        margin-bottom: 30px;
    }
    .card {
        background-color: #e8e8e8;
        height: auto;
        border-radius: 48px;
        padding-bottom: 20px;
        margin-left: auto;
        margin-right: auto;
        max-width: 380px;
    }

    .container {
        padding-left: 6%;
        padding-right: 6%;
    }

    .title {
        font-size: 38px;
        font-family: 'Borest-Standard';
        font-weight: normal;
    }

    .login {
        width: 66px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }
</style>
