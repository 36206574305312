<template>
    <section>
        <TopMenu />
        <div class="main">
            <div class="card">
                <p class="title has-text-centered pt-2">{{ t.free }}</p>
                <p class="text has-text-centered">{{ t.freeText }} {{ subscriptionEnds }}</p>
                <div class="btn-container mt-6">
                    <b-button
                        class="subscribe"
                        rounded
                        @click="goToPage('SignSubscription')"
                        >{{ t.subscribe }}</b-button
                    >
                </div>
                <p
                    class="later has-text-centered has-text-white mt-2 is-clickable"
                    @click="goToPage('Homepage')">
                    {{ t.later }}
                </p>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment';
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                freeTrial: null,
                t: this.$t('components.Subscription.FreeTrial'),
            };
        },
        computed: {
            subscriptionEnds() {
                return moment.unix(this.freeTrial.trial_end).toNow(true);
            },
        },

        created() {
            this.freeTrial = this.$store.getters['freeTrial/getFreeTrial'];
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 140px;
    }

    .card {
        background-color: $primary;
        height: auto;
        border-radius: 48px;
        padding-bottom: 20px;
        max-width: $card-max-width;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
    }
    .title {
        font-size: 30px;
        font-family: 'Borest-Standard';
        font-weight: bold;
        color: white;
    }

    .text {
        font-size: 22px;
        font-family: 'Lota Grotesque';
        font-weight: normal;
    }

    .subscribe {
        width: 100px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }
    .btn-container {
        display: flex;
        justify-content: center;
    }
</style>
