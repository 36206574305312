<template>
    <section>
        <div>
            <Intolerances v-if="getPreferenceStep === 0" />
            <Metrics v-else />
        </div>
    </section>
</template>

<script>
    import Intolerances from '@/components/MySpot/Intolerances.vue';
    import Metrics from '@/components/MySpot/Metrics.vue';
    export default {
        components: {
            Intolerances,
            Metrics,
        },

        created() {},
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .column-title {
        color: $pink-variant-two;
        font-size: 0.75rem;
        font-family: 'Lota Grotesque';
        font-weight: bold;
    }

    .vertical-line {
        border-left: 1px solid #707070;
        margin-top: 15px;
        margin-bottom: 18px;
    }

    .text {
        font-size: 0.875rem;
        font-family: 'Lota Grotesque';
    }
</style>
