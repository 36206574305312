<template>
    <section>
        <TopMenu />
        <div class="main">
            <p class="title has-text-centered">{{ t.panel }}</p>

            <div class="columns is-mobile is-vcentered">
                <div
                    div
                    class="column option-card"
                    @click="goToPage('EditProfile')">
                    <div class="card-content">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-user"
                            type="is-white"
                            class="is-size-2">
                        </b-icon>
                        <p class="mt-2">{{ t.account }}</p>
                    </div>
                </div>

                <!-- <div div class="column option-card" @click="goToPage('MyOrders')">
					<div class="card-content">
						<b-icon pack="bl-icons" icon="bl-user" type="is-white" class="is-size-2"> </b-icon>
						<p class="mt-2">{{ t.orders }}</p>
					</div>
				</div> -->
            </div>

            <div class="columns is-mobile is-vcentered mt-5">
                <div
                    div
                    class="column option-card"
                    @click="goToPage('Payments')">
                    <div class="card-content">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-credit-card"
                            type="is-white"
                            class="is-size-2">
                        </b-icon>
                        <p class="mt-2">{{ t.payment }}</p>
                    </div>
                </div>
                <!-- 	<div div class="column option-card">
					<div class="card-content">
						<b-icon pack="bl-icons" icon="bl-coupon" type="is-white" class="is-size-2"> </b-icon>
						<p class="mt-2">{{ t.cupons }}</p>
					</div>
				</div> -->
            </div>

            <!-- <div class="columns is-mobile is-vcentered mt-5">
				<div div class="column option-card">
					<div class="card-content">
						<b-icon pack="bl-icons" icon="bl-bell" type="is-white" class="is-size-2"> </b-icon>
						<p class="mt-2">{{ t.notifications }}</p>
					</div>
				</div>
				<div div class="column option-card">
					<div class="card-content">
						<b-icon pack="bl-icons" icon="bl-heart" type="is-white" class="is-size-2"> </b-icon>
						<p class="mt-2">{{ t.products }}</p>
					</div>
				</div>
			</div> -->

            <div class="columns is-mobile is-vcentered mt-5">
                <div
                    div
                    class="column option-card"
                    @click="goToPage('ChangePassword')">
                    <div class="card-content">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-lock"
                            type="is-white"
                            class="is-size-2">
                        </b-icon>
                        <p class="mt-2">{{ t.password }}</p>
                    </div>
                </div>
                <!-- 	<div div class="column option-card" @click="goToPage('ContactSupport')">
					<div class="card-content">
						<b-icon pack="bl-icons" icon="bl-user" type="is-white" class="is-size-2"> </b-icon>
						<p class="mt-2">{{ t.support }}</p>
					</div>
				</div> -->
            </div>
            <div class="columns is-mobile is-vcentered mt-5 last">
                <!-- <div div class="column option-card" @click="goToPage('ChangePassword')">
					<div class="card-content">
						<b-icon pack="bl-icons" icon="bl-lock" type="is-white" class="is-size-2"> </b-icon>
						<p class="mt-2">{{ t.password }}</p>
					</div>
				</div> -->
                <div
                    div
                    class="column option-card"
                    @click="goToPage('ContactSupport')">
                    <div class="card-content">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-user"
                            type="is-white"
                            class="is-size-2">
                        </b-icon>
                        <p class="mt-2">{{ t.support }}</p>
                    </div>
                </div>
            </div>
        </div>
        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    export default {
        components: { TopMenu, BotMenu },
        data() {
            return {
                t: this.$t('components.User.Panel'),
            };
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },

        created() {
            this.scrollToTop();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 70px;
        margin-bottom: 140px;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            margin-bottom: 0px;
        }
    }

    .title {
        font-size: 39px;
        font-family: 'Borest-Standard';
        font-weight: normal;
    }

    .option-card {
        width: 110px;
        height: 141px;
        border-radius: 48px;
        background-color: $primary;
        margin-left: 12px;
        margin-right: 12px;
        display: flex;
        justify-content: center;
        cursor: pointer;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 90px;
        }
    }

    .card-content {
        text-align: center;
        margin: auto;
        font-size: 0.75rem;
        font-family: 'Lota Grotesque';
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding: 1rem;
        }
    }

    .last {
        padding-bottom: 100px;
    }

    .columns {
        @include from($tablet) {
            width: 685px;
            margin: 0 auto;
        }
    }
</style>
