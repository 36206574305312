<template>
    <section>
        <div class="modal is-active">
            <div class="modal-background"></div>
            <div class="modal-card">
                <section class="modal-card-body">
                    <button
                        @click="closeModal()"
                        class="delete"
                        aria-label="close"></button>
                    <p class="modal-card-title title has-text-primary">
                        <b>{{ t.title }}</b>
                    </p>
                    <br />
                    <div class="field email-field">
                        <label class="label">{{ t.problem }}</label>
                        <span>{{ problem }}</span>
                    </div>
                    <div class="field email-field">
                        <label class="label">{{ t.description }}</label>
                        <span>{{ description }}</span>
                    </div>
                    <div class="field email-field">
                        <label class="label">{{ t.sent_by }}</label>
                        <span>{{ email }}</span>
                    </div>
                    <div class="field email-field">
                        <label class="label">{{ created }}</label>
                        <span>{{ created }}</span>
                    </div>
                    <div
                        v-if="file"
                        class="field email-field">
                        <label class="label">{{ t.file }}</label>
                        <a
                            :href="file"
                            target="_blank"
                            >Download</a
                        >
                    </div>
                    <div class="field email-field">
                        <label class="label">{{ t.status }}</label>
                        <div class="select control">
                            <select
                                v-validate="'required'"
                                v-model="request"
                                name="status">
                                <option value="open">{{ t.open }}</option>
                                <option value="in-progress">{{ t.progress }}</option>
                                <option value="solved">{{ t.solved }}</option>
                                <option value="closed">{{ t.closed }}</option>
                            </select>
                        </div>
                        <p
                            v-show="errors.has('status')"
                            class="help is-danger">
                            Selecione um tipo de estado
                        </p>
                    </div>

                    <button
                        class="button is-rounded is-primary is-fullwidth"
                        :class="{ 'is-loading': isButtonLoading }"
                        @click="updateTicket">
                        Guardar
                    </button>
                </section>
            </div>
        </div>
    </section>
</template>

<script>
    import { updateSupport } from '@/api/support';
    export default {
        name: 'TicketsModal',
        props: ['id', 'problem', 'description', 'created', 'email', 'status', 'file'],
        data() {
            return {
                t: this.$t('components.BackOffice.Support.Modal'),
                isButtonLoading: false,
                request: this.status,
                requestDescription: '',
                inputError: false,
            };
        },
        methods: {
            closeModal(event) {
                this.$emit('clicked', false);
            },
            updateTicket() {
                updateSupport({
                    id: this.id,
                    status: this.request,
                })
                    .then((response) => {
                        this.closeModal();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .auth-div {
        bottom: 5%;
    }

    .field {
        margin-bottom: 0;
    }

    .email-field {
        position: relative;
        margin-bottom: 40px;
    }

    .button {
        margin-top: 25px;
        margin-bottom: 5px;
    }

    .next-div {
        margin-top: 25px;
    }

    .register-steps > .step-1 {
        background: #1d4696;
    }

    .select-origin {
        color: #bac3dc !important;
    }

    .select,
    select {
        width: 100% !important;
    }

    .modal-card {
        border-radius: 20px;
    }

    .delete {
        float: right;
    }

    textarea {
        resize: none;
        max-height: 80px;
    }
</style>
