<template>
    <section>
        <div class="bot-menu-default">
            <div class="icons-container">
                <div @click="goToPage('Homepage')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-home"
                        type="is-white"
                        class="is-size-4">
                    </b-icon>
                </div>
                <div @click="goToPage('Menus')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-file"
                        type="is-white"
                        class="is-size-4">
                    </b-icon>
                </div>
                <div @click="goToPage('Profile')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-user"
                        type="is-white"
                        class="is-size-4">
                    </b-icon>
                </div>
                <div @click="goToPage('MyShop')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-cesto_compras"
                        type="is-white"
                        class="is-size-4">
                    </b-icon>
                </div>
                <div @click="goToPage('MySpot')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-objective"
                        type="is-white"
                        class="is-size-4">
                    </b-icon>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    export default {};
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .bot-menu-default {
        position: fixed;
        bottom: 40px;
        height: 70px;
        background-color: $pink-variant;
        width: 100%;
        z-index: 2;
    }

    .icons-container {
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
        justify-content: space-between;
        display: flex;
        height: 50px;
        align-items: center;
        color: #f2d8e1;
    }

    /* @media only screen and (max-height: 736px) {
		.bot-menu-default {
			bottom: 100px;
		}
	} */
    @media only screen and (max-height: 660px) {
        .bot-menu-default {
            bottom: 15px;
        }
    }

    /* @media only screen and (max-height: 659px) {
		.bot-menu-default {
			bottom: 5rem;
		}
	} */

    @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
        .bot-menu-default {
            bottom: 2rem;
        }
    }
</style>
