const state = {
    activeStep: 0,
};

// getters
const getters = {
    getTrackerStep(state, getters) {
        return state.activeStep;
    },
};

// mutations
const mutations = {
    setTrackerStep(state, active) {
        state.activeStep = active;
    },

    resetTracker(state) {
        state.activeStep = 0;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
