<template>
    <div>
        <b-table
            :data="data"
            ref="table"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="_id"
            :show-detail-icon="showDetailIcon"
            paginated
            backend-pagination
            :total="total"
            per-page="10"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            default-sort="description.pt">
            <b-table-column
                field="buyer_email"
                :label="t.columns.buyer_email"
                v-slot="props">
                {{ props.row.buyer_email }}
            </b-table-column>

            <b-table-column
                field="receiver_email"
                :label="t.columns.receiver_email"
                v-slot="props">
                {{ props.row.receiver_email }}
            </b-table-column>

            <b-table-column
                field="code"
                :label="t.columns.code"
                :searchable="true">
                <template #searchable="props">
                    <b-input
                        @input="changeFilter"
                        icon="magnify"
                        size="is-small" />
                </template>
                <template v-slot="props">
                    {{ props.row.code }}
                </template>
            </b-table-column>

            <b-table-column
                field="used"
                :label="t.columns.used"
                v-slot="props">
                {{ t.status[props.row.used] }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    :disabled="props.row.used"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-pencil"
                    type="is-primary"
                    :loading="isLoading"
                    @click="openModal(props.row._id, props.row.id, props.row.used)">
                </b-button>
            </b-table-column>

            <template #detail="props">
                <article class="media">
                    <figure
                        v-if="!isMobile"
                        class="media-left">
                        <img
                            class="image is-48x48"
                            :src="props.row.product.images[0]" />
                    </figure>
                    <div class="media-content pl-8">
                        <div class="content">
                            <img
                                v-if="isMobile"
                                class="image is-48x48"
                                :src="props.row.product.images[0]" />
                            <p>{{ props.row.product.name[getLocale] }} : {{ props.row.product.unit_amount }}€</p>
                        </div>
                    </div>
                </article>
            </template>
        </b-table>
        <Modal
            v-if="isModalOpen"
            :isModalOpen="isModalOpen"
            @clicked="closeModal"
            :id="modalId"
            :gift="modalGift"
            :status="modalStatus"></Modal>
    </div>
</template>

<script>
    import { getGifts } from '@/api/gifts';
    import Modal from '@/components/BackOffice/Gifts/Modal';
    export default {
        name: 'List',
        components: {
            Modal,
        },
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Gifts.List'),
                data: [],
                total: 0,
                defaultOpenedDetails: [1],
                showDetailIcon: true,
                page: 1,
                isModalOpen: false,
                modalId: '',
                modalGift: '',
                modalStatus: '',
            };
        },
        created() {
            this.getData({
                page: this.page,
                limit: 10,
            });
        },
        methods: {
            changeFilter(text) {
                if (text != '') {
                    this.getData({
                        code: text,
                    });
                } else {
                    this.getData({
                        page: this.page,
                        limit: 10,
                    });
                }
            },
            getData(query) {
                getGifts(query)
                    .then((response) => {
                        this.data = response.data.gifts;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.data = [];
                        this.$buefy.toast.open({
                            message: this.t.api.no_data,
                            position: 'is-bottom',
                            type: 'is-danger',
                        });
                    });
            },
            onPageChange(page) {
                this.page = page;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
            openModal(id, gift, status) {
                this.modalId = id;
                this.modalGift = gift;
                this.modalStatus = status;
                this.isModalOpen = true;
            },
            closeModal(value) {
                this.isModalOpen = value;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
        },
        computed: {},
    };
</script>
<style scoped>
    .image {
        object-fit: cover;
    }

    ul {
        list-style: disc outside;
    }
</style>
