<template>
    <form
        @submit.prevent="formSubmit"
        class="columns is-mobile is-multiline">
        <b-field
            :type="invalids.includes('image') ? 'is-danger' : ''"
            class="column is-12 has-text-centered"
            :label="t.preview">
            <b-upload
                v-model="form.image"
                expanded
                drag-drop
                accept="image/*">
                <section class="section">
                    <div
                        v-if="!form.image && !form._image"
                        class="content has-text-centered">
                        <p>
                            <b-icon
                                icon="upload"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>{{ t.upload }}</p>
                    </div>
                    <div
                        v-else
                        class="content has-text-centered">
                        <img
                            class="img-preview"
                            :src="getFileURL" />
                    </div>
                </section>
            </b-upload>
        </b-field>
        <b-field
            :type="invalids.includes('title.pt') ? 'is-danger' : ''"
            class="column is-6"
            :label="t.title.pt">
            <b-input
                type="text"
                v-model="form.title.pt"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('title.en') ? 'is-danger' : ''"
            class="column is-6"
            :label="t.title.en">
            <b-input
                type="text"
                v-model="form.title.en"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('video') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.video">
            <b-input
                type="url"
                v-model="form.video"></b-input>
        </b-field>
        <b-tabs v-model="activeTab">
            <b-tab-item :label="t.short.pt">
                <b-field
                    :type="invalids.includes('description.pt') ? 'is-danger' : ''"
                    class="column is-12">
                    <quill-editor
                        ref="editor_pt"
                        class="editor has-background-white"
                        v-model="form.description.pt"
                        :options="editorOption" />
                </b-field>
            </b-tab-item>
            <b-tab-item :label="t.short.en">
                <b-field
                    :type="invalids.includes('description.en') ? 'is-danger' : ''"
                    class="column is-12">
                    <quill-editor
                        ref="editor_en"
                        class="editor has-background-white"
                        v-model="form.description.en"
                        :options="editorOption" />
                </b-field>
            </b-tab-item>
        </b-tabs>

        <div class="column is-offset-4-desktop is-4-desktop is-12-touch">
            <b-button
                expanded
                :loading="isLoading"
                class="is-headline-bold-d-d is-headline-m-m"
                type="is-primary"
                native-type="submit"
                >{{ t.submit }}</b-button
            >
        </div>
    </form>
</template>
<script>
    import { addVideo, getVideos, updateVideo } from '@/api/videos';
    import { addImage } from '@/api/posts';
    import { Quill } from 'vue-quill-editor';
    import { ImageDrop } from 'quill-image-drop-module';
    import ImageResize from 'quill-image-resize-vue';

    Quill.register('modules/imageDrop', ImageDrop);
    Quill.register('modules/imageResize', ImageResize);

    export default {
        name: 'VideosForm',
        data() {
            return {
                t: this.$t('components.BackOffice.Videos.Form'),
                activeTab: 0,
                editorOption: {
                    modules: {
                        toolbar: {
                            container: [
                                ['bold', 'italic', 'underline', 'strike'], // toggled buttons
                                ['blockquote', 'code-block'],

                                [{ header: 1 }, { header: 2 }], // custom button values
                                [{ list: 'ordered' }, { list: 'bullet' }],
                                [{ script: 'sub' }, { script: 'super' }], // superscript/subscript
                                [{ indent: '-1' }, { indent: '+1' }], // outdent/indent
                                [{ direction: 'rtl' }], // text direction

                                [{ size: ['small', false, 'large', 'huge'] }], // custom dropdown
                                [{ header: [1, 2, 3, 4, 5, 6, false] }],

                                [{ color: [] }, { background: [] }], // dropdown with defaults from theme
                                [{ font: [] }],
                                [{ align: [] }],

                                ['image', 'link'],

                                ['clean'], // remove formatting button
                            ],
                            handlers: {
                                image: () => {
                                    let input = document.createElement('input');
                                    input.setAttribute('type', 'file');
                                    input.setAttribute('accept', 'image/*');
                                    input.click();

                                    // Listen upload local image and save to server
                                    input.onchange = () => {
                                        const file = input.files[0];

                                        // file type is only image.
                                        if (/^image\//.test(file.type)) {
                                            addImage(file)
                                                .then((response) => {
                                                    let url = response.data.url;

                                                    let editor = this.$refs.editor.quill;
                                                    let range = editor.getSelection();

                                                    editor.insertEmbed(range.index, 'image', url);
                                                })
                                                .catch(() => {
                                                    this.$buefy.toast.open({
                                                        message: this.t.api.success,
                                                        position: 'is-bottom',
                                                        type: 'is-success',
                                                    });
                                                });
                                        } else {
                                            this.$buefy.toast.open({
                                                message: this.t.api.success,
                                                position: 'is-bottom',
                                                type: 'is-success',
                                            });
                                        }
                                    };
                                },
                            },
                        },
                        imageDrop: true,
                        imageResize: {
                            displayStyles: {
                                backgroundColor: 'black',
                                border: 'none',
                                color: 'white',
                            },
                        },
                    },
                },
                isUpdate: false,
                isLoading: false,
                invalids: [],
                form: {
                    title: {
                        pt: null,
                        en: null,
                    },
                    image: null,
                    video: null,
                    description: {
                        pt: null,
                        en: null,
                    },
                },
            };
        },
        created() {
            if (this.$route.name == 'UpdateVideos') {
                this.isUpdate = true;
                this.getData();
            }
        },
        mounted() {
            this.$refs['editor_pt'].quill.enable(false);
            this.$refs['editor_en'].quill.enable(false);
            setTimeout(() => {
                this.$refs['editor_pt'].quill.enable(true);
                this.$refs['editor_en'].quill.enable(true);
            }, 2000);
        },
        methods: {
            getData() {
                //used to update
                let query = {
                    id: this.$route.params.id,
                };
                getVideos(query)
                    .then((response) => {
                        let video = response.data.videos[0];

                        if (this.isUpdate) this.$emit('on-edit', video.title[this.getLocale]);

                        let doc = new DOMParser().parseFromString(video.description.pt, 'text/html');
                        video.description.pt = doc.documentElement.textContent;

                        doc = new DOMParser().parseFromString(video.description.en, 'text/html');
                        video.description.en = doc.documentElement.textContent;

                        this.form = {
                            id: video._id,
                            title: video.title,
                            video: video.video,
                            description: video.description,
                            image: null,
                            _image: video.image,
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            formSubmit() {
                this.isLoading = true;
                this.invalids = [];
                let copy = Object.clone(this.form);
                let image = this.form.image;
                delete copy.image;

                if (!this.isUpdate) {
                    addVideo(image, copy)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeVideos' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updateVideo(image, copy)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.update_success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeVideos' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
        },
        computed: {
            getFileURL() {
                if (this.form.image) {
                    let url = window.URL.createObjectURL(this.form.image);
                    return url;
                } else if (this.form._image) {
                    return this.form._image;
                }
            },
        },
    };
</script>
