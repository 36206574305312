import { get, formData, formDataPut, remove } from '@/utils/http';
const queryString = require('query-string');
const moment = require('moment');

export function addPartner(image, data) {
    let new_name = 'image.' + image.type.split('/')[1];
    let form = new FormData();
    form.append('image', image, new_name);
    form.append('data', JSON.stringify(data));
    return formData('/partners', form);
}

export function getPartners(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/partners' + _query);
}

export function updatePartner(image, data) {
    let new_name = 'image.' + image.type.split('/')[1];
    let form = new FormData();
    form.append('image', image, 'image');
    form.append('data', JSON.stringify(data));
    return formDataPut('/partners', form);
}

export function removePartner(id) {
    return remove('/partners/', { id: id });
}
