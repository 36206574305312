var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"top-menu-default"},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[(
                        this.$route.name === 'Panel' ||
                        this.$route.name === 'Profile' ||
                        this.$route.name === 'ChangePassword' ||
                        this.$route.name === 'EditProfile' ||
                        this.$route.name === 'Payments' ||
                        this.$route.name === 'Settings' ||
                        this.$route.name === 'Help' ||
                        this.$route.name === 'ContactSupport' ||
                        this.$route.name === 'EditIntolerances' ||
                        this.$route.name === 'EditMetrics' ||
                        this.$route.name === 'AppGuide' ||
                        this.$route.name === 'ToKnow' ||
                        this.$route.name === 'AboutUs' ||
                        this.$route.name === 'HowAllStarted'
                    )?_c('div',{staticClass:"is-clickable",on:{"click":function($event){return _vm.$router.back()}}},[_c('b-icon',{staticClass:"is-size-5",attrs:{"pack":"bl-icons","icon":"bl-arrow-left","type":"is-white"}})],1):_vm._e()]),_vm._m(0),_c('div',{staticClass:"media-right"},[_c('div',{staticClass:"is-clickable",on:{"click":function($event){return _vm.goToPage('Profile')}}},[(
                            this.$route.name !== 'Register' &&
                            this.$route.name !== 'Login' &&
                            this.$route.name !== 'RecoverPassword' &&
                            this.$route.name !== 'Home' &&
                            this.$route.name !== 'CompleteRegister' &&
                            this.$route.name !== 'Panel' &&
                            this.$route.name !== 'Profile' &&
                            this.$route.name !== 'ChangePassword' &&
                            this.$route.name !== 'EditProfile' &&
                            this.$route.name !== 'Payments' &&
                            this.$route.name !== 'SubscriptionExpired' &&
                            this.$route.name !== 'FreeTrial' &&
                            this.$route.name !== 'SignSubscription' &&
                            this.$route.name !== 'AddNewCard'
                        )?_c('b-icon',{staticClass:"is-size-5",attrs:{"pack":"bl-icons","icon":"bl-user","type":"is-white"}}):_vm._e()],1),(
                        this.$route.name === 'Panel' ||
                        this.$route.name === 'Profile' ||
                        this.$route.name === 'ChangePassword' ||
                        this.$route.name === 'EditProfile' ||
                        this.$route.name === 'Payments'
                    )?_c('div',{staticClass:"is-clickable",on:{"click":function($event){return _vm.goToPage('MyShop')}}},[_c('b-icon',{staticClass:"is-size-5",attrs:{"pack":"bl-icons","icon":"bl-cesto_compras","type":"is-white"}})],1):_vm._e()])])])])
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"media-content"},[_c('img',{staticClass:"logo-ag",attrs:{"src":require("../../assets/icons/logo_ana_garcez_v2.svg"),"alt":""}})])
}]

export { render, staticRenderFns }