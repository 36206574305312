var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"top-menu-default",class:_vm.myClass},[_c('div',{staticClass:"media"},[_c('div',{staticClass:"media-left"},[_c('div',{staticClass:"is-clickable",on:{"click":function($event){return _vm.goToPage('Homepage')}}},[_c('b-icon',{staticClass:"is-size-5 custom-color",attrs:{"pack":"bl-icons","icon":"bl-home"}})],1),_c('div',{staticClass:"is-clickable",on:{"click":function($event){return _vm.goToPage('Menus')}}},[_c('b-icon',{staticClass:"is-size-5 custom-color",attrs:{"pack":"bl-icons","icon":"bl-file"}})],1)]),_c('div',{staticClass:"media-content has-text-centered column is-vcentered"},[_c('p',[_vm._v(_vm._s(_vm.letter))])]),_c('div',{staticClass:"media-right"},[(
                        this.$route.name != 'MySpot' &&
                        this.$route.name != 'Menus' &&
                        this.$route.name != 'MyShop' &&
                        this.$route.name != 'Products' &&
                        this.$route.name != 'GiftCard' &&
                        this.$route.name != 'Partners' &&
                        this.$route.name != 'OurChoices' &&
                        this.$route.name != 'DailyRecipes' &&
                        this.$route.name != 'RecipeDetails' &&
                        this.$route.name != 'Charts' &&
                        this.$route.name != 'Search'
                    )?_c('div',{staticClass:"is-clickable",on:{"click":function($event){return _vm.goToPage('MyShop')}}},[_c('b-icon',{staticClass:"is-size-5 custom-color",attrs:{"pack":"bl-icons","icon":"bl-cesto_compras"}})],1):_vm._e(),(this.$route.name != 'Tracker' && this.$route.name != 'TrackerInfo')?_c('div',[_c('div',{staticClass:"is-clickable",on:{"click":function($event){return _vm.goToPage('Profile')}}},[_c('b-icon',{staticClass:"is-size-5 custom-color",attrs:{"pack":"bl-icons","icon":"bl-user"}})],1)]):_vm._e(),_c('div',{staticClass:"is-clickable",on:{"click":function($event){return _vm.goToPage('MySpot')}}},[_c('b-icon',{staticClass:"is-size-5 custom-color",attrs:{"pack":"bl-icons","icon":"bl-objective"}})],1)])])])])
}
var staticRenderFns = []

export { render, staticRenderFns }