<template>
    <DailyRecipes />
</template>

<script>
    import DailyRecipes from '@/components/Recipes/DailyRecipes.vue';
    export default {
        components: {
            DailyRecipes,
        },
    };
</script>
