<template>
    <div>
        <b-table
            :data="data"
            ref="table"
            class="coupons-table"
            paginated
            backend-pagination
            :total="total"
            per-page="10"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            default-sort="name">
            <b-table-column
                field="name"
                :label="t.columns.name"
                v-slot="props">
                {{ props.row.metadata.colaborator }}
            </b-table-column>

            <b-table-column
                field="code"
                :label="t.columns.code"
                v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column
                field="percent"
                :label="t.columns.percent"
                v-slot="props">
                {{ props.row.percent_off }}
            </b-table-column>

            <b-table-column
                field="usage"
                :label="t.columns.usage"
                v-slot="props">
                {{ props.row.times_redeemed }}
            </b-table-column>

            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-delete"
                    type="is-danger"
                    :loading="isLoading"
                    @click="deleteCoupon(props.row.id)"></b-button>
            </b-table-column>
        </b-table>
    </div>
</template>

<script>
    import { removeCoupon, getCoupons } from '@/api/coupons';
    export default {
        name: 'List',
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Coupons.List'),
                data: [],
                total: 0,
                page: 1,
            };
        },
        created() {
            this.getData({});
        },
        methods: {
            getData(query) {
                getCoupons(query)
                    .then((response) => {
                        this.data = response.data.coupons.data;
                        let total = this.data.length;
                        total == 10 && response.data.coupons.has_more ? total++ : total;
                        if (this.page > 1) {
                            total += 10 * (this.page - 1);
                        }
                        this.total = total;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.data = [];
                        this.$buefy.toast.open({
                            message: this.t.api.no_data,
                            position: 'is-bottom',
                            type: 'is-danger',
                        });
                    });
            },
            onPageChange(page) {
                this.page = page;
                let query = {};

                if (page != 1) query.start = this.data[this.data.length - 1].id;
                this.getData(query);
            },
            deleteCoupon(id) {
                this.$buefy.dialog.confirm({
                    title: this.t.dialog.title,
                    message: this.t.dialog.message,
                    confirmText: this.t.dialog.confirm,
                    cancelText: this.t.dialog.cancel,
                    type: 'is-danger',
                    hasIcon: true,
                    onConfirm: () => {
                        this.isLoading = true;
                        removeCoupon(id)
                            .then((response) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.success,
                                    position: 'is-bottom',
                                    type: 'is-success',
                                });
                                this.getData();
                            })
                            .catch((error) => {
                                this.isLoading = false;
                                this.$buefy.toast.open({
                                    message: this.t.api.error,
                                    position: 'is-bottom',
                                    type: 'is-danger',
                                });
                            });
                    },
                });
            },
        },
        computed: {},
    };
</script>
<style lang="scss" scoped>
    .logo {
        height: 4rem;
    }
</style>
