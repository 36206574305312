<template>
    <section>
        <TopMenu v-if="$route.name === 'EditIntolerances'" />
        <div
            class="columns is-mobile main"
            :class="{ 'padding-top': $route.name === 'EditIntolerances' }">
            <div class="column">
                <p class="column-title has-text-centered mb-12 mt-3">{{ this.t.mealType }}</p>

                <b-field
                    v-for="(value, name, i) in meals"
                    :key="100 + i">
                    <b-radio
                        v-if="name !== 'all' && name !== 'athlete' && name !== 'geriatrics'"
                        class="text checkbox"
                        :native-value="name"
                        type="is-secondary"
                        v-model="checkedMeals[0]"
                        >{{ value }}
                    </b-radio>
                </b-field>
            </div>
            <div class="vertical-line"></div>
            <div class="column">
                <p class="column-title has-text-centered mb-12 mt-3">{{ this.t.dontLike }}</p>

                <b-field
                    v-for="(value, intolerance, i) in intolerances"
                    :key="200 + i">
                    <b-checkbox
                        v-if="intolerance !== 'all'"
                        :native-value="intolerance"
                        class="text"
                        type="is-secondary"
                        v-model="checkedIntolerances"
                        >{{ value }}
                    </b-checkbox>
                </b-field>
            </div>
        </div>
        <p
            v-if="intolerancesError"
            class="has-text-centered has-text-secondary mb-2">
            {{ t.selectMeal }}
        </p>
        <div class="center-inside mb-3">
            <b-button
                v-if="$route.name === 'MyPreferences'"
                type="is-primary"
                class="edit"
                rounded
                @click="next"
                :loading="isLoading"
                >{{ t.next }}</b-button
            >
            <b-button
                v-else
                type="is-primary"
                :loading="isLoading"
                class="edit"
                @click="update"
                rounded
                >{{ t.save }}</b-button
            >
        </div>
    </section>
</template>

<script>
    import { updatePreferences } from '@/api/apiUser';
    import { getLoggedUser } from '@/api/apiAuth';
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                t: this.$t('components.MySpot.MyPreferences'),
                checkedMeals: [],
                checkedIntolerances: [],
                meals: this.$t('shared.tags'),
                intolerances: this.$t('shared.intolerances'),
                intolerancesError: false,
                countMeals: 0,
                countIntolerances: 0,
                isLoading: false,
            };
        },

        created() {
            this.scrollToTop();
            if (this.$route.name === 'EditIntolerances') {
                this.checkedMeals = this.getMeals;
                this.checkedIntolerances = this.getIntolerances;
            }

            for (const key in this.meals) {
                this.countMeals++;
            }

            for (const key in this.meals) {
                this.countIntolerances++;
            }
        },

        methods: {
            next() {
                this.isLoading = true;
                if (this.checkedMeals) {
                    this.intolerancesError = false;

                    const resultIntolerances = this.checkedIntolerances.filter((intolerance) => intolerance !== 'all');
                    this.checkedIntolerances = resultIntolerances;

                    this.updatePreferenceStep(1);
                    this.updateIntolerances(this.checkedIntolerances);
                    this.updateMeals(this.checkedMeals);
                } else {
                    this.intolerancesError = true;
                    this.isLoading = false;
                }
            },

            update() {
                if (this.checkedMeals) {
                    this.isLoading = true;
                    this.intolerancesError = false;

                    /* const resultMeals = this.checkedMeals.filter((meal) => meal !== 'all');
					this.checkedMeals = resultMeals; */

                    const resultIntolerances = this.checkedIntolerances.filter((intolerance) => intolerance !== 'all');
                    this.checkedIntolerances = resultIntolerances;

                    let data = {
                        info: {
                            meals: this.checkedMeals,
                            intolerances: this.checkedIntolerances,
                        },
                    };

                    updatePreferences(null, data)
                        .then((response) => {
                            getLoggedUser()
                                .then((response) => {
                                    this.updateIntolerances(this.checkedIntolerances);
                                    this.updateMeals(this.checkedMeals);
                                    this.infoReceived = response.data.body;
                                    this.addUser(this.infoReceived);
                                    this.isLoading = false;
                                    this.$buefy.toast.open({
                                        message: this.t.changesSaved,
                                        type: 'is-secondary',
                                    });
                                    this.goToPage('Profile');
                                })
                                .catch((error) => {});
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            if (error.response.data.code == 'WithoutRecipes') {
                                this.$buefy.toast.open({
                                    message: this.t.error,
                                    type: 'is-secondary',
                                    duration: 4000,
                                });
                            }
                        });
                } else {
                    this.isLoading = false;
                    this.intolerancesError = true;
                }
            },
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .padding-top {
        padding-top: 100px;
    }

    .column-title {
        color: $pink-variant-two;
        font-size: 0.75rem;
        font-family: 'Lota Grotesque';
        font-weight: bold;
    }

    .vertical-line {
        border-left: 1px solid #707070;
        margin-top: 15px;
        margin-bottom: 18px;
    }

    .text {
        font-size: 0.875rem;
        font-family: 'Lota Grotesque';
    }
</style>
