import { post, get, formData, formDataPut, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addNew(image, data) {
    let form = new FormData();
    if (image) {
        let new_name = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name);
    }
    form.append('data', JSON.stringify(data));
    return formData('/news', form);
}

export function addImage(image) {
    let new_name = 'image.' + image.type.split('/')[1];
    let form = new FormData();
    form.append('image', image, new_name);
    return formData('/images', form);
}

export function getNews(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/news' + _query);
}

export function updateNew(image, data) {
    let form = new FormData();
    if (image) {
        let new_name = 'image.' + image.type.split('/')[1];
        form.append('image', image, new_name);
    } else {
        form.append('image', null);
    }
    form.append('data', JSON.stringify(data));
    return formDataPut('/news', form);
}

export function removeNew(id) {
    return remove('/news', { id: id });
}
