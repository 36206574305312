<template>
    <div>
        <b-table
            :data="data"
            ref="table"
            :opened-detailed="defaultOpenedDetails"
            detailed
            detail-key="_id"
            :show-detail-icon="showDetailIcon"
            paginated
            backend-pagination
            :total="total"
            per-page="10"
            @page-change="onPageChange"
            aria-next-label="Next page"
            aria-previous-label="Previous page"
            aria-page-label="Page"
            aria-current-label="Current page"
            default-sort="description.pt">
            <b-table-column
                field="id"
                :label="t.columns.id"
                v-slot="props">
                {{ props.row.id }}
            </b-table-column>

            <b-table-column
                field="client"
                :label="t.columns.client"
                v-slot="props">
                {{ props.row.customer.info.name + ' ' + props.row.customer.info.last_name }}
            </b-table-column>

            <b-table-column
                field="amount"
                :label="t.columns.amount"
                v-slot="props">
                {{ props.row.amount }}€
            </b-table-column>

            <b-table-column
                field="status"
                :label="t.columns.status"
                v-slot="props">
                {{ t.status[props.row.status] }}
            </b-table-column>
            <b-table-column
                v-slot="props"
                width="5">
                <b-button
                    :disabled="props.row.status == 'unpaid'"
                    class="level-right"
                    size="is-small"
                    icon-right="mdi mdi-pencil"
                    type="is-primary"
                    :loading="isLoading"
                    @click="openModal(props.row._id, props.row.id, props.row.status)">
                </b-button>
            </b-table-column>

            <template #detail="props">
                <!-- <article class="media" v-for="(item, i) in props.row.items" :key="i"> -->
                <article class="media">
                    <!-- <figure v-if="!isMobile" class="media-left">
						<img class="image is-48x48" :src="item.product.images[0]" />
					</figure> -->
                    <div class="media-content pl-8">
                        <ul>
                            <li
                                v-for="(item, i) in props.row.items"
                                :key="i">
                                {{ item.product.name[getLocale] }} : {{ item.quantity }} x {{ item.product.unit_amount }}€
                            </li>
                        </ul>
                        <!-- <div class="content">
							<img v-if="isMobile" class="image is-48x48" :src="item.product.images[0]" />
							<p >{{ item.product.name[getLocale] }} : {{ item.quantity }} x {{ item.product.unit_amount }}€</p>
							<div class="columns is-multiline">
								<p class="column is-6-desktop is-12-mobile">{{ item.product.name[getLocale] }}</p>
								<p class="column is-6-desktop is-12-mobile">{{ item.quantity }} x {{ item.product.unit_amount }} €</p>
							</div>
						</div> -->
                    </div>
                </article>
            </template>
        </b-table>
        <Modal
            v-if="isModalOpen"
            :isModalOpen="isModalOpen"
            @clicked="closeModal"
            :id="modalId"
            :order="modalOrder"
            :status="modalStatus"></Modal>
    </div>
</template>

<script>
    import { getOrders } from '@/api/orders';
    import Modal from '@/components/BackOffice/Orders/Modal';
    export default {
        name: 'List',
        components: {
            Modal,
        },
        data() {
            return {
                isLoading: false,
                t: this.$t('components.BackOffice.Orders.List'),
                data: [],
                total: 0,
                defaultOpenedDetails: [1],
                showDetailIcon: true,
                page: 1,
                isModalOpen: false,
                modalId: '',
                modalOrder: '',
                modalStatus: '',
            };
        },
        created() {
            this.getData({
                page: this.page,
                limit: 10,
            });
        },
        methods: {
            getData(query) {
                getOrders(query)
                    .then((response) => {
                        this.data = response.data.orders;
                        this.total = response.data.count;
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        this.data = [];
                        this.$buefy.toast.open({
                            message: this.t.api.no_data,
                            position: 'is-bottom',
                            type: 'is-danger',
                        });
                    });
            },
            onPageChange(page) {
                this.page = page;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
            openModal(id, order, status) {
                this.modalId = id;
                this.modalOrder = order;
                this.modalStatus = status;
                this.isModalOpen = true;
            },
            closeModal(value) {
                this.isModalOpen = value;
                this.getData({
                    page: this.page,
                    limit: 10,
                });
            },
        },
        computed: {},
    };
</script>
<style scoped>
    .image {
        object-fit: cover;
    }

    ul {
        list-style: disc outside;
    }
</style>
