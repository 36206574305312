<template>
    <section>
        <div
            class="bot-menu-white"
            :class="{
                'profile-page-background': $route.name == 'Profile',
                'orders-page-background': $route.name == 'MyOrders' || $route.name == 'OrderDetails',
            }">
            <p
                style="transform: translateY(6px)"
                v-if="
                    $route.name != 'MyOrders' &&
                    $route.name != 'OrderDetails' &&
                    $route.name != 'Help' &&
                    $route.name != 'EditProfile' &&
                    $route.name != 'Panel' &&
                    $route.name != 'Settings' &&
                    $route.name != 'ContactSupport' &&
                    $route.name != 'Payments'
                "
                class="has-text-white has-text-centered mb-3"
                @click="logout">
                {{ t.logout }}
            </p>
            <div class="icons-container">
                <div @click="goToPage('Homepage')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-home"
                        class="is-size-4 profile-menu"
                        :class="{ 'icon-color': $route.name != 'Profile' && $route.name != 'MyOrders' && $route.name != 'OrderDetails' }">
                    </b-icon>
                </div>
                <div @click="goToPage('MySpot')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-objective"
                        class="is-size-4 profile-menu"
                        :class="{ 'icon-color': $route.name != 'Profile' && $route.name != 'MyOrders' && $route.name != 'OrderDetails' }">
                    </b-icon>
                </div>

                <b-icon
                    icon="magnify"
                    class="is-size-4 profile-menu"
                    :class="{ 'icon-color': $route.name != 'Profile' && $route.name != 'MyOrders' && $route.name != 'OrderDetails' }"
                    @click.native="goToPage('Search')">
                </b-icon>
                <div @click="goToPage('MyShop')">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-cesto_compras"
                        class="is-size-4 profile-menu"
                        :class="{ 'icon-color': $route.name != 'Profile' && $route.name != 'MyOrders' && $route.name != 'OrderDetails' }">
                    </b-icon>
                </div>
                <b-icon
                    pack="bl-icons"
                    icon="bl-user"
                    class="is-size-4 profile-menu"
                    :class="{
                        opacity:
                            $route.name == 'Panel' ||
                            $route.name == 'EditProfile' ||
                            $route.name == 'Profile' ||
                            $route.name == 'Payments' ||
                            $route.name == 'MyOrders' ||
                            $route.name == 'OrderDetails' ||
                            $route.name == 'Help' ||
                            $route.name == 'Settings' ||
                            $route.name == 'ContactSupport',

                        'icon-color':
                            $route.name == 'Profile' ||
                            $route.name == 'Panel' ||
                            $route.name == 'EditProfile' ||
                            $route.name == 'Payments' ||
                            $route.name == 'MyOrders' ||
                            $route.name == 'OrderDetails' ||
                            $route.name == 'Help' ||
                            $route.name == 'Settings' ||
                            $route.name == 'ContactSupport',
                    }">
                </b-icon>
            </div>
        </div>
    </section>
</template>

<script>
    import { logout } from '@/api/apiAuth';
    export default {
        data() {
            return {
                t: this.$t('components.Menu.BottomWhiteMenu'),
            };
        },
        methods: {
            logout() {
                logout()
                    .then((response) => {
                        this.onSessionExpired();
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .bot-menu-white {
        position: fixed;
        bottom: 0;
        color: $primary;
        width: 100%;
        background-color: white;
        border-top: 2px solid #bfd9d1;
        border-bottom: none;
        height: 160px;
        border-radius: 48px;
        transform: translateY(87px);
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 136px;
        }
    }

    .profile-page-background {
        background-color: $primary;
        border: none;
    }

    .orders-page-background {
        background-color: #e8e8e8;
        border: none;
    }

    .profile-menu {
        color: white;
        opacity: 0.7;
    }

    .icons-container {
        padding-left: 5%;
        padding-right: 5%;
        width: 100%;
        justify-content: space-between;
        display: flex;
        align-items: center;
        margin-top: 20px;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 25px;
            margin-top: 10px;
        }
    }

    .logo-ag {
        width: 55px;
        transform: translateY(7px);
    }

    .icon-color {
        color: black;
        opacity: 0.1;
    }

    .opacity {
        opacity: 1;
    }

    p {
        font-family: 'Lota Grotesque';
        font-size: 0.875rem;
        cursor: pointer;
    }
    .icons-container div {
        cursor: pointer;
    }
</style>
