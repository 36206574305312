<template>
    <section>
        <TopMenu />
        <div class="container">
            <div class="field">
                <b-field class="file center-inside">
                    <b-upload
                        v-model="img"
                        class="file-label"
                        accept="image/*"
                        @input="edit">
                        <div class="file-cta center-inside">
                            <img
                                class="profile-photo"
                                v-if="imgReceived && !img"
                                :src="imgReceived" />
                            <img
                                class="profile-photo"
                                v-else-if="img"
                                :src="getFileURL" />
                            <b-icon
                                v-else
                                pack="bl-icons"
                                icon="bl-user"
                                type="is-black"
                                class="is-size-1">
                            </b-icon>
                        </div>
                    </b-upload>
                </b-field>
            </div>

            <div class="profile-card">
                <div class="media">
                    <div class="media-left"></div>
                    <div class="media-content center-inside">
                        <b-upload
                            v-model="img"
                            class="file-label"
                            accept="image/*"
                            @input="edit"
                            ><p class="change-label">{{ t.changePhoto }}</p></b-upload
                        >
                    </div>
                    <div
                        class="media-right"
                        @click="$router.back()">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-black"
                            class="is-size-4">
                        </b-icon>
                    </div>
                </div>

                <div class="has-text-centered name">
                    <p>{{ info.info.name }} {{ info.info.last_name }}</p>
                </div>
                <!-- <div class="has-text-centered city"><p>PORTO, PORTUGAL</p></div> -->

                <div class="info">
                    <div class="media pt-2">
                        <div class="media-left">
                            <p class="label">{{ t.genre }}:</p>
                            <b-select
                                v-if="isEdit"
                                :class="{ 'error-text': errors.has('genre') }"
                                v-validate="'required'"
                                size="is-small"
                                name="genre"
                                v-model="genre"
                                expanded>
                                <option
                                    :value="gender.value"
                                    v-for="(gender, value, index) in g"
                                    :key="index">
                                    {{ gender.text }}
                                </option>
                            </b-select>
                            <p
                                v-else
                                class="user-info">
                                {{ getGender(genre).text }}
                            </p>
                        </div>
                        <div class="media-content"></div>
                        <div class="media-right">
                            <p class="label">{{ t.birthday }}:</p>
                            <b-datepicker
                                v-if="isEdit"
                                v-model="birthday"
                                :icon-right="birthday ? 'close-circle' : ''"
                                icon-right-clickable
                                @icon-right-click="clearDate"
                                trap-focus
                                :max-date="maxDate"
                                size="is-small"
                                class="datepicker">
                            </b-datepicker>
                            <p
                                v-else
                                class="user-info">
                                {{ formattedDate }}
                            </p>
                        </div>
                    </div>
                </div>

                <div class="info">
                    <div class="media pt-2">
                        <div class="media-left">
                            <p class="label">{{ t.email }}:</p>
                            <b-input
                                v-if="isEdit"
                                :class="{ 'error-text': errors.has('email') }"
                                class="field has-text-centered"
                                type="email"
                                v-validate="'required|email'"
                                name="email"
                                v-model="email"
                                size="is-small"
                                ref="email" />
                            <p
                                v-else
                                class="user-info">
                                {{ email }}
                            </p>
                        </div>
                        <div class="media-content"></div>
                        <div class="media-right"></div>
                    </div>
                </div>

                <div class="info">
                    <div class="media pt-2">
                        <div class="media-left">
                            <p class="label">{{ t.nif }}:</p>
                            <b-input
                                v-if="isEdit"
                                :class="{ 'error-text': errors.has('email') }"
                                class="field has-text-centered"
                                v-validate="'required|min:9'"
                                name="nif"
                                v-model="nif"
                                size="is-small"
                                ref="email"
                                type="number" />
                            <p
                                v-else
                                class="user-info">
                                {{ nif }}
                            </p>
                            <div class="mt-4">
                                <b-button
                                    v-if="!isEdit"
                                    @click="edit"
                                    class="edit"
                                    rounded
                                    >{{ t.edit }}</b-button
                                >
                                <b-button
                                    v-else
                                    @click="save"
                                    :loading="isLoading"
                                    type="is-primary"
                                    class="edit"
                                    rounded
                                    >{{ t.save }}</b-button
                                >
                            </div>
                        </div>
                        <div class="media-content"></div>
                        <div class="media-right"></div>
                    </div>
                </div>
                <p
                    v-show="errors.has('email')"
                    class="help is-danger has-text-centered">
                    {{ t.validEmail }}
                </p>
                <p
                    v-show="errors.has('nif')"
                    class="help is-danger has-text-centered">
                    {{ t.validNif }}
                </p>
            </div>
        </div>

        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    import moment from 'moment';
    import { updatePreferences } from '@/api/apiUser';
    import { getLoggedUser } from '@/api/apiAuth';

    export default {
        components: { TopMenu, BotMenu },
        data() {
            return {
                t: this.$t('components.User.EditProfile'),
                g: this.$t('shared.genders'),
                img: null,
                imgReceived: null,
                info: null,
                genre: null,
                email: null,
                nif: null,
                birthday: null,
                isEdit: false,
                maxDate: new Date(),
                isLoading: false,
            };
        },
        computed: {
            getFileURL() {
                if (this.img) {
                    return window.URL.createObjectURL(this.img);
                }
            },
            formattedDate() {
                return moment(this.birthday).format('DD-MM-YYYY');
            },
        },
        created() {
            this.info = this.getUser;
            this.genre = this.info.info.genre;
            this.email = this.info.auth.email;
            this.nif = this.info.info.nif;
            this.birthday = new Date(this.info.info.birthday);
            this.imgReceived = this.info.info.image;
        },
        methods: {
            edit() {
                this.isEdit = true;
            },

            save() {
                this.isLoading = true;

                this.$validator.validateAll().then((result) => {
                    if (result) {
                        let data = {
                            auth: {
                                email: this.email,
                            },
                            info: {
                                genre: this.genre,
                                nif: this.nif,
                                birthday: this.birthday,
                            },
                        };
                        updatePreferences(this.img, data)
                            .then((response) => {
                                this.$buefy.toast.open({
                                    message: this.t.success,
                                    type: 'is-primary',
                                });
                                getLoggedUser()
                                    .then((response) => {
                                        this.infoReceived = response.data.body;
                                        this.addUser(this.infoReceived);
                                    })
                                    .catch((error) => {});
                            })
                            .catch((error) => {
                                if (error.response.data.code == 'InvalidImage') {
                                    this.$buefy.toast.open({
                                        message: this.t.errorImage,
                                        type: 'is-secondary',
                                    });
                                } else {
                                    this.$buefy.toast.open({
                                        message: this.t.error,
                                        type: 'is-secondary',
                                    });
                                }
                            })
                            .finally(() => {
                                this.isLoading = false;
                                this.isEdit = false;
                            });
                    }
                });
            },
            clearDate() {
                this.birth = null;
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        margin-bottom: 200px;
        padding: 1rem;
        @include from($tablet) {
            width: 70%;
            margin: 0 auto;
        }
    }

    .container {
        padding-top: 40px;
        @include from($tablet) {
            padding-bottom: 200px;
        }
    }

    .field {
        transform: translateY(25%);
    }

    .file-cta {
        width: 138px;
        height: 138px;
        border-radius: 138px;
        margin: 0 auto;
        padding: 0;
    }

    .profile-photo {
        border-radius: 50%;
        width: 138px;
        height: 138px;
        object-fit: cover;
    }

    .media {
        padding-top: 30px;
        width: 100%;
    }

    .change-label {
        font-family: 'Lota Grotesque';
        font-size: 0.9rem;
        text-align: center;
        margin-top: 10px;
        margin-left: 30px;
    }

    .name {
        margin-top: 20px;
        font-family: 'Reftograph';
        font-size: 50px;
    }

    .city {
        font-family: 'Lota Grotesque';
        font-size: 0.75rem;
    }

    .label {
        font-family: 'Lota Grotesque';
        font-size: 0.9rem;
        font-weight: bold;
    }

    .user-info {
        font-family: 'Lota Grotesque';
        font-size: 0.9rem;
    }

    .info {
        padding-left: 14%;
        padding-top: 6%;
        padding-right: 14%;
    }

    .edit {
        width: 89px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }

    @media only screen and (min-width: 325px) and (max-width: 425px) {
        .container {
            padding-bottom: 10px;
        }
    }
</style>
