import Vue from 'vue';
import App from './App.vue';
import './registerServiceWorker';
import router from './router';
import store from './store';
import Buefy from 'buefy';
import i18n from './i18n';
import VueCookies from 'vue-cookies';
import VeeValidate from 'vee-validate';
import HighchartsVue from 'highcharts-vue';
import VueSocialSharing from 'vue-social-sharing';
import VueMeta from 'vue-meta';

import './assets/scss/spacing.scss';
import './assets/scss/icons.scss';

Vue.use(Buefy, {
    customIconPacks: {
        bl: {
            iconPrefix: 'bl-',
            sizes: {
                default: 'bl-24px',
                'is-small': '',
                'is-medium': 'bl-36px',
                'is-large': 'bl-48px',
            },
        },
    },
});

//Validate
Vue.use(VeeValidate);

//Charts
Vue.use(HighchartsVue);

// Cookies
Vue.use(VueCookies);
VueCookies.config('31d');

import { _Object } from '@iotechpis/utils';
_Object;

import moment from 'moment';

moment.locale(store.getters['language/getLanguage']);
Vue.prototype.moment = moment;

import VueQuillEditor from 'vue-quill-editor';

import 'quill/dist/quill.core.css'; // import styles
import 'quill/dist/quill.snow.css'; // for snow theme
import 'quill/dist/quill.bubble.css'; // for bubble theme
Vue.use(VueQuillEditor);

import './mixins/index';

Vue.config.productionTip = false;

//Requires Auth
import requiresAuth from './utils/requiresAuth';
requiresAuth();

//Stripe Options
import { StripePlugin } from '@vue-stripe/vue-stripe';
const options = {
    pk: `${process.env.VUE_APP_STRIPE_PUBLISHABLE_KEY}`,
    stripeAccount: process.env.VUE_APP_STRIPE_ACCOUNT,
    /* 	apiVersion: process.env.VUE_APP_API_VERSION, */
    locale: store.getters['language/getLanguage'],
};

Vue.use(StripePlugin, options);

//Touch Events
import Vue2TouchEvents from 'vue2-touch-events';
Vue.use(Vue2TouchEvents);

//Social Sharing
Vue.use(VueSocialSharing);

//Vue meta-tags
Vue.use(VueMeta);

import vueDebounce from 'vue-debounce';

Vue.use(vueDebounce);

new Vue({
    router,
    store,
    i18n,
    render: (h) => h(App),
}).$mount('#app');
