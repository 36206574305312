import store from '@/store/index';

export default {
    computed: {
        getCheckedMeals() {
            return store.getters['searchRecipes/getCheckedMeals'];
        },
    },
    methods: {
        setCheckedMeals(meals) {
            store.commit('searchRecipes/setCheckedMeals', meals);
        },
    },
};
