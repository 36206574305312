<template>
    <section>
        <TopMenu />
        <div class="main">
            <p
                class="has-text-centered is-size-2 mt-20 has-text-primary"
                :style="{ 'font-family': this.changeFont }">
                {{ t.about }}
            </p>

            <p
                class="has-text-primary is-size-6 mt-8 has-text-centered"
                v-html="t.text"></p>
        </div>
        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomDefaultMenu.vue';
    export default {
        components: {
            TopMenu,
            BotMenu,
        },
        data() {
            return {
                t: this.$t('views.AboutUs'),
            };
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },

        created() {
            this.scrollToTop();
        },
    };
</script>

<style lang="scss" scoped>
    .main p {
        font-family: 'Lota Grotesque';
    }
</style>
