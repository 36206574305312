<template>
    <section>
        <TopMenu />

        <div class="menu-header">
            <b-icon
                pack="bl-icons"
                @click.native="goToPage('Homepage')"
                style="position: absolute; left: 10px"
                icon="bl-arrow-left"
                type="is-white"
                class="is-size-5">
            </b-icon>
            <p>Menus</p>
        </div>

        <div class="main">
            <Content />
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import Content from '@/components/Menus/MenusContent.vue';

    export default {
        components: {
            TopMenu,
            Content,
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .menu-header {
        position: fixed;
        height: 101px;
        background-color: $secondary;
        top: 84px;
        width: 100%;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        z-index: 20;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 58px;
            top: 53px;
        }
    }

    .menu-header p {
        color: white;
        font-size: 67px;
        font-family: 'Borest-Standard';
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            font-size: 30px;
        }
    }

    .main {
        padding-top: 170px;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 88px;
        }
    }

    @media print {
        .menu-header {
            visibility: hidden;
        }
    }
</style>
