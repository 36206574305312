<template>
    <div class="home">
        <Auth />
    </div>
</template>

<script>
    import Auth from '@/components/Auth/Auth.vue';
    export default {
        name: 'Home',
        components: {
            Auth,
        },
        data() {
            return {};
        },
    };
</script>
