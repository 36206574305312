const state = {
    isMobile: window.innerWidth <= 1023,
    width: window.innerWidth,
    isSidebarOpen: false,
    isPageLoading: false,
};

const getters = {
    isMobile(state, getters) {
        return state.isMobile;
    },
    isSidebarOpen(state) {
        return state.isSidebarOpen;
    },

    isPageLoading(state) {
        return state.isPageLoading;
    },
};

const mutations = {
    updateWidth(state, width) {
        state.isMobile = width <= 1023;
        state.width = width;
    },
    setSidebarOpen(state, bol) {
        state.isSidebarOpen = bol;
    },

    setIsPageLoading(state, bol) {
        state.isPageLoading = bol;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
