<template>
    <section class="grey">
        <div class="media">
            <div class="media-left">
                <div
                    @click="$router.back()"
                    class="icons">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-arrow-left"
                        type="is-black"
                        class="is-size-4">
                    </b-icon>
                </div>
            </div>
            <div class="media-content has-text-centered column is-vcentered">
                <p class="header-text">{{ t.details }}</p>
            </div>
            <div class="media-right">
                <div
                    @click="goToPage('MyShop')"
                    class="icons">
                    <b-icon
                        pack="bl-icons"
                        icon="bl-cesto_compras"
                        type="is-black"
                        class="is-size-4">
                    </b-icon>
                </div>
            </div>
        </div>

        <div class="main mt-6">
            <div class="id-container">
                <b-icon
                    pack="bl-icons"
                    icon="bl-cesto_compras"
                    type="is-primary"
                    class="is-size-4">
                </b-icon>
                <div class="ml-6">
                    <p>AG/30912</p>
                    <p class="date-text">2-09-2021</p>
                </div>
            </div>

            <div class="info-card mt-6">
                <p class="mt-3 ml-10 info-title">{{ t.method }}</p>
                <p class="mt-3 ml-10 info-text">MB Way</p>
            </div>

            <div class="info-card mt-6">
                <p class="mt-3 ml-10 info-title">{{ t.address }}</p>
                <p class="mt-3 ml-10 info-text">Margarida Ferreira Av. Infante Dom Henriques, 1090 Vila Nova de Gaia Portugal</p>
            </div>

            <div class="info-product mt-6">
                <div style="display: flex">
                    <img
                        class="ml-10 mt-6"
                        src="https://img.wook.pt/images/refeicoes-saudaveis-todos-os-dias-ana-garcez/MXwyMzQxNDg5MHwxOTM3ODQ0MXwxNTcwMDU3MjAwMDAw/175x"
                        alt="" />
                    <div class="mt-6 ml-3">
                        <p class="info-title">“Refeições Saudáveis”</p>
                        <p class="info-text">Ana Garcez - Book</p>

                        <div
                            class="mt-6"
                            style="display: flex">
                            <p class="info-text">Quantity: 1</p>
                            <p class="info-title ml-12">€19.25</p>
                        </div>
                    </div>
                </div>
                <b-button
                    class="ml-10 mt-3 comment"
                    rounded
                    >Write comment</b-button
                >
            </div>

            <div>
                <span class="info-title mt-6 total">Total: <span class="value">€19.25</span> </span>
            </div>
        </div>

        <BotMenu />
    </section>
</template>

<script>
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';
    export default {
        components: {
            BotMenu,
        },
        data() {
            return {
                sent: false,
                t: this.$t('components.Orders.OrderDetails'),
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .grey {
        background-color: #e8e8e8;
        padding-bottom: 10%;
        @include until($tablet) {
            padding-bottom: 150px;
        }
    }
    .media {
        padding-left: 5%;
        padding-right: 5%;
    }

    .media-left,
    .media-right {
        height: 80px;
        align-items: center;
        display: flex;
    }

    .header-text {
        font-family: 'Borest-Standard';
        font-size: 2.3rem;
        @include until($mobile-s) {
            font-size: 1.9rem;
        }
    }

    .icons {
        cursor: pointer;
    }

    .id-container {
        display: flex;
    }

    .main p {
        font-family: 'Lota Grotesque';
        font-size: 1rem;
    }

    .date-text {
        font-size: 0.8rem !important;
        color: #b4b4b4;
    }

    .info-card {
        margin: 0 auto;
        border: solid 1px black;
        height: 140px;
        border-radius: 48px;
        @include from($mobile-l) {
            max-width: $card-max-width;
            margin: 0 auto;
        }
    }

    .info-title {
        font-size: 0.9rem !important;
        font-weight: bold;
    }

    .info-text {
        font-size: 0.8rem !important;
    }

    .info-product {
        margin: 0 auto;
        border: solid 1px black;
        height: 200px;
        border-radius: 48px;
        @include from($mobile-l) {
            max-width: $card-max-width;
            margin: 0 auto;
        }
    }

    img {
        max-width: 20%;
    }

    .comment {
        width: 80%;
        font-family: 'Lota Grotesque';
        font-size: 1rem;
        background-color: #bfd9d1;
        height: 30px;
    }

    .total {
        justify-content: center;
        display: flex;
    }

    .value {
        position: absolute;
        right: 60px;

        @include from($mobile-l) {
            right: 35%;
        }
    }

    @media only screen and (min-height: 1024px) {
        .grey {
            padding-bottom: 30%;
        }
    }

    @media only screen and (min-height: 1366px) {
        .grey {
            padding-bottom: 52%;
        }
    }
</style>
