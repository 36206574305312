import { Product, get, formData, formDataPut, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addProduct(images, data) {
    let form = new FormData();
    for (let i = 0; i < images.length; i++) {
        let new_name = 'image' + i + '.' + images[i].type.split('/')[1];
        form.append('images', images[i], new_name);
    }
    form.append('data', JSON.stringify(data));
    return formData('/products', form);
}

export function getProducts(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/products' + _query);
}

export function updateProduct(images, data) {
    let form = new FormData();
    for (let i = 0; i < images.length; i++) {
        let new_name = 'image' + i + '.' + images[i].type.split('/')[1];
        form.append('images', images[i], new_name);
    }
    form.append('data', JSON.stringify(data));
    return formDataPut('/products', form);
}

export function removeProduct(id) {
    return remove('/products', { id: id });
}

export function uploadImages(images) {
    let form = new FormData();
    for (let i = 0; i < images.length; i++) {
        let new_name = 'image' + i + '.' + images[i].type.split('/')[1];
        form.append('images', images[i], new_name);
    }
    return formData('/products/images', form);
}
