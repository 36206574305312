<template>
    <b-loading
        :is-full-page="true"
        v-model="isPageLoading"
        :can-cancel="false">
        <div class="center-inside">
            <img
                width="140"
                class="loading-animation"
                src="@/assets/icons/coracao_verde.png" />
        </div>
    </b-loading>
</template>

<script>
    export default {
        name: 'Loading',
    };
</script>

<style lang="scss" scoped>
    .loading-animation {
        transform: scale(1);
        animation: pulse 2s infinite;
    }

    @keyframes pulse {
        0% {
            transform: scale(0.9);
        }

        70% {
            transform: scale(1);
        }

        100% {
            transform: scale(0.9);
        }
    }
</style>
