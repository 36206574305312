<template>
    <section>
        <div class="main">
            <div class="is-flex">
                <b-icon
                    @click.native="$router.back()"
                    pack="bl-icons"
                    icon="bl-arrow-left"
                    type="is-black"
                    class="is-size-5 arrow mt-7 mr-1">
                </b-icon>
                <p
                    class="has-text-centered is-size-6 mt-8"
                    v-html="t.privacy">
                    {{ t.privacy }}
                </p>
            </div>

            <p
                class="has-text-justified is-size-6 mt-8 mb-4"
                v-html="t.text"></p>
        </div>
    </section>
</template>

<script>
    export default {
        data() {
            return {
                t: this.$t('views.Privacy'),
            };
        },
    };
</script>

<style lang="scss" scoped>
    .main p {
        font-family: 'Lota Grotesque';
        color: black;
    }
</style>
