<template>
    <section>
        <TopMenu />
        <div class="main">
            <div @click="$router.back()">
                <b-icon
                    pack="bl-icons"
                    icon="bl-arrow-left"
                    type="is-black"
                    class="arrow-left is-size-4">
                </b-icon>
            </div>
            <p
                class="has-text-primary mt-3 has-text-centered"
                v-html="t"></p>
        </div>

        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomDefaultMenu.vue';
    export default {
        components: {
            TopMenu,
            BotMenu,
        },

        data() {
            return {
                type: this.$route.params.type,
            };
        },

        computed: {
            t() {
                return this.$t('components.IsGoodToKnowInfo.' + this.type);
            },
        },

        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },
        },

        created() {
            this.scrollToTop();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 70px;
        padding-bottom: 120px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-bottom: 100px;
        }
    }

    .main p {
        white-space: pre-line;
        font-size: 0.875rem;
        font-family: 'Lota Grotesque';
    }
</style>
