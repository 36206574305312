import store from '@/store/index';

export default {
    computed: {
        getTrackerStep() {
            return store.getters['trackerSteps/getTrackerStep'];
        },
    },
    methods: {
        updateTrackerStep(step) {
            store.commit('trackerSteps/setTrackerStep', step);
        },
    },
};
