var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('div',{staticClass:"bot-menu-white",class:{
            'profile-page-background': _vm.$route.name == 'Profile',
            'orders-page-background': _vm.$route.name == 'MyOrders' || _vm.$route.name == 'OrderDetails',
        }},[(
                _vm.$route.name != 'MyOrders' &&
                _vm.$route.name != 'OrderDetails' &&
                _vm.$route.name != 'Help' &&
                _vm.$route.name != 'EditProfile' &&
                _vm.$route.name != 'Panel' &&
                _vm.$route.name != 'Settings' &&
                _vm.$route.name != 'ContactSupport' &&
                _vm.$route.name != 'Payments'
            )?_c('p',{staticClass:"has-text-white has-text-centered mb-3",staticStyle:{"transform":"translateY(6px)"},on:{"click":_vm.logout}},[_vm._v(" "+_vm._s(_vm.t.logout)+" ")]):_vm._e(),_c('div',{staticClass:"icons-container"},[_c('div',{on:{"click":function($event){return _vm.goToPage('Homepage')}}},[_c('b-icon',{staticClass:"is-size-4 profile-menu",class:{ 'icon-color': _vm.$route.name != 'Profile' && _vm.$route.name != 'MyOrders' && _vm.$route.name != 'OrderDetails' },attrs:{"pack":"bl-icons","icon":"bl-home"}})],1),_c('div',{on:{"click":function($event){return _vm.goToPage('MySpot')}}},[_c('b-icon',{staticClass:"is-size-4 profile-menu",class:{ 'icon-color': _vm.$route.name != 'Profile' && _vm.$route.name != 'MyOrders' && _vm.$route.name != 'OrderDetails' },attrs:{"pack":"bl-icons","icon":"bl-objective"}})],1),_c('b-icon',{staticClass:"is-size-4 profile-menu",class:{ 'icon-color': _vm.$route.name != 'Profile' && _vm.$route.name != 'MyOrders' && _vm.$route.name != 'OrderDetails' },attrs:{"icon":"magnify"},nativeOn:{"click":function($event){return _vm.goToPage('Search')}}}),_c('div',{on:{"click":function($event){return _vm.goToPage('MyShop')}}},[_c('b-icon',{staticClass:"is-size-4 profile-menu",class:{ 'icon-color': _vm.$route.name != 'Profile' && _vm.$route.name != 'MyOrders' && _vm.$route.name != 'OrderDetails' },attrs:{"pack":"bl-icons","icon":"bl-cesto_compras"}})],1),_c('b-icon',{staticClass:"is-size-4 profile-menu",class:{
                    opacity:
                        _vm.$route.name == 'Panel' ||
                        _vm.$route.name == 'EditProfile' ||
                        _vm.$route.name == 'Profile' ||
                        _vm.$route.name == 'Payments' ||
                        _vm.$route.name == 'MyOrders' ||
                        _vm.$route.name == 'OrderDetails' ||
                        _vm.$route.name == 'Help' ||
                        _vm.$route.name == 'Settings' ||
                        _vm.$route.name == 'ContactSupport',

                    'icon-color':
                        _vm.$route.name == 'Profile' ||
                        _vm.$route.name == 'Panel' ||
                        _vm.$route.name == 'EditProfile' ||
                        _vm.$route.name == 'Payments' ||
                        _vm.$route.name == 'MyOrders' ||
                        _vm.$route.name == 'OrderDetails' ||
                        _vm.$route.name == 'Help' ||
                        _vm.$route.name == 'Settings' ||
                        _vm.$route.name == 'ContactSupport',
                },attrs:{"pack":"bl-icons","icon":"bl-user"}})],1)])])
}
var staticRenderFns = []

export { render, staticRenderFns }