<template>
    <div>
        <form
            @submit.prevent="formSubmit"
            class="columns is-mobile is-multiline">
            <b-field
                class="column is-12-mobile is-3-desktop"
                :label="t.name"
                :type="invalids.includes('colaborator.name') ? 'is-danger' : ''">
                <b-input
                    type="text"
                    v-model="form.colaborator.name"
                    required></b-input>
            </b-field>
            <b-field
                class="column is-12-mobile is-3-desktop"
                :label="t.surname"
                :type="invalids.includes('colaborator.surname') ? 'is-danger' : ''">
                <b-input
                    type="text"
                    v-model="form.colaborator.surname"
                    required></b-input>
            </b-field>
            <b-field
                class="column is-12-mobile is-3-desktop"
                :label="t.percent"
                :type="invalids.includes('percent') ? 'is-danger' : ''">
                <b-input
                    type="number"
                    v-model="form.percent"
                    required
                    icon-right="mdi mdi-percent"
                    min="0"
                    max="100"
                    step="0"></b-input>
            </b-field>
            <b-field
                class="column is-12-mobile is-3-desktop"
                :label="t.duration.label"
                :type="invalids.includes('percent') ? 'is-danger' : ''">
                <b-select
                    :placeholder="t.duration.placeholder"
                    v-model="form.duration"
                    required
                    expanded>
                    <option
                        v-for="(option, name, i) in t.duration.options"
                        :value="name"
                        :key="i">
                        {{ option }}
                    </option>
                </b-select>
            </b-field>

            <div class="column is-offset-2-desktop is-8-desktop is-12-touch">
                <b-button
                    expanded
                    :loading="isLoading"
                    class="is-headline-bold-d-d is-headline-m-m"
                    type="is-primary"
                    native-type="submit"
                    >{{ t.submit }}</b-button
                >
            </div>
        </form>
    </div>
</template>

<script>
    import { addCoupon, updateCoupon, getCoupons } from '@/api/coupons';
    export default {
        name: 'Form',
        data() {
            return {
                t: this.$t('components.BackOffice.Coupons.Form'),
                isLoading: false,
                invalids: [],
                form: {
                    colaborator: {
                        name: null,
                        surname: null,
                    },
                    percent: null,
                    duration: null,
                },
            };
        },
        methods: {
            formSubmit() {
                this.invalids = [];
                addCoupon(this.form)
                    .then((response) => {
                        this.isLoading = false;
                        this.$buefy.toast.open({
                            message: this.t.api.success,
                            position: 'is-bottom',
                            type: 'is-success',
                        });
                        this.$router.push({ name: 'BackOfficeCoupons' });
                    })
                    .catch((error) => {
                        this.isLoading = false;
                        let message = this.t.api.error;
                        if (Array.isArray(error.response.data)) {
                            error.response.data.forEach((element) => {
                                this.invalids.push(element.param);
                            });
                        } else if (error.response.data.code == 'AlreadyExists') {
                            message = this.t.api.duplicated;
                        }

                        this.$buefy.toast.open({
                            message: message,
                            position: 'is-bottom',
                            type: 'is-danger',
                        });
                    });
            },
        },
    };
</script>
