import { get, post, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function addPhrase(data) {
    return post('/phrases', data);
}

export function getPhrases(query = {}) {
    let _query = '?' + queryString.stringify(query);
    return get('/phrases' + _query);
}

export function updatePhrases(data) {
    return put('/phrases', data);
}

export function removePhrase(id) {
    return remove('/phrases/', { id: id });
}

export function reorderPhrases(data) {
    return put('/phrases/reorder', data);
}
