<template>
    <section>
        <TopMenu />

        <div class="main">
            <div class="card">
                <p
                    class="title has-text-centered pt-2"
                    :style="{ 'font-family': this.changeFont }">
                    {{ t.expired }}
                </p>
                <p class="text has-text-centered">{{ t.expiredText }}</p>
                <div class="btn-container mt-6">
                    <b-button
                        class="add"
                        rounded
                        @click="goToPage('SignSubscription')"
                        >{{ t.renew }}</b-button
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    export default {
        components: {
            TopMenu,
        },
        data() {
            return {
                t: this.$t('components.Subscription.SubscriptionExpired'),
            };
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 140px;
    }
    .card {
        background-color: $primary;
        height: auto;
        border-radius: 48px;
        padding-bottom: 20px;

        max-width: $card-max-width;
        margin-left: auto;
        margin-right: auto;
        padding: 1rem;
    }
    .title {
        font-size: 22px;
        font-family: 'Borest-Standard';
        font-weight: bold;
        color: white;
    }

    .text {
        font-size: 18px;
        font-family: 'Lota Grotesque';
        font-weight: normal;
    }

    .add {
        width: 76px;
        height: 32px;
        font-size: 0.9rem;
        font-family: 'Lota Grotesque';
        background-color: $secondary;
        color: white;
        border-radius: 16px;
        border: none;
        outline: none;
        cursor: pointer;
    }
    .btn-container {
        display: flex;
        justify-content: center;
    }
</style>
