<template>
    <section>
        <div class="container">
            <p
                class="has-text-white"
                :style="{ 'font-family': changeFont }">
                {{ this.t.dailyRecipes }}
            </p>
            <div class="days-container my-5">
                <div
                    v-for="(day, index) in days"
                    :key="index">
                    <div class="has-text-centered">
                        <p class="has-text-white day-label">{{ day | momentString }}</p>
                        <span
                            class="day"
                            :class="{ active: isActive === index }"
                            @click="getRecipesDay(day, index)">
                            {{ day | moment }}
                        </span>
                    </div>
                </div>
            </div>
        </div>
        <div class="main">
            <div class="cards-container">
                <div class="desktop-alignment">
                    <p
                        class="has-text-secondary day-label has-text-centered"
                        :style="{ 'font-family': this.changeFont }">
                        {{ t.breakfast }}
                    </p>
                    <div
                        class="card"
                        v-touch:swipe="handleBreakfastSwipe"
                        @click="seeDetails(selectedDay[0].recipes.breakfast[breakfastIndex]._id)">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${selectedDay[0].recipes.breakfast[breakfastIndex].image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="selectedDay[0].recipes.breakfast[breakfastIndex].name[getLocale].length < 40">
                                    {{ selectedDay[0].recipes.breakfast[breakfastIndex].name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ selectedDay[0].recipes.breakfast[breakfastIndex].name[getLocale].substring(0, 40) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-5">
                                    </b-icon>
                                    x
                                    {{ selectedDay[0].recipes.breakfast[breakfastIndex].people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                    <p style="transform: translateY(17px)">{{ selectedDay[0].recipes.breakfast[breakfastIndex].preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>

                    <div class="register-steps center-inside is-fullwidth">
                        <div
                            class="recipe-steps"
                            v-for="(step, stepIndex) in selectedDay[0].recipes.breakfast.length"
                            :key="stepIndex"
                            :class="{ 'step-1': breakfastIndex === stepIndex }"></div>
                    </div>

                    <div
                        v-if="!isMobile"
                        @click="handleBreakfastSwipe('right')">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-primary"
                            class="arrow-left is-size-4">
                        </b-icon>
                    </div>
                    <div
                        v-if="!isMobile"
                        @click="handleBreakfastSwipe('left')">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-right"
                            type="is-primary"
                            class="arrow-right is-size-4">
                        </b-icon>
                    </div>
                </div>

                <div class="mt-4 desktop-alignment">
                    <p
                        class="has-text-secondary day-label has-text-centered"
                        :style="{ 'font-family': this.changeFont }">
                        {{ t.lunch }}
                    </p>
                    <div
                        class="card"
                        @click="seeDetails(selectedDay[0].recipes.lunch[lunchIndex]._id)"
                        v-touch:swipe="handleLunchSwipe">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${selectedDay[0].recipes.lunch[lunchIndex].image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="selectedDay[0].recipes.lunch[lunchIndex].name[getLocale].length < 40">
                                    {{ selectedDay[0].recipes.lunch[lunchIndex].name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ selectedDay[0].recipes.lunch[lunchIndex].name[getLocale].substring(0, 40) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-5">
                                    </b-icon>
                                    x
                                    {{ selectedDay[0].recipes.lunch[lunchIndex].people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                    <p style="transform: translateY(17px)">{{ selectedDay[0].recipes.lunch[lunchIndex].preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>

                    <div class="register-steps center-inside is-fullwidth">
                        <div
                            class="recipe-steps"
                            v-for="(step, stepIndex) in selectedDay[0].recipes.lunch.length"
                            :key="stepIndex"
                            :class="{ 'step-1': lunchIndex === stepIndex }"></div>
                    </div>
                    <div
                        v-if="!isMobile"
                        @click="handleLunchSwipe('right')">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-primary"
                            class="arrow-left is-size-4">
                        </b-icon>
                    </div>
                    <div
                        v-if="!isMobile"
                        @click="handleLunchSwipe('left')">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-right"
                            type="is-primary"
                            class="arrow-right is-size-4">
                        </b-icon>
                    </div>
                </div>
                <div class="mt-4 desktop-alignment">
                    <p
                        class="has-text-secondary day-label has-text-centered"
                        :style="{ 'font-family': this.changeFont }">
                        {{ t.dinner }}
                    </p>
                    <div
                        class="card"
                        @click="seeDetails(selectedDay[0].recipes.dinner[dinnerIndex]._id)"
                        v-touch:swipe="handleDinnerSwipe">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${selectedDay[0].recipes.dinner[dinnerIndex].image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="selectedDay[0].recipes.dinner[dinnerIndex].name[getLocale].length < 40">
                                    {{ selectedDay[0].recipes.dinner[dinnerIndex].name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ selectedDay[0].recipes.dinner[dinnerIndex].name[getLocale].substring(0, 40) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-5">
                                    </b-icon>
                                    x
                                    {{ selectedDay[0].recipes.dinner[dinnerIndex].people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                    <p style="transform: translateY(17px)">{{ selectedDay[0].recipes.dinner[dinnerIndex].preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>

                    <div class="register-steps center-inside is-fullwidth">
                        <div
                            class="recipe-steps"
                            v-for="(step, stepIndex) in selectedDay[0].recipes.dinner.length"
                            :key="stepIndex"
                            :class="{ 'step-1': dinnerIndex === stepIndex }"></div>
                    </div>

                    <div
                        v-if="!isMobile"
                        @click="handleDinnerSwipe('right')">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-primary"
                            class="arrow-left is-size-4">
                        </b-icon>
                    </div>
                    <div
                        v-if="!isMobile"
                        @click="handleDinnerSwipe('left')">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-right"
                            type="is-primary"
                            class="arrow-right is-size-4">
                        </b-icon>
                    </div>
                </div>
                <div class="mt-4 desktop-alignment">
                    <p
                        class="has-text-secondary day-label has-text-centered"
                        :style="{ 'font-family': this.changeFont }">
                        {{ t.snacks }}
                    </p>
                    <div
                        class="card"
                        @click="seeDetails(selectedDay[0].recipes.snacks[snacksIndex]._id)"
                        v-touch:swipe="handleSnacksSwipe">
                        <div class="media">
                            <div class="media-left">
                                <div
                                    class="card-image"
                                    :style="{ 'background-image': `url(${selectedDay[0].recipes.snacks[snacksIndex].image})` }"></div>
                            </div>
                            <div class="media-content has-text-centered has-text-secondary">
                                <p
                                    class="pt-3"
                                    v-if="selectedDay[0].recipes.snacks[snacksIndex].name[getLocale].length < 40">
                                    {{ selectedDay[0].recipes.snacks[snacksIndex].name[getLocale] }}
                                </p>
                                <p
                                    class="pt-5"
                                    v-else>
                                    {{ selectedDay[0].recipes.snacks[snacksIndex].name[getLocale].substring(0, 40) + '...' }}
                                </p>
                                <div>
                                    <b-icon
                                        pack="bl-icons"
                                        icon="bl-user"
                                        type="is-secondary"
                                        class="is-size-5">
                                    </b-icon>
                                    x
                                    {{ selectedDay[0].recipes.snacks[snacksIndex].people }}
                                </div>
                                <div style="display: inline-flex">
                                    <i class="mdi mdi-clock mdi-36px mr-2"> </i>
                                    <p style="transform: translateY(17px)">{{ selectedDay[0].recipes.snacks[snacksIndex].preparing_time }}</p>
                                </div>
                            </div>
                            <div class="media-right"></div>
                        </div>
                    </div>

                    <div class="register-steps center-inside is-fullwidth">
                        <div
                            class="recipe-steps"
                            v-for="(step, stepIndex) in selectedDay[0].recipes.snacks.length"
                            :key="stepIndex"
                            :class="{ 'step-1': snacksIndex === stepIndex }"></div>
                    </div>

                    <div
                        v-if="!isMobile"
                        @click="handleSnacksSwipe('right')">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-primary"
                            class="arrow-left is-size-4">
                        </b-icon>
                    </div>
                    <div
                        v-if="!isMobile"
                        @click="handleSnacksSwipe('left')">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-arrow-right"
                            type="is-primary"
                            class="arrow-right is-size-4">
                        </b-icon>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import moment from 'moment';
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import { getDailyRecipe } from '@/api/dailyRecipes';
    export default {
        data() {
            return {
                firstDay: new Date(),
                t: this.$t('components.Recipes.DailyRecipes'),
                recipes: null,
                selectedDay: [],
                isActive: 0,
                breakfastIndex: 0,
                lunchIndex: 0,
                dinnerIndex: 0,
                snacksIndex: 0,
            };
        },
        components: {
            TopMenu,
        },
        computed: {
            days() {
                return [
                    this.firstDay,
                    this.addDays(this.firstDay, 1),
                    this.addDays(this.firstDay, 2),
                    this.addDays(this.firstDay, 3),
                    this.addDays(this.firstDay, 4),
                    this.addDays(this.firstDay, 5),
                    this.addDays(this.firstDay, 6),
                ];
            },
        },
        methods: {
            addDays(date, days) {
                let newDate = new Date(date);

                newDate.setDate(this.firstDay.getDate() + days);
                return newDate;
            },

            moment: function () {
                return moment();
            },

            getRecipes() {
                getDailyRecipe()
                    .then((response) => {
                        this.recipes = response?.data?.recipes;
                        if (this.recipes.length == 0) {
                            this.$buefy.toast.open({
                                message: this.t.error,
                                type: 'is-secondary',
                                duration: 4000,
                            });
                        }
                        this.getRecipesDay(this.firstDay, 0);
                    })
                    .catch((error) => {
                        console.error(error);
                        this.$buefy.toast.open({
                            message: this.t.error,
                            type: 'is-secondary',
                            duration: 4000,
                        });
                    })
                    .finally(() => {
                        this.setIsPageLoading(false);
                    });
            },

            getRecipesDay(value, index) {
                this.isActive = index;
                this.selectedDay = [];
                this.breakfastIndex = 0;
                this.lunchIndex = 0;
                this.dinnerIndex = 0;
                this.snacksIndex = 0;
                for (let index = 0; index < this.recipes.length; index++) {
                    if (moment(value).isSame(this.recipes[index].day, 'day')) {
                        this.selectedDay.push(this.recipes[index]);
                    }
                }
            },

            seeDetails(id) {
                this.$router.push({ name: 'RecipeDetails', params: { id: id } });
            },

            handleSwipe(id) {
                return (dir) => {
                    if (dir === 'left') {
                        this.seeDetails(id);
                    }
                };
            },

            handleBreakfastSwipe(direction) {
                if (direction === 'left' && this.breakfastIndex >= 0 && this.breakfastIndex < 1) {
                    this.breakfastIndex++;
                } else if (direction === 'left' && this.breakfastIndex === 1) {
                    this.breakfastIndex = 0;
                }

                if (direction === 'right' && this.breakfastIndex === 0) {
                    this.breakfastIndex = 1;
                } else if (direction === 'right' && this.breakfastIndex <= 1 && this.breakfastIndex > 0) {
                    this.breakfastIndex--;
                }
            },
            handleLunchSwipe(direction) {
                if (direction === 'left' && this.lunchIndex >= 0 && this.lunchIndex < 1) {
                    this.lunchIndex++;
                } else if (direction === 'left' && this.lunchIndex === 1) {
                    this.lunchIndex = 0;
                }

                if (direction === 'right' && this.lunchIndex === 0) {
                    this.lunchIndex = 1;
                } else if (direction === 'right' && this.lunchIndex <= 1 && this.lunchIndex > 0) {
                    this.lunchIndex--;
                }
            },
            handleDinnerSwipe(direction) {
                if (direction === 'left' && this.dinnerIndex >= 0 && this.dinnerIndex < 1) {
                    this.dinnerIndex++;
                } else if (direction === 'left' && this.dinnerIndex === 1) {
                    this.dinnerIndex = 0;
                }

                if (direction === 'right' && this.dinnerIndex === 0) {
                    this.dinnerIndex = 1;
                } else if (direction === 'right' && this.dinnerIndex <= 1 && this.dinnerIndex > 0) {
                    this.dinnerIndex--;
                }
            },
            handleSnacksSwipe(direction) {
                if (direction === 'left' && this.snacksIndex >= 0 && this.snacksIndex < 6) {
                    this.snacksIndex++;
                } else if (direction === 'left' && this.snacksIndex === 6) {
                    this.snacksIndex = 0;
                }

                if (direction === 'right' && this.snacksIndex === 0) {
                    this.snacksIndex = 6;
                } else if (direction === 'right' && this.snacksIndex <= 6 && this.snacksIndex > 0) {
                    this.snacksIndex--;
                }
            },
        },
        filters: {
            moment: function (date) {
                return moment(date).format('DD');
            },
            momentString: function (date) {
                return moment(date).format('ddd');
            },
        },
        created() {
            this.setIsPageLoading(true);
            this.getRecipes();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 20px;
    }

    .container {
        background-color: #ce9aa9;
        width: 100%;
        z-index: 1;
        height: 166px;

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 126px;
            padding-top: 10px;
        }
    }

    .days-container {
        width: 100%;
        justify-content: space-between;
        display: flex;
        padding-left: 4%;
        padding-right: 4%;
        @include from($tablet) {
            padding-left: 28%;
            padding-right: 28%;
        }

        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-left: 2%;
            padding-right: 2%;
            transform: translateY(-24px);
        }
    }
    .day {
        background-color: $secondary;
        color: white;
        display: inline-block;
        padding-top: 0.6rem;
        border-radius: 50%;
        width: 38px;
        height: 38px;
        font-family: 'Lota Grotesque';
        font-size: 14px;
        cursor: pointer;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            width: 34px;
            height: 34px;
        }
    }

    .day-label {
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .btn-menu-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }

    .btn-menu {
        border-color: white;
        color: white;
        margin-left: 10px;
        height: 32px;
        font-size: 13px;
        font-family: 'Lota Grotesque';
        background-color: transparent !important;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            height: 28px;
        }
    }

    p {
        font-family: 'Borest-Standard';
        font-size: 40px;
        text-align: center;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            font-size: 30px;
        }
    }

    .card {
        height: 172px;
        border-radius: 48px;
        border: 1px solid $secondary;
        cursor: pointer;
        background-color: transparent;
        border-radius: 25px;
        @include from($mobile-l) {
            width: 400px;
            margin: 0 auto;
        }
    }

    .card-image {
        background-image: url('https://realfood.tesco.com/media/images/1400x919-Pink-ombre-pancakes-283d8170-a45b-4bc5-a3e9-ef09ad841530-0-1400x919.jpg');
        justify-content: center;
        display: flex;
        height: 170px;
        border-top-left-radius: 25px 25px;
        border-bottom-left-radius: 25px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: cover;
    }

    .media-left {
        width: 63%;
    }
    .media-right,
    .media-left {
        margin: 0;
    }

    .media-content p {
        font-size: 14px;
        font-family: 'Lota Grotesque';
    }

    .media-content {
        overflow: hidden;
    }

    .active {
        background-color: white;
        color: $secondary;
    }

    .register-steps > div {
        width: 8px;
        height: 8px;
        background: white;
        border: solid 1px $primary;
        border-radius: 50%;
        margin: 15px;
    }
    .register-steps > .step-1 {
        background: $primary;
    }

    .recipe-steps {
        margin: 8px !important;
    }

    .arrow-left {
        transform: translate(45px, -34px);
    }

    .arrow-right {
        float: right;
        transform: translate(-53px, -62px);
    }

    .arrow-left,
    .arrow-right,
    .arrow-right-recipes,
    .arrow-left-recipes {
        cursor: pointer;
    }

    .desktop-alignment {
        @include from($tablet) {
            width: 600px;
            margin: 0 auto;
        }
    }

    @media screen and (min-width: 1024px) {
        .container {
            max-width: 100% !important;
            height: 200px;
        }
    }
</style>
