<template>
    <section>
        <TopMenu />
        <div class="main">
            <div class="has-text-centered mt-3">
                <p
                    class="title has-text-primary header"
                    :style="{ 'font-family': this.changeFont }">
                    {{ t.toKnow }}
                </p>
            </div>

            <div class="has-text-centered cursor">
                <p
                    class="has-text-primary mt-10 mb-3"
                    @click="seeInfo('plantbased')">
                    {{ t.plantbased }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('cereals')">
                    {{ t.cereals }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('cruciferous')">
                    {{ t.cruciferous }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('protein')">
                    {{ t.protein }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('calcium')">
                    {{ t.calcium }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('sport')">
                    {{ t.sport }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('inflammation')">
                    {{ t.inflammation }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('vegetables')">
                    {{ t.vegetables }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('food')">
                    {{ t.food }}
                </p>
                <p
                    class="has-text-primary mb-3"
                    @click="seeInfo('pattern')">
                    {{ t.pattern }}
                </p>
            </div>
        </div>
        <BotMenu />
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomDefaultMenu.vue';
    export default {
        components: {
            TopMenu,
            BotMenu,
        },

        data() {
            return {
                t: this.$t('views.IsGoodToKnow'),
            };
        },

        methods: {
            seeInfo(infoType) {
                this.$router.push({ name: 'AppInfo', params: { type: infoType } });
            },
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';

    .main {
        padding-top: 100px;
        padding-bottom: 110px;
    }

    .title {
        margin-top: 10px;
        margin-bottom: 10px;
    }

    .body-icons {
        justify-content: space-between;
        display: flex;
        width: 70%;
        margin: 0 auto;
    }

    .header {
        font-family: 'Borest-Standard';
        font-size: 40px;
        font-weight: normal;
    }

    .black-bold {
        font-family: 'Lota Grotesque';
        font-size: 16px;
        font-weight: normal;
    }

    p {
        font-family: 'Lota Grotesque';
        font-size: 14px;
        font-weight: bold;
    }
    .cursor {
        cursor: pointer;
    }

    @media only screen and (max-width: 350px) {
        .main {
            padding-top: 80px;
        }

        p {
            font-size: 12px;
        }

        .header {
            font-size: 30px;
        }
    }
</style>
