import store from '@/store/index';

export default {
    computed: {
        getPreferenceStep() {
            return store.getters['registerPreferences/getPreferenceStep'];
        },

        getIntolerances() {
            return store.getters['registerPreferences/getIntolerances'];
        },

        getMetrics() {
            return store.getters['registerPreferences/getMetrics'];
        },

        getMeals() {
            return store.getters['registerPreferences/getMeals'];
        },
    },
    methods: {
        updatePreferenceStep(step) {
            store.commit('registerPreferences/setPreferenceStep', step);
        },
        updateIntolerances(data) {
            store.commit('registerPreferences/setIntolerances', data);
        },
        updateMetrics(data) {
            store.commit('registerPreferences/setMetrics', data);
        },

        updateMeals(data) {
            store.commit('registerPreferences/setMeals', data);
        },
    },
};
