<template>
    <section>
        <div class="tracker-container mx-0">
            <div class="icon-container">
                <p class="has-text-white pt-3 ml-5">{{ t.tracker }}</p>
                <b-icon
                    v-if="getTrackerStep === 0"
                    pack="bl-icons"
                    icon="bl-meal"
                    type="is-white"
                    class="is-size-4 ml-8 mt-5">
                </b-icon>
                <b-icon
                    v-if="getTrackerStep === 1"
                    pack="bl-icons"
                    icon="bl-apple"
                    type="is-white"
                    class="is-size-4 ml-8 mt-5">
                </b-icon>
                <b-icon
                    v-if="getTrackerStep === 2"
                    pack="bl-icons"
                    icon="bl-broccoli"
                    type="is-white"
                    class="is-size-4 ml-8 mt-5">
                </b-icon>
                <b-icon
                    v-if="getTrackerStep === 3"
                    pack="bl-icons"
                    icon="bl-medicine"
                    type="is-white"
                    class="is-size-4 ml-8 mt-5">
                </b-icon>
                <b-icon
                    v-if="getTrackerStep === 4"
                    pack="bl-icons"
                    icon="bl-cup"
                    type="is-white"
                    class="is-size-4 ml-8 mt-5">
                </b-icon>
                <b-icon
                    v-if="getTrackerStep === 5"
                    pack="bl-icons"
                    icon="bl-bed"
                    type="is-white"
                    class="is-size-4 ml-8 mt-5">
                </b-icon>
                <b-icon
                    v-if="getTrackerStep === 6"
                    pack="bl-icons"
                    icon="bl-legumes"
                    type="is-white"
                    class="is-size-4 ml-8 mt-5">
                </b-icon>
                <b-icon
                    v-if="getTrackerStep === 7"
                    pack="bl-icons"
                    icon="bl-sport"
                    type="is-white"
                    class="is-size-4 ml-8 mt-5">
                </b-icon>
            </div>

            <div class="swipe-container mt-5 ml-4">
                <div class="media">
                    <div class="media-left">
                        <div
                            @click="previousStep"
                            class="is-clickable">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-arrow-left"
                                type="is-primary"
                                class="is-size-4">
                            </b-icon>
                        </div>
                    </div>
                    <div class="media-content">
                        <p
                            v-if="getTrackerStep === 0"
                            class="has-text-primary">
                            {{ t.plant }}
                        </p>
                        <p
                            v-else-if="getTrackerStep === 1"
                            class="has-text-primary">
                            {{ t.fruits }}
                        </p>
                        <p
                            v-else-if="getTrackerStep === 2"
                            class="has-text-primary">
                            {{ t.veggies }}
                        </p>
                        <p
                            v-else-if="getTrackerStep === 3"
                            class="has-text-primary">
                            {{ t.vitamins }}
                        </p>
                        <p
                            v-else-if="getTrackerStep === 4"
                            class="has-text-primary">
                            {{ t.water }}
                        </p>
                        <p
                            v-else-if="getTrackerStep === 5"
                            class="has-text-primary">
                            {{ t.sleep }}
                        </p>
                        <p
                            v-else-if="getTrackerStep === 6"
                            class="has-text-primary">
                            {{ t.poo }}
                        </p>
                        <p
                            v-else-if="getTrackerStep === 7"
                            class="has-text-primary">
                            {{ t.exercise }}
                        </p>
                    </div>
                    <div class="media-right">
                        <div
                            @click="nextStep"
                            class="is-clickable">
                            <b-icon
                                pack="bl-icons"
                                icon="bl-arrow-right"
                                type="is-primary"
                                class="is-size-4">
                            </b-icon>
                        </div>
                    </div>
                </div>

                <div class="graph-container mt-14">
                    <div class="mx-2">
                        <p class="has-text-primary mb-3">{{ t.today }}</p>
                        <p class="has-text-primary today-value">{{ todayNumber }}</p>
                    </div>

                    <div class="mx-2">
                        <p class="has-text-primary mb-3">{{ t.week }}</p>
                        <b-icon
                            @click.native="seeChart('week')"
                            pack="bl-icons"
                            icon="bl-graph"
                            type="is-primary"
                            class="is-size-4 is-clickable">
                        </b-icon>
                    </div>

                    <div class="mx-2">
                        <p class="has-text-primary mb-3">{{ t.month }}</p>
                        <b-icon
                            @click.native="seeChart('month')"
                            pack="bl-icons"
                            icon="bl-graph"
                            type="is-primary"
                            class="is-size-4 is-clickable">
                        </b-icon>
                    </div>

                    <div class="mx-2">
                        <p class="has-text-primary mb-3">{{ t.year }}</p>
                        <b-icon
                            @click.native="seeChart('year')"
                            pack="bl-icons"
                            icon="bl-graph"
                            type="is-primary"
                            class="is-size-4 is-clickable">
                        </b-icon>
                    </div>
                    <div>
                        <b-button
                            class="btn is-rounded btn-add"
                            @click="openModal"
                            >{{ t.add }}</b-button
                        >
                    </div>
                </div>
            </div>
        </div>

        <ul
            class="circle-container"
            v-if="getTrackerStep === 6">
            <p class="mb-3 has-text-secondary">{{ t.type }}</p>
            <div
                class="my-4"
                style="display: inline-flex">
                <div class="mr-4">
                    <p class="has-text-centered has-text-weight-bold">1</p>
                    <li class="center-inside">
                        <img :src="require('@/assets/images/poop_1.png')" />
                    </li>
                </div>
                <div>
                    <p class="has-text-centered has-text-weight-bold">2</p>
                    <li class="center-inside">
                        <img :src="require('@/assets/images/poop_2.png')" />
                    </li>
                </div>
            </div>

            <div
                class="my-4"
                style="display: inline-flex">
                <div class="mr-4">
                    <p class="has-text-centered has-text-weight-bold">3</p>
                    <li class="center-inside"><img :src="require('@/assets/images/poop_3.png')" /></li>
                </div>
                <div>
                    <p class="has-text-centered has-text-weight-bold">4</p>
                    <li class="center-inside"><img :src="require('@/assets/images/poop_4.png')" /></li>
                </div>
            </div>

            <div
                class="my-4"
                style="display: inline-flex">
                <div class="mr-4">
                    <p class="has-text-centered has-text-weight-bold">5</p>
                    <li class="center-inside"><img :src="require('@/assets/images/poop_5.png')" /></li>
                </div>
                <div class="mr-4">
                    <p class="has-text-centered has-text-weight-bold">6</p>
                    <li class="center-inside"><img :src="require('@/assets/images/poop_6.png')" /></li>
                </div>
                <div>
                    <p class="has-text-centered has-text-weight-bold">7</p>
                    <li class="center-inside"><img :src="require('@/assets/images/poop_7.png')" /></li>
                </div>
            </div>
        </ul>
        <hr class="mt-15 mb-4" />
        <div class="question-container">
            <p
                v-if="getTrackerStep === 0"
                @click="seeInfo('planted')"
                class="is-clickable">
                {{ t.includePlant }}
            </p>
            <p
                v-else-if="getTrackerStep === 1"
                @click="seeInfo('fruit')"
                class="is-clickable">
                {{ t.includeFruits }}
            </p>
            <p
                v-else-if="getTrackerStep === 2"
                @click="seeInfo('veggies')"
                class="is-clickable">
                {{ t.includeVeggies }}
            </p>
            <p
                v-else-if="getTrackerStep === 3"
                @click="seeInfo('vitamins')"
                class="is-clickable">
                {{ t.includeVitamins }}
            </p>
            <p
                v-else-if="getTrackerStep === 4"
                @click="seeInfo('water')"
                class="is-clickable">
                {{ t.includeWater }}
            </p>
            <p
                v-else-if="getTrackerStep === 5"
                @click="seeInfo('sleep')"
                class="is-clickable">
                {{ t.includeSleep }}
            </p>
            <p
                v-else-if="getTrackerStep === 6"
                @click="seeInfo('poos')"
                class="is-clickable">
                {{ t.includePoo }}
            </p>
            <p
                v-else-if="getTrackerStep === 7"
                @click="seeInfo('exercise')"
                class="is-clickable">
                {{ t.includeExercise }}
            </p>
        </div>

        <hr class="mt-3" />

        <div class="btn-container">
            <b-icon
                pack="bl-icons"
                icon="bl-file"
                type="is-secondary"
                class="is-size-5">
            </b-icon>
            <b-button
                class="btn is-rounded"
                @click="goToPage('DailyRecipes')"
                >{{ t.goToMeal }}</b-button
            >
        </div>
        <TrackerModal
            v-if="isModalActive"
            :isModalOpen="isModalActive"
            @close="closeModal"
            @addTracker="addTracker"
            :title="this.param" />
    </section>
</template>

<script>
    import { getTrackerData, addTrackerData } from '@/api/tracker';
    import TrackerModal from '@/components/Tracker/TrackerModal.vue';
    export default {
        components: {
            TrackerModal,
        },
        data() {
            return {
                t: this.$t('components.Tracker.TrackerCategory'),
                param: null,
                todayNumber: 0,
                isModalActive: false,
            };
        },

        methods: {
            closeModal(value) {
                this.isModalActive = value;
            },
            openModal() {
                this.isModalActive = true;
            },
            previousStep() {
                let step = this.getTrackerStep;
                if (step === 0) {
                    step = 7;
                    this.updateTrackerStep(step);
                    this.getData();
                } else {
                    step = step - 1;
                    this.updateTrackerStep(step);
                    this.getData();
                }
            },
            nextStep() {
                let step = this.getTrackerStep;
                if (step === 7) {
                    step = 0;
                    this.updateTrackerStep(step);
                    this.getData();
                } else {
                    step = step + 1;
                    this.updateTrackerStep(step);
                    this.getData();
                }
            },

            seeInfo(infoType) {
                this.$router.push({ name: 'TrackerInfo', params: { type: infoType } });
            },

            addTracker(value) {
                let data;
                if (this.param === 'poos' || this.param === 'vitamins') {
                    data = {
                        types: value,
                    };
                } else {
                    data = {
                        quantity: value,
                    };
                }

                addTrackerData(this.param, data)
                    .then((response) => {
                        this.getData();
                    })
                    .catch((error) => {
                        console.error(error);
                        this.todayNumber = 0;
                    });
            },

            getData() {
                switch (this.getTrackerStep) {
                    case 0:
                        this.param = 'plantbasedmeals';
                        break;
                    case 1:
                        this.param = 'fruits';
                        break;
                    case 2:
                        this.param = 'veggies';
                        break;
                    case 3:
                        this.param = 'vitamins';
                        break;
                    case 4:
                        this.param = 'waterintakes';
                        break;
                    case 5:
                        this.param = 'sleephours';
                        break;
                    case 6:
                        this.param = 'poos';
                        break;

                    case 7:
                        this.param = 'exercisehours';
                        break;
                }

                let data = {
                    query: 'day',
                };

                getTrackerData(this.param, data)
                    .then((response) => {
                        if (this.param === 'vitamins' || this.param === 'poos') {
                            this.todayNumber = response.data.data[0].type.length;
                        } else this.todayNumber = response.data.data[0].quantity;
                    })
                    .catch((error) => {
                        console.error(error);
                        this.todayNumber = 0;
                    });
            },
            seeChart(queryType) {
                this.$router.push({ name: 'Charts', params: { query: queryType, type: this.param } });
            },
        },

        mounted() {
            this.getData();
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables.scss';
    @import '~bulma/sass/utilities/mixins.sass';

    .tracker-container {
        padding-top: 105px;
        display: inline-flex;
        width: 100%;
        max-width: 100%;

        @include from($mobile-l) {
            justify-content: center;
        }
    }

    .icon-container {
        background-color: $primary;
        height: 205px;
        width: 108px;
        border-bottom-right-radius: 48px;
        border-top-right-radius: 48px;

        @include until($mobile-s) {
            width: 70px;
        }

        @include from($mobile-l) {
            position: absolute;
            left: 0;
        }
    }

    .icon-container p {
        @include until($mobile-s) {
            margin-left: 6px !important;
        }
    }

    .icon-container span {
        @include until($mobile-s) {
            margin-left: 16px !important;
        }
    }

    .swipe-container {
        width: 60%;
        text-align: center;
    }

    .graph-container {
        display: inline-flex;
    }

    .today-value {
        font-size: 1.7rem;
        font-weight: bold;
        transform: translateY(-8px);
    }

    .question-container {
        width: 90%;
        margin: 0 auto;
        font-size: 1.563rem;
        color: $primary;
        text-align: center;
    }
    .question-container p {
        font-size: 1.5rem;
        color: $primary;
    }

    .btn-container {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 6rem;
        padding-bottom: 170px;
    }

    .btn {
        border-color: $secondary;
        background-color: $secondary;
        color: white;
        margin-left: 10px;
    }

    hr {
        color: $primary;
        border: solid 1px;
        width: 90%;
        margin: 0 auto;
    }

    p {
        font-family: 'Lota Grotesque';
        font-size: 0.9rem;
    }

    span {
        font-family: 'Lota Grotesque';
        font-size: 0.65rem;
    }

    img {
        width: 60px;
    }

    .red {
        color: red;
    }

    .red-background {
        color: white;
        background-color: red;
    }

    .orange {
        color: orange;
    }

    .orange-background {
        color: white;
        background-color: orange;
    }
    .green {
        color: green;
    }

    .green-background {
        color: white;
        background-color: green;
    }

    .btn-add {
        transform: translate(-237px, 75px);

        &:hover {
            color: white;
            background-color: $secondary;
            border-color: white;
        }
    }
    li span {
        font-size: 0.785rem;
    }
    li {
        text-align: center;
        border: solid 1px $secondary;
        border-radius: 40px;
        width: 90px;
        height: 45px;
    }

    .circle-container {
        width: 80%;
        margin: 0 auto;
        margin-top: 50px;
    }
</style>
