import { render, staticRenderFns } from "./ResendEmail.vue?vue&type=template&id=46ac17e8&scoped=true"
import script from "./ResendEmail.vue?vue&type=script&lang=js"
export * from "./ResendEmail.vue?vue&type=script&lang=js"
import style0 from "./ResendEmail.vue?vue&type=style&index=0&id=46ac17e8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "46ac17e8",
  null
  
)

export default component.exports