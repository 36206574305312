import Vue from 'vue';
import VueRouter from 'vue-router';
import Home from '@/views/Home.vue';
import Login from '@/views/Login.vue';
import Register from '@/views/Register.vue';
import AppGuide from '@/views/AppGuide.vue';
import HomePage from '@/components/HomePage/HomePage.vue';
import DailyRecipes from '@/views/DailyRecipes.vue';
import RecipeDetails from '@/components/Recipes/RecipeDetails.vue';
import BackOffice from '@/views/BackOffice/Home.vue';
import BackOfficeLogin from '@/views/BackOffice/Login.vue';
import BackOfficeRecipes from '@/components/BackOffice/Recipes/List.vue';
import BackOfficeFormRecipe from '@/components/BackOffice/Recipes/Form.vue';
import BackOfficeIngredients from '@/components/BackOffice/Ingredients/List.vue';
import BackOfficeFormIngredient from '@/components/BackOffice/Ingredients/Form.vue';
import BackOfficeDailyPhrase from '@/components/BackOffice/DailyPhrases/Draggable.vue';
import BackOfficeSupport from '@/components/BackOffice/Support/Tickets.vue';
import BackOfficePartners from '@/components/BackOffice/Partners/List.vue';
import BackOfficeFormPartner from '@/components/BackOffice/Partners/Form.vue';
import BackOfficeCoupons from '@/components/BackOffice/Coupons/List.vue';
import BackOfficeFormCoupon from '@/components/BackOffice/Coupons/Form.vue';
import BackOfficeNewsList from '@/components/BackOffice/News/List.vue';
import BackOfficeNewsForm from '@/components/BackOffice/News/Form.vue';
import BackOfficePostsList from '@/components/BackOffice/Posts/List.vue';
import BackOfficePostsForm from '@/components/BackOffice/Posts/Form.vue';
import BackOfficePodcastsList from '@/components/BackOffice/Podcasts/List.vue';
import BackOfficePodcastsForm from '@/components/BackOffice/Podcasts/Form.vue';
import BackOfficeVideosList from '@/components/BackOffice/Videos/List.vue';
import BackOfficeVideosForm from '@/components/BackOffice/Videos/Form.vue';
import BackOfficeUpcomingNewsList from '@/components/BackOffice/UpcomingNews/List.vue';
import BackOfficeUpcomingNewsForm from '@/components/BackOffice/UpcomingNews/Form.vue';
import BackOfficeProductsList from '@/components/BackOffice/Products/List.vue';
import BackOfficeProductsForm from '@/components/BackOffice/Products/Form.vue';
import BackOfficeTestimonialsList from '@/components/BackOffice/Testimonials/List.vue';
import BackOfficeTestimonialsForm from '@/components/BackOffice/Testimonials/Form.vue';
import BackOfficeParticipationsList from '@/components/BackOffice/Participations/List.vue';
import BackOfficeParticipationsForm from '@/components/BackOffice/Participations/Form.vue';
import BackOfficeOrdersList from '@/components/BackOffice/Orders/List.vue';
import BackOfficeGiftsList from '@/components/BackOffice/Gifts/List.vue';
import BackOfficeSoulStatesList from '@/components/BackOffice/SoulStates/List.vue';
import BackOfficeSoulStatesForm from '@/components/BackOffice/SoulStates/Form.vue';
import BackOfficeUserRecipesList from '@/components/BackOffice/UserRecipes/List.vue';
import BackOfficeForms from '@/components/BackOffice/Forms/List.vue';
import BackOfficeFormsForm from '@/components/BackOffice/Forms/Form.vue';
import BackOfficeWorkshops from '@/components/BackOffice/Workshops/List.vue';
import BackOfficeReiki from '@/components/BackOffice/Reiki/List.vue';
import About from '@/views/About.vue';
import MySpot from '@/views/MySpot.vue';
import Menus from '@/views/Menus.vue';
import MyPreferences from '@/components/MySpot/MyPreferences.vue';
import ResetPassword from '@/components/Auth/RecoverPassword.vue';
import ActivateAccount from '@/components/Auth/ActivateAccount.vue';
import CompleteRegister from '@/components/Auth/CompleteRegister.vue';
import Panel from '@/components/User/Panel.vue';
import EditProfile from '@/components/User/EditProfile.vue';
import Profile from '@/components/User/Profile.vue';
import ChangePassword from '@/components/User/ChangePassword.vue';
import Payments from '@/views/Payments.vue';
import SubscriptionExpired from '@/components/Subscription/SubscriptionExpired.vue';
import FreeTrial from '@/components/Subscription/FreeTrial.vue';
import ContactSupport from '@/components/Support/ContactSupport.vue';
import SignSubscription from '@/views/SignSubscription.vue';
import Tracker from '@/views/Tracker.vue';
import MyShop from '@/views/MyShop.vue';
import Products from '@/components/MyShop/Products.vue';
import GiftCard from '@/components/MyShop/GiftCard.vue';
import Partners from '@/components/MyShop/Partners.vue';
import OurChoices from '@/components/MyShop/OurChoices.vue';
import TrackerInfo from '@/components/Tracker/Info.vue';
import MyOrders from '@/components/Orders/MyOrders.vue';
import OrderDetails from '@/components/Orders/OrderDetails.vue';
import Settings from '@/components/Settings/Settings.vue';
import Help from '@/components/Help/Help.vue';
import EditIntolerances from '@/components/MySpot/Intolerances';
import EditMetrics from '@/components/MySpot/Metrics';
import Charts from '@/components/Tracker/Charts.vue';
import AppInfo from '@/components/AppGuide/IsGoodToKnowInfo.vue';
import IsGoodToKnow from '@/views/IsGoodToKnow.vue';
import CancelSubscription from '@/components/Subscription/CancelSubscription.vue';
import SearchRecipe from '@/views/SearchRecipe.vue';
import AddNewCard from '@/components/Subscription/AddNewCard.vue';
import AppGuideInfo from '@/components/AppGuide/AppGuideInfo.vue';
import Terms from '@/views/Terms.vue';
import PrivacyPolicies from '@/views/PrivacyPolicies.vue';
import AboutUs from '@/views/AboutUs.vue';
import HowAllStarted from '@/views/HowAllStarted.vue';
import HelpInfo from '@/components/Help/HelpInfo';

Vue.use(VueRouter);

const routes = [
    {
        path: '/',
        name: 'Home',
        component: Home,
        meta: {
            onlyNoAuth: true,
        },
    },
    {
        path: '/login',
        name: 'Login',
        component: Login,
        meta: {
            onlyNoAuth: true,
        },
    },
    {
        path: '/register',
        name: 'Register',
        component: Register,
        meta: {
            onlyNoAuth: true,
        },
    },
    {
        path: '/completeregister',
        name: 'CompleteRegister',
        component: CompleteRegister,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/appguide',
        name: 'AppGuide',
        component: AppGuide,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/home',
        name: 'Homepage',
        component: HomePage,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/dailyrecipes',
        name: 'DailyRecipes',
        component: DailyRecipes,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/details/:id',
        name: 'RecipeDetails',
        component: RecipeDetails,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/backoffice/login',
        name: 'BackOfficeLogin',
        component: BackOfficeLogin,
    },
    {
        path: '/backoffice',
        name: 'BackOffice',
        component: BackOffice,
        meta: {
            requiresAuth: true,
        },
        children: [
            {
                path: 'recipes',
                name: 'Recipes',
                component: BackOfficeRecipes,
            },
            {
                path: 'recipes/new-recipe',
                name: 'NewRecipe',
                component: BackOfficeFormRecipe,
            },
            {
                path: 'recipes/edit-recipe/:id',
                name: 'UpdateRecipe',
                component: BackOfficeFormRecipe,
            },
            {
                path: 'ingredients',
                name: 'Ingredients',
                component: BackOfficeIngredients,
            },
            {
                path: 'ingredients/new-ingredient',
                name: 'NewIngredient',
                component: BackOfficeFormIngredient,
            },
            {
                path: 'ingredients/edit-ingredient/:id',
                name: 'UpdateIngredient',
                component: BackOfficeFormIngredient,
            },
            {
                path: 'dailyphrases',
                name: 'DailyPhrases',
                component: BackOfficeDailyPhrase,
            },
            {
                path: 'support',
                name: 'Support',
                component: BackOfficeSupport,
            },
            {
                path: 'partners',
                name: 'BackOfficePartners',
                component: BackOfficePartners,
            },
            {
                path: 'partners/new-partner',
                name: 'NewPartner',
                component: BackOfficeFormPartner,
            },
            {
                path: 'partners/edit-partner/:id',
                name: 'UpdatePartner',
                component: BackOfficeFormPartner,
            },
            {
                path: 'coupons',
                name: 'BackOfficeCoupons',
                component: BackOfficeCoupons,
            },
            {
                path: 'coupons/new-coupon',
                name: 'NewCoupon',
                component: BackOfficeFormCoupon,
            },
            {
                path: 'news',
                name: 'BackOfficeNews',
                component: BackOfficeNewsList,
            },
            {
                path: 'news/add-news',
                name: 'AddNews',
                component: BackOfficeNewsForm,
            },
            {
                path: 'news/edit-news/:id',
                name: 'UpdateNews',
                component: BackOfficeNewsForm,
            },
            {
                path: 'posts',
                name: 'BackOfficePosts',
                component: BackOfficePostsList,
            },
            {
                path: 'posts/add-posts',
                name: 'AddPosts',
                component: BackOfficePostsForm,
            },
            {
                path: 'posts/edit-posts/:id',
                name: 'UpdatePosts',
                component: BackOfficePostsForm,
            },
            {
                path: 'podcasts',
                name: 'BackOfficePodcasts',
                component: BackOfficePodcastsList,
            },
            {
                path: 'podcasts/add-podcast',
                name: 'AddPodcasts',
                component: BackOfficePodcastsForm,
            },
            {
                path: 'podcasts/edit-podcast/:id',
                name: 'UpdatePodcasts',
                component: BackOfficePodcastsForm,
            },
            {
                path: 'upcomingnews',
                name: 'BackOfficeUpcomingNews',
                component: BackOfficeUpcomingNewsList,
            },
            {
                path: 'upcomingnews/add-upcomingnews',
                name: 'AddUpcomingNews',
                component: BackOfficeUpcomingNewsForm,
            },
            {
                path: 'upcomingnews/edit-upcomingnews/:id',
                name: 'UpdateUpcomingNews',
                component: BackOfficeUpcomingNewsForm,
            },
            {
                path: 'products',
                name: 'BackOfficeProducts',
                component: BackOfficeProductsList,
            },
            {
                path: 'products/add-product',
                name: 'AddProducts',
                component: BackOfficeProductsForm,
            },
            {
                path: 'products/edit-product/:id',
                name: 'UpdateProducts',
                component: BackOfficeProductsForm,
            },
            {
                path: 'orders',
                name: 'BackOfficeOrders',
                component: BackOfficeOrdersList,
            },
            {
                path: 'gifts',
                name: 'BackOfficeGifts',
                component: BackOfficeGiftsList,
            },
            {
                path: 'videos',
                name: 'BackOfficeVideos',
                component: BackOfficeVideosList,
            },
            {
                path: 'videos/add-video',
                name: 'AddVideos',
                component: BackOfficeVideosForm,
            },
            {
                path: 'videos/edit-video/:id',
                name: 'UpdateVideos',
                component: BackOfficeVideosForm,
            },
            {
                path: 'testimonials',
                name: 'BackOfficeTestimonials',
                component: BackOfficeTestimonialsList,
            },
            {
                path: 'testimonials/add-testimonial',
                name: 'AddTestimonials',
                component: BackOfficeTestimonialsForm,
            },
            {
                path: 'testimonials/edit-testimonial/:id',
                name: 'UpdateTestimonials',
                component: BackOfficeTestimonialsForm,
            },
            {
                path: 'participations',
                name: 'BackOfficeParticipations',
                component: BackOfficeParticipationsList,
            },
            {
                path: 'participations/add-participation',
                name: 'AddParticipations',
                component: BackOfficeParticipationsForm,
            },
            {
                path: 'participations/edit-participation/:id',
                name: 'UpdateParticipations',
                component: BackOfficeParticipationsForm,
            },
            {
                path: 'soul-states',
                name: 'BackOfficeSoulStates',
                component: BackOfficeSoulStatesList,
            },
            {
                path: 'soul-states/add-soul-state',
                name: 'AddSoulStates',
                component: BackOfficeSoulStatesForm,
            },
            {
                path: 'soul-states/edit-soul_state/:id',
                name: 'UpdateSoulStates',
                component: BackOfficeSoulStatesForm,
            },
            {
                path: 'user-recipes',
                name: 'BackOfficeUserRecipes',
                component: BackOfficeUserRecipesList,
            },
            {
                path: 'forms',
                name: 'BackOfficeForms',
                component: BackOfficeForms,
            },
            {
                path: 'forms/answer/:id',
                name: 'AnswerForm',
                component: BackOfficeFormsForm,
            },
            {
                path: 'workshops',
                name: 'BackOfficeWorkshops',
                component: BackOfficeWorkshops,
            },
            {
                path: 'reiki',
                name: 'BackOfficeReiki',
                component: BackOfficeReiki,
            },
        ],
    },
    {
        path: '/about',
        name: 'About',
        component: About,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/myspot',
        name: 'MySpot',
        component: MySpot,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/menus',
        name: 'Menus',
        component: Menus,
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/mypreferences',
        name: 'MyPreferences',
        component: MyPreferences,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/recoverpassword',
        name: 'RecoverPassword',
        component: ResetPassword,
        meta: {
            OnlyNoAuth: true,
        },
    },
    {
        path: '/activate/:id',
        name: 'Activate',
        component: ActivateAccount,
    },
    {
        path: '/panel',
        name: 'Panel',
        component: Panel,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/profile',
        name: 'Profile',
        component: Profile,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/editprofile',
        name: 'EditProfile',
        component: EditProfile,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/changepassword',
        name: 'ChangePassword',
        component: ChangePassword,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/payments',
        name: 'Payments',
        component: Payments,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/subscriptionexpired',
        name: 'SubscriptionExpired',
        component: SubscriptionExpired,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/freetrial',
        name: 'FreeTrial',
        component: FreeTrial,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/contactsupport',
        name: 'ContactSupport',
        component: ContactSupport,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/signsubscription',
        name: 'SignSubscription',
        component: SignSubscription,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/tracker',
        name: 'Tracker',
        component: Tracker,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/tracker/info',
        name: 'TrackerInfo',
        component: TrackerInfo,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/myshop',
        name: 'MyShop',
        component: MyShop,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/myshop/products',
        name: 'Products',
        component: Products,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/myshop/giftcard',
        name: 'GiftCard',
        component: GiftCard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/myshop/partners',
        name: 'Partners',
        component: Partners,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/myshop/choices',
        name: 'OurChoices',
        component: OurChoices,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/myorders',
        name: 'MyOrders',
        component: MyOrders,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/orderdetails',
        name: 'OrderDetails',
        component: OrderDetails,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/settings',
        name: 'Settings',
        component: Settings,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/help',
        name: 'Help',
        component: Help,
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/editintolerances',
        name: 'EditIntolerances',
        component: EditIntolerances,
        meta: {
            requiresAuth: true,
        },
    },

    {
        path: '/editmetrics',
        name: 'EditMetrics',
        component: EditMetrics,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/charts',
        name: 'Charts',
        component: Charts,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/appinfo/:type',
        name: 'AppInfo',
        component: AppInfo,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/toknow',
        name: 'ToKnow',
        component: IsGoodToKnow,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/subscription/:id/cancel',
        name: 'CancelSubscription',
        component: CancelSubscription,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/search',
        name: 'Search',
        component: SearchRecipe,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/signsubscription/add-new-card',
        name: 'AddNewCard',
        component: AddNewCard,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/appguide/:type',
        name: 'AppGuideInfo',
        component: AppGuideInfo,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/terms',
        name: 'Terms',
        component: Terms,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/privacy-policies',
        name: 'PrivacyPolicies',
        component: PrivacyPolicies,
        meta: {
            requiresAuth: false,
        },
    },
    {
        path: '/about-us',
        name: 'AboutUs',
        component: AboutUs,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/started',
        name: 'HowAllStarted',
        component: HowAllStarted,
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: '/help-info',
        name: 'HelpInfo',
        component: HelpInfo,
        meta: {
            requiresAuth: true,
        },
    },
];

const router = new VueRouter({
    mode: process.env.VUE_APP_ROUTER_MODE,
    base: process.env.BASE_URL,
    routes,
});

export default router;
