const state = {
    activeStep: 0,
};

// getters
const getters = {
    getMySpotStep(state, getters) {
        return state.activeStep;
    },
};

// mutations
const mutations = {
    setMySpotStep(state, active) {
        state.activeStep = active;
    },

    resetMySpot(state) {
        state.activeStep = 0;
    },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
};
