<template>
    <section>
        <TopMenu />
        <CategoryMenu />

        <div class="main">
            <div
                v-for="(partner, index) in partners"
                :key="index">
                <div
                    class="card mt-4 is-clickable"
                    @click="goToPartner(partner.url)">
                    <div class="media">
                        <div class="media-left">
                            <div
                                class="card-image"
                                :style="{ 'background-image': `url(${partner.image})` }"></div>
                        </div>
                        <div class="media-content has-text-centered has-text-secondary">
                            <div></div>
                            <div style="display: inline-flex"></div>
                        </div>
                        <div class="media-right">
                            <p>{{ partner.name }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/UserTopMenu.vue';
    import CategoryMenu from '@/components/MyShop/CategoryMenu.vue';
    import { getPartners } from '@/api/myShop';
    import { Browser } from '@capacitor/browser';
    export default {
        components: {
            TopMenu,
            CategoryMenu,
        },
        data() {
            return {
                t: this.$t('components.MyShop.Partners'),
                partners: [],
            };
        },
        methods: {
            scrollToTop() {
                window.scrollTo(0, 0);
            },

            goToPartner(url) {
                if (this.isMobile) {
                    /* const openCapacitorSite = async () => {
						await Browser.open({ url: 'https://'+url });
					};
					openCapacitorSite(); */
                    window.location.assign('https://' + url, '_blank');
                } else window.open('https://' + url, '_blank');
            },
        },
        created() {
            this.scrollToTop();
            let data = {
                order: 'asc',
            };

            getPartners(data)
                .then((response) => {
                    this.partners = response.data.partners;
                })
                .catch((error) => {
                    console.error(error);
                });
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .main {
        padding-top: 186px;
        padding-bottom: 20px;
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            padding-top: 130px;
        }
    }
    .card {
        height: 172px;
        border-radius: 48px;
        border: 1px solid $secondary;

        background-color: transparent;
        border-radius: 25px;
        @include from($mobile-l) {
            width: 400px;
            margin: 0 auto;
        }
    }

    .card-image {
        justify-content: center;
        display: flex;
        height: 170px;
        border-top-left-radius: 25px 25px;
        border-bottom-left-radius: 25px;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
    }

    .media-left,
    .media-right {
        width: 50%;
        text-align: center;
        word-wrap: break-word;
    }
    .media-right,
    .media-left {
        margin: 0;
    }

    .media-right {
        display: flex;
        align-self: center;
    }

    .media-right p {
        font-size: 20px;
        font-family: 'Lota Grotesque';
        color: $primary;
        padding: 0 6% !important;
    }
</style>
