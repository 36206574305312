<template>
    <form
        @submit.prevent="formSubmit"
        class="columns is-mobile is-multiline">
        <b-field
            class="column is-12"
            :label="t.image">
            <b-select
                placeholder="Selecione uma receita"
                @input="selectRecipe">
                <option
                    v-for="recipe in recipes"
                    :value="recipe"
                    :key="recipe._id">
                    {{ recipe.name[getLocale] }}
                </option>
            </b-select>
        </b-field>
        <b-field
            :type="invalids.includes('image') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.image">
            <b-upload
                v-model="form.image"
                expanded
                drag-drop
                accept="image/*">
                <section class="section">
                    <div
                        v-if="!form.image && !form._image"
                        class="content has-text-centered">
                        <p>
                            <b-icon
                                icon="upload"
                                size="is-large">
                            </b-icon>
                        </p>
                        <p>{{ t.upload }}</p>
                    </div>
                    <div
                        v-else
                        class="content has-text-centered">
                        <img
                            class="img-preview"
                            :src="getFileURL" />
                    </div>
                </section>
            </b-upload>
        </b-field>
        <b-field
            :type="invalids.includes('short_description.pt') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.short.pt">
            <b-input
                type="textarea"
                v-model="form.short_description.pt"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('short_description.en') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.short.en">
            <b-input
                type="textarea"
                v-model="form.short_description.en"></b-input>
        </b-field>
        <b-field
            :type="invalids.includes('link') ? 'is-danger' : ''"
            class="column is-12"
            :label="t.link">
            <b-input
                type="url"
                v-model="form.link"></b-input>
        </b-field>
        <div class="column is-offset-4-desktop is-4-desktop is-12-touch">
            <b-button
                expanded
                :loading="isLoading"
                class="is-headline-bold-d-d is-headline-m-m"
                type="is-primary"
                native-type="submit"
                >{{ t.submit }}</b-button
            >
        </div>
    </form>
</template>
<script>
    import { addUpcomingNew, getUpcomingNews, updateUpcomingNew } from '@/api/upcomingNews';
    import { getRecipes } from '@/api/recipes';

    export default {
        name: 'UpcomingNewsForm',
        data() {
            return {
                t: this.$t('components.BackOffice.UpcomingNews.Form'),
                isUpdate: false,
                isLoading: false,
                invalids: [],
                recipes: [],
                form: {
                    image: null,
                    short_description: {
                        pt: null,
                        en: null,
                    },
                    link: null,
                },
            };
        },
        created() {
            if (this.$route.name == 'UpdateUpcomingNews') {
                this.isUpdate = true;
                this.getData();
            }
            getRecipes({
                order_by: 'createdAt',
                order: 'desc',
                limit: 5,
            })
                .then((result) => {
                    this.recipes = result.data.recipes;
                })
                .catch((err) => {
                    console.error(err);
                });
        },
        methods: {
            selectRecipe(value) {
                this.form = {
                    _image: value.image,
                    short_description: {
                        pt: value.name.pt,
                        en: value.name.en,
                    },
                    link: process.env.VUE_APP_BASE_URL + 'details/' + value._id,
                };
            },
            getData() {
                //used to update
                let query = {
                    id: this.$route.params.id,
                };
                getUpcomingNews(query)
                    .then((response) => {
                        let upcoming_new = response.data.upcoming_news[0];

                        this.form = {
                            id: upcoming_new._id,
                            _image: upcoming_new.image,
                            link: upcoming_new.link,
                            short_description: upcoming_new.short_description,
                        };
                    })
                    .catch((error) => {
                        console.error(error);
                    });
            },
            formSubmit() {
                this.isLoading = true;
                this.invalids = [];
                let image = this.form.image;
                delete this.form.image;
                if (!this.isUpdate) {
                    addUpcomingNew(image, this.form)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeUpcomingNews' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                } else {
                    updateUpcomingNew(image, this.form)
                        .then((result) => {
                            this.isLoading = false;
                            this.$buefy.toast.open({
                                message: this.t.api.update_success,
                                position: 'is-bottom',
                                type: 'is-success',
                            });
                            this.$router.push({ name: 'BackOfficeUpcomingNews' });
                        })
                        .catch((error) => {
                            this.isLoading = false;
                            let message = this.t.api.error;
                            if (Array.isArray(error.response.data)) {
                                error.response.data.forEach((element) => {
                                    this.invalids.push(element.param);
                                });
                            }
                            this.$buefy.toast.open({
                                message: message,
                                position: 'is-bottom',
                                type: 'is-danger',
                            });
                        });
                }
            },
        },
        computed: {
            getFileURL() {
                if (this.form.image) {
                    let url = window.URL.createObjectURL(this.form.image);
                    return url;
                } else if (this.form._image) {
                    return this.form._image;
                }
            },
        },
    };
</script>
<style lang="scss" scoped>
    .img-preview {
        height: 30vh;
    }
</style>
