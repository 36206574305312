<template>
    <section>
        <TopMenu v-if="$route.name === 'EditMetrics'" />
        <div class="container">
            <div class="profile-card">
                <div class="media pt-6">
                    <div class="media-left">
                        <b-icon
                            pack="bl-icons"
                            icon="bl-scale"
                            type="is-white"
                            class="is-size-2">
                        </b-icon>
                    </div>
                    <div class="media-content">
                        <p class="label ml-2">{{ t.measures }}</p>
                        <p class="text mt-5 ml-2">{{ t.metricsDescription }}</p>
                    </div>
                    <div class="media-right">
                        <b-icon
                            v-if="$route.name === 'EditMeasures'"
                            pack="bl-icons"
                            icon="bl-arrow-left"
                            type="is-black"
                            class="is-size-4 arrow">
                        </b-icon>
                    </div>
                </div>
                <div class="metrics-container">
                    <b-radio-button
                        class="metric-button mt-5"
                        v-model="metric"
                        native-value="metrics"
                        rounded
                        >{{ t.metricsTitle }} <br />{{ t.metrics }}</b-radio-button
                    >
                    <b-radio-button
                        class="metric-button mt-5"
                        rounded
                        v-model="metric"
                        native-value="imperial"
                        >{{ t.imperialTitle }}<br />
                        {{ t.imperial }}</b-radio-button
                    >
                </div>
                <p
                    v-if="metricError"
                    class="has-text-secondary has-text-centered mt-2">
                    {{ t.selectMetric }}
                </p>
                <div class="metrics-container">
                    <b-button
                        v-if="$route.name === 'EditMetrics'"
                        type="is-primary"
                        class="edit mt-4"
                        :loading="isLoading"
                        rounded
                        @click="update"
                        >{{ t.save }}</b-button
                    >
                    <b-button
                        v-else
                        type="is-primary"
                        class="edit mt-4"
                        rounded
                        @click="save"
                        :loading="isLoading"
                        >{{ t.save }}</b-button
                    >
                </div>
            </div>
        </div>
    </section>
</template>

<script>
    import TopMenu from '@/components/Menu/TopDefaultMenu.vue';
    import BotMenu from '@/components/Menu/BottomWhiteMenu.vue';

    import { updatePreferences } from '@/api/apiUser';
    import { getLoggedUser } from '@/api/apiAuth';

    export default {
        components: { TopMenu, BotMenu },
        data() {
            return {
                t: this.$t('components.MySpot.MyPreferences'),
                metric: null,
                metricError: false,
                isLoading: false,
            };
        },
        methods: {
            save() {
                this.isLoading = true;
                if (this.metric != null) {
                    this.metricError = false;
                    let data = {
                        info: {
                            meals: this.getMeals,
                            intolerances: this.getIntolerances,
                            metrics: this.metric,
                        },
                        isRegistered: true,
                    };

                    updatePreferences(null, data)
                        .then((response) => {
                            getLoggedUser()
                                .then((response) => {
                                    this.addUser(response.data.body);
                                    this.$store.commit('freeTrial/setFreeTrial', response.data.body.subscription);
                                    if ((response.data.body.subscription.status === 'trialing') === true) {
                                        this.goToPage('FreeTrial');
                                    } else this.goToPage('Homepage');
                                    this.updatePreferenceStep(0);
                                })
                                .catch((error) => {});
                        })
                        .catch((error) => {});
                } else {
                    this.metricError = true;
                    this.isLoading = false;
                }
            },

            update() {
                this.isLoading = true;
                let data = {
                    info: {
                        metrics: this.metric,
                    },
                };
                updatePreferences(null, data)
                    .then((response) => {
                        getLoggedUser()
                            .then((response) => {
                                this.updateMetrics(this.metrics);
                                this.infoReceived = response.data.body;
                                this.addUser(this.infoReceived);
                                this.$buefy.toast.open({
                                    message: this.t.changesSaved,
                                    type: 'is-secondary',
                                });
                                this.goToPage('Profile');
                            })
                            .catch((error) => {});
                    })
                    .catch((error) => {})
                    .finally(() => {
                        this.isLoading = false;
                    });
            },
        },

        created() {
            if (this.$route.name === 'EditMetrics') {
                this.metric = this.getUser.info.metrics;
            }
        },
    };
</script>

<style lang="scss" scoped>
    @import '@/assets/scss/variables';
    @import '~bulma/sass/utilities/mixins.sass';

    .profile-card {
        background-color: $grey-variant;
        height: auto;
        border-radius: 48px;
        padding: 1rem;
        @include from($tablet) {
            width: 70%;
            margin: 0 auto;
        }
        @media screen and (orientation: landscape) and (hover: none) and (pointer: coarse) {
            margin-bottom: 30px;
        }
    }

    .container {
        padding-top: 140px;
    }

    .label {
        font-family: 'Lota Grotesque';
        font-size: 0.875rem;
        font-weight: bold;
    }

    .media {
        padding-left: 8%;
        padding-right: 6%;
    }
    .arrow {
        transform: translate(0, -23px);
    }

    .metrics-container {
        display: inline-flex;
        justify-content: space-between;
        width: 100%;
        padding: 0 6%;
    }

    .text {
        font-family: 'Lota Grotesque';
        font-size: 0.8rem;
    }
</style>
