import { get, post, put, remove } from '@/utils/http';
const queryString = require('query-string');

export function syncGroceryList(data) {
    return post('/grocerylists', data);
}

export function getGroceryList() {
    return get('/grocerylists');
}

export function checkIngredient(data) {
    return put('/grocerylists', data);
}

export function clearList() {
    return remove('/grocerylists');
}

export function addIngredient(data) {
    return post('/grocerylists/ingredient', data);
}
export function addOtherIngredient(data) {
    return post('/grocerylists/other', data);
}

export function checkOtherIngredient(data) {
    return put('/grocerylists/other', data);
}
